import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import icons from './_coopeuch-icon.scss'
import FilledLineModel from './models/icon-filled-line-list.model'
import IconTypesList from './models/icon-types.model'

const GetPathsForIcons = (n) => {
  const paths = [];

  for (let i = 0; i < n; i++) {
    paths.push(<i key={`path_${i}`} className={icons[`path${i + 1}`]}></i>)
  }

  return paths;
}


const Icon = ({ name, size, className, onClick }) => {
  const [pathInfo, setPathInfo] = useState({})
  const [iconPaths, setIconPaths] = useState('')

  const icon = icons[`icon-${name}`]
  const sizeWith = size > 0 && size <= 8 ? size : '1'
  const sizeSelected = icons[`size-${size}`] || icons[`size-1`]
  const type = IconTypesList.find(type => type.indexOf(name.split('-')[0]) >= 0);
  let renderStyles = ['coopeuch-icon', type]

  if (className) renderStyles = [...renderStyles].concat(className)

  if (icon) renderStyles = [...renderStyles].concat(icon)

  if (sizeSelected) renderStyles = [...renderStyles].concat(sizeSelected)

  useEffect(() => {
    if(name.indexOf('filled') >= 0) setPathInfo(FilledLineModel.getPathsForText(name))
  }, [name])

  useEffect(() => {
    setIconPaths(GetPathsForIcons(pathInfo.paths));
  }, [pathInfo])

  return (
    <i
      aria-hidden='true'
      className={renderStyles.join(' ')}
      data-size={sizeWith}
      onClick={onClick}
    >
      { iconPaths && iconPaths.map(path => ( path )) }
    </i>
  )
}

Icon.defaultProps = {
  name: 'alarm',
  size: 2,
  onClick: () => {}
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default Icon
