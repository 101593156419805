import React from 'react'

const INFODESKTOP = () => {
  const elm = (
    <svg
      width='164px'
      height='160px'
      viewBox='0 0 164 160'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <path
          d='M8.55542857,0.750628571 C4.32022857,0.904228571 0.903428571,4.32022857 0.750628571,8.55622857 L0.750628571,8.55622857 C0.744228571,8.71782857 0.874628571,8.85462857 1.03782857,8.85462857 L1.03782857,8.85462857 L7.13622857,8.85462857 C8.08502857,8.85462857 8.85382857,8.08582857 8.85382857,7.13622857 L8.85382857,7.13622857 L8.85382857,1.03782857 C8.85382857,0.878628571 8.72502857,0.750628571 8.56662857,0.750628571 L8.56662857,0.750628571 C8.56262857,0.750628571 8.55942857,0.750628571 8.55542857,0.750628571 L8.55542857,0.750628571 Z'
          id='path-1'
        ></path>
        <path
          d='M8.55542857,0.750628571 C4.32022857,0.904228571 0.903428571,4.32022857 0.750628571,8.55622857 L0.750628571,8.55622857 C0.744228571,8.71782857 0.874628571,8.85462857 1.03782857,8.85462857 L1.03782857,8.85462857 L7.13622857,8.85462857 C8.08502857,8.85462857 8.85382857,8.08582857 8.85382857,7.13622857 L8.85382857,7.13622857 L8.85382857,1.03782857 C8.85382857,0.878628571 8.72502857,0.750628571 8.56662857,0.750628571 L8.56662857,0.750628571 C8.56262857,0.750628571 8.55942857,0.750628571 8.55542857,0.750628571 L8.55542857,0.750628571 Z'
          id='path-3'
        ></path>
        <path
          d='M-0.326971429,-5.73257143 C-3.31337143,-5.73257143 -5.73337143,-3.31257143 -5.73337143,-0.326971429 L-5.73337143,-0.326971429 L-5.73337143,7.15782857 C-5.73337143,8.09462857 -4.97337143,8.85462857 -4.03657143,8.85462857 L-4.03657143,8.85462857 L-3.02617143,8.85462857 C-2.87257143,8.85462857 -2.74857143,8.73062857 -2.74697143,8.57702857 L-2.74697143,8.57702857 C-2.60217143,2.39702857 2.39862857,-2.60377143 8.57942857,-2.74697143 L8.57942857,-2.74697143 C8.73142857,-2.74937143 8.85382857,-2.87417143 8.85382857,-3.02537143 L8.85382857,-3.02537143 L8.85382857,-4.03737143 C8.85382857,-4.97417143 8.09462857,-5.73257143 7.15782857,-5.73257143 L7.15782857,-5.73257143 L-0.326971429,-5.73257143 Z'
          id='path-5'
        ></path>
      </defs>
      <g
        id='MENSAJERIAS'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='DESKTOP-INFO' transform='translate(-156.000000, -40.000000)'>
          <g id='INFO-DESKTOP' transform='translate(156.000000, 40.000000)'>
            <g transform='translate(10.000000, 8.000000)'>
              <g id='INFO-RESPONSIVE'>
                <g id='ROSTRO-Y-CUERPO'>
                  <path
                    d='M144,72 C144,111.7648 111.764,144 72,144 C32.2352,144 0,111.7648 0,72 C0,32.2352 32.2352,0 72,0 C111.764,0 144,32.2352 144,72'
                    id='Fill-1'
                    fill='#C8E8FB'
                  ></path>
                  <path
                    d='M111.14144,102.21328 L32.85824,102.21328 L32.85824,86.66768 C32.85824,68.84688 47.30464,54.39968 65.12544,54.39968 L78.87344,54.39968 C96.69424,54.39968 111.14144,68.84688 111.14144,86.66768 L111.14144,102.21328 Z'
                    id='Fill-3'
                    fill='#367BB2'
                  ></path>
                  <path
                    d='M72.06408,65.34296 C78.14328,65.34296 83.50008,61.38056 86.67368,55.35496 C84.17528,54.73416 81.56408,54.39976 78.87368,54.39976 L65.12568,54.39976 C62.48088,54.39976 59.91208,54.72536 57.45048,55.32616 C60.62088,61.36456 65.97848,65.34296 72.06408,65.34296'
                    id='Fill-5'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M46.63832,140.79992 L46.22632,140.79992 C38.84392,140.79992 32.85752,134.81512 32.85752,127.43112 L32.85752,89.59992 L46.63832,89.59992 L46.63832,140.79992 Z'
                    id='Fill-7'
                    fill='#F4B6A8'
                  ></path>
                  <polygon
                    id='Fill-9'
                    fill='#F4B6A8'
                    points='80.4 58.4 63.6 58.4 66 36.8 78 36.8'
                  ></polygon>
                  <line
                    x1='72.46568'
                    y1='27.67464'
                    x2='71.53448'
                    y2='27.67464'
                    id='Fill-11'
                    fill='#282E5E'
                  ></line>
                  <path
                    d='M72.47112,49.27464 L71.52792,49.27464 C65.82392,49.27464 61.19992,44.65064 61.19992,38.94584 L61.19992,18.07464 C61.19992,14.98184 63.70712,12.47464 66.79992,12.47464 L77.19992,12.47464 C80.29272,12.47464 82.79992,14.98184 82.79992,18.07464 L82.79992,38.94584 C82.79992,44.65064 78.17592,49.27464 72.47112,49.27464'
                    id='Fill-13'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M62.22656,35.33672 L56.04416,31.70632 C54.67856,30.90472 54.22176,29.14712 55.02416,27.78152 C55.82576,26.41592 57.58336,25.95912 58.94896,26.76072 L65.13056,30.39112 L62.22656,35.33672 Z'
                    id='Fill-23'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M81.77344,35.33632 L87.95584,31.70512 C89.32064,30.90352 89.77744,29.14672 88.97584,27.78112 C88.17344,26.41552 86.41664,25.95792 85.05104,26.76032 L78.86864,30.39072 L81.77344,35.33632 Z'
                    id='Fill-27'
                    fill='#F4B6A8'
                  ></path>
                  <polygon
                    id='Fill-35'
                    fill='#282E5E'
                    points='61.2 17.6 61.2 28 63.7896 17.6'
                  ></polygon>
                  <polygon
                    id='Fill-37'
                    fill='#282E5E'
                    points='82.8 17.6 82.8 28 80.2096 17.6'
                  ></polygon>
                  <path
                    d='M77.2,12.47464 L66.8,12.47464 C63.7072,12.47464 61.2,14.98184 61.2,18.07464 L82.8,18.07464 C82.8,14.98184 80.2928,12.47464 77.2,12.47464'
                    id='Fill-39'
                    fill='#282E5E'
                  ></path>
                  <path
                    d='M75.67976,21.31208 C72.84456,21.52728 68.79336,20.95368 63.14696,18.46728 L62.99016,18.45048 C62.99016,18.43368 62.99256,18.41768 62.99256,18.40088 L62.99016,18.40008 L62.99336,18.39688 C63.02216,15.32568 65.51176,12.84008 68.59016,12.82728 L74.96296,12.80008 L74.97656,12.02888 C74.99256,11.15208 75.70696,10.45048 76.58296,10.45048 C77.47016,10.45048 78.19016,11.17048 78.19016,12.05768 L78.19016,12.05048 C78.19016,11.16648 78.90616,10.45048 79.79016,10.45048 C80.67336,10.45048 81.39016,11.16648 81.39016,12.05048 C81.39016,11.16648 82.10616,10.45048 82.99016,10.45048 C83.87336,10.45048 84.59016,11.16648 84.59016,12.05048 L84.59016,13.07128 C84.59016,13.07128 84.59016,20.00008 75.67976,21.31208'
                    id='Fill-41'
                    fill='#282E5E'
                  ></path>
                  <path
                    d='M112.636,47.91488 L104.8,44.83488 L104.3744,47.09568 L103.7504,39.21888 L101.8864,39.24848 L101.232,48.50448 L99.2144,50.58848 L101.7824,57.49808 L98.0336,96.97888 C97.4128,101.12048 101.0312,104.79968 105.7256,104.79968 C110.3736,104.79968 113.9792,101.18848 113.4312,97.08048 L110.7224,58.20528 L112.636,47.91488 Z'
                    id='Fill-43'
                    fill='#F4B6A8'
                  ></path>
                  <polygon
                    id='Fill-45'
                    fill='#EF8674'
                    points='110.49304 52.78832 103.50344 51.47072 104.17544 47.15072 104.83304 47.25232 104.26104 50.93552 110.61544 52.13472'
                  ></polygon>
                  <path
                    d='M106.3852,137.71864 L37.614,137.71864 L37.614,99.64504 C37.614,96.77384 39.942,94.44664 42.8132,94.44664 L101.1868,94.44664 C104.058,94.44664 106.3852,96.77384 106.3852,99.64504 L106.3852,137.71864 Z'
                    id='Fill-47'
                    fill='#F6F3F5'
                  ></path>
                  <path
                    d='M120.15704,143.99992 L23.84184,143.99992 C23.84184,140.53112 26.65464,137.71832 30.12344,137.71832 L113.87544,137.71832 C117.34504,137.71832 120.15704,140.53112 120.15704,143.99992'
                    id='Fill-49'
                    fill='#367BB2'
                  ></path>
                  <polygon
                    id='Fill-51'
                    fill='#D4D0D5'
                    points='37.61424 137.71848 106.38544 137.71848 106.38544 133.73928 37.61424 133.73928'
                  ></polygon>
                  <path
                    d='M41.82504,135.7288 C41.82504,136.4152 41.30184,136.9728 40.65544,136.9728 C40.00904,136.9728 39.48584,136.4152 39.48584,135.7288 C39.48584,135.0424 40.00904,134.4856 40.65544,134.4856 C41.30184,134.4856 41.82504,135.0424 41.82504,135.7288'
                    id='Fill-53'
                    fill='#9C9A9F'
                  ></path>
                  <path
                    d='M46.0352,135.7288 C46.0352,136.4152 45.512,136.9728 44.8656,136.9728 C44.2192,136.9728 43.696,136.4152 43.696,135.7288 C43.696,135.0424 44.2192,134.4856 44.8656,134.4856 C45.512,134.4856 46.0352,135.0424 46.0352,135.7288'
                    id='Fill-54'
                    fill='#9C9A9F'
                  ></path>
                  <path
                    d='M78.99536,115.27256 C74.76016,115.42616 71.34336,118.84296 71.18976,123.07816 C71.18336,123.24056 71.31376,123.37656 71.47776,123.37656 L77.57616,123.37656 C78.52496,123.37656 79.29376,122.60776 79.29376,121.65816 L79.29376,115.55976 C79.29376,115.39656 79.15856,115.26616 78.99536,115.27256'
                    id='Fill-55'
                    fill='#D4D0D5'
                  ></path>
                  <g id='LOGO' transform='translate(70.439611, 114.522251)'>
                    <mask id='mask-2' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-58'></g>
                    <mask id='mask-4' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-61'></g>
                    <polygon
                      id='Fill-60'
                      fill='#D4D0D5'
                      mask='url(#mask-4)'
                      points='-1.74537143 11.3482286 11.3482286 11.3482286 11.3482286 -1.74537143 -1.74537143 -1.74537143'
                    ></polygon>
                    <path
                      d='M7.15806857,-5.73329143 L-0.326731429,-5.73329143 C-3.31313143,-5.73329143 -5.73313143,-3.31329143 -5.73313143,-0.326891429 L-5.73313143,7.15790857 C-5.73313143,8.09470857 -4.97393143,8.85390857 -4.03713143,8.85390857 L-3.02593143,8.85390857 C-2.87233143,8.85390857 -2.74913143,8.73070857 -2.74753143,8.57790857 C-2.60193143,2.39710857 2.39886857,-2.60369143 8.57966857,-2.74769143 C8.73166857,-2.75009143 8.85406857,-2.87409143 8.85406857,-3.02529143 L8.85406857,-4.03729143 C8.85406857,-4.97409143 8.09486857,-5.73329143 7.15806857,-5.73329143'
                      id='Fill-63'
                      fill='#D4D0D5'
                      mask='url(#mask-4)'
                    ></path>
                    <mask id='mask-6' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-66'></g>
                    <polygon
                      id='Fill-67'
                      fill='#D4D0D5'
                      mask='url(#mask-6)'
                      points='-8.22857143 11.3490286 11.3490286 11.3490286 11.3490286 -8.22857143 -8.22857143 -8.22857143'
                    ></polygon>
                  </g>
                </g>
                <g id='EXPRESIONES' transform='translate(56.258643, 23.981060)'>
                  <path
                    d='M18.3913571,13.2942998 L13.5865571,13.2878998 C13.3665571,13.2870998 13.1873571,13.1078998 13.1873571,12.8870998 C13.1873571,12.6662998 13.3673571,12.4878998 13.5873571,12.4878998 L13.5881571,12.4878998 L17.3993571,12.4926998 L15.4377571,3.46789983 C15.3905571,3.25189983 15.5273571,3.03909983 15.7425571,2.99189983 C15.9617571,2.94629983 16.1721571,3.08229983 16.2185571,3.29829983 L18.3913571,13.2942998 Z'
                    id='Fill-15'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M15.9773571,17.8369398 C13.6589571,17.8369398 12.3773571,16.8793398 12.3045571,16.8233398 C12.1293571,16.6881398 12.0965571,16.4369398 12.2309571,16.2625398 C12.3661571,16.0865398 12.6165571,16.0545398 12.7917571,16.1881398 C12.8853571,16.2593398 14.9901571,17.7945398 18.8109571,16.5601398 C19.0205571,16.4921398 19.2461571,16.6081398 19.3141571,16.8177398 C19.3821571,17.0273398 19.2669571,17.2537398 19.0565571,17.3209398 C17.8869571,17.6993398 16.8573571,17.8369398 15.9773571,17.8369398'
                    id='Fill-17'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M7.94999715,1.61813983 C7.81399715,1.61813983 7.68039715,1.54853983 7.60519715,1.42213983 C7.49319715,1.23173983 7.55639715,0.986139826 7.74599715,0.874139826 C10.8267971,-0.949860174 13.8355971,0.635739826 13.9619971,0.704539826 C14.1563971,0.810139826 14.2283971,1.05173983 14.1235971,1.24613983 C14.0195971,1.44053983 13.7763971,1.51253983 13.5819971,1.40933983 C13.5547971,1.39413983 10.8355971,-0.0274601742 8.15399715,1.56213983 C8.08999715,1.60053983 8.01959715,1.61813983 7.94999715,1.61813983'
                    id='Fill-19'
                    fill='#282E5E'
                  ></path>
                  <path
                    d='M23.5319971,1.61813983 C23.4623971,1.61813983 23.3919971,1.60053983 23.3279971,1.56213983 C20.6447971,-0.0258601742 17.9263971,1.39493983 17.8999971,1.40933983 C17.7063971,1.51253983 17.4631971,1.44053983 17.3583971,1.24613983 C17.2535971,1.05173983 17.3255971,0.810139826 17.5199971,0.704539826 C17.6471971,0.636539826 20.6559971,-0.949860174 23.7359971,0.874139826 C23.9255971,0.986139826 23.9887971,1.23173983 23.8767971,1.42213983 C23.8015971,1.54853983 23.6679971,1.61813983 23.5319971,1.61813983'
                    id='Fill-21'
                    fill='#282E5E'
                  ></path>
                  <path
                    d='M4.02735715,7.17677983 C3.96975715,7.17677983 3.91135715,7.16477983 3.85615715,7.13837983 L0.228957147,5.42317983 C0.0289571466,5.32797983 -0.0558428534,5.08957983 0.0385571466,4.89037983 C0.133757147,4.68957983 0.372157147,4.60557983 0.571357147,4.69917983 L4.19855715,6.41517983 C4.39855715,6.50957983 4.48335715,6.74797983 4.38895715,6.94797983 C4.32015715,7.09277983 4.17695715,7.17677983 4.02735715,7.17677983'
                    id='Fill-25'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M27.4554371,7.17637983 C27.3058371,7.17637983 27.1626371,7.09237983 27.0938371,6.94757983 C26.9994371,6.74757983 27.0842371,6.50917983 27.2842371,6.41477983 L30.9106371,4.69877983 C31.1082371,4.60357983 31.3474371,4.68837983 31.4434371,4.88917983 C31.5386371,5.08917983 31.4530371,5.32757983 31.2530371,5.42197983 L27.6266371,7.13797983 C27.5714371,7.16437983 27.5130371,7.17637983 27.4554371,7.17637983'
                    id='Fill-29'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M20.6211171,5.61893983 L20.6211171,5.61893983 C19.9587171,5.61893983 19.4211171,5.08213983 19.4211171,4.41893983 C19.4211171,3.75573983 19.9587171,3.21893983 20.6211171,3.21893983 C21.2843171,3.21893983 21.8211171,3.75573983 21.8211171,4.41893983 C21.8211171,5.08213983 21.2843171,5.61893983 20.6211171,5.61893983'
                    id='Fill-31'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M10.8609571,5.61893983 L10.8609571,5.61893983 C10.1985571,5.61893983 9.66095715,5.08213983 9.66095715,4.41893983 C9.66095715,3.75573983 10.1985571,3.21893983 10.8609571,3.21893983 C11.5241571,3.21893983 12.0609571,3.75573983 12.0609571,4.41893983 C12.0609571,5.08213983 11.5241571,5.61893983 10.8609571,5.61893983'
                    id='Fill-33'
                    fill='#1F1E1E'
                  ></path>
                </g>
              </g>
              <g id='INFO' transform='translate(108.000000, 8.000000)'>
                <circle
                  id='Oval'
                  fill='#FFFFFF'
                  cx='14'
                  cy='14'
                  r='12'
                ></circle>
                <g
                  id='Group'
                  transform='translate(0.000000, 0.000000)'
                  fill='#005D8F'
                >
                  <g
                    id='00.Token/Icons/Solid/32x32/information'
                    transform='translate(0.000000, 0.000000)'
                  >
                    <path
                      d='M13.8541668,0.875 C6.68966673,0.875 0.875,6.68966673 0.875,13.8541668 C0.875,21.0186669 6.68966673,26.8333336 13.8541668,26.8333336 C21.0186669,26.8333336 26.8333336,21.0186669 26.8333336,13.8541668 C26.8333336,6.68966673 21.0186669,0.875 13.8541668,0.875 Z M13.8541668,20.3437502 C13.1403126,20.3437502 12.5562501,19.7596877 12.5562501,19.0458335 L12.5562501,13.8541668 C12.5562501,13.1403126 13.1403126,12.5562501 13.8541668,12.5562501 C14.568021,12.5562501 15.1520835,13.1403126 15.1520835,13.8541668 L15.1520835,19.0458335 C15.1520835,19.7596877 14.568021,20.3437502 13.8541668,20.3437502 Z M13.8541668,9.96041677 C13.1373472,9.96041677 12.5562501,9.37931968 12.5562501,8.66250009 C12.5562501,7.9456805 13.1373472,7.36458341 13.8541668,7.36458341 C14.5709864,7.36458341 15.1520835,7.9456805 15.1520835,8.66250009 C15.1520835,9.37931968 14.5709864,9.96041677 13.8541668,9.96041677 Z'
                      id='color-icono'
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default INFODESKTOP
