import React from 'react'
import PropTypes from 'prop-types'
import styles from './_actionLink.scss'
import Icon from '../icons'
import IconListModel from '../icons/models/icon-list.model'

const ActionLink = ({ icon, label, onClick }) => (
  <div
    role='button'
    className={styles.actionLink}
    onClick={onClick}
    onKeyDown={onClick}
    tabIndex={0}
  >
    {icon && (
      <div className={styles.iconContainer}>
        <Icon name={icon} className={styles.iconSize} size='6' />
      </div>
    )}
    <div className={styles.label}>
      <p className='note'>{label}</p>
    </div>
  </div>
)

ActionLink.defaultProps = {
  icon: '',
  label: ''
}

ActionLink.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.oneOf(IconListModel.iconList),
  onClick: PropTypes.func
}

export default ActionLink
