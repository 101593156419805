import React from 'react'
import PropTypes from 'prop-types'
import styles from './_title.scss'
import Icon from '../icons'
import IconListModel from '../icons/models/icon-list.model'

const TitleSection = ({ label, prefix }) => {
  const containerClass = [styles.titleContainer]

  return (
    <div className={containerClass.join(' ')}>
      {prefix && <Icon className={styles.suffix} name={prefix} size='3' />}{' '}
      <div className={styles.title} data-testid='title-section-label'>
        {label}
      </div>
    </div>
  )
}

export default TitleSection

TitleSection.defaultProps = {}

TitleSection.propTypes = {
  prefix: PropTypes.oneOf(IconListModel.iconList).isRequired,
  label: PropTypes.string.isRequired
}
