import React from 'react'
import PropTypes from 'prop-types'
import styles from './_columncardimage.scss'

const ColumnCardImage = ({ imgUrl, className }) => {
  const containerStyles = [styles.columnCardImageContainer]

  return (
    <div
      data-testid='data-test-column-card-image'
      style={{ backgroundImage: `url(${imgUrl})` }}
      className={containerStyles.concat(className).join(' ')}
    />
  )
}

ColumnCardImage.propTypes = {
  imgUrl: PropTypes.string,
  className: PropTypes.string
}

export default ColumnCardImage
