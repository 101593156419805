import React from 'react'
import PropTypes from 'prop-types'
import MobileStepper from './mobileStepper'
import DesktopStepper from './desktopStepper'

const Stepper = ({ steps, currentStep, oldMobile, color }) => (
  <div>
    <MobileStepper steps={steps} currentStep={currentStep} oldMobile={oldMobile} color={color} />
    
    <DesktopStepper steps={steps} currentStep={currentStep} color={color} />
  </div>
)

Stepper.defaultProps = {
  oldMobile: false,
  currentStep: 1,
  color: '#e81e2b'
}

Stepper.propTypes = {
  oldMobile: PropTypes.bool,
  color: PropTypes.string,
  currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
}

export default Stepper
