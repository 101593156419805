import React from 'react'

const CoopeuchGris = () => {
  const elm = (
    <svg
      width='170px'
      height='29px'
      viewBox='0 0 170 29'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M28.4114513,13.0003641 C20.0498491,13.3047097 13.3039052,20.0503078 13.0004211,28.4114815 C12.9881414,28.7324916 13.2460152,29 13.5679189,29 L25.60817,29 C27.4817021,29 29,27.48178 29,25.6083438 L29,13.5687099 C29,13.2468227 28.733355,12.988962 28.4114513,13.0003641'
          fill='#646569'
        ></path>
        <path
          d='M25.6283936,0 L10.7478128,0 C4.81197198,0 0,4.81197198 0,10.7478127 L0,25.6283936 C0,27.4907549 1.51011695,29 3.37160639,29 L5.38218935,29 C5.68735155,29 5.93409699,28.7541265 5.93584077,28.4498361 C6.22530892,16.163134 16.1674935,6.22269324 28.4541956,5.93584077 C28.7567421,5.93148131 29,5.68560777 29,5.38306124 L29,3.37247828 C29,1.51011695 27.4907549,0 25.6283936,0'
          fill='#646569'
        ></path>
        <path
          d='M44.6655194,18.3641295 C43.5283897,18.3641295 42.5960137,17.9949751 41.8675125,17.2849312 C41.1390114,16.545766 40.7602611,15.6087477 40.7602611,14.5004283 C40.7602611,12.2281163 42.4211383,10.6084623 44.6655194,10.6084623 C45.9116167,10.6084623 46.9942625,11.1275053 47.6550983,11.9814424 C47.8730335,12.2640895 48.2658442,12.3531663 48.5786866,12.1750128 L50.6156778,11.0127334 C50.9663074,10.8123109 51.0673661,10.3643579 50.8397644,10.0363158 C49.5391833,8.15885342 47.2473486,7 44.6945188,7 C42.5090154,7 40.672384,7.71090048 39.1855035,9.15925314 C37.7285012,10.6084623 37,12.3694399 37,14.5004283 C37,16.6305601 37.7285012,18.3923942 39.1855035,19.8416034 C40.672384,21.289956 42.5090154,22 44.6945188,22 C47.2447123,22 49.5093051,20.8223034 50.8705214,18.9439844 C51.1095471,18.6142294 51.0076097,18.1542854 50.6473136,17.9538628 L48.604171,16.8164221 C48.2930861,16.6425513 47.9055481,16.7324844 47.6884916,17.0108491 C47.0276558,17.8622167 45.9397373,18.3641295 44.6655194,18.3641295'
          fill='#646569'
        ></path>
        <path
          d='M110.659202,7 C108.425122,7 106.568055,7.71004397 105.146606,9.15925312 C103.725157,10.5793411 103,12.3694398 103,14.4995717 C103,16.6588249 103.725157,18.4489237 105.175472,19.8690116 C106.626662,21.2899561 108.541463,22 110.949615,22 C113.525717,22 115.530616,21.1246503 116.942442,19.397933 C117.126137,19.1735283 117.060532,18.8394907 116.805983,18.6955976 L114.780966,17.5513048 C114.4993,17.3919946 114.138034,17.4245418 113.904479,17.6472335 C113.193317,18.3272998 112.216235,18.6758979 111.007348,18.6758979 C108.773268,18.6758979 107.409552,17.7954091 106.9162,16.0053104 L116.475332,16.0053104 C117.31683,16.0053104 117.999125,15.3380917 118,14.5141324 L118,14.4995717 C118,12.3694398 117.303709,10.6076058 115.911127,9.15925312 C114.547411,7.71004397 112.777817,7 110.659202,7 Z M106.858467,13.164278 C107.294087,11.346771 108.657803,10.2949809 110.659202,10.2949809 C112.371938,10.2949809 113.822253,11.2328556 114.257873,13.164278 L106.858467,13.164278 Z'
          fill='#646569'
        ></path>
        <path
          d='M132.342133,14.5035482 C132.342133,15.6398581 131.9736,16.5765819 131.236534,17.3146067 C130.499468,18.0526316 129.591425,18.421644 128.485826,18.421644 C127.380227,18.421644 126.472183,18.0526316 125.735117,17.3146067 C125.0264,16.5765819 124.657867,15.6398581 124.657867,14.5035482 L124.657867,7.59254879 C124.657867,7.26522768 124.392984,7 124.066088,7 L121.68214,7 C121.304748,7 121,7.30603194 121,7.6830278 L121,14.5035482 C121,16.6049675 121.708717,18.3932584 123.1545,19.8418096 C124.601169,21.2894737 126.387137,22 128.485826,22 C130.584515,22 132.370482,21.2894737 133.817151,19.8418096 C135.262934,18.3932584 136,16.6049675 136,14.5035482 L136,7.6830278 C136,7.30603194 135.694366,7 135.31786,7 L133.024274,7 C132.647768,7 132.342133,7.30603194 132.342133,7.6830278 L132.342133,14.5035482 Z'
          fill='#646569'
        ></path>
        <path
          d='M166.365053,7.62606473 L166.365053,12.5255537 C164.147735,12.9216354 161.840452,12.9045997 159.634947,12.4744464 L159.634947,7.62606473 C159.634947,7.2802385 159.335973,7 158.967026,7 L156.667922,7 C156.298974,7 156,7.2802385 156,7.62606473 L156,20.3739352 C156,20.7197614 156.298974,21 156.667922,21 L158.967026,21 C159.335973,21 159.634947,20.7197614 159.634947,20.3739352 L159.634947,15.979557 C160.788134,16.1643952 161.958588,16.2683134 163.139037,16.2683134 C164.22316,16.2683134 165.300013,16.1805792 166.365053,16.0238501 L166.365053,20.3739352 C166.365053,20.7197614 166.664027,21 167.032974,21 L169.332078,21 C169.701026,21 170,20.7197614 170,20.3739352 L170,7.62606473 C170,7.2802385 169.701026,7 169.332078,7 L167.032974,7 C166.664027,7 166.365053,7.2802385 166.365053,7.62606473'
          fill='#646569'
        ></path>
        <path
          d='M101.814389,10.8307355 C101.354633,8.63833418 99.3567989,7 96.8711321,7 L89.6290939,7 C89.2816446,7 89,7.27447638 89,7.61203118 L89,20.3879689 C89,20.726376 89.2816446,21 89.6290939,21 L91.9463005,21 C92.2946272,21 92.5762718,20.726376 92.5762718,20.3879689 L92.5762718,18.406113 L92.5762718,17.3303702 L96.435943,17.3303702 C98.8391344,17.3303702 101.033505,15.8659279 101.761745,13.6402825 C102.089014,12.6395519 102.052164,11.6950803 101.814389,10.8307355 M96.0376045,14.2693619 L92.4946738,14.2693619 L92.4946738,9.91865559 L96.0376045,9.91865559 C97.2738575,9.91865559 98.2767227,10.8921091 98.2767227,12.0940088 C98.2767227,13.2959085 97.2738575,14.2693619 96.0376045,14.2693619'
          fill='#646569'
        ></path>
        <path
          d='M60.4861075,7 C58.3872788,7 56.6013508,7.73916519 55.154826,9.18751788 C53.7087287,10.6358705 53,12.3977046 53,14.4995717 C53,16.6022955 53.7087287,18.3923942 55.154826,19.8407469 C56.6013508,21.289956 58.3872788,22 60.4861075,22 C62.5840814,22 64.3708643,21.289956 65.8165342,19.8407469 C67.2630589,18.3923942 68,16.6022955 68,14.4995717 C68,12.3977046 67.2630589,10.6358705 65.8165342,9.18751788 C64.3708643,7.73916519 62.5840814,7 60.4861075,7 M63.2363854,17.3123394 C62.4994443,18.0515046 61.5915192,18.4198024 60.4861075,18.4198024 C59.379841,18.4198024 58.4727708,18.0515046 57.7358297,17.3123394 C57.026246,16.5740307 56.6577755,15.636156 56.6577755,14.4995717 C56.6577755,13.363844 57.026246,12.4259693 57.7358297,11.6876606 C58.4727708,10.9493519 59.379841,10.579341 60.4861075,10.579341 C61.5915192,10.579341 62.4994443,10.9493519 63.2363854,11.6876606 C63.9733265,12.4259693 64.342652,13.363844 64.342652,14.4995717 C64.342652,15.636156 63.9733265,16.5740307 63.2363854,17.3123394'
          fill='#646569'
        ></path>
        <path
          d='M77.4858934,7 C75.3878598,7 73.6010259,7.73916519 72.15446,9.18751788 C70.7087489,10.6358705 70,12.3977046 70,14.4995717 C70,16.6022955 70.7087489,18.3923942 72.15446,19.8407469 C73.6010259,21.289956 75.3878598,22 77.4858934,22 C79.583927,22 81.3707609,21.289956 82.8164719,19.8407469 C84.2630379,18.3923942 85,16.6022955 85,14.4995717 C85,12.3977046 84.2630379,10.6358705 82.8164719,9.18751788 C81.3707609,7.73916519 79.583927,7 77.4858934,7 M80.2362496,17.3123394 C79.4992875,18.0515046 78.5913366,18.4198024 77.4858934,18.4198024 C76.3795953,18.4198024 75.4724993,18.0515046 74.7355372,17.3123394 C74.0267883,16.5740307 73.6574523,15.636156 73.6574523,14.4995717 C73.6574523,13.363844 74.0267883,12.4259693 74.7355372,11.6876606 C75.4724993,10.9493519 76.3795953,10.579341 77.4858934,10.579341 C78.5913366,10.579341 79.4992875,10.9493519 80.2362496,11.6876606 C80.9732117,12.4259693 81.3425477,13.363844 81.3425477,14.4995717 C81.3425477,15.636156 80.9732117,16.5740307 80.2362496,17.3123394'
          fill='#646569'
        ></path>
        <path
          d='M146.665519,18.3639219 C145.52839,18.3639219 144.596014,17.9947464 143.867513,17.2838054 C143.139011,16.5454545 142.760261,15.6075263 142.760261,14.5 C142.760261,12.2275583 144.421138,10.6078118 146.665519,10.6078118 C147.911617,10.6078118 148.994263,11.1268844 149.655098,11.9808703 C149.873034,12.2635336 150.265844,12.3526154 150.578687,12.1744518 L152.615678,11.0121059 C152.966307,10.811672 153.067366,10.3636935 152.839764,10.0356327 C151.539183,8.15806304 149.247349,7 146.694519,7 C144.508137,7 142.672384,7.71008451 141.185503,9.15851988 C139.728501,10.6078118 139,12.3688899 139,14.5 C139,16.6302535 139.728501,18.3921882 141.185503,19.8414801 C142.672384,21.2899155 144.508137,22 146.694519,22 C149.243834,22 151.509305,20.8222362 152.870521,18.9446666 C153.109547,18.6140361 153.00761,18.1540658 152.647314,17.9536318 L150.604171,16.8152695 C150.293086,16.6422453 149.905548,16.7313271 149.688492,17.0105642 C149.027656,17.8619804 147.939737,18.3639219 146.665519,18.3639219'
          fill='#646569'
        ></path>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default CoopeuchGris
