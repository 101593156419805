export default class FilledLineModel {
  static iconList = [
    [
      'filled-line-d-glasses',
      'filled-line-d-glasses',
      'filled-line-d-glasses',
      'filled-line-d-glasses',
      'filled-line-d-glasses',
      'filled-line-d-hologram',
      'filled-line-d-hologram',
      'filled-line-d-hologram',
      'filled-line-accounting',
      'filled-line-accounting',
      'filled-line-accounting',
      'filled-line-accounting',
      'filled-line-action-cam',
      'filled-line-action-cam',
      'filled-line-action-cam',
      'filled-line-action-cam',
      'filled-line-activity-log',
      'filled-line-activity-log',
      'filled-line-activity-log',
      'filled-line-activity-log',
      'filled-line-activity-log',
      'filled-line-activity-log',
      'filled-line-activity-log',
      'filled-line-add-contact',
      'filled-line-add-contact',
      'filled-line-add-contact',
      'filled-line-add-friend',
      'filled-line-add-friend',
      'filled-line-add-friend',
      'filled-line-add-friend',
      'filled-line-ads-manager',
      'filled-line-ads-manager',
      'filled-line-ads-manager',
      'filled-line-ads-manager',
      'filled-line-ads-manager',
      'filled-line-ads-manager',
      'filled-line-advertiser',
      'filled-line-advertiser',
      'filled-line-advertising',
      'filled-line-advertising',
      'filled-line-advertising',
      'filled-line-advertising',
      'filled-line-advertising',
      'filled-line-advertising',
      'filled-line-affiliate',
      'filled-line-affiliate',
      'filled-line-affiliate',
      'filled-line-affiliate',
      'filled-line-affiliate',
      'filled-line-affiliate',
      'filled-line-airplane',
      'filled-line-airplane',
      'filled-line-alarm',
      'filled-line-alarm',
      'filled-line-alarm',
      'filled-line-alarm',
      'filled-line-alarm',
      'filled-line-alert',
      'filled-line-alert',
      'filled-line-alert',
      'filled-line-alert',
      'filled-line-align-center',
      'filled-line-align-center',
      'filled-line-align-center',
      'filled-line-align-center',
      'filled-line-align-center',
      'filled-line-align-left',
      'filled-line-align-left',
      'filled-line-align-left',
      'filled-line-align-left',
      'filled-line-align-left',
      'filled-line-align-right',
      'filled-line-align-right',
      'filled-line-align-right',
      'filled-line-align-right',
      'filled-line-align-right',
      'filled-line-all-inboxes',
      'filled-line-all-inboxes',
      'filled-line-all-inboxes',
      'filled-line-all-inboxes',
      'filled-line-ambulance',
      'filled-line-ambulance',
      'filled-line-ambulance',
      'filled-line-american-football',
      'filled-line-american-football',
      'filled-line-american-football',
      'filled-line-antenna-alt',
      'filled-line-antenna-alt',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-antenna',
      'filled-line-app-notification',
      'filled-line-app-notification',
      'filled-line-app-notification',
      'filled-line-app-shop',
      'filled-line-app-shop',
      'filled-line-apple',
      'filled-line-apple',
      'filled-line-appointment',
      'filled-line-appointment',
      'filled-line-appointment',
      'filled-line-appointment',
      'filled-line-appointment',
      'filled-line-archery',
      'filled-line-archery',
      'filled-line-arrival',
      'filled-line-arrival',
      'filled-line-arrival',
      'filled-line-asparagus',
      'filled-line-asparagus',
      'filled-line-asparagus',
      'filled-line-asparagus',
      'filled-line-asparagus',
      'filled-line-asterisk',
      'filled-line-asterisk',
      'filled-line-attachment',
      'filled-line-attachment',
      'filled-line-audio-player',
      'filled-line-audio-player',
      'filled-line-audio-player',
      'filled-line-audio-player',
      'filled-line-audio-player',
      'filled-line-audio',
      'filled-line-audio',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-augmentative-communication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-authentication',
      'filled-line-auto-responder',
      'filled-line-auto-responder',
      'filled-line-auto-responder',
      'filled-line-auto-responder',
      'filled-line-auto-responder',
      'filled-line-avocado',
      'filled-line-avocado',
      'filled-line-avocado',
      'filled-line-back',
      'filled-line-back',
      'filled-line-back',
      'filled-line-backlink',
      'filled-line-backlink',
      'filled-line-backlink',
      'filled-line-backpack',
      'filled-line-backpack',
      'filled-line-backpack',
      'filled-line-backpack',
      'filled-line-backpack',
      'filled-line-backpack',
      'filled-line-bag-trolley',
      'filled-line-bag-trolley',
      'filled-line-bag-trolley',
      'filled-line-banana',
      'filled-line-banana',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bandage',
      'filled-line-bank',
      'filled-line-bank',
      'filled-line-bank',
      'filled-line-banner-exchange',
      'filled-line-banner-exchange',
      'filled-line-bar-chart-down',
      'filled-line-bar-chart-down',
      'filled-line-bar-chart-down',
      'filled-line-bar-chart-down',
      'filled-line-bar-chart-up',
      'filled-line-bar-chart-up',
      'filled-line-bar-chart-up',
      'filled-line-bar-chart-up',
      'filled-line-barbell',
      'filled-line-barbell',
      'filled-line-barbell',
      'filled-line-barcode',
      'filled-line-barcode',
      'filled-line-barcode',
      'filled-line-barcode',
      'filled-line-barcode',
      'filled-line-barcode',
      'filled-line-barcode',
      'filled-line-baseball-bat',
      'filled-line-baseball-bat',
      'filled-line-baseball-field',
      'filled-line-baseball-field',
      'filled-line-baseball',
      'filled-line-baseball',
      'filled-line-basic-kid-phone',
      'filled-line-basic-kid-phone',
      'filled-line-basic-kid-phone',
      'filled-line-basic-kid-phone',
      'filled-line-basic-kid-phone',
      'filled-line-basic-kid-phone',
      'filled-line-basic-kid-phone',
      'filled-line-basket-ring',
      'filled-line-basket-ring',
      'filled-line-basket-ring',
      'filled-line-basketball-field',
      'filled-line-basketball-field',
      'filled-line-basketball-field',
      'filled-line-basketball-field',
      'filled-line-basketball-jersey',
      'filled-line-basketball-jersey',
      'filled-line-basketball',
      'filled-line-basketball',
      'filled-line-beach',
      'filled-line-beach',
      'filled-line-beach',
      'filled-line-beach',
      'filled-line-beach',
      'filled-line-beach',
      'filled-line-beach',
      'filled-line-bell-pepper',
      'filled-line-bell-pepper',
      'filled-line-bicycle',
      'filled-line-bicycle',
      'filled-line-bicycle',
      'filled-line-bill',
      'filled-line-bill',
      'filled-line-bill',
      'filled-line-bill',
      'filled-line-billiard-ball',
      'filled-line-billiard-ball',
      'filled-line-billiard-ball',
      'filled-line-billiard-ball',
      'filled-line-binocular-disable',
      'filled-line-binocular-disable',
      'filled-line-binocular-disable',
      'filled-line-binocular-disable',
      'filled-line-binocular-disable',
      'filled-line-binocular',
      'filled-line-binocular',
      'filled-line-binocular',
      'filled-line-bio',
      'filled-line-bio',
      'filled-line-bio',
      'filled-line-bio',
      'filled-line-bio',
      'filled-line-bio',
      'filled-line-bio',
      'filled-line-biscuit',
      'filled-line-biscuit',
      'filled-line-biscuit',
      'filled-line-black-hat',
      'filled-line-black-hat',
      'filled-line-black-hat',
      'filled-line-black-hat',
      'filled-line-black-hat',
      'filled-line-black-hat',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block-group',
      'filled-line-block',
      'filled-line-block',
      'filled-line-blocked-user',
      'filled-line-blocked-user',
      'filled-line-blocked-user',
      'filled-line-blocked-user',
      'filled-line-blog',
      'filled-line-blog',
      'filled-line-blog',
      'filled-line-blog',
      'filled-line-blog',
      'filled-line-blood-donation',
      'filled-line-blood-donation',
      'filled-line-blood-donation',
      'filled-line-blood-donation',
      'filled-line-blood-transfusion',
      'filled-line-blood-transfusion',
      'filled-line-blood',
      'filled-line-blood',
      'filled-line-blood',
      'filled-line-blood',
      'filled-line-bluetooth',
      'filled-line-bluetooth',
      'filled-line-bold',
      'filled-line-bold',
      'filled-line-bold',
      'filled-line-bold',
      'filled-line-bone',
      'filled-line-bone',
      'filled-line-bone',
      'filled-line-bones',
      'filled-line-bones',
      'filled-line-bones',
      'filled-line-bones',
      'filled-line-book',
      'filled-line-book',
      'filled-line-book',
      'filled-line-book',
      'filled-line-book',
      'filled-line-book',
      'filled-line-book',
      'filled-line-book',
      'filled-line-bookmark',
      'filled-line-bookmark',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-boost',
      'filled-line-bot',
      'filled-line-bot',
      'filled-line-bot',
      'filled-line-bowling-ball',
      'filled-line-bowling-ball',
      'filled-line-bowling-ball',
      'filled-line-bowling-ball',
      'filled-line-bowling-ball',
      'filled-line-box',
      'filled-line-box',
      'filled-line-box',
      'filled-line-boxing',
      'filled-line-boxing',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-braille',
      'filled-line-brain',
      'filled-line-brain',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-bread',
      'filled-line-briefcase',
      'filled-line-briefcase',
      'filled-line-bug',
      'filled-line-bug',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-bulleted-list',
      'filled-line-burger',
      'filled-line-burger',
      'filled-line-burger',
      'filled-line-burger',
      'filled-line-business-account',
      'filled-line-business-account',
      'filled-line-business-account',
      'filled-line-business-growth',
      'filled-line-business-growth',
      'filled-line-business-law',
      'filled-line-business-law',
      'filled-line-business-model-canvas',
      'filled-line-business-model-canvas',
      'filled-line-business-model-canvas',
      'filled-line-business-model-canvas',
      'filled-line-business-note',
      'filled-line-business-note',
      'filled-line-business-note',
      'filled-line-business-performance',
      'filled-line-business-performance',
      'filled-line-business-performance',
      'filled-line-business-performance',
      'filled-line-business-plan',
      'filled-line-business-plan',
      'filled-line-business-plan',
      'filled-line-business-plan',
      'filled-line-business-strategy',
      'filled-line-business-strategy',
      'filled-line-business-strategy',
      'filled-line-business-strategy',
      'filled-line-business-strategy',
      'filled-line-business-to-business',
      'filled-line-business-to-business',
      'filled-line-business-to-business',
      'filled-line-business-to-business',
      'filled-line-business-to-business',
      'filled-line-business-to-consumer',
      'filled-line-business-to-consumer',
      'filled-line-business-to-consumer',
      'filled-line-business-to-consumer',
      'filled-line-business-to-consumer',
      'filled-line-businessman',
      'filled-line-businessman',
      'filled-line-businesswoman',
      'filled-line-businesswoman',
      'filled-line-cabin-bag',
      'filled-line-cabin-bag',
      'filled-line-cabinet',
      'filled-line-cabinet',
      'filled-line-cabinet',
      'filled-line-cabinet',
      'filled-line-cake',
      'filled-line-cake',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calculator',
      'filled-line-calendar-add-event-alt',
      'filled-line-calendar-add-event-alt',
      'filled-line-calendar-add-event-alt',
      'filled-line-calendar-add-event',
      'filled-line-calendar-add-event',
      'filled-line-calendar-add-event',
      'filled-line-calendar-alert-alt',
      'filled-line-calendar-alert-alt',
      'filled-line-calendar-alert-alt',
      'filled-line-calendar-alert-alt',
      'filled-line-calendar-alert',
      'filled-line-calendar-alert',
      'filled-line-calendar-alert',
      'filled-line-calendar-alert',
      'filled-line-calendar-alt',
      'filled-line-calendar-alt',
      'filled-line-calendar-favorite-alt',
      'filled-line-calendar-favorite-alt',
      'filled-line-calendar-favorite-alt',
      'filled-line-calendar-favorite',
      'filled-line-calendar-favorite',
      'filled-line-calendar-favorite',
      'filled-line-calendar-favorite',
      'filled-line-calendar-remove-event-alt',
      'filled-line-calendar-remove-event-alt',
      'filled-line-calendar-remove-event-alt',
      'filled-line-calendar-remove-event',
      'filled-line-calendar-remove-event',
      'filled-line-calendar-remove-event',
      'filled-line-calendar',
      'filled-line-calendar',
      'filled-line-call-block',
      'filled-line-call-block',
      'filled-line-call-block',
      'filled-line-call',
      'filled-line-call',
      'filled-line-call',
      'filled-line-call',
      'filled-line-call',
      'filled-line-camera-lens',
      'filled-line-camera-lens',
      'filled-line-camera-lens',
      'filled-line-camera-lens',
      'filled-line-camera',
      'filled-line-camera',
      'filled-line-camera',
      'filled-line-candle-stick-chart',
      'filled-line-candle-stick-chart',
      'filled-line-candle-stick-chart',
      'filled-line-candle-stick-chart',
      'filled-line-candle-stick-chart',
      'filled-line-candle-stick-chart',
      'filled-line-canvas',
      'filled-line-canvas',
      'filled-line-car',
      'filled-line-car',
      'filled-line-car',
      'filled-line-car',
      'filled-line-cardiogram',
      'filled-line-cardiogram',
      'filled-line-cardiogram',
      'filled-line-cardiogram',
      'filled-line-cardiogram',
      'filled-line-carrot',
      'filled-line-carrot',
      'filled-line-cash',
      'filled-line-cash',
      'filled-line-cassette',
      'filled-line-cassette',
      'filled-line-cassette',
      'filled-line-cassette',
      'filled-line-cctv',
      'filled-line-cctv',
      'filled-line-cctv',
      'filled-line-cereal',
      'filled-line-cereal',
      'filled-line-cereal',
      'filled-line-cereal',
      'filled-line-cereal',
      'filled-line-cereal',
      'filled-line-cereal',
      'filled-line-certificate',
      'filled-line-certificate',
      'filled-line-certificate',
      'filled-line-certificate',
      'filled-line-channel',
      'filled-line-channel',
      'filled-line-chat-check-double',
      'filled-line-chat-check-double',
      'filled-line-chat-check-double',
      'filled-line-chat-check',
      'filled-line-chat-check',
      'filled-line-chat-check',
      'filled-line-chat',
      'filled-line-chat',
      'filled-line-check-double',
      'filled-line-check-double',
      'filled-line-check-double',
      'filled-line-check-double',
      'filled-line-check',
      'filled-line-check',
      'filled-line-check',
      'filled-line-cheese',
      'filled-line-cheese',
      'filled-line-cheese',
      'filled-line-cheese',
      'filled-line-cheese',
      'filled-line-cheese',
      'filled-line-cheese',
      'filled-line-cheque',
      'filled-line-cheque',
      'filled-line-cheque',
      'filled-line-cheque',
      'filled-line-cheque',
      'filled-line-chicken',
      'filled-line-chicken',
      'filled-line-chili-pepper',
      'filled-line-chili-pepper',
      'filled-line-chocolate',
      'filled-line-chocolate',
      'filled-line-classic-phone',
      'filled-line-classic-phone',
      'filled-line-classic-phone',
      'filled-line-classic-phone',
      'filled-line-classic-phone',
      'filled-line-click',
      'filled-line-click',
      'filled-line-click',
      'filled-line-click',
      'filled-line-click',
      'filled-line-click',
      'filled-line-click',
      'filled-line-close-friend',
      'filled-line-close-friend',
      'filled-line-close-friend',
      'filled-line-close-friend',
      'filled-line-close-friend',
      'filled-line-close-friend',
      'filled-line-cloud-disable',
      'filled-line-cloud-disable',
      'filled-line-cloud-download',
      'filled-line-cloud-download',
      'filled-line-cloud-download',
      'filled-line-cloud-upload',
      'filled-line-cloud-upload',
      'filled-line-cloud-upload',
      'filled-line-cloud',
      'filled-line-cloud',
      'filled-line-cogwheel-alt',
      'filled-line-cogwheel-alt',
      'filled-line-cogwheel-alt',
      'filled-line-cogwheel',
      'filled-line-cogwheel',
      'filled-line-cogwheel',
      'filled-line-coin',
      'filled-line-coin',
      'filled-line-coins',
      'filled-line-coins',
      'filled-line-collections',
      'filled-line-collections',
      'filled-line-comment',
      'filled-line-comment',
      'filled-line-comment',
      'filled-line-comment',
      'filled-line-company',
      'filled-line-company',
      'filled-line-company',
      'filled-line-company',
      'filled-line-company',
      'filled-line-compass',
      'filled-line-compass',
      'filled-line-compass',
      'filled-line-confectionery',
      'filled-line-confectionery',
      'filled-line-confectionery',
      'filled-line-confectionery',
      'filled-line-conflict',
      'filled-line-conflict',
      'filled-line-conflict',
      'filled-line-conflict',
      'filled-line-connect',
      'filled-line-connect',
      'filled-line-connect',
      'filled-line-connect',
      'filled-line-connect',
      'filled-line-consultation',
      'filled-line-consultation',
      'filled-line-consultation',
      'filled-line-consultation',
      'filled-line-consultation',
      'filled-line-consultation',
      'filled-line-contact-list',
      'filled-line-contact-list',
      'filled-line-contact-list',
      'filled-line-contact-list',
      'filled-line-contact-list',
      'filled-line-contact-list',
      'filled-line-contract',
      'filled-line-contract',
      'filled-line-contract',
      'filled-line-contract',
      'filled-line-contract',
      'filled-line-contract',
      'filled-line-conversation-marketing',
      'filled-line-conversation-marketing',
      'filled-line-conversation-marketing',
      'filled-line-conversation',
      'filled-line-conversation',
      'filled-line-conversation',
      'filled-line-conversation',
      'filled-line-conversation',
      'filled-line-conversion-rate',
      'filled-line-conversion-rate',
      'filled-line-conversion-rate',
      'filled-line-conversion-rate',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-cookie',
      'filled-line-copy',
      'filled-line-copy',
      'filled-line-copy',
      'filled-line-copywriting',
      'filled-line-copywriting',
      'filled-line-copywriting',
      'filled-line-copywriting',
      'filled-line-copywriting',
      'filled-line-copywriting',
      'filled-line-copywriting',
      'filled-line-corn',
      'filled-line-corn',
      'filled-line-corn',
      'filled-line-corn',
      'filled-line-corn',
      'filled-line-corn',
      'filled-line-cost-per-click',
      'filled-line-cost-per-click',
      'filled-line-cost-per-click',
      'filled-line-cpanel',
      'filled-line-cpanel',
      'filled-line-cpanel',
      'filled-line-cpanel',
      'filled-line-cpanel',
      'filled-line-cpu',
      'filled-line-cpu',
      'filled-line-cpu',
      'filled-line-cpu',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-create-group',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card-alt',
      'filled-line-credit-card',
      'filled-line-credit-card',
      'filled-line-credit-card',
      'filled-line-credit-card',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-credit-control',
      'filled-line-croissant',
      'filled-line-croissant',
      'filled-line-cross',
      'filled-line-cross',
      'filled-line-cross',
      'filled-line-crown-alt',
      'filled-line-crown-alt',
      'filled-line-crown',
      'filled-line-crown',
      'filled-line-cruise',
      'filled-line-cruise',
      'filled-line-css',
      'filled-line-css',
      'filled-line-css',
      'filled-line-css',
      'filled-line-curriculum',
      'filled-line-curriculum',
      'filled-line-curriculum',
      'filled-line-curriculum',
      'filled-line-customer-service',
      'filled-line-customer-service',
      'filled-line-customer-service',
      'filled-line-cut',
      'filled-line-cut',
      'filled-line-cut',
      'filled-line-dashboard',
      'filled-line-dashboard',
      'filled-line-data-communication',
      'filled-line-data-communication',
      'filled-line-data-communication',
      'filled-line-data-communication',
      'filled-line-deal',
      'filled-line-deal',
      'filled-line-deal',
      'filled-line-deal',
      'filled-line-deal',
      'filled-line-decision-making',
      'filled-line-decision-making',
      'filled-line-decision-making',
      'filled-line-decision-making',
      'filled-line-delivery',
      'filled-line-delivery',
      'filled-line-delivery',
      'filled-line-delivery',
      'filled-line-departure',
      'filled-line-departure',
      'filled-line-departure',
      'filled-line-desk-alt',
      'filled-line-desk-alt',
      'filled-line-desk-alt',
      'filled-line-desk-alt',
      'filled-line-desk-alt',
      'filled-line-desk',
      'filled-line-desk',
      'filled-line-desk',
      'filled-line-desktop-alt',
      'filled-line-desktop-alt',
      'filled-line-desktop',
      'filled-line-desktop',
      'filled-line-desktop',
      'filled-line-diamond',
      'filled-line-diamond',
      'filled-line-dictionary',
      'filled-line-dictionary',
      'filled-line-dictionary',
      'filled-line-dictionary',
      'filled-line-direct-linking',
      'filled-line-direct-linking',
      'filled-line-direction',
      'filled-line-direction',
      'filled-line-direction',
      'filled-line-direction',
      'filled-line-direction',
      'filled-line-directory',
      'filled-line-directory',
      'filled-line-directory',
      'filled-line-directory',
      'filled-line-directory',
      'filled-line-directory',
      'filled-line-disconnect',
      'filled-line-disconnect',
      'filled-line-disconnect',
      'filled-line-disconnect',
      'filled-line-disconnect',
      'filled-line-discount',
      'filled-line-discount',
      'filled-line-discount',
      'filled-line-discount',
      'filled-line-discount',
      'filled-line-dislike',
      'filled-line-dislike',
      'filled-line-dislike',
      'filled-line-diving',
      'filled-line-diving',
      'filled-line-dna',
      'filled-line-dna',
      'filled-line-dollar-coin',
      'filled-line-dollar-coin',
      'filled-line-dollar-coin',
      'filled-line-dollar-coin',
      'filled-line-dollar-paper',
      'filled-line-dollar-paper',
      'filled-line-dollar-paper',
      'filled-line-domain',
      'filled-line-domain',
      'filled-line-domain',
      'filled-line-domain',
      'filled-line-domain',
      'filled-line-domain',
      'filled-line-donation',
      'filled-line-donation',
      'filled-line-donation',
      'filled-line-donut-chart',
      'filled-line-donut-chart',
      'filled-line-donut-chart',
      'filled-line-donut',
      'filled-line-donut',
      'filled-line-donut',
      'filled-line-donut',
      'filled-line-donut',
      'filled-line-donut',
      'filled-line-donut',
      'filled-line-down-alt',
      'filled-line-down-alt',
      'filled-line-down-alt',
      'filled-line-down-alt',
      'filled-line-down',
      'filled-line-down',
      'filled-line-down',
      'filled-line-download-queue',
      'filled-line-download-queue',
      'filled-line-download-queue',
      'filled-line-download-queue',
      'filled-line-download-queue',
      'filled-line-download',
      'filled-line-download',
      'filled-line-download',
      'filled-line-draft-form-library',
      'filled-line-draft-form-library',
      'filled-line-draft-form-library',
      'filled-line-draft-form-library',
      'filled-line-draft',
      'filled-line-draft',
      'filled-line-drone',
      'filled-line-drone',
      'filled-line-drone',
      'filled-line-duct-tape',
      'filled-line-duct-tape',
      'filled-line-duct-tape',
      'filled-line-dvd-player',
      'filled-line-dvd-player',
      'filled-line-dvd-player',
      'filled-line-dvd-player',
      'filled-line-dvd',
      'filled-line-dvd',
      'filled-line-dvd',
      'filled-line-dvd',
      'filled-line-earphone-alt',
      'filled-line-earphone-alt',
      'filled-line-earphone-alt',
      'filled-line-earphone-alt',
      'filled-line-earphone',
      'filled-line-earphone',
      'filled-line-earphone',
      'filled-line-edit-profile-alt',
      'filled-line-edit-profile-alt',
      'filled-line-edit-profile-alt',
      'filled-line-edit-profile-alt',
      'filled-line-edit-profile-alt',
      'filled-line-edit-profile',
      'filled-line-edit-profile',
      'filled-line-edit-profile',
      'filled-line-edit-profile',
      'filled-line-egg',
      'filled-line-egg',
      'filled-line-egg',
      'filled-line-eject',
      'filled-line-eject',
      'filled-line-eject',
      'filled-line-email-attachment',
      'filled-line-email-attachment',
      'filled-line-email-attachment',
      'filled-line-email-draft',
      'filled-line-email-draft',
      'filled-line-email-encryption',
      'filled-line-email-encryption',
      'filled-line-email-encryption',
      'filled-line-email-encryption',
      'filled-line-email-save',
      'filled-line-email-save',
      'filled-line-email-save',
      'filled-line-email-save',
      'filled-line-email-search',
      'filled-line-email-search',
      'filled-line-email-search',
      'filled-line-email-search',
      'filled-line-email',
      'filled-line-email',
      'filled-line-enema',
      'filled-line-enema',
      'filled-line-exit-fullscreen',
      'filled-line-exit-fullscreen',
      'filled-line-exit-fullscreen',
      'filled-line-exit-fullscreen',
      'filled-line-export-data',
      'filled-line-export-data',
      'filled-line-export-data',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-external-link',
      'filled-line-eye-chart',
      'filled-line-eye-chart',
      'filled-line-eye-chart',
      'filled-line-eye-chart',
      'filled-line-eye-chart',
      'filled-line-eye-close',
      'filled-line-eye-close',
      'filled-line-eye-open',
      'filled-line-eye-open',
      'filled-line-eye-open',
      'filled-line-fake-traffic',
      'filled-line-fake-traffic',
      'filled-line-fake-traffic',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-fax',
      'filled-line-feather-pen',
      'filled-line-feather-pen',
      'filled-line-feedback',
      'filled-line-feedback',
      'filled-line-feedback',
      'filled-line-feeling',
      'filled-line-feeling',
      'filled-line-feeling',
      'filled-line-female-user-alt',
      'filled-line-female-user-alt',
      'filled-line-female-user',
      'filled-line-female-user',
      'filled-line-fencing-helmet',
      'filled-line-fencing-helmet',
      'filled-line-fencing',
      'filled-line-fencing',
      'filled-line-fencing',
      'filled-line-fencing',
      'filled-line-fencing',
      'filled-line-file-storage',
      'filled-line-file-storage',
      'filled-line-file',
      'filled-line-file',
      'filled-line-file',
      'filled-line-file',
      'filled-line-file',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-film',
      'filled-line-filter',
      'filled-line-filter',
      'filled-line-filter',
      'filled-line-filter',
      'filled-line-filter',
      'filled-line-filter',
      'filled-line-fish',
      'filled-line-fish',
      'filled-line-fish',
      'filled-line-flag-alt',
      'filled-line-flag-alt',
      'filled-line-flag',
      'filled-line-flag',
      'filled-line-flash',
      'filled-line-flash',
      'filled-line-flashdisk',
      'filled-line-flashdisk',
      'filled-line-flashdisk',
      'filled-line-flashdisk',
      'filled-line-flask',
      'filled-line-flask',
      'filled-line-folder-close-alt',
      'filled-line-folder-close-alt',
      'filled-line-folder-close',
      'filled-line-folder-close',
      'filled-line-folder-open-alt',
      'filled-line-folder-open-alt',
      'filled-line-folder-open',
      'filled-line-folder-open',
      'filled-line-follow',
      'filled-line-follow',
      'filled-line-follow',
      'filled-line-follow',
      'filled-line-followers',
      'filled-line-followers',
      'filled-line-followers',
      'filled-line-followers',
      'filled-line-following',
      'filled-line-following',
      'filled-line-following',
      'filled-line-following',
      'filled-line-font',
      'filled-line-font',
      'filled-line-font',
      'filled-line-footstep',
      'filled-line-footstep',
      'filled-line-footstep',
      'filled-line-footstep',
      'filled-line-footstep',
      'filled-line-footstep',
      'filled-line-forest',
      'filled-line-forest',
      'filled-line-form-2',
      'filled-line-form-2',
      'filled-line-form-2',
      'filled-line-form-library',
      'filled-line-form-library',
      'filled-line-form-library',
      'filled-line-form',
      'filled-line-form',
      'filled-line-form',
      'filled-line-form',
      'filled-line-form',
      'filled-line-free',
      'filled-line-free',
      'filled-line-free',
      'filled-line-french-fries',
      'filled-line-french-fries',
      'filled-line-french-fries',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-friend-list',
      'filled-line-ftp',
      'filled-line-ftp',
      'filled-line-fullscreen',
      'filled-line-fullscreen',
      'filled-line-fullscreen',
      'filled-line-fullscreen',
      'filled-line-funnel',
      'filled-line-funnel',
      'filled-line-gameboy',
      'filled-line-gameboy',
      'filled-line-gameboy',
      'filled-line-gameboy',
      'filled-line-gameboy',
      'filled-line-gameboy',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gamepad',
      'filled-line-gasoline',
      'filled-line-gasoline',
      'filled-line-gasoline',
      'filled-line-gift',
      'filled-line-gift',
      'filled-line-glass-hour',
      'filled-line-glass-hour',
      'filled-line-globe',
      'filled-line-globe',
      'filled-line-globe',
      'filled-line-globe',
      'filled-line-globe',
      'filled-line-goal',
      'filled-line-goal',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-gold',
      'filled-line-grape',
      'filled-line-grape',
      'filled-line-grocery',
      'filled-line-grocery',
      'filled-line-grocery',
      'filled-line-grocery',
      'filled-line-grocery',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-group',
      'filled-line-gymnastic',
      'filled-line-gymnastic',
      'filled-line-gymnastic',
      'filled-line-gymnastic',
      'filled-line-handicap',
      'filled-line-handicap',
      'filled-line-handicap',
      'filled-line-handicap',
      'filled-line-handsfree',
      'filled-line-handsfree',
      'filled-line-handsfree',
      'filled-line-handy-talkie',
      'filled-line-handy-talkie',
      'filled-line-handy-talkie',
      'filled-line-handy-talkie',
      'filled-line-handy-talkie',
      'filled-line-handy-talkie',
      'filled-line-handy-talkie',
      'filled-line-hashtag',
      'filled-line-hashtag',
      'filled-line-hashtag',
      'filled-line-hdd',
      'filled-line-hdd',
      'filled-line-hdd',
      'filled-line-hdd',
      'filled-line-hdd',
      'filled-line-hdd',
      'filled-line-hdd',
      'filled-line-hdmi-port',
      'filled-line-hdmi-port',
      'filled-line-hdmi-port',
      'filled-line-headphone-alt',
      'filled-line-headphone-alt',
      'filled-line-headphone-alt',
      'filled-line-headphone',
      'filled-line-headphone',
      'filled-line-headphone',
      'filled-line-heart-rate',
      'filled-line-heart-rate',
      'filled-line-heart',
      'filled-line-heart',
      'filled-line-help',
      'filled-line-help',
      'filled-line-help',
      'filled-line-help',
      'filled-line-hierarchial-structure',
      'filled-line-hierarchial-structure',
      'filled-line-hieroglyphic',
      'filled-line-hieroglyphic',
      'filled-line-hieroglyphic',
      'filled-line-hieroglyphic',
      'filled-line-hieroglyphic',
      'filled-line-hieroglyphic',
      'filled-line-hit',
      'filled-line-hit',
      'filled-line-hit',
      'filled-line-hit',
      'filled-line-hit',
      'filled-line-hit',
      'filled-line-hiv-ribbon',
      'filled-line-hiv-ribbon',
      'filled-line-hockey',
      'filled-line-hockey',
      'filled-line-hockey',
      'filled-line-home-alt',
      'filled-line-home-alt',
      'filled-line-home-theater',
      'filled-line-home-theater',
      'filled-line-home-theater',
      'filled-line-home-theater',
      'filled-line-home-theater',
      'filled-line-home-theater',
      'filled-line-home-theater',
      'filled-line-home',
      'filled-line-home',
      'filled-line-honey',
      'filled-line-honey',
      'filled-line-honey',
      'filled-line-horizontal-graphic-alt',
      'filled-line-horizontal-graphic-alt',
      'filled-line-horizontal-graphic',
      'filled-line-horizontal-graphic',
      'filled-line-horizontal-graphic',
      'filled-line-horizontal-graphic',
      'filled-line-hospital-sign',
      'filled-line-hospital-sign',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hospital',
      'filled-line-hosting',
      'filled-line-hosting',
      'filled-line-hosting',
      'filled-line-hosting',
      'filled-line-hot-coffee',
      'filled-line-hot-coffee',
      'filled-line-hot-coffee',
      'filled-line-hot-coffee',
      'filled-line-hot-coffee',
      'filled-line-hotel',
      'filled-line-hotel',
      'filled-line-hotel',
      'filled-line-hotel',
      'filled-line-hotel',
      'filled-line-hotel',
      'filled-line-html',
      'filled-line-html',
      'filled-line-html',
      'filled-line-html',
      'filled-line-html',
      'filled-line-human-to-human',
      'filled-line-human-to-human',
      'filled-line-human-to-human',
      'filled-line-human-to-human',
      'filled-line-human-to-human',
      'filled-line-human-to-human',
      'filled-line-ice-coffee',
      'filled-line-ice-coffee',
      'filled-line-ice-coffee',
      'filled-line-ice-cream-cone',
      'filled-line-ice-cream-cone',
      'filled-line-ice-skate',
      'filled-line-ice-skate',
      'filled-line-id-card',
      'filled-line-id-card',
      'filled-line-id-card',
      'filled-line-id-card',
      'filled-line-id-card',
      'filled-line-idea',
      'filled-line-idea',
      'filled-line-idea',
      'filled-line-idea',
      'filled-line-import-data',
      'filled-line-import-data',
      'filled-line-import-data',
      'filled-line-important',
      'filled-line-important',
      'filled-line-important',
      'filled-line-important',
      'filled-line-impression',
      'filled-line-impression',
      'filled-line-impression',
      'filled-line-inbox-empty',
      'filled-line-inbox-empty',
      'filled-line-inbox-full',
      'filled-line-inbox-full',
      'filled-line-incoming-call',
      'filled-line-incoming-call',
      'filled-line-incoming-call',
      'filled-line-indent-less',
      'filled-line-indent-less',
      'filled-line-indent-less',
      'filled-line-indent-less',
      'filled-line-indent-less',
      'filled-line-indent-less',
      'filled-line-indent-more',
      'filled-line-indent-more',
      'filled-line-indent-more',
      'filled-line-indent-more',
      'filled-line-indent-more',
      'filled-line-indent-more',
      'filled-line-infographic',
      'filled-line-infographic',
      'filled-line-infographic',
      'filled-line-infographic',
      'filled-line-infographic',
      'filled-line-infographic',
      'filled-line-information',
      'filled-line-information',
      'filled-line-information',
      'filled-line-information',
      'filled-line-infusion',
      'filled-line-infusion',
      'filled-line-infusion',
      'filled-line-initiate-money-transfer',
      'filled-line-initiate-money-transfer',
      'filled-line-initiate-money-transfer',
      'filled-line-initiate-money-transfer',
      'filled-line-ink',
      'filled-line-ink',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-insights',
      'filled-line-intercom',
      'filled-line-intercom',
      'filled-line-intercom',
      'filled-line-intercom',
      'filled-line-intercom',
      'filled-line-intercom',
      'filled-line-intercom',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internal-link',
      'filled-line-internet-banking',
      'filled-line-internet-banking',
      'filled-line-internet-banking',
      'filled-line-investment',
      'filled-line-investment',
      'filled-line-invite-friends',
      'filled-line-invite-friends',
      'filled-line-invite-friends',
      'filled-line-invite-friends',
      'filled-line-invite-friends',
      'filled-line-invite-friends',
      'filled-line-invite-friends',
      'filled-line-invoice',
      'filled-line-invoice',
      'filled-line-invoice',
      'filled-line-invoice',
      'filled-line-italic',
      'filled-line-italic',
      'filled-line-joystick',
      'filled-line-joystick',
      'filled-line-juice',
      'filled-line-juice',
      'filled-line-justify',
      'filled-line-justify',
      'filled-line-justify',
      'filled-line-justify',
      'filled-line-justify',
      'filled-line-karate',
      'filled-line-karate',
      'filled-line-kayak',
      'filled-line-kayak',
      'filled-line-kayak',
      'filled-line-kayak',
      'filled-line-key',
      'filled-line-key',
      'filled-line-key',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
      'filled-line-keyboard-alt',
    ],
    [
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyboard',
      'filled-line-keyword-density',
      'filled-line-keyword-density',
      'filled-line-keyword-density',
      'filled-line-keyword-density',
      'filled-line-keyword-density',
      'filled-line-keyword-research',
      'filled-line-keyword-research',
      'filled-line-keyword-research',
      'filled-line-keyword',
      'filled-line-keyword',
      'filled-line-keyword',
      'filled-line-kidney',
      'filled-line-kidney',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-kiwi',
      'filled-line-label',
      'filled-line-label',
      'filled-line-label',
      'filled-line-label',
      'filled-line-label',
      'filled-line-language',
      'filled-line-language',
      'filled-line-language',
      'filled-line-language',
      'filled-line-laptop-alt',
      'filled-line-laptop-alt',
      'filled-line-laptop',
      'filled-line-laptop',
      'filled-line-leader',
      'filled-line-leader',
      'filled-line-leads',
      'filled-line-leads',
      'filled-line-leads',
      'filled-line-leads',
      'filled-line-leads',
      'filled-line-left-alt',
      'filled-line-left-alt',
      'filled-line-left-alt',
      'filled-line-left-alt',
      'filled-line-left',
      'filled-line-left',
      'filled-line-left',
      'filled-line-legal',
      'filled-line-legal',
      'filled-line-lemon',
      'filled-line-lemon',
      'filled-line-lettuce',
      'filled-line-lettuce',
      'filled-line-lettuce',
      'filled-line-lifebuoy',
      'filled-line-lifebuoy',
      'filled-line-lifebuoy',
      'filled-line-like',
      'filled-line-like',
      'filled-line-like',
      'filled-line-link-exchange',
      'filled-line-link-exchange',
      'filled-line-link-exchange',
      'filled-line-link-exchange',
      'filled-line-link-farm',
      'filled-line-link-farm',
      'filled-line-link-farm',
      'filled-line-link',
      'filled-line-link',
      'filled-line-linked-accounts',
      'filled-line-linked-accounts',
      'filled-line-linked-accounts',
      'filled-line-linked-accounts',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-list',
      'filled-line-live-video',
      'filled-line-live-video',
      'filled-line-live-video',
      'filled-line-liver',
      'filled-line-liver',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-loading',
      'filled-line-local-subaquatic-network',
      'filled-line-local-subaquatic-network',
      'filled-line-local-subaquatic-network',
      'filled-line-local-subaquatic-network',
      'filled-line-local-subaquatic-network',
      'filled-line-location-area',
      'filled-line-location-area',
      'filled-line-location-area',
      'filled-line-location-pin',
      'filled-line-location-pin',
      'filled-line-location-pin',
      'filled-line-lock',
      'filled-line-lock',
      'filled-line-lock',
      'filled-line-login',
      'filled-line-login',
      'filled-line-login',
      'filled-line-login',
      'filled-line-logout',
      'filled-line-logout',
      'filled-line-logout',
      'filled-line-logout',
      'filled-line-lollipop',
      'filled-line-lollipop',
      'filled-line-luggage',
      'filled-line-luggage',
      'filled-line-lungs',
      'filled-line-lungs',
      'filled-line-magazine',
      'filled-line-magazine',
      'filled-line-magazine',
      'filled-line-magazine',
      'filled-line-magazine',
      'filled-line-magnet',
      'filled-line-magnet',
      'filled-line-magnet',
      'filled-line-mailbox',
      'filled-line-mailbox',
      'filled-line-mailing-list',
      'filled-line-mailing-list',
      'filled-line-mailing-list',
      'filled-line-mailing-list',
      'filled-line-map-alt',
      'filled-line-map-alt',
      'filled-line-map-alt',
      'filled-line-map',
      'filled-line-map',
      'filled-line-map',
      'filled-line-marketing',
      'filled-line-marketing',
      'filled-line-marketing',
      'filled-line-marketing',
      'filled-line-mastercard',
      'filled-line-mastercard',
      'filled-line-mastercard',
      'filled-line-mastercard',
      'filled-line-medal',
      'filled-line-medal',
      'filled-line-medal',
      'filled-line-medical-bag',
      'filled-line-medical-bag',
      'filled-line-medical-bag',
      'filled-line-medicine',
      'filled-line-medicine',
      'filled-line-medicine',
      'filled-line-medicine',
      'filled-line-megaphone-alt',
      'filled-line-megaphone-alt',
      'filled-line-megaphone',
      'filled-line-megaphone',
      'filled-line-melon',
      'filled-line-melon',
      'filled-line-membership-card',
      'filled-line-membership-card',
      'filled-line-membership-card',
      'filled-line-membership-card',
      'filled-line-menu-burger',
      'filled-line-menu-dots',
      'filled-line-menu-dots',
      'filled-line-menu-dots',
      'filled-line-menu-dots',
      'filled-line-menu-dots',
      'filled-line-menu-dots',
      'filled-line-message-bottle',
      'filled-line-message-bottle',
      'filled-line-message-bottle',
      'filled-line-message',
      'filled-line-message',
      'filled-line-messages',
      'filled-line-messages',
      'filled-line-meta-tag',
      'filled-line-meta-tag',
      'filled-line-meta-tag',
      'filled-line-meta-tag',
      'filled-line-meta-tag',
      'filled-line-microphone-disable',
      'filled-line-microphone-disable',
      'filled-line-microphone-disable',
      'filled-line-microphone',
      'filled-line-microphone',
      'filled-line-microphone',
      'filled-line-microphone',
      'filled-line-microscope',
      'filled-line-microscope',
      'filled-line-milk',
      'filled-line-milk',
      'filled-line-mini-traffic',
      'filled-line-mini-traffic',
      'filled-line-minus',
      'filled-line-minus',
      'filled-line-minus',
      'filled-line-mobile-banking',
      'filled-line-mobile-banking',
      'filled-line-mobile-banking',
      'filled-line-mocktail',
      'filled-line-mocktail',
      'filled-line-moderator',
      'filled-line-moderator',
      'filled-line-moderator',
      'filled-line-moderator',
      'filled-line-money-exchange',
      'filled-line-money-exchange',
      'filled-line-money-exchange',
      'filled-line-money-exchange',
      'filled-line-monopod',
      'filled-line-monopod',
      'filled-line-morse-code',
      'filled-line-morse-code',
      'filled-line-morse-code',
      'filled-line-morse-code',
      'filled-line-mortar',
      'filled-line-mortar',
      'filled-line-mouse-alt',
      'filled-line-mouse-alt',
      'filled-line-mouse-alt',
      'filled-line-mouse-alt',
      'filled-line-mouse',
      'filled-line-mouse',
      'filled-line-mouse',
      'filled-line-movie-ticket',
      'filled-line-movie-ticket',
      'filled-line-movie-ticket',
      'filled-line-movie-ticket',
      'filled-line-movie-ticket',
      'filled-line-muffin',
      'filled-line-muffin',
      'filled-line-muffin',
      'filled-line-muffin',
      'filled-line-muffin',
      'filled-line-multiple-destinations',
      'filled-line-multiple-destinations',
      'filled-line-multiple-destinations',
      'filled-line-multiple-destinations',
      'filled-line-multiple-destinations',
      'filled-line-multiple-destinations',
      'filled-line-multiple-destinations',
      'filled-line-navigation',
      'filled-line-navigation',
      'filled-line-navigation',
      'filled-line-network',
      'filled-line-network',
      'filled-line-network',
      'filled-line-network',
      'filled-line-network',
      'filled-line-network',
      'filled-line-neutral-trading',
      'filled-line-neutral-trading',
      'filled-line-neutral-trading',
      'filled-line-neutral-trading',
      'filled-line-neutral-trading',
      'filled-line-neutral-trading',
      'filled-line-neutral-trading',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-news-feed',
      'filled-line-newspaper',
      'filled-line-newspaper',
      'filled-line-newspaper',
      'filled-line-newspaper',
      'filled-line-newspaper',
      'filled-line-newspaper',
      'filled-line-next',
      'filled-line-next',
      'filled-line-next',
      'filled-line-nintendo-switch',
      'filled-line-nintendo-switch',
      'filled-line-nintendo-switch',
      'filled-line-nintendo-switch',
      'filled-line-nintendo-wii',
      'filled-line-nintendo-wii',
      'filled-line-nintendo-wii',
      'filled-line-nintendo-wii',
      'filled-line-nintendo-wii',
      'filled-line-nintendo-wii',
      'filled-line-nintendo-wii',
      'filled-line-noodle',
      'filled-line-noodle',
      'filled-line-note',
      'filled-line-note',
      'filled-line-note',
      'filled-line-note',
      'filled-line-note',
      'filled-line-notification-disable',
      'filled-line-notification-disable',
      'filled-line-notification',
      'filled-line-notification',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-numpad',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-alt',
      'filled-line-office-chair',
      'filled-line-office-chair',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-office',
      'filled-line-olive-oil',
      'filled-line-olive-oil',
      'filled-line-onion',
      'filled-line-onion',
      'filled-line-onion',
      'filled-line-orange',
      'filled-line-orange',
      'filled-line-organic',
      'filled-line-organic',
      'filled-line-organization',
      'filled-line-organization',
      'filled-line-organization',
      'filled-line-outbox-empty',
      'filled-line-outbox-empty',
      'filled-line-outbox-empty',
      'filled-line-outbox-empty',
      'filled-line-outbox-empty',
      'filled-line-outbox-empty',
      'filled-line-outbox-full',
      'filled-line-outbox-full',
      'filled-line-outbox-full',
      'filled-line-outbox-full',
      'filled-line-outbox-full',
      'filled-line-outgoing-call',
      'filled-line-outgoing-call',
      'filled-line-outgoing-call',
      'filled-line-packaging',
      'filled-line-packaging',
      'filled-line-page',
      'filled-line-page',
      'filled-line-page',
      'filled-line-page',
      'filled-line-page',
      'filled-line-pager',
      'filled-line-pager',
      'filled-line-pager',
      'filled-line-pager',
      'filled-line-pages',
      'filled-line-pages',
      'filled-line-pages',
      'filled-line-pages',
      'filled-line-pages',
      'filled-line-paid',
      'filled-line-paid',
      'filled-line-paid',
      'filled-line-paid',
      'filled-line-paid',
      'filled-line-paid',
      'filled-line-panel',
      'filled-line-panel',
      'filled-line-paperplane',
      'filled-line-paperplane',
      'filled-line-passport',
      'filled-line-passport',
      'filled-line-passport',
      'filled-line-passport',
      'filled-line-passport',
      'filled-line-password-alt',
      'filled-line-password-alt',
      'filled-line-password-alt',
      'filled-line-password-alt',
      'filled-line-password-alt',
      'filled-line-password-alt',
      'filled-line-password',
      'filled-line-password',
      'filled-line-password',
      'filled-line-password',
      'filled-line-password',
      'filled-line-password',
      'filled-line-pasta',
      'filled-line-pasta',
      'filled-line-pasta',
      'filled-line-paste',
      'filled-line-paste',
      'filled-line-paste',
      'filled-line-paste',
      'filled-line-paste',
      'filled-line-pause',
      'filled-line-pause',
      'filled-line-pause',
      'filled-line-pause',
      'filled-line-pay-per-click',
      'filled-line-pay-per-click',
      'filled-line-pay-per-click',
      'filled-line-pay-per-click',
      'filled-line-pear',
      'filled-line-pear',
      'filled-line-pen',
      'filled-line-pen',
      'filled-line-pepper',
      'filled-line-pepper',
      'filled-line-pepper',
      'filled-line-pepper',
      'filled-line-pepper',
      'filled-line-percentage',
      'filled-line-percentage',
      'filled-line-percentage',
      'filled-line-percentage',
      'filled-line-percentage',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-petri-dish',
      'filled-line-phone-book',
      'filled-line-phone-book',
      'filled-line-phone-book',
      'filled-line-phone-book',
      'filled-line-phone-book',
      'filled-line-phone-book',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-booth',
      'filled-line-phone-lock',
      'filled-line-phone-lock',
      'filled-line-phone-lock',
      'filled-line-phone-support-24-hour',
      'filled-line-phone-support-24-hour',
      'filled-line-phone-support-24-hour',
      'filled-line-phone-support-24-hour',
      'filled-line-phone-unlock',
      'filled-line-phone-unlock',
      'filled-line-phone-unlock',
      'filled-line-php',
      'filled-line-php',
      'filled-line-php',
      'filled-line-php',
      'filled-line-php',
      'filled-line-php',
      'filled-line-picture',
      'filled-line-picture',
      'filled-line-picture',
      'filled-line-pie-chart',
      'filled-line-pie-chart',
      'filled-line-pie-chart',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pills',
      'filled-line-pineapple',
      'filled-line-pineapple',
      'filled-line-pineapple',
      'filled-line-pizza',
      'filled-line-pizza',
      'filled-line-pizza',
      'filled-line-pizza',
      'filled-line-pizza',
      'filled-line-pizza',
      'filled-line-play',
      'filled-line-play',
      'filled-line-playstation',
      'filled-line-playstation',
      'filled-line-playstation',
      'filled-line-playstation',
      'filled-line-playstation',
      'filled-line-playstation',
      'filled-line-plugs',
      'filled-line-plugs',
      'filled-line-plus',
      'filled-line-plus',
      'filled-line-plus',
      'filled-line-pocket-knife',
      'filled-line-pocket-knife',
      'filled-line-pocket-knife',
      'filled-line-podium',
      'filled-line-podium',
      'filled-line-podium',
      'filled-line-podium',
      'filled-line-podium',
      'filled-line-podium',
      'filled-line-popcorn',
      'filled-line-popcorn',
      'filled-line-popsicle',
      'filled-line-popsicle',
      'filled-line-popsicle',
      'filled-line-popsicle',
      'filled-line-post-engagements',
      'filled-line-post-engagements',
      'filled-line-post',
      'filled-line-post',
      'filled-line-post',
      'filled-line-potion',
      'filled-line-potion',
      'filled-line-power-sign',
      'filled-line-power-sign',
      'filled-line-power-sign',
      'filled-line-presentation-alt',
      'filled-line-presentation-alt',
      'filled-line-presentation-alt',
      'filled-line-presentation-alt',
      'filled-line-presentation',
      'filled-line-presentation',
      'filled-line-presentation',
      'filled-line-primary-inbox',
      'filled-line-primary-inbox',
      'filled-line-primary-inbox',
      'filled-line-print-alt',
      'filled-line-print-alt',
      'filled-line-print-alt',
      'filled-line-print-alt',
      'filled-line-print',
      'filled-line-print',
      'filled-line-print',
      'filled-line-print',
      'filled-line-private-account',
      'filled-line-private-account',
      'filled-line-private-account',
      'filled-line-private-account',
      'filled-line-private-account',
      'filled-line-private-account',
      'filled-line-profile-picture-alt',
      'filled-line-profile-picture-alt',
      'filled-line-profile-picture-alt',
      'filled-line-profile-picture',
      'filled-line-profile-picture',
      'filled-line-profit',
      'filled-line-profit',
      'filled-line-profit',
      'filled-line-projector',
      'filled-line-projector',
      'filled-line-projector',
      'filled-line-promote',
      'filled-line-promote',
      'filled-line-promote',
      'filled-line-promote',
      'filled-line-promote',
      'filled-line-public-speaking',
      'filled-line-public-speaking',
      'filled-line-publish',
      'filled-line-publish',
      'filled-line-publish',
      'filled-line-publish',
      'filled-line-publisher',
      'filled-line-publisher',
      'filled-line-publisher',
      'filled-line-publisher',
      'filled-line-publisher',
      'filled-line-purse',
      'filled-line-purse',
      'filled-line-push-notification',
      'filled-line-push-notification',
      'filled-line-push-notification',
      'filled-line-push-notification',
      'filled-line-pushpin',
      'filled-line-pushpin',
      'filled-line-puzzle',
      'filled-line-puzzle',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-qr-code',
      'filled-line-quote',
      'filled-line-quote',
      'filled-line-quote',
      'filled-line-quote',
      'filled-line-racing-flag',
      'filled-line-racing-helmet',
      'filled-line-racing-helmet',
      'filled-line-racing-helmet',
      'filled-line-racing-helmet',
      'filled-line-racket',
      'filled-line-racket',
      'filled-line-radio',
      'filled-line-radio',
      'filled-line-radio',
      'filled-line-radio',
      'filled-line-ranking',
      'filled-line-ranking',
      'filled-line-ranking',
      'filled-line-ranking',
      'filled-line-ranking',
      'filled-line-ranking',
      'filled-line-ranking',
      'filled-line-reach',
      'filled-line-reach',
      'filled-line-reach',
      'filled-line-reach',
      'filled-line-reach',
      'filled-line-reach',
      'filled-line-reach',
      'filled-line-receipt',
      'filled-line-receipt',
      'filled-line-receipt',
      'filled-line-receipt',
      'filled-line-recommendation',
      'filled-line-recommendation',
      'filled-line-recommendation',
      'filled-line-record',
      'filled-line-record',
      'filled-line-refresh-wifi',
      'filled-line-refresh-wifi',
      'filled-line-refresh-wifi',
      'filled-line-refresh-wifi',
      'filled-line-refresh-wifi',
      'filled-line-refresh',
      'filled-line-refresh',
      'filled-line-refresh',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remote',
      'filled-line-remove-contact',
      'filled-line-remove-contact',
      'filled-line-remove-contact',
      'filled-line-remove-friend',
      'filled-line-remove-friend',
      'filled-line-remove-friend',
      'filled-line-remove-friend',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-remove-group',
      'filled-line-repeat',
      'filled-line-repeat',
      'filled-line-reply-all',
      'filled-line-reply-all',
      'filled-line-reply-all',
      'filled-line-reply',
      'filled-line-reply',
      'filled-line-rice',
      'filled-line-rice',
      'filled-line-rice',
      'filled-line-rice',
      'filled-line-rice',
      'filled-line-rice',
      'filled-line-rice',
      'filled-line-right-alt',
      'filled-line-right-alt',
      'filled-line-right-alt',
      'filled-line-right-alt',
      'filled-line-right',
      'filled-line-right',
      'filled-line-right',
      'filled-line-rocket-launch',
      'filled-line-rocket-launch',
      'filled-line-rocket-launch',
      'filled-line-rugby-ball',
      'filled-line-rugby-ball',
      'filled-line-running-shoe',
      'filled-line-running-shoe',
      'filled-line-sad',
      'filled-line-sad',
      'filled-line-sad',
      'filled-line-sale',
      'filled-line-sale',
      'filled-line-sale',
      'filled-line-sale',
      'filled-line-sandals',
      'filled-line-sandals',
      'filled-line-sandals',
      'filled-line-sandals',
      'filled-line-sandals',
      'filled-line-sandwich',
      'filled-line-sandwich',
      'filled-line-sandwich',
      'filled-line-sandwich',
      'filled-line-sandwich',
      'filled-line-satellite-alt',
      'filled-line-satellite-alt',
      'filled-line-satellite-alt',
      'filled-line-satellite-alt',
      'filled-line-satellite-alt',
      'filled-line-satellite-alt',
      'filled-line-satellite',
      'filled-line-satellite',
      'filled-line-satellite',
      'filled-line-satellite',
      'filled-line-satellite',
      'filled-line-sauce',
      'filled-line-sauce',
      'filled-line-sausage',
      'filled-line-sausage',
      'filled-line-sausage',
      'filled-line-save',
      'filled-line-save',
      'filled-line-savings',
      'filled-line-savings',
      'filled-line-savings',
      'filled-line-savings',
      'filled-line-scale',
      'filled-line-scale',
      'filled-line-scale',
      'filled-line-scale',
      'filled-line-scale',
      'filled-line-scam',
      'filled-line-scam',
      'filled-line-scam',
      'filled-line-scoreboard',
      'filled-line-scoreboard',
      'filled-line-scoreboard',
      'filled-line-scoreboard',
      'filled-line-screenshot',
      'filled-line-screenshot',
      'filled-line-screenshot',
      'filled-line-screenshot',
      'filled-line-screenshot',
      'filled-line-screenshot',
      'filled-line-script',
      'filled-line-script',
      'filled-line-script',
      'filled-line-script',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-scroll',
      'filled-line-search-alt',
      'filled-line-search-alt',
      'filled-line-search-check-alt',
      'filled-line-search-check-alt',
      'filled-line-search-check-alt',
      'filled-line-search-check',
      'filled-line-search-check',
      'filled-line-search-check',
      'filled-line-search-cross-alt',
      'filled-line-search-cross-alt',
      'filled-line-search-cross-alt',
      'filled-line-search-cross',
      'filled-line-search-cross',
      'filled-line-search-cross',
      'filled-line-search-engine-result-page',
      'filled-line-search-engine-result-page',
      'filled-line-search-engine-result-page',
      'filled-line-search-engine-result-page',
      'filled-line-search-minus-alt',
      'filled-line-search-minus-alt',
      'filled-line-search-minus-alt',
      'filled-line-search-minus',
      'filled-line-search-minus',
      'filled-line-search-minus',
      'filled-line-search-plus-alt',
      'filled-line-search-plus-alt',
      'filled-line-search-plus-alt',
      'filled-line-search-plus',
      'filled-line-search-plus',
      'filled-line-search-plus',
      'filled-line-search',
      'filled-line-search',
      'filled-line-semaphore',
      'filled-line-semaphore',
      'filled-line-semaphore',
      'filled-line-semaphore',
      'filled-line-send-alt',
      'filled-line-send-alt',
      'filled-line-send-alt',
      'filled-line-send',
      'filled-line-send',
      'filled-line-send',
      'filled-line-send',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-server',
      'filled-line-share',
      'filled-line-share',
      'filled-line-shared-hosting',
      'filled-line-shared-hosting',
      'filled-line-shared-hosting',
      'filled-line-shared-hosting',
      'filled-line-shared-hosting',
      'filled-line-shared-hosting',
      'filled-line-shield',
      'filled-line-shield',
      'filled-line-shield',
      'filled-line-ship-flag',
      'filled-line-ship-flag',
      'filled-line-ship',
      'filled-line-ship',
      'filled-line-shipping',
      'filled-line-shipping',
      'filled-line-shipping',
      'filled-line-shipping',
      'filled-line-shipping',
      'filled-line-shirt',
      'filled-line-shirt',
      'filled-line-shirt',
      'filled-line-shirt',
      'filled-line-shop-sign',
      'filled-line-shop-sign',
      'filled-line-shop',
      'filled-line-shop',
      'filled-line-shop',
      'filled-line-shop',
      'filled-line-shopping-bag-alt',
      'filled-line-shopping-bag-alt',
      'filled-line-shopping-bag',
      'filled-line-shopping-bag',
      'filled-line-shopping-bag',
      'filled-line-shopping-bag',
      'filled-line-shopping-bag',
      'filled-line-shopping-cart-alt',
      'filled-line-shopping-cart-alt',
      'filled-line-shopping-cart',
      'filled-line-shopping-cart',
      'filled-line-shorts',
      'filled-line-shorts',
      'filled-line-shredder',
      'filled-line-shredder',
      'filled-line-shuffle:before',
      'filled-line-shuttlecock',
      'filled-line-shuttlecock',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-signal',
      'filled-line-skull',
      'filled-line-skull',
      'filled-line-skull',
      'filled-line-skull',
      'filled-line-skull',
      'filled-line-skull',
      'filled-line-skull',
      'filled-line-smartphone-alt',
      'filled-line-smartphone-alt',
      'filled-line-smartphone',
      'filled-line-smartphone',
      'filled-line-smile',
      'filled-line-smile',
      'filled-line-smile',
      'filled-line-smoke-signal',
      'filled-line-smoke-signal',
      'filled-line-smoke-signal',
      'filled-line-smoke-signal',
      'filled-line-snooze',
      'filled-line-snooze',
      'filled-line-snooze',
      'filled-line-snooze',
      'filled-line-snooze',
      'filled-line-soccer-ball',
      'filled-line-soccer-ball',
      'filled-line-soccer-field',
      'filled-line-soccer-field',
      'filled-line-soccer-field',
      'filled-line-soccer-field',
      'filled-line-soccer-jersey',
      'filled-line-soccer-jersey',
      'filled-line-soccer-jersey',
      'filled-line-soccer-shoe',
      'filled-line-soccer-shoe',
      'filled-line-soda',
      'filled-line-soda',
      'filled-line-sort-by-alphabet-a-to-z',
      'filled-line-sort-by-alphabet-a-to-z',
      'filled-line-sort-by-alphabet-a-to-z',
      'filled-line-sort-by-alphabet-a-to-z',
      'filled-line-sort-by-alphabet-z-to-a',
      'filled-line-sort-by-alphabet-z-to-a',
      'filled-line-sort-by-alphabet-z-to-a',
      'filled-line-sort-by-alphabet-z-to-a',
      'filled-line-sort-by-attribute-max-to-min',
      'filled-line-sort-by-attribute-max-to-min',
      'filled-line-sort-by-attribute-max-to-min',
      'filled-line-sort-by-attribute-max-to-min',
      'filled-line-sort-by-attribute-max-to-min',
      'filled-line-sort-by-attribute-max-to-min',
      'filled-line-sort-by-attribute-min-to-max',
      'filled-line-sort-by-attribute-min-to-max',
      'filled-line-sort-by-attribute-min-to-max',
      'filled-line-sort-by-attribute-min-to-max',
      'filled-line-sort-by-attribute-min-to-max',
      'filled-line-sort-by-attribute-min-to-max',
      'filled-line-sort-by-order-max-to-min',
      'filled-line-sort-by-order-max-to-min',
      'filled-line-sort-by-order-max-to-min',
      'filled-line-sort-by-order-max-to-min',
      'filled-line-sort-by-order-max-to-min',
      'filled-line-sort-by-order-max-to-min',
      'filled-line-sort-by-order-min-to-max',
      'filled-line-sort-by-order-min-to-max',
      'filled-line-sort-by-order-min-to-max',
      'filled-line-sort-by-order-min-to-max',
      'filled-line-sort-by-order-min-to-max',
      'filled-line-sort-by-order-min-to-max',
      'filled-line-sos-panic-button',
      'filled-line-sos-panic-button',
      'filled-line-sos-panic-button',
      'filled-line-sos-panic-button',
      'filled-line-sos-panic-button',
      'filled-line-soup',
      'filled-line-soup',
      'filled-line-soup',
      'filled-line-soup',
      'filled-line-spam',
      'filled-line-spam',
      'filled-line-spam',
      'filled-line-spam',
      'filled-line-speech',
      'filled-line-speech',
      'filled-line-speech',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-speedometer',
      'filled-line-sperm',
      'filled-line-sperm',
      'filled-line-sperm',
      'filled-line-sperm',
      'filled-line-stamp',
      'filled-line-stamp',
      'filled-line-stapler',
      'filled-line-stapler',
      'filled-line-stapler',
      'filled-line-star',
      'filled-line-star',
      'filled-line-stars',
      'filled-line-stars',
      'filled-line-stars',
      'filled-line-stars',
      'filled-line-stars',
      'filled-line-statistic-alt',
      'filled-line-statistic-alt',
      'filled-line-statistic-alt',
      'filled-line-statistic',
      'filled-line-statistic',
      'filled-line-statistic',
      'filled-line-statistic',
      'filled-line-statistic',
      'filled-line-statistic',
      'filled-line-steak',
      'filled-line-steak',
      'filled-line-steak',
      'filled-line-stethoscope',
      'filled-line-stethoscope',
      'filled-line-stop',
      'filled-line-stop',
      'filled-line-stopwatch',
      'filled-line-stopwatch',
      'filled-line-stopwatch',
      'filled-line-subdomain',
      'filled-line-subdomain',
      'filled-line-subdomain',
      'filled-line-subdomain',
      'filled-line-subdomain',
      'filled-line-subdomain',
      'filled-line-subdomain',
      'filled-line-subject',
      'filled-line-subject',
      'filled-line-subject',
      'filled-line-subscribe',
      'filled-line-subscribe',
      'filled-line-subscribe',
      'filled-line-subscribe',
      'filled-line-subscribe',
      'filled-line-suitcase',
      'filled-line-suitcase',
      'filled-line-suitcase',
      'filled-line-sunblock',
      'filled-line-sunblock',
      'filled-line-sunblock',
      'filled-line-sunblock',
      'filled-line-sunblock',
      'filled-line-sunblock',
      'filled-line-sunblock',
      'filled-line-surface-acoustic-wave',
      'filled-line-surface-acoustic-wave',
      'filled-line-surfboard',
      'filled-line-surfboard',
      'filled-line-swipe',
      'filled-line-swipe',
      'filled-line-swipe',
      'filled-line-sync',
      'filled-line-sync',
      'filled-line-sync',
      'filled-line-sync',
      'filled-line-sync',
      'filled-line-tag',
      'filled-line-tag',
      'filled-line-tag',
      'filled-line-tags',
      'filled-line-tags',
      'filled-line-tags',
      'filled-line-tape-recorder',
      'filled-line-tape-recorder',
      'filled-line-tape-recorder',
      'filled-line-target-alt',
      'filled-line-target-alt',
      'filled-line-target',
      'filled-line-target',
      'filled-line-target',
      'filled-line-task',
      'filled-line-task',
      'filled-line-task',
      'filled-line-task',
      'filled-line-task',
      'filled-line-task',
      'filled-line-task',
      'filled-line-tea',
      'filled-line-tea',
      'filled-line-tea',
      'filled-line-tea',
      'filled-line-team-strategy',
      'filled-line-team-strategy',
      'filled-line-teamwork',
      'filled-line-teamwork',
      'filled-line-teamwork',
      'filled-line-teamwork',
      'filled-line-teamwork',
      'filled-line-teamwork',
      'filled-line-teamwork',
      'filled-line-tee',
      'filled-line-tee',
      'filled-line-telegraph',
      'filled-line-telegraph',
      'filled-line-telegraph',
      'filled-line-telepathy',
      'filled-line-telepathy',
      'filled-line-telepathy',
      'filled-line-telepathy',
      'filled-line-telepathy',
      'filled-line-television-alt',
      'filled-line-television-alt',
      'filled-line-television',
      'filled-line-television',
      'filled-line-television',
      'filled-line-television',
      'filled-line-television',
      'filled-line-template',
      'filled-line-template',
      'filled-line-template',
      'filled-line-template',
      'filled-line-template',
      'filled-line-template',
      'filled-line-template',
      'filled-line-tennis-ball',
      'filled-line-tennis-ball',
      'filled-line-tennis-field',
      'filled-line-tennis-field',
      'filled-line-tent',
      'filled-line-tent',
      'filled-line-tent',
      'filled-line-tent',
      'filled-line-tent',
      'filled-line-test-tube',
      'filled-line-test-tube',
      'filled-line-test-tube',
      'filled-line-test-tube',
      'filled-line-test-tube',
      'filled-line-text-colour',
      'filled-line-text-colour',
      'filled-line-text-lines',
      'filled-line-text-lines',
      'filled-line-text-lines',
      'filled-line-text-lines',
      'filled-line-text-lines',
      'filled-line-text-lines',
      'filled-line-thermometer',
      'filled-line-thermometer',
      'filled-line-tie',
      'filled-line-tie',
      'filled-line-time',
      'filled-line-time',
      'filled-line-time',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-timeline',
      'filled-line-tin-cans-phone',
      'filled-line-tin-cans-phone',
      'filled-line-tomato',
      'filled-line-tomato',
      'filled-line-tooth',
      'filled-line-tooth',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracking-code',
      'filled-line-tracks',
      'filled-line-tracks',
      'filled-line-tracks',
      'filled-line-tracks',
      'filled-line-tracks',
      'filled-line-trademark',
      'filled-line-trademark',
      'filled-line-traffic',
      'filled-line-traffic',
      'filled-line-train',
      'filled-line-train',
      'filled-line-train',
      'filled-line-train',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-transmission',
      'filled-line-trash-empty',
      'filled-line-trash-empty',
      'filled-line-trash-empty',
      'filled-line-trash-empty',
      'filled-line-trash-full',
      'filled-line-trash-full',
      'filled-line-trash-full',
      'filled-line-trash-full',
      'filled-line-trash',
      'filled-line-trash',
      'filled-line-trash',
      'filled-line-trash',
      'filled-line-trash',
      'filled-line-travel-ticket',
      'filled-line-travel-ticket',
      'filled-line-travel-ticket',
      'filled-line-tripod',
      'filled-line-tripod',
      'filled-line-trophy',
      'filled-line-trophy',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-typewriter',
      'filled-line-umbrella',
      'filled-line-umbrella',
      'filled-line-underline',
      'filled-line-underline',
      'filled-line-underline',
      'filled-line-unfollow',
      'filled-line-unfollow',
      'filled-line-unfollow',
      'filled-line-unfollow',
      'filled-line-unlock',
      'filled-line-unlock',
      'filled-line-unlock',
      'filled-line-unsubscribe',
      'filled-line-unsubscribe',
      'filled-line-unsubscribe',
      'filled-line-unsubscribe',
      'filled-line-unsubscribe',
      'filled-line-unsubscribe',
      'filled-line-up-alt',
      'filled-line-up-alt',
      'filled-line-up-alt',
      'filled-line-up-alt',
      'filled-line-up',
      'filled-line-up',
      'filled-line-up',
      'filled-line-upload-queue',
      'filled-line-upload-queue',
      'filled-line-upload-queue',
      'filled-line-upload-queue',
      'filled-line-upload-queue',
      'filled-line-upload',
      'filled-line-upload',
      'filled-line-upload',
      'filled-line-usb-port',
      'filled-line-usb-port',
      'filled-line-user-alt',
      'filled-line-user-alt',
      'filled-line-user',
      'filled-line-user',
      'filled-line-vaccine',
      'filled-line-vaccine',
      'filled-line-verification-fail',
      'filled-line-verification-fail',
      'filled-line-verification-fail',
      'filled-line-verification',
      'filled-line-verification',
      'filled-line-verification',
      'filled-line-vga-port',
      'filled-line-vga-port',
      'filled-line-vga-port',
      'filled-line-vga-port',
      'filled-line-vga-port',
      'filled-line-vga-port',
      'filled-line-vga-port',
      'filled-line-video-call',
      'filled-line-video-call',
      'filled-line-video-call',
      'filled-line-video-recorder',
      'filled-line-video-recorder',
      'filled-line-video-recorder',
      'filled-line-video-recorder',
      'filled-line-video-recorder',
      'filled-line-video',
      'filled-line-video',
      'filled-line-video',
      'filled-line-video',
      'filled-line-vintage-phone',
      'filled-line-vintage-phone',
      'filled-line-vintage-phone',
      'filled-line-vintage-phone',
      'filled-line-vintage-phone',
      'filled-line-vintage-phone',
      'filled-line-vintage-phone',
      'filled-line-viral-marketing',
      'filled-line-viral-marketing',
      'filled-line-viral-marketing',
      'filled-line-viral-marketing',
      'filled-line-viral-marketing',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-virus',
      'filled-line-voicemail',
      'filled-line-voicemail',
      'filled-line-voicemail',
      'filled-line-voicemail',
      'filled-line-volley-ball',
      'filled-line-volley-ball',
      'filled-line-volume-disable',
      'filled-line-volume-disable',
      'filled-line-volume-down-2',
      'filled-line-volume-down-2',
      'filled-line-volume-down',
      'filled-line-volume-down',
      'filled-line-volume-down',
      'filled-line-volume-up',
      'filled-line-volume-up',
      'filled-line-volume',
      'filled-line-volume',
      'filled-line-vr-box',
      'filled-line-vr-box',
      'filled-line-wallet',
      'filled-line-wallet',
      'filled-line-wallet',
      'filled-line-wallet',
      'filled-line-wallet',
      'filled-line-watch-alt',
      'filled-line-watch-alt',
      'filled-line-watch-alt',
      'filled-line-watch-alt',
      'filled-line-watch',
      'filled-line-watch',
      'filled-line-watch',
      'filled-line-water-bottle',
      'filled-line-water-bottle',
      'filled-line-water-glass',
      'filled-line-water-glass',
      'filled-line-watermelon',
      'filled-line-watermelon',
      'filled-line-watermelon',
      'filled-line-watermelon',
      'filled-line-watermelon',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-wave',
      'filled-line-webcam',
      'filled-line-webcam',
      'filled-line-webcam',
      'filled-line-webcam',
      'filled-line-wheelchair',
      'filled-line-wheelchair',
      'filled-line-wheelchair',
      'filled-line-white-hat',
      'filled-line-white-hat',
      'filled-line-white-hat',
      'filled-line-white-hat',
      'filled-line-white-hat',
      'filled-line-white-hat',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-alt',
      'filled-line-wifi-router-alt',
      'filled-line-wifi-router-alt',
      'filled-line-wifi-router-alt',
      'filled-line-wifi-router-alt',
      'filled-line-wifi-router-alt',
      'filled-line-wifi-router',
      'filled-line-wifi-router',
      'filled-line-wifi-router',
      'filled-line-wifi-router',
      'filled-line-wifi-router',
      'filled-line-wifi',
      'filled-line-wifi',
      'filled-line-wifi',
      'filled-line-wifi',
      'filled-line-wifi',
      'filled-line-work',
      'filled-line-work',
      'filled-line-work',
      'filled-line-work',
      'filled-line-work',
      'filled-line-work',
      'filled-line-workflow',
      'filled-line-workflow',
      'filled-line-workflow',
      'filled-line-workflow',
      'filled-line-workflow',
      'filled-line-workflow',
      'filled-line-workflow',
      'filled-line-wrench',
      'filled-line-wrench',
      'filled-line-write',
      'filled-line-write',
      'filled-line-write',
      'filled-line-write',
      'filled-line-write',
      'filled-line-x-ray',
      'filled-line-x-ray',
      'filled-line-x-ray',
      'filled-line-x-ray',
      'filled-line-x-ray',
      'filled-line-yogurt',
      'filled-line-yogurt',
      'filled-line-yogurt',
    ]
  ]

  static getPathsForText(text) {
    let allList = [];

    // Juntar las listas de iconos
    this.iconList.forEach(list => {
      allList = [...allList, ...list]
    });

    // Filtrar las repeticiones del texto dentro del array
    const filteredList = allList.filter(watching => watching === text);
  
    // Si hay alguna repetición, retorna el objeto con la cantidad de repeticiones
    if(filteredList) return { iconName: text, paths: filteredList.length };

    return {};
  }
}