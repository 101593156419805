import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './_chips.scss'
import Icon from '../../icons'

const InputChip = ({ value, onClose, variant, disabled }) => {
  const mainRef = React.createRef(null)
  const [visible, setVisible] = useState(true)

  const classesComponent = [
    styles.chip,
    disabled ? styles.disabled : null,
    variant ? styles.variant : null
  ]

  const onPress = () => {
    mainRef.current.className = classesComponent
      .concat(styles.pressed)
      .join(' ')
  }

  const mouseUp = () => {
    mainRef.current.className = classesComponent.join(' ')
  }

  const clickHandler = () => {
    onClose()

    setVisible(false)
  }

  return (
    visible && (
      <div ref={mainRef} className={classesComponent.join(' ')}>
        {value}
        <Icon
          onMouseDown={onPress}
          onClick={clickHandler}
          onMouseUp={mouseUp}
          name='old-plus'
          size='2'
        />
      </div>
    )
  )
}

InputChip.defaultProps = {
  variant: false,
  disabled: false
}

InputChip.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}

export default InputChip
