import React from 'react'
import classnames from 'classnames'
import styles from '../_tab.scss'

const TabTitle = ({ title, disabled, active, isLast, onClick, offsetLeft }) => (
  <button
    type='button'
    className={classnames(styles.tabTitle, {
      [styles.disabled]: disabled,
      [styles.pressed]: active,
      [styles.lastTitle]: isLast
    })}
    onClick={onClick}
    style={{ left: offsetLeft }}
  >
    <span>{title || 'Título'}</span>
  </button>
)

export default TabTitle
