import React from 'react'
import { DefaultCard, Mastercard } from './assets'

const IconCard = ({ variant }) => {
  switch (variant) {
    case 'mastercard':
      return <Mastercard />

    default:
      return <DefaultCard />
  }
}

IconCard.defaultProps = {}

export default IconCard
