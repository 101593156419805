import React from 'react'
import PropTypes from 'prop-types'
import DefaultStamp from './stamps/DefaultStamp'
import CheckStamp from './stamps/CheckStamp'

const stampSvg = {
  default: <DefaultStamp />,
  check: <CheckStamp />
}

const Stamp = ({ type, width, height }) => {
let stampSvg;

  switch (type) {
    case "default":
     stampSvg= <DefaultStamp width={width} height={height}/>
      break;

    case "check":
      stampSvg= <CheckStamp width={width} height={height}/>
      break;
  
    default:
      break;
  }
  return(stampSvg)
}
//  stampSvg[type]

export default Stamp

Stamp.defaultProps = {
  type: 'default',
  width: '114px',
  height: '114px'
}

Stamp.prototype = {
  type: PropTypes.oneOf([undefined, 'default', 'check']),
  width: PropTypes.string,
  height: PropTypes.string
}
