/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Icon
  // eslint-disable-next-line import/no-unresolved
} from '@design-system-coopeuch/web'
import styles from './_modal.scss'

const Modal = ({ children, isOpen, closeModal, unableToClose, closeEscKey, closeClickOutside, title }) => {
  useEffect(() => {
    if (!unableToClose && closeEscKey) {
      const closeKeyDown = (e) => {
        if (e.keyCode === 27) {
          closeModal();
        }
      };
      window.addEventListener('keydown', closeKeyDown);
      return () => window.removeEventListener('keydown', closeKeyDown);
    }
  }, []);

  const handleClickKeypressInModalContent = (e) => e.stopPropagation();
  const handleClickCloseModal = () => {
    if (!unableToClose && closeClickOutside) closeModal();
  };

  
  return (
    <div
      role="button"
      onClick={handleClickCloseModal}
      tabIndex="0"
      className={`${styles.modalContainer} ${isOpen ? styles.modalContainerOpen : ''}`}
    >
      <div
        onClick={handleClickKeypressInModalContent}
        role="button"
        tabIndex="0"
        className={styles.modalContent}
      >
         {typeof title === 'string' ? (
      <h4 className={styles.textTitle}>{title}</h4>
    ) : (
      title
    )}
        {!unableToClose && (
          <Icon
            className={styles.modalClose}
            name="old-line-cross"
            onClick={closeModal}
            size={4}
          />
        )}

        {children}
        
      </div>
    </div>
  );
};

// Modal.defaultProps = {
//   title: ''
// }

Modal.propTypes = {
  title: PropTypes.string
}

export default Modal;
