import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import Card from '..'
import Icons from '../../icons'
import styles from './_cardSearch.scss'

const CardSearch = ({ title, routes, description, className }) => (
  <Card className={[styles.cardSearch, className].join(' ').trim()}>
    <div className={styles.headerSection}>
      <h5>{title}</h5>
      {routes && (
        <ul className={styles.routes}>
          {routes.map((route) => (
            <li key={`route-${nanoid}`}>
              {route} <Icons name='old-arrow-right' size={1} />
            </li>
          ))}
        </ul>
      )}
    </div>
    <p>{description}</p>
  </Card>
)

CardSearch.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string
}

export default CardSearch
