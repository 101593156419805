import React from 'react'

export const DefaultCard = () => (
  <svg width='40px' height='24px' viewBox='0 0 40 24'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-94.000000, -445.000000)'>
        <g transform='translate(94.000000, 445.000000)'>
          <rect
            fill='#E7E8E3'
            x='4.54747351e-13'
            y='0'
            width='40'
            height='24'
            rx='3'
          />
          <circle
            fill='#D33331'
            cx='30.0722892'
            cy='17.0722892'
            r='3.07228916'
          />
          <circle
            fill='#E9A03D'
            cx='33.9277108'
            cy='17.0722892'
            r='3.07228916'
          />
          <rect
            fill='#34495E'
            x='-2.84217094e-14'
            y='6'
            width='40'
            height='4'
          />
        </g>
      </g>
    </g>
  </svg>
)

export const Mastercard = () => (
  <svg width='40px' height='24px' viewBox='0 0 40 24'>
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-198.000000, -617.000000)'>
        <g transform='translate(94.000000, 581.000000)'>
          <g transform='translate(104.000000, 36.000000)'>
            <ellipse
              fill='#D33331'
              cx='12.2891566'
              cy='12'
              rx='12.2891566'
              ry='12'
            />
            <ellipse
              fill='#E9A03D'
              cx='27.7108434'
              cy='12'
              rx='12.2891566'
              ry='12'
            />
            <path
              d='M20.0006817,2.65613373 C22.7925145,4.85586274 24.5783133,8.22429056 24.5783133,12 C24.5783133,15.7757094 22.7925145,19.1441373 20.0006817,21.3438663 C17.2077426,19.1446222 15.4216867,15.7759812 15.4216867,12 C15.4216867,8.28462986 17.1508641,4.9636279 19.8662441,2.76248712 Z'
              id='Combined-Shape'
              fill='#D96035'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)
