import React from 'react'

const INFOMOBILE = () => {
  const elm = (
    <svg
      width='112px'
      height='112px'
      viewBox='0 0 112 112'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <polygon
          id='path-1'
          points='0.000124444444 0 112 0 112 112 0.000124444444 112'
        ></polygon>
        <polygon
          id='path-3'
          points='0.0448 0.22848 66.9741333 0.22848 66.9741333 65.3333333 0.0448 65.3333333'
        ></polygon>
      </defs>
      <g
        id='MENSAJERIAS'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='APP-INFO' transform='translate(-104.000000, -48.000000)'>
          <g id='INFO-APP' transform='translate(104.000000, 48.000000)'>
            <g>
              <g id='ROSTRO-Y-CUERPO'>
                <g id='Group-3'>
                  <mask id='mask-2' fill='white'>
                    <use></use>
                  </mask>
                  <g id='Clip-2'></g>
                  <path
                    d='M112.000124,56 C112.000124,86.9281778 86.92768,112 56.0001244,112 C25.0719467,112 0.000124444444,86.9281778 0.000124444444,56 C0.000124444444,25.0718222 25.0719467,0 56.0001244,0 C86.92768,0 112.000124,25.0718222 112.000124,56'
                    id='Fill-1'
                    fill='#C8E8FB'
                    mask='url(#mask-2)'
                  ></path>
                </g>
                <g id='Group-6' transform='translate(22.400000, 46.666667)'>
                  <mask id='mask-4' fill='white'>
                    <use></use>
                  </mask>
                  <g id='Clip-5'></g>
                  <path
                    d='M39.3860444,0.22848 L27.6322667,0.22848 C12.3965333,0.22848 0.0448,12.5802133 0.0448,27.8165689 L0.0448,54.1701689 C9.3968,61.1801244 21.0136889,65.3334578 33.6,65.3334578 C41.4518222,65.3334578 48.9253333,63.7144356 55.7075556,60.7968356 L55.7075556,36.7112356 L66.9741333,36.8456356 L66.9741333,27.8165689 C66.9741333,12.5802133 54.6224,0.22848 39.3860444,0.22848'
                    id='Fill-4'
                    fill='#367BB2'
                    mask='url(#mask-4)'
                  ></path>
                </g>
                <polygon
                  id='Fill-7'
                  fill='#F4B6A8'
                  points='63.09128 50.3149422 48.7279022 50.3149422 50.7799911 31.8480089 61.0391911 31.8480089'
                ></polygon>
                <line
                  x1='56.3075644'
                  y1='24.0460889'
                  x2='55.5117422'
                  y2='24.0460889'
                  id='Fill-9'
                  fill='#282E5E'
                ></line>
                <path
                  d='M56.31248,42.5134578 L55.50608,42.5134578 C50.6291022,42.5134578 46.6761244,38.5598578 46.6761244,33.68288 L46.6761244,15.8387911 C46.6761244,13.1943467 48.81968,11.0514133 51.4635022,11.0514133 L60.3550578,11.0514133 C62.9995022,11.0514133 65.1430578,13.1943467 65.1430578,15.8387911 L65.1430578,33.68288 C65.1430578,38.5598578 61.1894578,42.5134578 56.31248,42.5134578'
                  id='Fill-11'
                  fill='#F4B6A8'
                ></path>
                <path
                  d='M47.5539556,30.5970311 L42.2688,27.4933867 C41.1008889,26.8076978 40.7107556,25.3050311 41.3964444,24.1377422 C42.0815111,22.9704533 43.5841778,22.5796978 44.7514667,23.2653867 L50.0366222,26.3690311 L47.5539556,30.5970311 Z'
                  id='Fill-21'
                  fill='#F4B6A8'
                ></path>
                <path
                  d='M64.2658489,30.5964711 L69.5503822,27.4928267 C70.7176711,26.8071378 71.1084267,25.3044711 70.42336,24.1371822 C69.7370489,22.9692711 68.2350044,22.5791378 67.0670933,23.2648267 L61.78256,26.3684711 L64.2658489,30.5964711 Z'
                  id='Fill-25'
                  fill='#F4B6A8'
                ></path>
                <polygon
                  id='Fill-33'
                  fill='#282E5E'
                  points='46.6758756 15.4327911 46.6758756 24.3243467 48.8909867 15.4327911'
                ></polygon>
                <polygon
                  id='Fill-35'
                  fill='#282E5E'
                  points='65.1432444 15.4327911 65.1432444 24.3243467 62.9281333 15.4327911'
                ></polygon>
                <path
                  d='M60.3550578,11.0511022 L51.4635022,11.0511022 C48.81968,11.0511022 46.6761244,13.1940356 46.6761244,15.83848 L65.1430578,15.83848 C65.1430578,13.1940356 62.9995022,11.0511022 60.3550578,11.0511022'
                  id='Fill-37'
                  fill='#282E5E'
                ></path>
                <path
                  d='M59.05592,18.6064978 C56.6317422,18.7906756 53.1672089,18.3003644 48.3406311,16.1742311 L48.2062311,16.15992 C48.2062311,16.1462311 48.2080978,16.13192 48.20872,16.1176089 C48.2074756,16.1176089 48.2068533,16.1176089 48.2062311,16.1169867 L48.20872,16.1144978 C48.2329867,13.4880978 50.3622311,11.3632089 52.9942311,11.3526311 L58.4424089,11.3296089 L58.4542311,10.6694311 C58.46792,9.92089778 59.0789422,9.32045333 59.8274756,9.32045333 C60.5865867,9.32045333 61.2019644,9.93520889 61.2019644,10.69432 L61.2019644,10.6880978 C61.2019644,9.93334222 61.8142311,9.32045333 62.5696089,9.32045333 C63.3249867,9.32045333 63.9378756,9.93334222 63.9378756,10.6880978 C63.9378756,9.93334222 64.5501422,9.32045333 65.30552,9.32045333 C66.06152,9.32045333 66.6731644,9.93334222 66.6731644,10.6880978 L66.6731644,11.5610756 C66.6731644,11.5610756 66.6731644,17.4846311 59.05592,18.6064978'
                  id='Fill-39'
                  fill='#282E5E'
                ></path>
                <path
                  d='M90.6519289,41.3510222 L83.95184,38.7177778 L83.5872178,40.6497778 L83.0545956,33.9167111 L81.4610844,33.9416 L80.9017067,41.8550222 L79.1769067,43.6370667 L81.3721067,49.5444444 L78.1676622,83.2981333 C77.6362844,86.8385778 80.7305956,89.9845333 84.7433067,89.9845333 C88.71744,89.9845333 91.7999289,86.8964444 91.3313956,83.3846222 L89.0161067,50.1486222 L90.6519289,41.3510222 Z'
                  id='Fill-41'
                  fill='#F4B6A8'
                ></path>
                <polygon
                  id='Fill-43'
                  fill='#EF8674'
                  points='88.8192978 45.5176089 82.8434756 44.3907644 83.4171644 40.6972533 83.9802756 40.7843644 83.4912089 43.9334311 88.9244533 44.9582311'
                ></polygon>
                <path
                  d='M62.2618578,89.9979733 L52.9409689,89.9979733 C52.1538578,89.9979733 51.51608,89.3608178 51.51608,88.5737067 L51.51608,70.4017067 C51.51608,69.6152178 52.1538578,68.97744 52.9409689,68.97744 L62.2618578,68.97744 C63.0489689,68.97744 63.6861244,69.6152178 63.6861244,70.4017067 L63.6861244,88.5737067 C63.6861244,89.3608178 63.0489689,89.9979733 62.2618578,89.9979733'
                  id='Fill-45'
                  fill='#F6F3F5'
                ></path>
                <path
                  d='M54.8966756,71.3441244 C54.8966756,71.9035022 54.4430756,72.3583467 53.8824533,72.3583467 C53.3230756,72.3583467 52.8682311,71.9035022 52.8682311,71.3441244 C52.8682311,70.7841244 53.3230756,70.3299022 53.8824533,70.3299022 C54.4430756,70.3299022 54.8966756,70.7841244 54.8966756,71.3441244'
                  id='Fill-47'
                  fill='#D4D0D5'
                ></path>
                <path
                  d='M29.8242311,110.547733 C25.8861867,110.239733 22.4452978,106.983022 22.4452978,102.5752 L22.4452978,98.0796444 L22.4452978,84.1157333 L22.4452978,76.9894222 L34.2264533,76.9894222 L34.2264533,94.3400889 L49.4105422,82.5402667 L58.6293867,82.2677333 L59.3057422,88.3524444 L50.8684089,90.6297778 L35.3694756,108.222489 C34.0839644,109.6816 32.2440533,110.595644 30.2989867,110.570133 C30.1440533,110.567644 29.9853867,110.5608 29.8242311,110.547733'
                  id='Fill-49'
                  fill='#F4B6A8'
                ></path>
                <path
                  d='M55.9642844,56.2509422 C61.1617067,56.2509422 65.7418844,52.8635644 68.4541511,47.7115644 C66.3186844,47.1814311 64.0861511,46.8952089 61.7857956,46.8952089 L50.03264,46.8952089 C47.7708622,46.8952089 45.5744178,47.1733422 43.4706844,47.6866756 C46.1810844,52.8498756 50.7612622,56.2509422 55.9642844,56.2509422'
                  id='Fill-51'
                  fill='#F4B6A8'
                ></path>
              </g>
              <g id='EXPRESIONES' transform='translate(42.451679, 20.888846)'>
                <path
                  d='M15.7244184,11.3658477 L11.6152628,11.3602477 C11.4267295,11.3596255 11.274285,11.2065588 11.274285,11.0174033 C11.2749073,10.8294922 11.4279739,10.6764255 11.615885,10.6764255 L11.6165073,10.6764255 L14.875085,10.6801588 L13.1975739,2.9652255 C13.1577517,2.7804255 13.2747295,2.59811439 13.4589073,2.55829217 C13.6468184,2.5172255 13.8260184,2.63544772 13.8664628,2.8196255 L15.7244184,11.3658477 Z'
                  id='Fill-13'
                  fill='#EF8674'
                ></path>
                <path
                  d='M13.6602584,15.2498833 C11.6772362,15.2498833 10.582125,14.4304166 10.5199028,14.3818833 C10.3699473,14.2667722 10.3419473,14.0521055 10.4576806,13.9027722 C10.5727917,13.7521944 10.7868362,13.7254388 10.9361695,13.8393055 C11.0151917,13.8990388 12.8146584,15.2144166 16.0825695,14.1572611 C16.2617695,14.1000166 16.4546584,14.1989499 16.5131473,14.3781499 C16.5710139,14.5579722 16.4727028,14.7502388 16.2922584,14.8087277 C15.2929695,15.1316611 14.412525,15.2498833 13.6602584,15.2498833'
                  id='Fill-15'
                  fill='#EF8674'
                ></path>
                <path
                  d='M6.79758282,1.38353661 C6.68060505,1.38353661 6.56673838,1.32318105 6.50264949,1.21553661 C6.40620505,1.05313661 6.46033838,0.843447721 6.62273838,0.747625498 C9.2553606,-0.812285613 11.8282495,0.54353661 11.9371384,0.602647721 C12.1026495,0.692869943 12.1642495,0.899447721 12.0746495,1.06495883 C11.9862939,1.23171439 11.7784717,1.29269217 11.6117162,1.20495883 C11.5886939,1.19064772 9.26469393,-0.0226856126 6.97118282,1.3356255 C6.91642727,1.36860328 6.85669393,1.38353661 6.79758282,1.38353661'
                  id='Fill-17'
                  fill='#282E5E'
                ></path>
                <path
                  d='M20.1182406,1.38353661 C20.0591295,1.38353661 19.9993962,1.36860328 19.9446406,1.3356255 C17.6424184,-0.0276633904 15.3271295,1.19189217 15.3041073,1.20433661 C15.1373517,1.29455883 14.9301517,1.23233661 14.8405517,1.06620328 C14.7503295,0.900069943 14.8125517,0.692869943 14.9780628,0.602647721 C15.0869517,0.54353661 17.6598406,-0.812285613 20.293085,0.747625498 C20.455485,0.843447721 20.5096184,1.05313661 20.4131739,1.21553661 C20.349085,1.32318105 20.2352184,1.38353661 20.1182406,1.38353661'
                  id='Fill-19'
                  fill='#282E5E'
                ></path>
                <path
                  d='M3.44280949,6.13588328 C3.39365393,6.13588328 3.34387616,6.1253055 3.29658727,6.1029055 L0.195431712,4.63632772 C0.0249428234,4.55543883 -0.0478571766,4.35134994 0.0330317123,4.18023883 C0.113298379,4.00912772 0.31800949,3.93632772 0.488498379,4.01783883 L3.58903171,5.48441661 C3.76014282,5.5653055 3.83294282,5.76939439 3.75205393,5.9405055 C3.69356505,6.0637055 3.57036505,6.13588328 3.44280949,6.13588328'
                  id='Fill-23'
                  fill='#EF8674'
                ></path>
                <path
                  d='M23.4730139,6.13526105 C23.3454584,6.13526105 23.2222584,6.06308328 23.1637695,5.93988328 C23.0828806,5.76877217 23.1556806,5.56468328 23.3267917,5.48379439 L26.427325,4.01721661 C26.5971917,3.93694994 26.802525,4.00912772 26.8827917,4.17961661 C26.9636806,4.35072772 26.8908806,4.55481661 26.7203917,4.6357055 L23.6192362,6.10228328 C23.5719473,6.12468328 23.5221695,6.13526105 23.4730139,6.13526105'
                  id='Fill-27'
                  fill='#EF8674'
                ></path>
                <path
                  d='M17.6299739,4.80333217 L17.6299739,4.80333217 C17.0637517,4.80333217 16.6045517,4.34475439 16.6045517,3.77790994 C16.6045517,3.2110655 17.0637517,2.7518655 17.6299739,2.7518655 C18.1968184,2.7518655 18.6560184,3.2110655 18.6560184,3.77790994 C18.6560184,4.34475439 18.1968184,4.80333217 17.6299739,4.80333217'
                  id='Fill-29'
                  fill='#1F1E1E'
                ></path>
                <path
                  d='M9.28584949,4.80333217 L9.28584949,4.80333217 C8.71900505,4.80333217 8.25980505,4.34475439 8.25980505,3.77790994 C8.25980505,3.2110655 8.71900505,2.7518655 9.28584949,2.7518655 C9.85207171,2.7518655 10.3112717,3.2110655 10.3112717,3.77790994 C10.3112717,4.34475439 9.85207171,4.80333217 9.28584949,4.80333217'
                  id='Fill-31'
                  fill='#1F1E1E'
                ></path>
              </g>
            </g>
            <g id='INFO' transform='translate(84.000000, 8.000000)'>
              <circle id='Oval' fill='#FFFFFF' cx='10' cy='10' r='8'></circle>
              <g
                id='00.Token/Icons/Solid/16x16/information-Copy'
                fill='#005D8F'
              >
                <path
                  d='M10,1.25 C5.17,1.25 1.25,5.17 1.25,10 C1.25,14.83 5.17,18.75 10,18.75 C14.83,18.75 18.75,14.83 18.75,10 C18.75,5.17 14.83,1.25 10,1.25 Z M10,14.375 C9.51875,14.375 9.125,13.98125 9.125,13.5 L9.125,10 C9.125,9.51875 9.51875,9.125 10,9.125 C10.48125,9.125 10.875,9.51875 10.875,10 L10.875,13.5 C10.875,13.98125 10.48125,14.375 10,14.375 Z M10,7.375 C9.51675084,7.375 9.125,6.98324916 9.125,6.5 C9.125,6.01675084 9.51675084,5.625 10,5.625 C10.4832492,5.625 10.875,6.01675084 10.875,6.5 C10.875,6.98324916 10.4832492,7.375 10,7.375 Z'
                  id='color-icono'
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default INFOMOBILE
