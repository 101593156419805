import React from 'react'
import PropTypes from 'prop-types'
import styles from './_cardcontent.scss'
import Bullets from '../../bullets'

const CardContent = ({
  content,
  title,
  typeList,
  bullets,
  className,
  children
}) => {
  const cardContentStyle = [styles.cardContentContainer]

  const getContent = () => {
    let appliedContent
    if (content) appliedContent = <p>{content}</p>
    if (bullets)
      appliedContent = <Bullets typeList={typeList} items={bullets} />

    return appliedContent
  }

  return (
    <div className={cardContentStyle.concat(className).join(' ')}>
      {title && (
        <div className={styles.cardHeaderTitle}>
          <h6>{title}</h6>
        </div>
      )}
      {getContent()}
      {children}
    </div>
  )
}

CardContent.defaultProps = {
  className: ''
}

CardContent.propTypes = {
  title: PropTypes.string,
  typeList: PropTypes.string,
  content: PropTypes.string,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      type: PropTypes.string,
      disabled: PropTypes.bool,
      prefixType: PropTypes.string,
      contentType: PropTypes.string
    })
  ),
  className: PropTypes.string,
  children: PropTypes.node
}

export default CardContent
