import React from 'react'
import PropTypes from 'prop-types'
import styles from './_publicFooter.scss'

const PublicFooter = ({ children, className }) => (
  <div
    className={[styles.publicFooter, styles.footerDesktop]
      .concat(className)
      .join(' ')}
  >
    {children}
  </div>
)

PublicFooter.defaultProps = {}

PublicFooter.propTypes = {
  className: PropTypes.string
}

export default PublicFooter
