import * as React from "react"

const infoApp = (props) => (
  <svg
    {...props}
    width="140px"
    height="128px"
    viewBox="0 0 140 128"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="14.0001244 8 126 8 126 120 14.0001244 120" />
      <polygon
        id="path-3"
        points="0 0 66.9293333 0 66.9293333 65.1048533 0 65.1048533"
      />
    </defs>
    <g
      id="Molecula-app-ilustracion-info"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <mask id="mask-2" fill="white">
        <use xlinkHref="#path-1" />
      </mask>
      <g id="Clip-2" />
      <path
        d="M70.0001244,120 C39.0719467,120 14.0001244,94.9281778 14.0001244,64 C14.0001244,33.0718222 39.0719467,8 70.0001244,8 C100.92768,8 126.000124,33.0718222 126.000124,64 C126.000124,94.9281778 100.92768,120 70.0001244,120 Z"
        id="Fill-1"
        fill="#C0E9FD"
        mask="url(#mask-2)"
      />
      <g id="Group-6" mask="url(#mask-2)">
        <g transform="translate(36.444800, 54.895147)">
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-3" />
          </mask>
          <g id="Clip-5" stroke="none" fill="none" />
          <path
            d="M39.3412444,0 L27.5874667,0 C12.3517333,0 0,12.3517333 0,27.5880889 L0,53.9416889 C9.352,60.9516444 20.9688889,65.1049778 33.5552,65.1049778 C41.4070222,65.1049778 48.8805333,63.4859556 55.6627556,60.5683556 L55.6627556,36.4827556 L66.9293333,36.6171556 L66.9293333,27.5880889 C66.9293333,12.3517333 54.5776,0 39.3412444,0"
            id="Fill-4"
            stroke="none"
            fill="#007DB7"
            fillRule="evenodd"
            mask="url(#mask-4)"
          />
        </g>
      </g>
      <polygon
        id="Fill-7"
        fill="#FFB3A5"
        mask="url(#mask-2)"
        points="77.09128 58.3149422 62.7279022 58.3149422 64.7799911 39.8480089 75.0391911 39.8480089"
      />
      <line
        x1={70.3075644}
        y1={32.0460889}
        x2={69.5117422}
        y2={32.0460889}
        id="Fill-9"
        fill="#282E5E"
        mask="url(#mask-2)"
      />
      <path
        d="M70.31248,50.5134578 L69.50608,50.5134578 C64.6291022,50.5134578 60.6761244,46.5598578 60.6761244,41.68288 L60.6761244,23.8387911 C60.6761244,21.1943467 62.81968,19.0514133 65.4635022,19.0514133 L74.3550578,19.0514133 C76.9995022,19.0514133 79.1430578,21.1943467 79.1430578,23.8387911 L79.1430578,41.68288 C79.1430578,46.5598578 75.1894578,50.5134578 70.31248,50.5134578"
        id="Fill-11"
        fill="#FFB3A5"
        mask="url(#mask-2)"
      />
      <path
        d="M61.5539556,38.5970311 L56.2688,35.4933867 C55.1008889,34.8076978 54.7107556,33.3050311 55.3964444,32.1377422 C56.0815111,30.9704533 57.5841778,30.5796978 58.7514667,31.2653867 L64.0366222,34.3690311 L61.5539556,38.5970311 Z"
        id="Fill-21"
        fill="#FFB3A5"
        mask="url(#mask-2)"
      />
      <path
        d="M78.2658489,38.5964711 L83.5503822,35.4928267 C84.7176711,34.8071378 85.1084267,33.3044711 84.42336,32.1371822 C83.7370489,30.9692711 82.2350044,30.5791378 81.0670933,31.2648267 L75.78256,34.3684711 L78.2658489,38.5964711 Z"
        id="Fill-25"
        fill="#FFB3A5"
        mask="url(#mask-2)"
      />
      <polygon
        id="Fill-33"
        fill="#272E61"
        mask="url(#mask-2)"
        points="60.6758756 23.4327911 60.6758756 32.3243467 62.8909867 23.4327911"
      />
      <polygon
        id="Fill-35"
        fill="#272E61"
        mask="url(#mask-2)"
        points="79.1432444 23.4327911 79.1432444 32.3243467 76.9281333 23.4327911"
      />
      <path
        d="M74.3550578,19.0511022 L65.4635022,19.0511022 C62.81968,19.0511022 60.6761244,21.1940356 60.6761244,23.83848 L79.1430578,23.83848 C79.1430578,21.1940356 76.9995022,19.0511022 74.3550578,19.0511022"
        id="Fill-37"
        fill="#272E61"
        mask="url(#mask-2)"
      />
      <path
        d="M73.05592,26.6064978 C70.6317422,26.7906756 67.1672089,26.3003644 62.3406311,24.1742311 L62.2062311,24.15992 C62.2062311,24.1462311 62.2080978,24.13192 62.20872,24.1176089 C62.2074756,24.1176089 62.2068533,24.1176089 62.2062311,24.1169867 L62.20872,24.1144978 C62.2329867,21.4880978 64.3622311,19.3632089 66.9942311,19.3526311 L72.4424089,19.3296089 L72.4542311,18.6694311 C72.46792,17.9208978 73.0789422,17.3204533 73.8274756,17.3204533 C74.5865867,17.3204533 75.2019644,17.9352089 75.2019644,18.69432 L75.2019644,18.6880978 C75.2019644,17.9333422 75.8142311,17.3204533 76.5696089,17.3204533 C77.3249867,17.3204533 77.9378756,17.9333422 77.9378756,18.6880978 C77.9378756,17.9333422 78.5501422,17.3204533 79.30552,17.3204533 C80.06152,17.3204533 80.6731644,17.9333422 80.6731644,18.6880978 L80.6731644,19.5610756 C80.6731644,19.5610756 80.6731644,25.4846311 73.05592,26.6064978"
        id="Fill-39"
        fill="#272E61"
        mask="url(#mask-2)"
      />
      <path
        d="M104.651929,49.3510222 L97.95184,46.7177778 L97.5872178,48.6497778 L97.0545956,41.9167111 L95.4610844,41.9416 L94.9017067,49.8550222 L93.1769067,51.6370667 L95.3721067,57.5444444 L92.1676622,91.2981333 C91.6362844,94.8385778 94.7305956,97.9845333 98.7433067,97.9845333 C102.71744,97.9845333 105.799929,94.8964444 105.331396,91.3846222 L103.016107,58.1486222 L104.651929,49.3510222 Z"
        id="Fill-41"
        fill="#FFB3A5"
        mask="url(#mask-2)"
      />
      <polygon
        id="Fill-43"
        fill="#FF7F6E"
        mask="url(#mask-2)"
        points="102.819298 53.5176089 96.8434756 52.3907644 97.4171644 48.6972533 97.9802756 48.7843644 97.4912089 51.9334311 102.924453 52.9582311"
      />
      <path
        d="M76.2618578,97.9979733 L66.9409689,97.9979733 C66.1538578,97.9979733 65.51608,97.3608178 65.51608,96.5737067 L65.51608,78.4017067 C65.51608,77.6152178 66.1538578,76.97744 66.9409689,76.97744 L76.2618578,76.97744 C77.0489689,76.97744 77.6861244,77.6152178 77.6861244,78.4017067 L77.6861244,96.5737067 C77.6861244,97.3608178 77.0489689,97.9979733 76.2618578,97.9979733"
        id="Fill-45"
        fill="#F6F3F5"
        mask="url(#mask-2)"
      />
      <path
        d="M68.8966756,79.3441244 C68.8966756,79.9035022 68.4430756,80.3583467 67.8824533,80.3583467 C67.3230756,80.3583467 66.8682311,79.9035022 66.8682311,79.3441244 C66.8682311,78.7841244 67.3230756,78.3299022 67.8824533,78.3299022 C68.4430756,78.3299022 68.8966756,78.7841244 68.8966756,79.3441244"
        id="Fill-47"
        fill="#D4D0D5"
        mask="url(#mask-2)"
      />
      <path
        d="M43.8242311,118.547733 C39.8861867,118.239733 36.4452978,114.983022 36.4452978,110.5752 L36.4452978,106.079644 L36.4452978,92.1157333 L36.4452978,84.9894222 L48.2264533,84.9894222 L48.2264533,102.340089 L63.4105422,90.5402667 L72.6293867,90.2677333 L73.3057422,96.3524444 L64.8684089,98.6297778 L49.3694756,116.222489 C48.0839644,117.6816 46.2440533,118.595644 44.2989867,118.570133 C44.1440533,118.567644 43.9853867,118.5608 43.8242311,118.547733"
        id="Fill-49"
        fill="#FFB3A5"
        mask="url(#mask-2)"
      />
      <path
        d="M69.9642844,64.2509422 C75.1617067,64.2509422 79.7418844,60.8635644 82.4541511,55.7115644 C80.3186844,55.1814311 78.0861511,54.8952089 75.7857956,54.8952089 L64.03264,54.8952089 C61.7708622,54.8952089 59.5744178,55.1733422 57.4706844,55.6866756 C60.1810844,60.8498756 64.7612622,64.2509422 69.9642844,64.2509422"
        id="Fill-51"
        fill="#FFB3A5"
        mask="url(#mask-2)"
      />
      <g id="EXPRESIONES" mask="url(#mask-2)">
        <g transform="translate(56.451679, 28.888846)">
          <path
            d="M15.7244184,11.3658477 L11.6152628,11.3602477 C11.4267295,11.3596255 11.274285,11.2065588 11.274285,11.0174033 C11.2749073,10.8294922 11.4279739,10.6764255 11.615885,10.6764255 L11.6165073,10.6764255 L14.875085,10.6801588 L13.1975739,2.9652255 C13.1577517,2.7804255 13.2747295,2.59811439 13.4589073,2.55829217 C13.6468184,2.5172255 13.8260184,2.63544772 13.8664628,2.8196255 L15.7244184,11.3658477 Z"
            id="Fill-13"
            fill="#FF7F6E"
          />
          <path
            d="M13.6602584,15.2498833 C11.6772362,15.2498833 10.582125,14.4304166 10.5199028,14.3818833 C10.3699473,14.2667722 10.3419473,14.0521055 10.4576806,13.9027722 C10.5727917,13.7521944 10.7868362,13.7254388 10.9361695,13.8393055 C11.0151917,13.8990388 12.8146584,15.2144166 16.0825695,14.1572611 C16.2617695,14.1000166 16.4546584,14.1989499 16.5131473,14.3781499 C16.5710139,14.5579722 16.4727028,14.7502388 16.2922584,14.8087277 C15.2929695,15.1316611 14.412525,15.2498833 13.6602584,15.2498833"
            id="Fill-15"
            fill="#FF7F6E"
          />
          <path
            d="M6.79758282,1.38353661 C6.68060505,1.38353661 6.56673838,1.32318105 6.50264949,1.21553661 C6.40620505,1.05313661 6.46033838,0.843447721 6.62273838,0.747625498 C9.2553606,-0.812285613 11.8282495,0.54353661 11.9371384,0.602647721 C12.1026495,0.692869943 12.1642495,0.899447721 12.0746495,1.06495883 C11.9862939,1.23171439 11.7784717,1.29269217 11.6117162,1.20495883 C11.5886939,1.19064772 9.26469393,-0.0226856126 6.97118282,1.3356255 C6.91642727,1.36860328 6.85669393,1.38353661 6.79758282,1.38353661"
            id="Fill-17"
            fill="#272E61"
          />
          <path
            d="M20.1182406,1.38353661 C20.0591295,1.38353661 19.9993962,1.36860328 19.9446406,1.3356255 C17.6424184,-0.0276633904 15.3271295,1.19189217 15.3041073,1.20433661 C15.1373517,1.29455883 14.9301517,1.23233661 14.8405517,1.06620328 C14.7503295,0.900069943 14.8125517,0.692869943 14.9780628,0.602647721 C15.0869517,0.54353661 17.6598406,-0.812285613 20.293085,0.747625498 C20.455485,0.843447721 20.5096184,1.05313661 20.4131739,1.21553661 C20.349085,1.32318105 20.2352184,1.38353661 20.1182406,1.38353661"
            id="Fill-19"
            fill="#272E61"
          />
          <path
            d="M3.44280949,6.13588328 C3.39365393,6.13588328 3.34387616,6.1253055 3.29658727,6.1029055 L0.195431712,4.63632772 C0.0249428234,4.55543883 -0.0478571766,4.35134994 0.0330317123,4.18023883 C0.113298379,4.00912772 0.31800949,3.93632772 0.488498379,4.01783883 L3.58903171,5.48441661 C3.76014282,5.5653055 3.83294282,5.76939439 3.75205393,5.9405055 C3.69356505,6.0637055 3.57036505,6.13588328 3.44280949,6.13588328"
            id="Fill-23"
            fill="#FF7F6E"
          />
          <path
            d="M23.4730139,6.13526105 C23.3454584,6.13526105 23.2222584,6.06308328 23.1637695,5.93988328 C23.0828806,5.76877217 23.1556806,5.56468328 23.3267917,5.48379439 L26.427325,4.01721661 C26.5971917,3.93694994 26.802525,4.00912772 26.8827917,4.17961661 C26.9636806,4.35072772 26.8908806,4.55481661 26.7203917,4.6357055 L23.6192362,6.10228328 C23.5719473,6.12468328 23.5221695,6.13526105 23.4730139,6.13526105"
            id="Fill-27"
            fill="#FF7F6E"
          />
          <path
            d="M17.6299739,4.80333217 L17.6299739,4.80333217 C17.0637517,4.80333217 16.6045517,4.34475439 16.6045517,3.77790994 C16.6045517,3.2110655 17.0637517,2.7518655 17.6299739,2.7518655 C18.1968184,2.7518655 18.6560184,3.2110655 18.6560184,3.77790994 C18.6560184,4.34475439 18.1968184,4.80333217 17.6299739,4.80333217"
            id="Fill-29"
            fill="#1F1E1E"
          />
          <path
            d="M9.28584949,4.80333217 L9.28584949,4.80333217 C8.71900505,4.80333217 8.25980505,4.34475439 8.25980505,3.77790994 C8.25980505,3.2110655 8.71900505,2.7518655 9.28584949,2.7518655 C9.85207171,2.7518655 10.3112717,3.2110655 10.3112717,3.77790994 C10.3112717,4.34475439 9.85207171,4.80333217 9.28584949,4.80333217"
            id="Fill-31"
            fill="#1F1E1E"
          />
        </g>
      </g>
      <g id="INFO" mask="url(#mask-2)">
        <g transform="translate(98.000000, 16.000000)">
          <circle id="Oval" fill="#FFFFFF" cx={10} cy={10} r={8} />
          <g
            id="00.Token/Icons/Solid/16x16/information-Copy"
            strokeWidth={1}
            fill="none"
            transform="translate(1.250000, 1.250000)"
          >
            <path
              d="M8.75,-4.4408921e-16 C3.92,-4.4408921e-16 -4.4408921e-16,3.92 -4.4408921e-16,8.75 C-4.4408921e-16,13.58 3.92,17.5 8.75,17.5 C13.58,17.5 17.5,13.58 17.5,8.75 C17.5,3.92 13.58,-4.4408921e-16 8.75,-4.4408921e-16 Z M8.75,13.125 C8.26875,13.125 7.875,12.73125 7.875,12.25 L7.875,8.75 C7.875,8.26875 8.26875,7.875 8.75,7.875 C9.23125,7.875 9.625,8.26875 9.625,8.75 L9.625,12.25 C9.625,12.73125 9.23125,13.125 8.75,13.125 Z M8.75,6.125 C8.26675084,6.125 7.875,5.73324916 7.875,5.25 C7.875,4.76675084 8.26675084,4.375 8.75,4.375 C9.23324916,4.375 9.625,4.76675084 9.625,5.25 C9.625,5.73324916 9.23324916,6.125 8.75,6.125 Z"
              id="color-icono"
              fill="#005D8F"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default infoApp
