import * as React from "react"

const ErrorApp = (props) => (
  <svg
    {...props}
    width="140px"
    height="128px"
    viewBox="0 0 140 128"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0 111.999876 0 111.999876 112 0 112" />
      <polygon
        id="path-3"
        points="0 0 19.4709178 0 19.4709178 40.87328 0 40.87328"
      />
    </defs>
    <g
      id="Molecula-app-ilustracion-error"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="ERROR-APP" transform="translate(14.000124, 5.385156)">
        <g>
          <g id="ROSTRO-Y-CUERPO">
            <g id="Group-3" transform="translate(0.000000, 2.177778)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-2" />
              <path
                d="M112,56 C112,86.9281778 86.9275556,112 56,112 C25.0718222,112 0,86.9281778 0,56 C0,25.0718222 25.0718222,0 56,0 C86.9275556,0 112,25.0718222 112,56"
                id="Fill-1"
                fill="#F9D4D5"
                mask="url(#mask-2)"
              />
            </g>
            <path
              d="M95.6235467,70.8927022 C95.6235467,54.8362578 82.60728,41.8199911 66.5508356,41.8199911 L45.4631022,41.8199911 C29.4060356,41.8199911 16.3897689,54.8362578 16.3897689,70.8927022 L16.3897689,98.3115467 C26.5245244,108.452524 40.5295022,114.725769 55.9998133,114.725769 C71.4769689,114.725769 85.4875467,108.446302 95.6235467,98.2978578 L95.6235467,70.8927022 Z"
              id="Fill-4"
              fill="#CC284E"
            />
            <path
              d="M83.8304444,111.003573 C78.9988889,110.838684 74.3876,106.659218 72.2403111,101.878062 L56.7911556,67.4865956 L54.7938222,68.2811733 L53.2040444,64.2865067 L57.2055556,58.8377067 L61.1933778,61.1069511 L62.3961333,58.3144178 L57.8781778,54.7136178 L58.0138222,53.1169956 L64.7941778,56.5889956 L64.3797778,65.2372622 L82.0079556,90.2841956 L82.0079556,72.1202844 L95.6234222,72.1202844 L95.6147111,98.8752178 C95.6134667,101.960818 94.5756,104.992284 92.5334667,107.304462 C90.7196889,109.357796 87.9495556,111.144196 83.8304444,111.003573"
              id="Fill-6"
              fill="#FFB3A5"
            />
            <path
              d="M56.1518844,67.7439467 L55.1395289,65.3807467 C55.0661067,65.2096356 55.1457511,65.0111467 55.3168622,64.9377244 C55.4879733,64.8643022 55.6864622,64.9427022 55.7605067,65.1150578 L56.7734844,67.4776356 L56.1518844,67.7439467 Z"
              id="Fill-8"
              fill="#EF8674"
            />
            <polygon
              id="Fill-10"
              fill="#FFB3A5"
              points="65.1206578 44.9240711 46.8926578 44.9240711 48.24288 26.6960711 63.7704356 26.6960711"
            />
            <path
              d="M47.5228444,26.0228267 L42.3061333,22.9590044 C41.1537778,22.2820267 40.768,20.7986489 41.4443556,19.6469156 C42.1213333,18.4939378 43.6047111,18.1087822 44.7570667,18.78576 L49.9731556,21.8495822 L47.5228444,26.0228267 Z"
              id="Fill-12"
              fill="#FFB3A5"
            />
            <path
              d="M64.4911556,26.0228267 L69.7078667,22.9590044 C70.8596,22.2820267 71.2453778,20.7986489 70.5690222,19.6469156 C69.8920444,18.4939378 68.4092889,18.1087822 67.2563111,18.78576 L62.0402222,21.8495822 L64.4911556,26.0228267 Z"
              id="Fill-16"
              fill="#FFB3A5"
            />
            <path
              d="M46.5709067,30.7468622 L46.5709067,11.8219733 C46.5709067,8.85086222 48.9795289,6.44224 51.95064,6.44224 L56.3516178,6.44224 C61.3897511,6.44224 65.4740178,10.5258844 65.4740178,15.56464 L65.4740178,30.7468622 C65.4740178,35.9666844 61.2429067,40.1984178 56.0224622,40.1984178 C50.8020178,40.1984178 46.5709067,35.9666844 46.5709067,30.7468622 Z"
              id="Fill-20"
              fill="#FFB3A5"
            />
            <path
              d="M55.8316889,18.2566222 C55.8316889,18.2566222 58.3162222,21.3098667 58.4164,26.7518222 L54.9792444,26.5645333"
              id="Fill-30"
              fill="#FFB3A5"
            />
            <g id="Group-36" transform="translate(46.570596, 0.000000)">
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3" />
              </mask>
              <g id="Clip-35" />
              <path
                d="M17.5535111,19.6760356 C17.5535111,24.1367467 14.0634667,27.9627911 9.604,28.0443022 C5.05991111,28.12768 1.35022222,24.4690133 1.35022222,19.9448356 L1.35022222,14.1351467 C1.35022222,12.26848 2.97235556,10.81808 4.82782222,11.0234133 C7.81822222,11.3544356 11.2541333,11.7035022 11.7736889,11.6238578 C13.5551111,11.6723911 14.9364444,11.2063467 16.0147556,10.4590578 C16.9904,11.5579022 17.5535111,12.9859022 17.5535111,14.4929244 L17.5535111,19.6760356 Z M17.2262222,0.428835556 L17.2256,0.428835556 C15.0210667,-0.67312 12.3511111,0.432568889 11.5720889,2.77150222 L9.67306667,6.44199111 L5.38035556,6.44199111 C2.50195556,6.44199111 0.141244444,8.72181333 0.0398222222,11.5977244 L0,12.7065244 L0,28.0461689 L0,30.7466133 L0,31.4217244 C0,36.6421689 4.23173333,40.87328 9.45155556,40.87328 C14.672,40.87328 18.9037333,36.6421689 18.9037333,31.4217244 L18.9037333,30.7466133 L18.9037333,28.0461689 L18.9037333,14.20608 C18.9037333,14.20608 19.0306667,10.9842133 17.4477333,9.11070222 C18.4476444,7.86003556 19.0263111,6.33621333 19.3554667,4.99470222 C19.8047111,3.16536889 18.9112,1.27194667 17.2262222,0.428835556 L17.2262222,0.428835556 Z"
                id="Fill-34"
                fill="#FF8300"
                mask="url(#mask-4)"
              />
            </g>
            <path
              d="M65.9470311,93.48472 L55.6722756,93.48472 C54.80552,93.48472 54.1030311,92.7816089 54.1030311,91.9154756 L54.1030311,71.8848978 C54.1030311,71.0181422 54.80552,70.3156533 55.6722756,70.3156533 L65.9470311,70.3156533 C66.8144089,70.3156533 67.5162756,71.0181422 67.5162756,71.8848978 L67.5162756,91.9154756 C67.5162756,92.7816089 66.8144089,93.48472 65.9470311,93.48472"
              id="Fill-39"
              fill="#F6F3F5"
            />
            <path
              d="M57.8290222,72.9234489 C57.8290222,73.5406933 57.3281333,74.0415822 56.7108889,74.0415822 C56.0936444,74.0415822 55.5933778,73.5406933 55.5933778,72.9234489 C55.5933778,72.3062044 56.0936444,71.8053156 56.7108889,71.8053156 C57.3281333,71.8053156 57.8290222,72.3062044 57.8290222,72.9234489"
              id="Fill-41"
              fill="#D4D0D5"
            />
            <path
              d="M64.3519644,89.18504 L63.5760533,82.1999733 L52.9926756,83.1345511 L30.0052978,95.4371289 L30.0052978,90.2845067 L30.0052978,72.1199733 L16.3898311,72.1199733 L16.3991644,98.8749067 C16.3997867,101.960507 17.4382756,104.991351 19.4804089,107.304151 C20.74912,108.742107 22.4894756,110.044418 24.8296533,110.651084 C29.1957867,111.925396 34.63152,109.996507 38.2932978,106.700596 L55.0677867,91.7037956 L55.0609422,91.6919733 L64.3519644,89.18504 Z"
              id="Fill-43"
              fill="#FFB3A5"
            />
            <path
              d="M56.0729244,52.6222044 C63.2602133,52.6222044 69.4718578,48.4607822 72.44048,42.41776 C70.5383467,42.0263822 68.5683911,41.8204267 66.5505244,41.8204267 L45.4627911,41.8204267 C43.4909689,41.8204267 41.5670578,42.0182933 39.7047467,42.3928711 C42.6690133,48.4464711 48.8781689,52.6222044 56.0729244,52.6222044"
              id="Fill-45"
              fill="#FFB3A5"
            />
          </g>
          <g id="EXPRESIONES" transform="translate(42.486465, 15.054611)">
            <path
              d="M3.39875303,7.44208248 C3.3502197,7.44208248 3.30106414,7.43088248 3.25439748,7.40910471 L0.193064145,5.96119359 C0.0244419224,5.88154915 -0.0471136332,5.68057137 0.0325308113,5.51132693 C0.112797478,5.34394915 0.3150197,5.27301582 0.482397478,5.35141582 L3.54310859,6.79932693 C3.71173081,6.87897137 3.78328637,7.07994915 3.70364192,7.24857137 C3.64577526,7.37052693 3.52506414,7.44208248 3.39875303,7.44208248"
              id="Fill-14"
              fill="#FF7F6E"
            />
            <path
              d="M23.6422553,7.44208248 C23.5159441,7.44208248 23.395233,7.37052693 23.3373664,7.24857137 C23.2577219,7.07994915 23.3292775,6.87897137 23.4978997,6.79932693 L26.5586108,5.35141582 C26.7278553,5.27301582 26.9275886,5.34394915 27.0078553,5.51132693 C27.0874997,5.68057137 27.0159441,5.88154915 26.8473219,5.96119359 L23.7866108,7.40910471 C23.7399441,7.43088248 23.6907886,7.44208248 23.6422553,7.44208248"
              id="Fill-18"
              fill="#FF7F6E"
            />
            <path
              d="M10.5039086,4.74599359 C10.583553,5.29977137 10.1996419,5.81310471 9.64586414,5.89274915 C9.09208637,5.97239359 8.57875303,5.58786026 8.49910859,5.03408248 C8.41946414,4.48030471 8.80399748,3.96697137 9.35777526,3.88794915 C9.91155303,3.80830471 10.4242641,4.19221582 10.5039086,4.74599359"
              id="Fill-22"
              fill="#1E1E1E"
            />
            <path
              d="M18.7149397,4.74599359 C18.7945841,5.29977137 18.410673,5.81310471 17.8568953,5.89274915 C17.3031175,5.97239359 16.7897841,5.58786026 16.7101397,5.03408248 C16.6304953,4.48030471 17.0156508,3.96697137 17.5688064,3.88794915 C18.1225841,3.80830471 18.6359175,4.19221582 18.7149397,4.74599359"
              id="Fill-24"
              fill="#1E1E1E"
            />
            <path
              d="M6.37266414,2.15213582 C6.17479748,1.95364693 6.17479748,1.63444693 6.37204192,1.43658026 C7.30413081,0.503246928 9.62004192,-0.849464183 11.711953,0.717291373 C11.935953,0.885291373 11.9813753,1.20200248 11.8139975,1.42600248 C11.6453753,1.64938026 11.3286641,1.69542471 11.1052864,1.52804693 C9.1938197,0.0969358172 7.17346414,2.06875804 7.08759748,2.15338026 C6.98928637,2.25169137 6.85986414,2.30022471 6.73044192,2.30022471 C6.6010197,2.30022471 6.47097526,2.25106915 6.37266414,2.15213582 Z"
              id="Fill-26"
              fill="#FF8300"
            />
            <path
              d="M19.8276597,2.15338026 C19.7411708,2.06938026 17.7189486,0.0975580394 15.8112153,1.52804693 C15.5859708,1.69666915 15.2698819,1.64938026 15.1018819,1.42662471 C14.9345041,1.20200248 14.9799264,0.885291373 15.2033041,0.717291373 C17.2964597,-0.848841961 19.6105041,0.502624706 20.5432153,1.43658026 C20.7404597,1.63444693 20.7404597,1.95364693 20.542593,2.15213582 C20.4442819,2.25106915 20.3142375,2.30022471 20.1848153,2.30022471 C20.055393,2.30022471 19.9259708,2.25169137 19.8276597,2.15338026 Z"
              id="Fill-28"
              fill="#FF8300"
            />
            <path
              d="M16.2740241,12.0540558 L12.4747353,11.8468558 C12.2880686,11.836278 12.1455797,11.6776114 12.1555353,11.4915669 C12.1654908,11.3055225 12.3322464,11.1736114 12.5108241,11.1723669 L15.582113,11.3403669 C15.3854908,6.30161137 13.106913,3.44374471 13.0832686,3.41512248 C12.9656686,3.27076693 12.9874464,3.05796693 13.1318019,2.94036693 C13.2761575,2.82338915 13.4883353,2.84330026 13.6071797,2.98890026 C13.711713,3.11770026 16.1663797,6.19583359 16.2671797,11.6913003 L16.2740241,12.0540558 Z"
              id="Fill-32"
              fill="#FF7F6E"
            />
            <path
              d="M15.2823886,17.4589892 C15.3881664,18.1975669 14.8760775,18.8813892 14.1381219,18.9877892 C13.4001664,19.0935669 12.7157219,18.581478 12.6093219,17.8429003 C12.5035441,17.1055669 13.015633,16.4211225 13.7542108,16.3147225 C14.4915441,16.2089447 15.1759886,16.7210336 15.2823886,17.4589892"
              id="Fill-37"
              fill="#1E1E1E"
            />
          </g>
        </g>
        <g id="ERROR" transform="translate(83.999876, 10.614844)">
          <circle id="Oval" fill="#FFFFFF" cx={10} cy={10.7116667} r={8} />
          <g
            id="00.Token/Icons/Solid/16x16/cross"
            transform="translate(1.250000, 1.250000)"
            fill="#BF0238"
          >
            <path
              d="M8.75,-8.8817842e-16 C3.91125,-8.8817842e-16 0,3.91125 0,8.75 C0,13.58875 3.91125,17.5 8.75,17.5 C13.58875,17.5 17.5,13.58875 17.5,8.75 C17.5,3.91125 13.58875,-8.8817842e-16 8.75,-8.8817842e-16 Z M12.2611595,12.2611595 C11.9427055,12.5796135 11.4282798,12.5796135 11.1098258,12.2611595 L8.75,9.9013337 L6.3901742,12.2611595 C6.0717202,12.5796135 5.5572945,12.5796135 5.2388405,12.2611595 C4.9203865,11.9427055 4.9203865,11.4282798 5.2388405,11.1098258 L7.5986663,8.75 L5.2388405,6.3901742 C4.9203865,6.0717202 4.9203865,5.5572945 5.2388405,5.2388405 C5.5572945,4.9203865 6.0717202,4.9203865 6.3901742,5.2388405 L8.75,7.5986663 L11.1098258,5.2388405 C11.4282798,4.9203865 11.9427055,4.9203865 12.2611595,5.2388405 C12.5796135,5.5572945 12.5796135,6.0717202 12.2611595,6.3901742 L9.9013337,8.75 L12.2611595,11.1098258 C12.571448,11.4201143 12.571448,11.9427055 12.2611595,12.2611595 Z"
              id="color-icono"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ErrorApp
