import * as React from "react"

const AlertaDesk = (props) => (
  <svg
    {...props}
    width="164px"
    height="160px"
    viewBox="0 0 164 160"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M8.4848,0.7584 C4.2496,0.9136 0.8328,4.3296 0.68,8.5648 L0.68,8.5648 C0.6736,8.7272 0.804,8.8624 0.9672,8.8624 L0.9672,8.8624 L7.0656,8.8624 C8.0144,8.8624 8.7832,8.0944 8.7832,7.1456 L8.7832,7.1456 L8.7832,1.0472 C8.7832,0.8864 8.6536,0.7584 8.4944,0.7584 L8.4944,0.7584 C8.4912,0.7584 8.488,0.7584 8.4848,0.7584 L8.4848,0.7584 Z"
        id="path-1"
      />
      <path
        d="M8.4848,0.7584 C4.2496,0.9136 0.8328,4.3296 0.68,8.5648 L0.68,8.5648 C0.6736,8.7272 0.804,8.8624 0.9672,8.8624 L0.9672,8.8624 L7.0656,8.8624 C8.0144,8.8624 8.7832,8.0944 8.7832,7.1456 L8.7832,7.1456 L8.7832,1.0472 C8.7832,0.8872 8.6536,0.7584 8.4944,0.7584 L8.4944,0.7584 C8.4912,0.7584 8.4888,0.7584 8.4848,0.7584 L8.4848,0.7584 Z"
        id="path-3"
      />
      <path
        d="M6.0024,0.6752 C3.016,0.6752 0.596,3.096 0.596,6.0808 L0.596,6.0808 L0.596,13.5672 C0.596,14.504 1.356,15.2624 2.2928,15.2624 L2.2928,15.2624 L3.3032,15.2624 C3.4568,15.2624 3.5808,15.1392 3.5824,14.9856 L3.5824,14.9856 C3.7272,8.8048 8.728,3.8056 14.9088,3.6608 L14.9088,3.6608 C15.0608,3.6584 15.1832,3.5352 15.1832,3.3832 L15.1832,3.3832 L15.1832,2.372 C15.1832,1.4344 14.424,0.6752 13.4872,0.6752 L13.4872,0.6752 L6.0024,0.6752 Z"
        id="path-5"
      />
    </defs>
    <g
      id="Molecula-alerta-desktop"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="ALERTA-DESKTOP" transform="translate(0.000000, 0.000000)">
        <g transform="translate(10.000000, 8.000000)">
          <g id="ALERTA-RESPONSIVE">
            <g id="ROSTRO-Y-CUERPO">
              <path
                d="M144,72 C144,111.7648 111.7648,144 72,144 C32.236,144 0,111.7648 0,72 C0,32.2352 32.236,0 72,0 C111.7648,0 144,32.2352 144,72"
                id="Fill-1"
                fill="#EFE6B2"
              />
              <path
                d="M83.62272,40.22968 L59.69952,40.22968 C59.01472,40.22968 58.46112,39.67528 58.46112,38.99128 L58.46112,31.01048 C58.46112,25.05608 63.28672,20.22968 69.24032,20.22968 L74.08032,20.22968 C80.03472,20.22968 84.86112,25.05608 84.86112,31.01048 L84.86112,38.99128 C84.86112,39.67528 84.30592,40.22968 83.62272,40.22968"
                id="Fill-3"
                fill="#EA5474"
              />
              <path
                d="M69.8172,61.72888 L69.8172,42.52888 L58.6172,41.72888 L54.6452,45.07768 C49.5324,49.38888 50.5748,57.70408 56.7196,60.34568 C59.8612,61.69528 64.1036,62.41848 69.8172,61.72888"
                id="Fill-5"
                fill="#EA5474"
              />
              <path
                d="M73.50392,61.72888 L73.50392,42.52888 L84.70392,41.72888 L88.67592,45.07768 C93.78872,49.38888 92.74632,57.70408 86.60152,60.34568 C83.46072,61.69528 79.21752,62.41848 73.50392,61.72888"
                id="Fill-7"
                fill="#EA5474"
              />
              <path
                d="M72.68752,55.32888 L71.67472,55.32888 C66.63312,55.32888 62.54512,51.24168 62.54512,46.19848 L62.54512,27.74888 C62.54512,25.18168 64.62672,23.10008 67.19472,23.10008 L75.84992,23.10008 C79.14512,23.10008 81.81712,25.77128 81.81712,29.06648 L81.81712,46.19848 C81.81712,51.24168 77.72992,55.32888 72.68752,55.32888"
                id="Fill-9"
                fill="#FFB3A5"
              />
              <path
                d="M63.67344,46.30232 L57.49104,42.67192 C56.12544,41.86952 55.66864,40.11272 56.47104,38.74712 C57.27264,37.38152 59.03024,36.92472 60.39584,37.72632 L66.57744,41.35672 L63.67344,46.30232 Z"
                id="Fill-19"
                fill="#FFB3A5"
              />
              <path
                d="M62.54536,38.98904 L62.89496,38.24184 C64.79496,34.18664 68.56376,31.24744 72.99736,30.61944 C76.45656,30.12904 80.29496,28.66904 81.81736,24.78904 C73.50376,21.27064 73.66136,21.02984 73.66136,21.02984 L64.10776,23.10024 L60.79816,27.71304 L62.54536,38.98904 Z"
                id="Fill-23"
                fill="#EA5474"
              />
              <path
                d="M77.66096,25.82968 C78.46096,27.42968 81.81696,40.00808 81.81696,40.00808 L83.26096,37.82968 L82.46096,28.22968 L80.46096,24.21208 L77.66096,25.82968 Z"
                id="Fill-25"
                fill="#EA5474"
              />
              <path
                d="M80.57968,46.30232 L86.76208,42.67192 C88.12768,41.86952 88.58448,40.11272 87.78208,38.74712 C86.98048,37.38152 85.22288,36.92472 83.85728,37.72632 L77.67568,41.35672 L80.57968,46.30232 Z"
                id="Fill-27"
                fill="#FFB3A5"
              />
              <path
                d="M87.7852,103.01328 L40.1668,103.01328 L40.1668,78.81408 C40.1668,68.86528 48.2316,60.79968 58.1804,60.79968 L86.07,60.79968 C89.8692,60.80768 93.4132,62.71488 95.5116,65.88128 L104.0844,78.81408 L104.0844,91.82688 C104.0844,95.92608 99.1124,97.96288 96.2356,95.04128 L89.3044,87.99968 L87.7852,103.01328 Z"
                id="Fill-31"
                fill="#967100"
              />
              <path
                d="M52.1664,136.80008 L51.808,136.80008 C45.3784,136.80008 40.1664,131.58728 40.1664,125.15848 L40.1664,85.60008 L52.1664,85.60008 L52.1664,136.80008 Z"
                id="Fill-33"
                fill="#FFB3A5"
              />
              <path
                d="M72.12504,73.6 C79.83624,73.6 86.27064,68.1448 87.78504,60.8832 C87.22024,60.8304 86.64824,60.8 86.07064,60.8 L58.18024,60.8 C57.60184,60.8 57.02984,60.8304 56.46584,60.8832 C57.98024,68.1448 64.41384,73.6 72.12504,73.6"
                id="Fill-35"
                fill="#FFB3A5"
              />
              <polygon
                id="Fill-37"
                fill="#FFB3A5"
                points="78.6172 63.32888 65.8172 63.32888 67.4172 44.12888 77.0172 44.12888"
              />
              <path
                d="M100.54928,98.28472 C101.36848,98.31672 102.19568,98.20392 102.95168,97.88712 C105.53488,96.80392 107.25648,94.12952 106.90208,90.83272 L105.79888,73.53672 L104.05968,46.77832 L98.28768,45.59992 L94.65968,90.17992 C94.24128,93.64312 95.78448,96.80072 98.77728,97.94792 C99.34208,98.16472 99.94528,98.26152 100.54928,98.28472"
                id="Fill-43"
                fill="#FFB3A5"
              />
              <polyline
                id="Fill-45"
                fill="#FFB3A5"
                points="98.48912 58.8148 92.32272 55.0956 93.99232 53.5116 99.19152 54.6076"
              />
              <polygon
                id="Fill-47"
                fill="#FF7F6E"
                points="97.64696 54.6512 103.11576 54.6512 103.11576 53.8096 97.64696 53.8096"
              />
              <path
                d="M120.95784,144.00008 L24.64264,144.00008 C24.64264,140.53128 27.45544,137.71848 30.92424,137.71848 L114.67624,137.71848 C118.14504,137.71848 120.95784,140.53128 120.95784,144.00008"
                id="Fill-49"
                fill="#967100"
              />
              <path
                d="M106.66256,137.7188 L37.89136,137.7188 L37.89136,99.6452 C37.89136,96.774 40.21856,94.4468 43.08976,94.4468 L101.46416,94.4468 C104.33536,94.4468 106.66256,96.774 106.66256,99.6452 L106.66256,137.7188 Z"
                id="Fill-50"
                fill="#F6F3F5"
              />
              <polygon
                id="Fill-51"
                fill="#D4D0D5"
                points="37.89096 137.7192 106.66216 137.7192 106.66216 133.74 37.89096 133.74"
              />
              <path
                d="M42.1016,135.72896 C42.1016,136.41536 41.5784,136.97296 40.932,136.97296 C40.2864,136.97296 39.7624,136.41536 39.7624,135.72896 C39.7624,135.04256 40.2864,134.48576 40.932,134.48576 C41.5784,134.48576 42.1016,135.04256 42.1016,135.72896"
                id="Fill-52"
                fill="#9C9A9F"
              />
              <path
                d="M46.31256,135.72896 C46.31256,136.41536 45.78936,136.97296 45.14296,136.97296 C44.49656,136.97296 43.97336,136.41536 43.97336,135.72896 C43.97336,135.04256 44.49656,134.48576 45.14296,134.48576 C45.78936,134.48576 46.31256,135.04256 46.31256,135.72896"
                id="Fill-53"
                fill="#9C9A9F"
              />
              <path
                d="M79.89848,115.23208 C75.66328,115.38648 72.24648,118.80328 72.09288,123.03848 C72.08648,123.20088 72.21688,123.33608 72.38088,123.33608 L78.47928,123.33608 C79.42808,123.33608 80.19688,122.56728 80.19688,121.61848 L80.19688,115.52008 C80.19688,115.35688 80.06168,115.22648 79.89848,115.23208"
                id="Fill-54"
                fill="#D4D0D5"
              />
              <g id="Group-58" transform="translate(71.413360, 114.473600)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-57" />
                <polygon
                  id="Fill-56"
                  fill="#D4D0D5"
                  mask="url(#mask-2)"
                  points="-589.6 1630.4 307.5496 1630.4 307.5496 -1766.0936 -589.6 -1766.0936"
                />
              </g>
              <g id="Group-61" transform="translate(71.413360, 114.473600)">
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3" />
                </mask>
                <g id="Clip-60" />
                <polygon
                  id="Fill-59"
                  fill="#D4D0D5"
                  mask="url(#mask-4)"
                  points="-1.816 11.3576 11.2776 11.3576 11.2776 -1.736 -1.816 -1.736"
                />
              </g>
              <path
                d="M78.5008,108.74848 L71.016,108.74848 C68.0296,108.74848 65.6096,111.16848 65.6096,114.15488 L65.6096,121.64048 C65.6096,122.57728 66.3688,123.33568 67.3056,123.33568 L68.3168,123.33568 C68.4704,123.33568 68.5936,123.21248 68.5952,123.05968 C68.7408,116.87888 73.7416,111.87888 79.9224,111.73408 C80.0744,111.73168 80.1968,111.60848 80.1968,111.45648 L80.1968,110.44528 C80.1968,109.50768 79.4376,108.74848 78.5008,108.74848"
                id="Fill-62"
                fill="#D4D0D5"
              />
              <g id="Group-67" transform="translate(65.013360, 108.073600)">
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5" />
                </mask>
                <g id="Clip-65" />
                <polygon
                  id="Fill-64"
                  fill="#D4D0D5"
                  mask="url(#mask-6)"
                  points="-583.2 1636.8 313.9496 1636.8 313.9496 -1759.6936 -583.2 -1759.6936"
                />
                <polygon
                  id="Fill-66"
                  fill="#D4D0D5"
                  mask="url(#mask-6)"
                  points="-1.8992 17.7576 17.6784 17.7576 17.6784 -1.82 -1.8992 -1.82"
                />
              </g>
            </g>
            <g id="EXPRESIONES" transform="translate(57.705523, 34.484557)">
              <path
                d="M11.2991971,3.47360347 C11.3935971,4.12960347 10.9383971,4.73840347 10.2823971,4.83200347 C9.62639715,4.92640347 9.01759715,4.47120347 8.92399715,3.81520347 C8.82959715,3.15920347 9.28479715,2.55120347 9.94159715,2.45680347 C10.5967971,2.36240347 11.2055971,2.81760347 11.2991971,3.47360347"
                id="Fill-11"
                fill="#1F1E1E"
              />
              <path
                d="M20.0991971,3.47360347 C20.1935971,4.12960347 19.7383971,4.73840347 19.0823971,4.83200347 C18.4263971,4.92640347 17.8175971,4.47120347 17.7239971,3.81520347 C17.6295971,3.15920347 18.0847971,2.55120347 18.7415971,2.45680347 C19.3967971,2.36240347 20.0055971,2.81760347 20.0991971,3.47360347"
                id="Fill-13"
                fill="#1F1E1E"
              />
              <path
                d="M20.5452771,1.07872347 C20.5020771,1.07872347 20.4596771,1.07232347 20.4164771,1.05712347 C18.8068771,0.509923471 17.7452771,0.998723471 17.7004771,1.02032347 C17.5012771,1.11232347 17.2620771,1.03072347 17.1676771,0.833923471 C17.0716771,0.635523471 17.1524771,0.397923471 17.3500771,0.301123471 C17.4052771,0.273923471 18.7316771,-0.362076529 20.6740771,0.300323471 C20.8836771,0.371523471 20.9948771,0.598723471 20.9244771,0.807523471 C20.8668771,0.973923471 20.7116771,1.07872347 20.5452771,1.07872347"
                id="Fill-15"
                fill="#EA5474"
              />
              <path
                d="M8.72655715,1.07872347 C8.56015715,1.07872347 8.40495715,0.973923471 8.34735715,0.807523471 C8.27695715,0.598723471 8.38815715,0.371523471 8.59775715,0.300323471 C10.5417571,-0.361276529 11.8665571,0.273923471 11.9217571,0.301123471 C12.1201571,0.398723471 12.2017571,0.638723471 12.1041571,0.836323471 C12.0065571,1.03392347 11.7697571,1.11552347 11.5713571,1.01952347 C11.5257571,0.998723471 10.4689571,0.508323471 8.85535715,1.05712347 C8.81215715,1.07232347 8.76975715,1.07872347 8.72655715,1.07872347"
                id="Fill-17"
                fill="#EA5474"
              />
              <path
                d="M4.02735715,7.63888347 C3.96975715,7.63888347 3.91135715,7.62688347 3.85615715,7.60048347 L0.228957147,5.88528347 C0.0289571466,5.79008347 -0.0558428534,5.55168347 0.0385571466,5.35248347 C0.133757147,5.15168347 0.373757147,5.06768347 0.571357147,5.16128347 L4.19855715,6.87728347 C4.39855715,6.97168347 4.48335715,7.21008347 4.38895715,7.41008347 C4.32015715,7.55488347 4.17695715,7.63888347 4.02735715,7.63888347"
                id="Fill-21"
                fill="#FF7F6E"
              />
              <path
                d="M24.8147971,7.63888347 C24.6651971,7.63888347 24.5219971,7.55488347 24.4531971,7.41008347 C24.3587971,7.21008347 24.4435971,6.97168347 24.6435971,6.87728347 L28.2707971,5.16128347 C28.4683971,5.06768347 28.7075971,5.15168347 28.8035971,5.35248347 C28.8979971,5.55168347 28.8131971,5.79008347 28.6131971,5.88528347 L24.9859971,7.60048347 C24.9307971,7.62688347 24.8723971,7.63888347 24.8147971,7.63888347"
                id="Fill-29"
                fill="#FF7F6E"
              />
              <path
                d="M13.7475971,10.2099235 C13.5739971,10.2099235 13.4147971,10.0971235 13.3643971,9.92272347 C13.3011971,9.71072347 13.4235971,9.48832347 13.6347971,9.42592347 L15.8923971,8.76352347 C15.4603971,7.80752347 14.5115971,5.41552347 14.5115971,2.84432347 C14.5115971,2.62352347 14.6907971,2.44432347 14.9115971,2.44432347 C15.1323971,2.44432347 15.3115971,2.62352347 15.3115971,2.84432347 C15.3115971,5.91072347 16.7955971,8.80192347 16.8099971,8.83072347 L17.0339971,9.26192347 L13.8603971,10.1939235 C13.8227971,10.2051235 13.7843971,10.2099235 13.7475971,10.2099235"
                id="Fill-39"
                fill="#FF7F6E"
              />
              <path
                d="M16.6124771,14.7962435 C16.5804771,14.7962435 16.5476771,14.7922435 16.5148771,14.7842435 C14.5476771,14.2890435 12.8764771,14.6810435 12.8604771,14.6858435 C12.6492771,14.7330435 12.4292771,14.6058435 12.3772771,14.3914435 C12.3252771,14.1770435 12.4564771,13.9610435 12.6700771,13.9082435 C12.7444771,13.8898435 14.5364771,13.4618435 16.7100771,14.0082435 C16.9244771,14.0626435 17.0548771,14.2794435 17.0004771,14.4938435 C16.9548771,14.6754435 16.7916771,14.7962435 16.6124771,14.7962435"
                id="Fill-41"
                fill="#EA4766"
              />
            </g>
          </g>
          <g id="ALERT" transform="translate(108.000000, 5.888889)">
            <path
              d="M14.8609265,4.45695259 L26.1084781,23.4912707 C26.3894416,23.9667473 26.2317575,24.5799631 25.7562809,24.8609265 C25.6022043,24.9519718 25.4265176,25 25.2475516,25 L2.75244839,25 C2.20016364,25 1.75244839,24.5522847 1.75244839,24 C1.75244839,23.821034 1.8004766,23.6453473 1.89152187,23.4912707 L13.1390735,4.45695259 C13.4200369,3.98147599 14.0332527,3.82379193 14.5087293,4.10475537 C14.6539545,4.19057024 14.7751117,4.31172742 14.8609265,4.45695259 Z"
              id="Triangle"
              fill="#FFFFFF"
            />
            <g
              id="00.Token/Icons/Solid/32x32/alert"
              transform="translate(0.875000, 2.625000)"
              fill="#8C6700"
            >
              <path
                d="M1.26750482,22.7529164 L24.6871155,22.7529164 C25.6602995,22.7529164 26.2669597,21.7038998 25.786687,20.8571033 L14.0705623,0.635097374 C13.5776509,-0.211699125 12.3643306,-0.211699125 11.8840579,0.635097374 L0.167933245,20.8571033 C-0.312339396,21.7038998 0.294320782,22.7529164 1.26750482,22.7529164 Z M12.9836295,18.9612903 C12.2856104,18.9612903 11.7197541,18.395434 11.7197541,17.6974149 C11.7197541,16.9993958 12.2856104,16.4335395 12.9836295,16.4335395 C13.6816486,16.4335395 14.2475049,16.9993958 14.2475049,17.6974149 C14.2475049,18.395434 13.6816486,18.9612903 12.9836295,18.9612903 Z M12.9836295,13.9057888 C12.2884981,13.9057888 11.7197541,13.3370449 11.7197541,12.6419134 L11.7197541,10.1141627 C11.7197541,9.41903121 12.2884981,8.85028729 12.9836295,8.85028729 C13.678761,8.85028729 14.2475049,9.41903121 14.2475049,10.1141627 L14.2475049,12.6419134 C14.2475049,13.3370449 13.678761,13.9057888 12.9836295,13.9057888 Z"
                id="color-icono"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default AlertaDesk
