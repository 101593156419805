import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_voucherresumecontainer.scss'
import VoucherDataResume from '../voucherDataResume'

const VoucherResumeContainer = ({ items }) => (
  <React.Fragment>
    {items.map((row) => (
      <div className={styles.voucherResumeContainer} key={`item-${nanoid()}`}>
        {row.map((column) => (
          <VoucherDataResume key={`row-${nanoid()}`} {...column} />
        ))}
      </div>
    ))}
  </React.Fragment>
)

export default VoucherResumeContainer

VoucherResumeContainer.defaultProps = {
  items: []
}

VoucherResumeContainer.prototype = {
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        prefixType: PropTypes.string,
        contentType: PropTypes.string
      })
    )
  )
}
