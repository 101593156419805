import React from 'react'
import styles from './_label.scss'

const Label = ({ htmlFor, label, disabled }) => (
  <label
    htmlFor={htmlFor}
    className={[styles.inputLabel, disabled ? styles.disabled : '']
      .join(' ')
      .trim()}
  >
    {label}
  </label>
)

export default Label
