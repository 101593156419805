import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './_accessPoints.scss'
import Icon from '../icons'
import Button from '../buttons'

const AccessPoints = ({
  action,
  className,
  points,
  expirationDate,
  pointsToExpire,
  icon
}) => {
  const messageClasses = [styles.message,  className]

  const handleResize = (e) => {
    if(e < 563 ){
      setfirst(false)
    } else{
      setfirst(true)
    }
    }
  
    const [first, setfirst] = useState(true)

  
    useEffect(() => {
      window.removeEventListener('resize', e => handleResize(e.target.innerWidth))
      window.addEventListener('resize', e => handleResize(e.target.innerWidth))
      handleResize(window.innerWidth)
    }, [])


  return (
    <div>
  <div className={messageClasses.join(' ').trim()}>
      <div className={styles.textContainer}>
          <div className={styles.headerContainer}>

          <div className={styles.iconDesktop}>
            <Icon name={icon} className={styles.prefix} size='5' />
          </div>            
          <div className={styles.iconMobile}>
            <Icon name={icon} className={styles.prefix} size='4' />
          </div>
             <div className={styles.points}>
                tienes { points } puntos Coopeuch
            </div>
          </div>
          <div className={styles.valuePointsResponsive}>
              <div className={styles.valuePoints}>
                <span className={styles.divisorHidden}>/</span> 1 punto = CLP $1   
              </div>
              <div className={styles.pointsToExpire}>
                <span className={styles.divisor}>/</span> <span className={styles.pointsBold}> { pointsToExpire } pts</span> vencerán el { expirationDate }  
              </div>
          </div>
      </div>
      <div className={styles.actionContainer}>
        {action && (
          <div className={styles.action}>
            <Button
              variant='text'
              onClick={action.onClick}
              suffix='old-arrow-right'
            >
              { first && action.name }
            </Button>
          </div>
        )}
      </div>
</div>
<div className={styles.abajo}>
    <div className={styles.textContainer}>
        <div className={styles.valuePointsResponsive}>
            <div className={styles.valuePoints}>
              <span className={styles.divisorHidden}>/</span> 1 punto = CLP $1   
            </div>
            <div className={styles.pointsToExpire}>
              <span className={styles.divisor}>/</span> <span className={styles.pointsBold}> { pointsToExpire } pts</span> vencerán el { expirationDate }  
            </div>
        </div>
    </div>
</div>
</div>
  )}

AccessPoints.defaultProps = {
  pointsToExpire:'',
  points:'',
  expirationDate:'',
  action: undefined,
  // eslint-disable-next-line react/default-props-match-prop-types
  icon: 'filled-line-check'
}

AccessPoints.propTypes = {
    pointsToExpire: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    points:PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    expirationDate:PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    action: PropTypes.exact({
        name: PropTypes.string,
        onClick: PropTypes.func
  }),

}

export default AccessPoints
