import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styles from './_message.scss'
import Icon from '../icons'
import Button from '../buttons'

const Message = ({
  title,
  action,
  type,
  className,
  description,
  onClickClose,
  closed
}) => {
  const messageClasses = [styles.message, styles[type], className]

  const getIcon = () => {
    switch (type) {
      case 'success':
        return 'old-check'
      case 'info':
        return 'old-information'
      case 'error':
        return 'old-cross'
      case 'warning':
        return 'old-alert'
      default:
        return ''
    }
  }
  const clickHandler = () => {
    onClickClose(!closed)
    console.log(closed) 
  } 

  return (
    <div>
{ !closed && 
  <div className={messageClasses.join(' ').trim()}>
  <div className={styles.textContainer}>
    <Icon name={getIcon()} className={styles.prefix} size='3' />
    <div className={styles.content}>
      {title && <div className={styles.title}> {title} </div>}
      {description && <p> {description} </p>}
    </div>
  </div>

  <div className={styles.actionContainer}>
    {onClickClose && (
      <div onClick={clickHandler}>
        <Icon
          name='old-line-cross'
          size={3}
          className={styles.closer}
        />
      </div>
    )}
    {action && (
      <div className={styles.action}>
        <Button
          variant='text'
          onClick={action.onClick}
          suffix='old-arrow-right'
        >
          {action.name}
        </Button>
      </div>
    )}
  </div>
</div>
}
</div>
  )
}

Message.defaultProps = {
  title: '',
  description: '',
  action: undefined,
  onClickClose: undefined
}

Message.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'info', 'warning']).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  action: PropTypes.exact({
    name: PropTypes.string,
    onClick: PropTypes.func

  }),
  onClickClose: PropTypes.func,
  closed: PropTypes.bool

}

export default Message
