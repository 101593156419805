import React, { Fragment } from 'react'
import Step from './desktopStep'

import styles from './_desktopTracker.scss'

const DesktopStepper = (props) => {
  const { steps, currentStep } = props

  return (
    <div className={styles.desktopStepperContainer}>
      {steps.map((s, index) => (
        index < 6 ?
          <Step
            key={`track_desktop_${s.step}`}
            iteratedStep={s}
            isLast={s.step === steps.length}
            currentStep={currentStep}
          />
        :
          <div key={`track_desktop_${s.step}`} />
      ))}
    </div>
  )
}

export default DesktopStepper
