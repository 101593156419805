import React from 'react'
import PropTypes from 'prop-types'
import Label from './_commons/label'
import styles from './_input.scss'
import InputCore from './core'
import AssistText from './_commons/assistText'

const Input = ({
  label,
  name,
  value,
  type,
  suffix,
  prefix,
  variant,
  assistText,
  maxLength,
  showLength,
  error,
  success,
  disabled,
  placeholder,
  onlyNumber,
  autoComplete,
  allowSpaces,
  readOnly,
  rows,
  onChange,
  onBlur,
}) => {
  let validateStyle = []

  if (error === true) validateStyle = validateStyle.concat(styles.error)
  else if (success === true)
    validateStyle = validateStyle.concat(styles.success)

  if (disabled) validateStyle = validateStyle.concat(styles.disabled)

  return (
    <div className={[styles.inputContainer, validateStyle].join(' ')}>
      {typeof label === 'string' ? (
        <Label htmlFor={name} label={label} disabled={disabled} />
      ) : (
        label
      )}

      <InputCore
        id={name}
        name={name}
        value={value}
        type={type}
        maxLength={maxLength}
        error={error}
        success={success}
        suffix={suffix}
        prefix={prefix}
        onlyNumber={onlyNumber}
        variant={variant}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        readOnly={readOnly}
        allowSpaces={allowSpaces}
        rows={rows}
        onChange={onChange}
        onBlur={onBlur}
      />

      <div className={styles.assistTextContainer}>
        {assistText && <AssistText assistText={assistText} disabled={disabled} />}
        {showLength && <AssistText assistText={`${value.length}${maxLength ? `/${maxLength}` : ''}`} disabled={disabled} />}
      </div>
    </div>
  )
}

Input.defaultProps = {
  label: undefined,
  name: undefined,
  value: '',
  type: 'text',
  disabled: false,
  assistText: undefined,
  maxLength: undefined,
  showLength: false,
  error: false,
  success: false,
  suffix: undefined,
  prefix: undefined,
  onlyNumber: false,
  variant: undefined,
  placeholder: undefined,
  autoComplete: undefined,
  rows: 1,
  onChange: () => {},
  onBlur: () => {}
}

Input.propTypes = {
  label: PropTypes.node,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  assistText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.oneOf(['assist', 'success', 'error'])
      })
    ),
    PropTypes.string,
    PropTypes.element
  ]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showLength: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  error: PropTypes.bool,
  success: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onlyNumber: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

export default Input
