import React from 'react'
import PropTypes from 'prop-types'
import styles from './_cardRibbons.scss'
import classNames from 'classnames'

const CardRibbons = ({
    title,
    position,
    color

  }) => {

    return (
      <div className={classNames(
        styles.cardRibbon,
        (color === 'primary' ? styles.primary: color === 'secondary' ? styles.secondary: ''),
        (position === 'left' ? styles.left: position === 'right' ? styles.right: '')
        )} >
          <div className={styles.cardLabel}>
              <div className={styles.cardLabelWrapper}>
                <p>{title}</p>
              </div>
          </div>
      </div>
    )
  }
  
CardRibbons.defaultProps = {
    title: '',
    position: 'right',
    color: ''
  }
  
CardRibbons.propTypes = {
    title: PropTypes.string,
    position: PropTypes.string,
    color: PropTypes.oneOf(['primary', 'secondary']),

  }
  
  export default CardRibbons
  