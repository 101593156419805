const listToMatrix = (list, elementsPerSubArray) => {
  const matrix = []
  let i
  let k

  for (i = 0, k = -1; i < list.length; i += 1) {
    if (i % elementsPerSubArray === 0) {
      k += 1
      matrix[k] = []
    }

    matrix[k].push(list[i])
  }

  return matrix
};

const changeNameKeysInArrayItems = (originalArray, arrayParKeys) => {
  const tempArray = originalArray.map((el) => {
    const arrayEntries = Object.entries(el).map((entrie) => {
      const entrieFiltered = arrayParKeys.filter((parKeys) => entrie[0] === parKeys[0]);
      if (entrieFiltered.length > 0) return [entrieFiltered[0][1], entrie[1]];
      return entrie;
    });
    return Object.fromEntries(arrayEntries);
  });
  return tempArray;
};

export default { listToMatrix, changeNameKeysInArrayItems }
