import React from 'react'
import PropTypes from 'prop-types'
import styles from './_publicFooterColumn.scss'

const PublicFooterColumn = ({ children, title, className }) => (
  <div className={[styles.column].concat(className).join(' ')}>
    <p className={styles.titleColumn}>{title}</p>
    <div className={styles.items}>{children}</div>
  </div>
)

PublicFooterColumn.defaultProps = {}

PublicFooterColumn.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
}

export default PublicFooterColumn
