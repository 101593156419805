/* eslint-disable arrow-body-style */
/* eslint-disable react/default-props-match-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_nextSteps.scss'
import Icon from '../icons'



const NextSteps = ({ steps, title, subtitle }) => {
return (
    <div className={styles.nextStepsContainer}>
        <div className={styles.contentHeader}>
            <h4 className={styles.title}>{title}</h4>
            <p className={styles.subtitle}>{subtitle}</p>
        </div>
        <div className={styles.stepContainer}>
            {steps.map((sectionContent) => (
                <div
                    className={`${styles.stepContent} ${sectionContent.currently ? `${styles.stepContentCurrently}` : ''}`}

                    key={`sectioncontent-${nanoid()}`}
                >
                
                    <div className={styles.stepNumber} >
                        <p>{sectionContent.number}</p>
                    </div>
                    <div className={styles.stepTexts}>
                        <div className={styles.stepTitle}>
                        {sectionContent.title}
                        </div>
                        <div className={styles.stepDescription}>
                            <p>{sectionContent.description}</p>
                        </div>
                    </div>
                    <div className={styles.stepIcon}>
                        <Icon name='line-print' size={6} />
                    </div>
                </div>
            ))}
        </div>
    </div>
)
}

NextSteps.defaultProps = {
    steps: [],
    currently: false
}

NextSteps.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    steps: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
            number: PropTypes.number,
            currently: PropTypes.bool,
        })
    ),

}

export default NextSteps
