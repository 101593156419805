import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../icons'

import headerItemStyles from './_headerItem.scss'

const HeaderItem = ({ icon, children, bold }) => (
  <div
    className={[
      headerItemStyles.headerItem,
      bold ? headerItemStyles.bold : null
    ]
      .join(' ')
      .trim()}
  >
    {icon && <Icon name={icon} />}
    {children}
  </div>
)

HeaderItem.defaultProps = {}

HeaderItem.propTypes = {
  icon: PropTypes.string,
  bold: PropTypes.bool
}

export default HeaderItem
