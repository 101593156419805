import React, { useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './_ecommerceAccess.scss'
import Icon from '../icons'
import Button from '../buttons'

const EcommerceAccess = ({
  title,
  action,
  className,
  description,
  icon
}) => {
  const messageClasses = [styles.message_,  className]

  const handleResize = (e) => {
    if(e < 563 ){
      // eslint-disable-next-line no-use-before-define
      setfirst(false)
    } else{
      // eslint-disable-next-line no-use-before-define
      setfirst(true)
    }
    }
  
    const [first, setfirst] = useState(true)
  
    useEffect(() => {
      window.removeEventListener('resize', e => handleResize(e.target.innerWidth))
      window.addEventListener('resize', e => handleResize(e.target.innerWidth))
      handleResize(window.innerWidth)
    }, [])


  return (
    <div>
  <div className={messageClasses.join(' ').trim()}>
  <div className={styles.textContainer_}>

          <div className={styles.iconDesktop_}>
            <Icon name={icon} className={styles.prefix_} size='5' />
          </div>            
          <div className={styles.iconMobile_}>
            <Icon name={icon} className={styles.prefix_} size='4' />
          </div>

    <div className={styles.content_}>
      {title && <div className={styles.title_}> {title} </div>}
      {description && <p> {description} </p>}
    </div>
  </div>

  <div className={styles.actionContainer_}>
    {action && (
      <div className={styles.action_}>
        <Button
          variant='text'
          onClick={action.onClick}
          suffix='old-arrow-right'
        >
          { first && action.name }

        </Button>
      </div>
    )}
  </div>
</div>

</div>
  )
}

EcommerceAccess.defaultProps = {
  title: '',
  description: '',
  action: undefined,
}

EcommerceAccess.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  action: PropTypes.exact({
    name: PropTypes.string,
    onClick: PropTypes.func

  }),

}

export default EcommerceAccess
