import React, { Fragment } from 'react'
import classNames from 'classnames'
import Step from './mobileStep'
import MobileStepperOld from './old'

import styles from './_mobileStepper.scss'

const MobileStepper = (props) => {
  const { steps, currentStep, oldMobile, color } = props

  const qtySteps = steps.length > 6 ? 6 : steps.length

  return (
    <>
      { oldMobile ?
        <MobileStepperOld currentStep={currentStep} steps={steps} color={color}/>
        :
        <>
          <div className={styles.desktopStepperContainer}>
            {steps.map((s, index) => (
              index < 6 ?
                <Step
                  key={`step_mobile_${s.step}`}
                  iteratedStep={s}
                  isLast={s.step === steps.length}
                  currentStep={currentStep}
                  color= {color}
                />
              :
                <div key={`step_mobile_${s.step}`} />
            ))}
          </div>

          <div
            className={styles.stepNameContainer}
          >
            <span
              className={styles.stepName}
            >
              {`${steps[currentStep - 1].step}. ${steps[currentStep - 1].name}`}
            </span>

            <span
              className={classNames(
                styles.stepIndicator,
              )}
            >{`${currentStep}/${qtySteps}`}</span>
          </div>
        </>
      
      }
    </>
  )
}

export default MobileStepper
