import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Card from '..'
import Radio from '../../radio'
import Checkbox from '../../checkbox'
import Icon from '../../icons'
import styles from './_cardDropdown.scss'

const CardDropdown = ({
  children,
  headerType,
  headerElementProps,
  opened,
  className,
  title,
  dark,
  disabled,
  description,
  onClick
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(opened || false)
  const [isSelectedStatus, setSelectedStatus] = useState(
    headerElementProps.checked
  )

  const handleClick = () => {
    setIsDropdownOpened((isOpened) => {
      if (onClick) onClick(!isOpened)
      return !isOpened
    })
  }

  useEffect(() => {
    setIsDropdownOpened(opened)
  }, [opened])

  useEffect(() => {
    setSelectedStatus(headerElementProps.checked)
  }, [headerElementProps.checked])

  const getHeaderByType = (header) => {
    const headerTypes = {
      text:
        typeof title === 'string' ? (
          <p className={styles.title}>{title}</p>
        ) : (
          title // just for compatibility with older versions
        ),
      radio: <Radio {...headerElementProps} />,
      checkbox: <Checkbox {...headerElementProps} />
    }
    return headerTypes[header]
  }

  return (
    <Card
      className={classNames(
        styles.card,
        { [styles.disabled]: disabled },
        className
      )}
    >
      <div
        aria-hidden='true'
        className={classNames(styles.dropdown, {
          [styles.dark]: dark,
          [styles.selected]: isSelectedStatus
        })}
        onClick={handleClick}
      >
        <div className={styles.textContainer}>
          {getHeaderByType(headerType)}

          <div className={styles.note}>{description}</div>
        </div>
        <Icon
          size={3}
          name={isDropdownOpened ? 'old-arrow-up' : 'old-arrow-down'}
          className={styles.action}
        />
      </div>
      {isDropdownOpened && <div className={styles.cardContent}>{children}</div>}
    </Card>
  )
}

CardDropdown.defaultProps = {
  headerType: 'text',
  headerElementProps: {},
  opened: false,
  disabled: false,
  title: '',
  dark: false,
  description: '',
  onClick: () => {}
}

CardDropdown.propTypes = {
  headerType: PropTypes.oneOf(['text', 'radio', 'checkbox']),
  headerElementProps: PropTypes.shape({
    checked: PropTypes.bool,
    label: PropTypes.string,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    inline: PropTypes.bool,
    id: PropTypes.string,
    variant: PropTypes.bool,
    onChange: PropTypes.func
  }),
  opened: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  dark: PropTypes.bool,
  description: PropTypes.string,
  onClick: PropTypes.func
}

export default CardDropdown
