import React from 'react'

const EXITOMOBILE = () => {
  const elm = (
    <svg
      width='140px'
      height='129px'
      viewBox='0 0 140 129'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <polygon
          id='path-1'
          points='0.561991111 0.434249784 52.4732444 0.434249784 52.4732444 35.7281289 0.561991111 35.7281289'
        ></polygon>
      </defs>
      <g
        id='PROPUESTA-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g id='PASO-03_01' transform='translate(-90.000000, -247.000000)'>
          <g id='Group-5' transform='translate(10.000000, 174.000000)'>
            <g id='Group-2' transform='translate(25.000000, 73.000000)'>
              <g id='EXITO-APP' transform='translate(55.000000, 0.000000)'>
                <g transform='translate(14.000000, 8.028777)'>
                  <g>
                    <g id='ROSTRO-Y-CUERPO'>
                      <path
                        d='M112,56.2014388 C112,87.2408691 86.9281778,112.402878 56,112.402878 C25.0718222,112.402878 0,87.2408691 0,56.2014388 C0,25.1620086 25.0718222,0 56,0 C86.9281778,0 112,25.1620086 112,56.2014388'
                        id='Fill-1'
                        fill='#D3F0C5'
                      ></path>
                      <path
                        d='M73.3097867,51.7600889 C72.51272,51.4865753 72.1350311,50.6117062 72.4579644,49.8305062 C72.4604533,49.824886 72.4629422,49.8198904 72.4648089,49.8142702 C73.9761867,46.1305781 72.9296089,41.8886184 69.8800978,39.338322 L66.6800089,36.6618846 L56.9677422,37.3587824 L56.9677422,55.4593925 L59.7428533,55.4593925 L59.7428533,56.9268745 C59.7428533,61.6234414 62.4009867,66.0190184 66.6992978,67.8755393 C73.0484533,70.617545 79.1667644,66.018394 79.1667644,59.9848573 C79.1667644,56.1687796 76.7164533,52.9322012 73.3097867,51.7600889'
                        id='Fill-3'
                        fill='#702C7E'
                      ></path>
                      <path
                        d='M53.6174489,37.3584702 L43.9058044,36.6621968 L40.7050933,39.3386342 C37.6562044,41.8889306 36.6096267,46.1308904 38.1210044,49.813958 C38.1259822,49.8251983 38.1297156,49.8364386 38.1346933,49.8476788 C38.4582489,50.6257565 38.0699822,51.4875119 37.2754044,51.7604012 C32.8215378,53.2934515 30.0034933,58.3534544 32.1576267,63.6257738 C33.4275822,66.7355868 36.5380711,68.6876501 39.8868711,68.6876501 L40.0710489,68.6876501 C46.0275822,68.6876501 50.84296,63.8561997 50.84296,57.8963494 L50.84296,55.4590803 L53.6174489,55.4590803 L53.6174489,37.3584702 Z'
                        id='Fill-5'
                        fill='#702C7E'
                      ></path>
                      <path
                        d='M68.0671289,35.2374904 L43.4408178,35.2374904 L43.4408178,27.0939019 C43.4408178,21.9789465 47.5723733,17.8325292 52.6696178,17.8325292 L58.8389511,17.8325292 C63.9355733,17.8325292 68.0671289,21.9789465 68.0671289,27.0939019 L68.0671289,35.2374904 Z'
                        id='Fill-7'
                        fill='#702C7E'
                      ></path>
                      <path
                        d='M56.2521867,48.746318 L55.5465867,48.746318 C51.1437422,48.746318 47.5752978,45.1644129 47.5752978,40.7451065 L47.5752978,24.2755871 C47.5752978,22.0262806 49.3928089,20.2016072 51.6340533,20.2016072 L59.0136089,20.2016072 C61.8913867,20.2016072 64.2240978,22.5427094 64.2240978,25.4302144 L64.2240978,40.7451065 C64.2240978,45.1644129 60.6544089,48.746318 56.2521867,48.746318'
                        id='Fill-9'
                        fill='#F4B6A8'
                      ></path>
                      <path
                        d='M66.4817689,25.2491833 L64.5012356,25.6725675 L63.3899467,19.256861 L53.9657689,18.5612121 C48.0652356,18.5612121 43.3195467,22.7351056 43.4508356,28.654366 L43.5591022,33.5295286 L47.3745689,35.2698999 L49.45528,25.522697 C49.45528,25.522697 54.8866578,29.6672409 62.4746578,28.3564984 L64.0233689,34.5736265 L66.52408,33.8773531 C66.8563467,30.3528984 66.7679911,27.5072322 66.4817689,25.2491833'
                        id='Fill-11'
                        fill='#702C7E'
                      ></path>
                      <path
                        d='M63.2296622,40.6423827 L68.5894844,37.4826129 C69.7741956,36.7844662 70.1699289,35.2557871 69.4749067,34.0674388 C68.7792622,32.8784662 67.25544,32.4813094 66.0713511,33.1794561 L60.7109067,36.3386014 L63.2296622,40.6423827 Z'
                        id='Fill-13'
                        fill='#F4B6A8'
                      ></path>
                      <path
                        d='M66.23568,39.1263177 C66.23568,39.7033191 65.7696356,40.17104 65.1947022,40.17104 C64.6203911,40.17104 64.1543467,39.7033191 64.1543467,39.1263177 C64.1543467,38.5499407 64.6203911,38.0822199 65.1947022,38.0822199 C65.7696356,38.0822199 66.23568,38.5499407 66.23568,39.1263177'
                        id='Fill-17'
                        fill='#FFFFFF'
                      ></path>
                      <path
                        d='M48.5691733,40.6423827 L43.2087289,37.4826129 C42.02464,36.7844662 41.6289067,35.2557871 42.3239289,34.0674388 C43.0195733,32.8784662 44.5427733,32.4813094 45.7274844,33.1794561 L51.0879289,36.3386014 L48.5691733,40.6423827 Z'
                        id='Fill-19'
                        fill='#F4B6A8'
                      ></path>
                      <path
                        d='M47.6534489,39.0989039 C47.6534489,39.6759053 47.1874044,40.1430017 46.6124711,40.1430017 C46.03816,40.1430017 45.5721156,39.6759053 45.5721156,39.0989039 C45.5721156,38.5219024 46.03816,38.0541816 46.6124711,38.0541816 C47.1874044,38.0541816 47.6534489,38.5219024 47.6534489,39.0989039'
                        id='Fill-23'
                        fill='#FFFFFF'
                      ></path>
                      <g
                        id='Group-31'
                        transform='translate(65.955556, 31.223022)'
                      >
                        <mask id='mask-2' fill='white'>
                          {/* <use xlink:href='#path-1'></use> */}
                        </mask>
                        <g id='Clip-30'></g>
                        <path
                          d='M26.3873244,22.5744944 L0.561991111,22.8117894 L3.84670222,35.7281289 L25.7819022,33.1590987 C27.9111467,32.909939 29.9016356,31.9726239 31.4547022,30.4882814 L42.4195022,19.9792368 C46.1883022,16.3667332 49.55888,12.3576973 52.4733689,8.02081957 L51.11008,0.434249784 L45.50448,1.60199079 L46.2131911,4.69931453 L42.77728,6.25297209 L44.2077689,10.7734412 L26.3873244,22.5744944 Z'
                          id='Fill-29'
                          fill='#F4B6A8'
                          mask='url(#mask-2)'
                        ></path>
                      </g>
                      <polygon
                        id='Fill-32'
                        fill='#EF8674'
                        points='113.807742 40.6149065 111.928009 34.9897669 112.543387 34.782446 114.20472 39.7568978 117.739564 38.3481151 117.978498 38.9525928'
                      ></polygon>
                      <path
                        d='M68.4298844,54.0182627 L68.4311289,54.0201361 L66.9863289,54.0344987 L43.4706844,54.0344987 C34.8429511,54.0344987 27.8497956,61.053434 27.8497956,69.7115778 L27.8497956,90.770257 L34.8441956,90.770257 L34.8441956,108.249529 C41.3725511,110.924717 48.5131733,112.402815 55.9997511,112.402815 C62.1591289,112.402815 68.0845511,111.40243 73.6279289,109.558398 L73.5296178,107.143609 C73.0903289,96.238657 73.3292622,85.316844 74.24704,74.4424901 L74.8033067,67.8394455 L83.8603733,66.7784872 L83.8603733,53.8646455 L68.4311289,54.0201361 L68.4298844,54.0182627 Z'
                        id='Fill-44'
                        fill='#3E872E'
                      ></path>
                      <path
                        d='M69.1413956,54.1073108 C68.6517067,54.0611007 68.1564178,54.0348734 67.6542844,54.0348734 L43.4703733,54.0348734 C42.9688622,54.0348734 42.4735733,54.0611007 41.9838844,54.1073108 C43.2973956,60.4262259 48.87624,65.1739986 55.56264,65.1739986 C62.24904,65.1739986 67.8278844,60.4262259 69.1413956,54.1073108'
                        id='Fill-46'
                        fill='#F4B6A8'
                      ></path>
                      <path
                        d='M65.7155644,90.0223407 L56.0574311,90.0223407 C55.2416978,90.0223407 54.5808978,89.3591637 54.5808978,88.541745 L54.5808978,69.643699 C54.5808978,68.8262803 55.2416978,68.1624788 56.0574311,68.1624788 L65.7155644,68.1624788 C66.5312978,68.1624788 67.1914756,68.8262803 67.1914756,69.643699 L67.1914756,88.541745 C67.1914756,89.3591637 66.5312978,90.0223407 65.7155644,90.0223407'
                        id='Fill-48'
                        fill='#F6F3F5'
                      ></path>
                      <path
                        d='M58.0841956,70.6231652 C58.0841956,71.2064112 57.6131733,71.6778788 57.0332622,71.6778788 C56.4527289,71.6778788 55.9823289,71.2064112 55.9823289,70.6231652 C55.9823289,70.0405436 56.4527289,69.569076 57.0332622,69.569076 C57.6131733,69.569076 58.0841956,70.0405436 58.0841956,70.6231652'
                        id='Fill-50'
                        fill='#D4D0D5'
                      ></path>
                      <path
                        d='M34.4324711,105.085013 C30.9194044,104.809002 27.8499822,101.89402 27.8499822,97.946806 L27.8499822,93.9221586 L27.8499822,81.4204607 L27.8499822,75.0409729 L38.3599378,75.0409729 L38.3599378,90.5744262 L51.9044711,80.0104291 L60.1290044,79.766265 L60.73256,85.2146823 L53.2055378,87.2529212 L39.3791378,103.003062 C38.2323822,104.309433 36.5915822,105.127477 34.8562044,105.104996 C34.7174489,105.103123 34.5762044,105.096878 34.4324711,105.085013'
                        id='Fill-52'
                        fill='#F4B6A8'
                      ></path>
                      <polygon
                        id='Fill-54'
                        fill='#F4B6A8'
                        points='61.4486756 57.5217355 50.3494756 57.5217355 52.4308089 44.983819 59.3679644 44.983819'
                      ></polygon>
                    </g>
                    <g
                      id='EXPRESIONES'
                      transform='translate(43.394453, 30.357616)'
                    >
                      <path
                        d='M21.5177604,6.64778464 C21.3883382,6.64778464 21.2638937,6.57472277 21.2041604,6.44920623 C21.1220271,6.27498177 21.1960715,6.06703644 21.3696715,5.98460767 L24.5143826,4.49214723 C24.6861159,4.41034292 24.8945604,4.48340479 24.9773159,4.65762925 C25.0588271,4.83185371 24.9847826,5.03979903 24.8118048,5.12160335 L21.6658493,6.61468824 C21.6179382,6.63716882 21.5681604,6.64778464 21.5177604,6.64778464'
                        id='Fill-15'
                        fill='#EF8674'
                      ></path>
                      <path
                        d='M3.49154705,6.64778464 C3.44176928,6.64778464 3.39136928,6.63716882 3.34345817,6.61468824 L0.198747054,5.12160335 C0.0251470542,5.03979903 -0.048275168,4.83185371 0.0332359431,4.65762925 C0.115991499,4.48340479 0.322569276,4.41096738 0.495547054,4.49214723 L3.63963594,5.98460767 C3.81385817,6.06703644 3.88728039,6.27498177 3.80514705,6.44920623 C3.74541372,6.57472277 3.62096928,6.64778464 3.49154705,6.64778464'
                        id='Fill-21'
                        fill='#EF8674'
                      ></path>
                      <path
                        d='M12.5046848,14.2785038 C14.2288626,14.2785038 15.6269959,12.8759657 15.6269959,11.1449613 L9.38299594,11.1449613 C9.38299594,12.8759657 10.7805071,14.2785038 12.5046848,14.2785038'
                        id='Fill-25'
                        fill='#1F1E1E'
                      ></path>
                      <path
                        d='M9.46649817,11.8416094 L15.5431204,11.8416094 C15.5941426,11.6174281 15.6264982,11.3857533 15.6264982,11.145336 L9.38312039,11.145336 C9.38312039,11.3857533 9.41547594,11.6174281 9.46649817,11.8416094'
                        id='Fill-27'
                        fill='#FFFFFF'
                      ></path>
                      <path
                        d='M11.3307382,8.88522637 C11.1801604,8.88522637 11.0420271,8.78718608 10.9978493,8.6354422 C10.9437159,8.45122637 11.0494937,8.25764364 11.2330493,8.20331558 L13.2502937,7.60945371 L12.0064715,3.26820479 C11.9535826,3.08274004 12.0599826,2.88978177 12.2441604,2.83670263 C12.4277159,2.78362349 12.6199826,2.89040623 12.6728715,3.07524651 L14.1083382,8.08279472 L11.4278048,8.8721127 C11.3954493,8.88085515 11.3624715,8.88522637 11.3307382,8.88522637'
                        id='Fill-34'
                        fill='#EF8674'
                      ></path>
                      <path
                        d='M9.78974261,3.02297918 C9.87125372,3.59436047 9.47614261,4.12390292 8.9074315,4.20570723 C8.33872039,4.28751155 7.81107594,3.89160364 7.72956483,3.3208468 C7.64805372,2.74946551 8.04254261,2.22054752 8.61125372,2.13811874 C9.18058705,2.05568997 9.70760928,2.45222234 9.78974261,3.02297918'
                        id='Fill-36'
                        fill='#1F1E1E'
                      ></path>
                      <path
                        d='M7.45093372,0.938592702 C7.30657817,0.938592702 7.17155594,0.847421479 7.12240039,0.702546659 C7.06017817,0.520204213 7.15724483,0.323499177 7.33893372,0.261053133 C9.02328928,-0.314074924 10.1731559,0.238572558 10.2210671,0.262302054 C10.3934226,0.347228673 10.4643559,0.555798457 10.3791115,0.728149536 C10.2951115,0.899876155 10.0885337,0.971689105 9.91680039,0.888011407 C9.87635594,0.868653133 8.9567115,0.4446445 7.56293372,0.919858889 C7.52497817,0.932972558 7.48826705,0.938592702 7.45093372,0.938592702'
                        id='Fill-38'
                        fill='#702C7E'
                      ></path>
                      <path
                        d='M17.5589959,0.938592702 C17.5210404,0.938592702 17.4843293,0.932972558 17.4469959,0.919858889 C16.0494848,0.443395579 15.1317071,0.869902054 15.0918848,0.888011407 C14.9195293,0.969191263 14.7129515,0.898002774 14.6301959,0.725651695 C14.5468182,0.553925076 14.6165071,0.347228673 14.7882404,0.262302054 C14.8361515,0.237948097 15.9860182,-0.314699385 17.6709959,0.261053133 C17.8520626,0.323499177 17.9491293,0.520204213 17.8869071,0.702546659 C17.8377515,0.847421479 17.7027293,0.938592702 17.5589959,0.938592702'
                        id='Fill-40'
                        fill='#702C7E'
                      ></path>
                      <path
                        d='M16.2501515,4.18347644 L16.2501515,4.18347644 C15.6752182,4.18347644 15.2097959,3.71575558 15.2097959,3.13875414 C15.2097959,2.56237716 15.6752182,2.0946563 16.2501515,2.0946563 C16.8250848,2.0946563 17.2905071,2.56237716 17.2905071,3.13875414 C17.2905071,3.71575558 16.8250848,4.18347644 16.2501515,4.18347644'
                        id='Fill-42'
                        fill='#1F1E1E'
                      ></path>
                    </g>
                  </g>
                  <g id='EXITO' transform='translate(84.000000, 8.028777)'>
                    <ellipse
                      id='Oval'
                      fill='#FFFFFF'
                      cx='10'
                      cy='10.0359712'
                      rx='8'
                      ry='8.02877698'
                    ></ellipse>
                    <g id='00.Token/Icons/Solid/16x16/check' fill='#006B14'>
                      <path
                        d='M10.00025,0.627373651 C4.830875,0.627373651 0.62525,4.84749955 0.62525,10.0360967 C0.62525,15.2240665 4.830875,19.4448197 10.00025,19.4448197 C15.169625,19.4448197 19.37525,15.2240665 19.37525,10.0360967 C19.37525,4.84749955 15.169625,0.627373651 10.00025,0.627373651 M12.4194593,6.90740967 C12.7604113,6.51634868 13.3526887,6.47672148 13.7423481,6.81889985 C14.0965838,7.1299711 14.1614195,7.64949654 13.914118,8.03519479 L13.8305407,8.14654716 L8.79566568,13.9213883 L5.58708739,10.7012684 C5.22097087,10.3338349 5.22097087,9.73810753 5.58708739,9.37067404 C5.9199206,9.03664359 6.4407502,9.00627719 6.80776457,9.27957483 L6.91291261,9.37067404 L8.70375,11.167527 L12.4194593,6.90740967 Z'
                        id='color-icono'
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default EXITOMOBILE
