import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import Icon from '../icons'
import styles from './_kebabMenu.scss'
import IconListModel from '../icons/models/icon-list.model'

const KebabMenu = ({ options, className }) => {
  const [open, setOptionOpen] = useState(false)

  const handlerOpen = () => {
    if (open) return

    setOptionOpen(true)

    const globalClick = () => {
      setOptionOpen(false)
      setTimeout(() => document.removeEventListener('click', globalClick), 10)
    }

    document.addEventListener('click', globalClick)
  }

  return (
    <button
      type='button'
      className={[styles.menuContainer, className].join(' ').trim()}
      tabIndex={0}
      onClick={handlerOpen}
    >
      <Icon name='old-menu-kebab' size='3' />
      <ul className={styles.dropdownMenu}>
        {open &&
          options.map((opt) => (
            <button
              type='button'
              key={`key-${nanoid()}`}
              className={styles.option}
              onClick={opt.action}
            >
              {opt.icon && <Icon name={opt.icon} size={2} />}
              {opt.text}
            </button>
          ))}
      </ul>
    </button>
  )
}

KebabMenu.defaultProps = {
  options: []
}

KebabMenu.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.oneOf(IconListModel.iconList),
      text: PropTypes.string,
      action: PropTypes.func
    })
  )
}

export default KebabMenu
