import React, { useState, useEffect } from 'react'

import ErrorApp from './assets/errorApp'
import ErrorDesk from './assets/errorDesk'

import ExitoApp from './assets/exitoApp'
import ExitoDesk from './assets/exitoDesk'

import InfoDesk from './assets/infoDesk'
import InfoApp from './assets/infoApp'

import AlertaDesk from './assets/alertaDesk'
import AlertaApp from './assets/alertaApp'

function debounce(fn, ms) {
  let timer

  return (_) => {
    clearTimeout(timer)

    timer = setTimeout((_) => {
      timer = null

      fn.apply(this, arguments)
    }, ms)
  }
}

const types = {
  success: {
    desktop: <ExitoDesk />,
    mobile: <ExitoApp />
  },
  alert: {
    desktop: <AlertaDesk />,
    mobile: <AlertaApp />
  },
  error: {
    desktop: <ErrorDesk />,
    mobile: <ErrorApp />
  },
  info: {
    desktop: <InfoDesk />,
    mobile: <InfoApp />
  }
}

const InfoImage = ({ type }) => {
  const [usingImage, setUsingImage] = useState(types[type])
  const [platform, setPlatform] = useState('mobile')

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  })

  useEffect(() => {
    setUsingImage(types[type])
  }, [type])

  useEffect(() => {
    if (dimensions.width > 767) setPlatform('desktop')
    else setPlatform('mobile')
  }, [dimensions])

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      })
    }, 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return (_) => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  })

  return <React.Fragment>{usingImage[platform]}</React.Fragment>
}

export default InfoImage
