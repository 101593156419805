import React, { Fragment } from 'react'
import Step from './desktopStep'
import styles from './_desktopStepper.scss'

const DesktopStepper = (props) => {
  const { steps, currentStep, color } = props

  return (
    <div className={styles.desktopStepperContainer}>
      {steps.map((s, index) => (
        index < 6 ?
          <Step
            key={`step_desktop_${s.step}`}
            iteratedStep={s}
            isLast={s.step === steps.length}
            currentStep={currentStep}
            color= {color}
          />
        :
          <div key={`step_desktop_${s.step}`} />
      ))}
    </div>
  )
}

export default DesktopStepper