import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_vouchertitle.scss'
import Button from '../../buttons'
import { set } from 'date-fns'

const VoucherTitle = ({ title, subtitle,items }) => {
  const containerClass = [styles.voucherTitle]

  const refContainer = createRef()

  const handleResize = (e) => {
  if(e < 563 ){
    setfirst(false)
  } else{
    setfirst(true)
  }
  }

  const [first, setfirst] = useState(true)

  useEffect(() => {
    window.removeEventListener('resize', e => handleResize(e.target.innerWidth))
    window.addEventListener('resize', e => handleResize(e.target.innerWidth))
    handleResize(window.innerWidth)
  }, [])

  return (
    <div className={containerClass.join(' ')}>
      <div className={styles.prefix}>
        <h4 data-testid='test-title' className={styles.title}>{title}</h4>
        <p className={styles.subtitle}>{subtitle}</p>

      </div>

      <div className={styles.suffix}>
        {items.map((row) => (
          <div key={`row-${nanoid()}`} className={styles.rowitem}>
            {row.map((rowitem) => (
              <div
                key={`rowitem-${nanoid()}`}
                style={rowitem.flex ? { flex: rowitem.flex } : { flex: 1 }}
                ref={refContainer}
                className={styles.item}
              >
                <Button
                  className={styles.buttonfix} 
                  onClick={rowitem.action}
                  variant='text'
                  ariant='text'
                  color='primary'
                  utility='alignLeft'
                  prefix={rowitem.icon}
                >
                  { first && rowitem.label}
                </Button>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

export default VoucherTitle

VoucherTitle.defaultProps = {
  items: [],
  title: '',
  subtitle: ''
}

VoucherTitle.prototype = {
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        flex: PropTypes.number,
        action: PropTypes.func,
        icon: PropTypes.string,
        label: PropTypes.string
      })
    )
  ),
  title: PropTypes.string,
  subtitle: PropTypes.string,

  flex: PropTypes.number
}
