import React from 'react'

const ALERTAMOBILE = () => {
  const elm = (
    <svg
      width='164px'
      height='160px'
      viewBox='0 0 164 160'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <path
          d='M8.55542857,0.750628571 C4.32022857,0.904228571 0.903428571,4.32022857 0.750628571,8.55622857 L0.750628571,8.55622857 C0.744228571,8.71782857 0.874628571,8.85462857 1.03782857,8.85462857 L1.03782857,8.85462857 L7.13622857,8.85462857 C8.08502857,8.85462857 8.85382857,8.08582857 8.85382857,7.13622857 L8.85382857,7.13622857 L8.85382857,1.03782857 C8.85382857,0.878628571 8.72502857,0.750628571 8.56662857,0.750628571 L8.56662857,0.750628571 C8.56262857,0.750628571 8.55942857,0.750628571 8.55542857,0.750628571 L8.55542857,0.750628571 Z'
          id='path-1'
        ></path>
        <path
          d='M8.55542857,0.750628571 C4.32022857,0.904228571 0.903428571,4.32022857 0.750628571,8.55622857 L0.750628571,8.55622857 C0.744228571,8.71782857 0.874628571,8.85462857 1.03782857,8.85462857 L1.03782857,8.85462857 L7.13622857,8.85462857 C8.08502857,8.85462857 8.85382857,8.08582857 8.85382857,7.13622857 L8.85382857,7.13622857 L8.85382857,1.03782857 C8.85382857,0.878628571 8.72502857,0.750628571 8.56662857,0.750628571 L8.56662857,0.750628571 C8.56262857,0.750628571 8.55942857,0.750628571 8.55542857,0.750628571 L8.55542857,0.750628571 Z'
          id='path-3'
        ></path>
        <path
          d='M-0.326971429,-5.73257143 C-3.31337143,-5.73257143 -5.73337143,-3.31257143 -5.73337143,-0.326971429 L-5.73337143,-0.326971429 L-5.73337143,7.15782857 C-5.73337143,8.09462857 -4.97337143,8.85462857 -4.03657143,8.85462857 L-4.03657143,8.85462857 L-3.02617143,8.85462857 C-2.87257143,8.85462857 -2.74857143,8.73062857 -2.74697143,8.57702857 L-2.74697143,8.57702857 C-2.60217143,2.39702857 2.39862857,-2.60377143 8.57942857,-2.74697143 L8.57942857,-2.74697143 C8.73142857,-2.74937143 8.85382857,-2.87417143 8.85382857,-3.02537143 L8.85382857,-3.02537143 L8.85382857,-4.03737143 C8.85382857,-4.97417143 8.09462857,-5.73257143 7.15782857,-5.73257143 L7.15782857,-5.73257143 L-0.326971429,-5.73257143 Z'
          id='path-5'
        ></path>
      </defs>
      <g
        id='MENSAJERIAS'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='DESKTOP-ERROR' transform='translate(-156.000000, -40.000000)'>
          <g id='ERROR-DESKTOP' transform='translate(156.000000, 40.000000)'>
            <g transform='translate(10.000000, 6.000000)'>
              <g id='ERROR-RESPONSIVE'>
                <g id='ROSTRO-Y-CUERPO'>
                  <path
                    d='M144,74 C144,113.7648 111.764,146 72,146 C32.2352,146 0,113.7648 0,74 C0,34.2352 32.2352,2 72,2 C111.764,2 144,34.2352 144,74'
                    id='Fill-1'
                    fill='#F3E0E1'
                  ></path>
                  <path
                    d='M118.94456,102.355447 L25.05496,102.355447 L25.05496,84.0058465 C25.05496,64.9794465 40.47896,49.5554465 59.50536,49.5554465 L84.49416,49.5554465 C103.52056,49.5554465 118.94456,64.9794465 118.94456,84.0058465 L118.94456,102.355447 Z'
                    id='Fill-3'
                    fill='#BC3951'
                  ></path>
                  <path
                    d='M72.07816,62.3554465 C80.59496,62.3554465 87.95656,57.4242465 91.47336,50.2634465 C89.21976,49.8002465 86.88536,49.5554465 84.49416,49.5554465 L59.50536,49.5554465 C57.16936,49.5554465 54.88856,49.7906465 52.68296,50.2338465 C56.19496,57.4074465 63.55256,62.3554465 72.07816,62.3554465'
                    id='Fill-5'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M104.97032,131.535847 C99.24552,131.340647 93.78152,126.387847 91.23592,120.722247 L72.92952,79.9694465 L70.56232,80.9110465 L68.67912,76.1774465 L73.41992,69.7206465 L78.14632,72.4094465 L79.57112,69.1006465 L74.21752,64.8342465 L74.37832,62.9422465 L82.41272,67.0558465 L81.92152,77.3046465 L102.81112,106.983847 L102.81112,85.4606465 L118.94472,85.4606465 L118.93432,117.164647 C118.93272,120.819847 117.70232,124.412647 115.28312,127.152647 C113.13432,129.585447 109.85192,131.702247 104.97032,131.535847'
                    id='Fill-7'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M72.17192,80.2741665 L70.97192,77.4741665 C70.88552,77.2717665 70.97912,77.0365665 71.18232,76.9493665 C71.38472,76.8621665 71.62072,76.9557665 71.70792,77.1589665 L72.90792,79.9589665 L72.17192,80.2741665 Z'
                    id='Fill-9'
                    fill='#EF8674'
                  ></path>
                  <polygon
                    id='Fill-11'
                    fill='#F4B6A8'
                    points='82.8 53.2336065 61.2 53.2336065 62.8 31.6336065 81.2 31.6336065'
                  ></polygon>
                  <path
                    d='M61.94616,30.8359265 L55.76456,27.2055265 C54.39896,26.4031265 53.94216,24.6463265 54.74456,23.2807265 C55.54616,21.9151265 57.30296,21.4583265 58.66856,22.2599265 L64.85096,25.8903265 L61.94616,30.8359265 Z'
                    id='Fill-13'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M82.05312,30.8359265 L88.23552,27.2055265 C89.60112,26.4031265 90.05792,24.6463265 89.25552,23.2807265 C88.45312,21.9151265 86.69632,21.4583265 85.33072,22.2599265 L79.14912,25.8903265 L82.05312,30.8359265 Z'
                    id='Fill-17'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M72.0188,47.6336065 L72.0188,47.6336065 C65.8332,47.6336065 60.8188,42.6192065 60.8188,36.4336065 L60.8188,14.0088065 C60.8188,10.4880065 63.6724,7.63360653 67.1932,7.63360653 L72.4084,7.63360653 C78.3788,7.63360653 83.2188,12.4736065 83.2188,18.4440065 L83.2188,36.4336065 C83.2188,42.6192065 78.2036,47.6336065 72.0188,47.6336065'
                    id='Fill-21'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M81.6188,23.3152065 C81.6188,28.6008065 77.4828,33.1352065 72.1988,33.2320065 C66.814,33.3304065 62.4188,28.9952065 62.4188,23.6336065 L62.418,16.7488065 C62.418,14.5376065 64.3404,12.8192065 66.5388,13.0624065 C70.082,13.4544065 74.154,13.8680065 74.7692,13.7736065 C76.8812,13.8320065 78.5172,13.2792065 79.7956,12.3936065 C80.9508,13.6960065 81.6188,15.3872065 81.6188,17.1736065 L81.6188,23.3152065 Z M81.2308,0.508806535 L81.2308,0.508806535 C78.618,-0.797593465 75.454,0.512806535 74.5308,3.28400653 L72.2804,7.63360653 L67.1932,7.63360653 C63.7828,7.63360653 60.9852,10.3344065 60.8652,13.7432065 L60.8188,15.0568065 L60.8188,33.2336065 L60.8188,36.4336065 L60.8188,37.2336065 C60.8188,43.4192065 65.8332,48.4336065 72.0188,48.4336065 C78.2036,48.4336065 83.2188,43.4192065 83.2188,37.2336065 L83.2188,36.4336065 L83.2188,33.2336065 L83.2188,16.8336065 C83.2188,16.8336065 83.3692,13.0160065 81.494,10.7952065 C82.6772,9.31360653 83.3644,7.50800653 83.754,5.91840653 C84.2868,3.75040653 83.2268,1.50720653 81.2308,0.508806535 L81.2308,0.508806535 Z'
                    id='Fill-35'
                    fill='#EF8A33'
                  ></path>
                  <path
                    d='M41.05472,139.955367 L41.05472,139.955367 C32.21872,139.955367 25.05472,132.792167 25.05472,123.955367 L25.05472,85.5553665 L41.05472,85.5553665 L41.05472,139.955367 Z'
                    id='Fill-37'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M120.15704,146.355367 L23.84184,146.355367 C23.84184,142.886567 26.65464,140.073767 30.12344,140.073767 L113.87544,140.073767 C117.34504,140.073767 120.15704,142.886567 120.15704,146.355367'
                    id='Fill-41'
                    fill='#BC3951'
                  ></path>
                  <path
                    d='M106.3852,140.074087 L37.614,140.074087 L37.614,102.000487 C37.614,99.1292865 39.942,96.8020865 42.8132,96.8020865 L101.1868,96.8020865 C104.058,96.8020865 106.3852,99.1292865 106.3852,102.000487 L106.3852,140.074087 Z'
                    id='Fill-43'
                    fill='#F6F3F5'
                  ></path>
                  <polygon
                    id='Fill-45'
                    fill='#D4D0D5'
                    points='37.61424 140.073927 106.38544 140.073927 106.38544 136.094727 37.61424 136.094727'
                  ></polygon>
                  <path
                    d='M41.82504,138.084247 C41.82504,138.770647 41.30184,139.328247 40.65544,139.328247 C40.00904,139.328247 39.48584,138.770647 39.48584,138.084247 C39.48584,137.397847 40.00904,136.841047 40.65544,136.841047 C41.30184,136.841047 41.82504,137.397847 41.82504,138.084247'
                    id='Fill-47'
                    fill='#9C9A9F'
                  ></path>
                  <path
                    d='M46.0352,138.084247 C46.0352,138.770647 45.512,139.328247 44.8656,139.328247 C44.2192,139.328247 43.696,138.770647 43.696,138.084247 C43.696,137.397847 44.2192,136.841047 44.8656,136.841047 C45.512,136.841047 46.0352,137.397847 46.0352,138.084247'
                    id='Fill-48'
                    fill='#9C9A9F'
                  ></path>
                  <path
                    d='M78.99536,117.628007 C74.76016,117.781607 71.34336,121.198407 71.18976,125.433607 C71.18336,125.596007 71.31376,125.732007 71.47776,125.732007 L77.57616,125.732007 C78.52496,125.732007 79.29376,124.963207 79.29376,124.013607 L79.29376,117.915207 C79.29376,117.752007 79.15856,117.621607 78.99536,117.628007'
                    id='Fill-49'
                    fill='#D4D0D5'
                  ></path>
                  <g id='LOGO' transform='translate(70.439611, 116.877698)'>
                    <mask id='mask-2' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-52'></g>
                    <mask id='mask-4' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-55'></g>
                    <polygon
                      id='Fill-54'
                      fill='#D4D0D5'
                      mask='url(#mask-4)'
                      points='-1.74537143 11.3482286 11.3482286 11.3482286 11.3482286 -1.74537143 -1.74537143 -1.74537143'
                    ></polygon>
                    <path
                      d='M7.15806857,-5.73329143 L-0.326731429,-5.73329143 C-3.31313143,-5.73329143 -5.73313143,-3.31329143 -5.73313143,-0.326891429 L-5.73313143,7.15790857 C-5.73313143,8.09470857 -4.97393143,8.85390857 -4.03713143,8.85390857 L-3.02593143,8.85390857 C-2.87233143,8.85390857 -2.74913143,8.73070857 -2.74753143,8.57790857 C-2.60193143,2.39710857 2.39886857,-2.60369143 8.57966857,-2.74769143 C8.73166857,-2.75009143 8.85406857,-2.87409143 8.85406857,-3.02529143 L8.85406857,-4.03729143 C8.85406857,-4.97409143 8.09486857,-5.73329143 7.15806857,-5.73329143'
                      id='Fill-57'
                      fill='#D4D0D5'
                      mask='url(#mask-4)'
                    ></path>
                    <mask id='mask-6' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-60'></g>
                    <polygon
                      id='Fill-61'
                      fill='#D4D0D5'
                      mask='url(#mask-6)'
                      points='-8.22857143 11.3490286 11.3490286 11.3490286 11.3490286 -8.22857143 -8.22857143 -8.22857143'
                    ></polygon>
                  </g>
                </g>
                <g id='EXPRESIONES' transform='translate(55.978615, 17.838755)'>
                  <path
                    d='M4.02690473,8.81829136 C3.96930473,8.81829136 3.91090473,8.80629136 3.85570473,8.77989136 L0.229304732,7.06469136 C0.0293047315,6.96949136 -0.0562952685,6.73109136 0.0389047315,6.53189136 C0.134104732,6.33109136 0.373304732,6.24789136 0.571704732,6.34069136 L4.19810473,8.05669136 C4.39810473,8.15109136 4.48290473,8.38949136 4.38850473,8.58949136 C4.31970473,8.73429136 4.17650473,8.81829136 4.02690473,8.81829136'
                    id='Fill-15'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M28.0151447,8.81829136 C27.8655447,8.81829136 27.7223447,8.73429136 27.6535447,8.58949136 C27.5591447,8.38949136 27.6439447,8.15109136 27.8439447,8.05669136 L31.4711447,6.34069136 C31.6687447,6.24709136 31.9079447,6.33029136 32.0039447,6.53189136 C32.0983447,6.73109136 32.0135447,6.96949136 31.8135447,7.06469136 L28.1863447,8.77989136 C28.1311447,8.80629136 28.0727447,8.81829136 28.0151447,8.81829136'
                    id='Fill-19'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M12.4472247,5.62453136 C12.5408247,6.28053136 12.0864247,6.88853136 11.4304247,6.98293136 C10.7736247,7.07733136 10.1656247,6.62213136 10.0712247,5.96613136 C9.97682473,5.30933136 10.4320247,4.70133136 11.0888247,4.60773136 C11.7448247,4.51333136 12.3528247,4.96853136 12.4472247,5.62453136'
                    id='Fill-23'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M22.1769047,5.62453136 C22.2713047,6.28053136 21.8161047,6.88853136 21.1601047,6.98293136 C20.5041047,7.07733136 19.8953047,6.62213136 19.8009047,5.96613136 C19.7073047,5.30933136 20.1625047,4.70133136 20.8185047,4.60773136 C21.4745047,4.51333136 22.0833047,4.96853136 22.1769047,5.62453136'
                    id='Fill-25'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M7.97530473,2.72605136 C7.82170473,2.72605136 7.66810473,2.66765136 7.55130473,2.55005136 C7.31770473,2.31565136 7.31690473,1.93725136 7.55130473,1.70285136 C8.65610473,0.596451365 11.3993047,-1.00674864 13.8785047,0.850051365 C14.1441047,1.04845136 14.1977047,1.42445136 13.9993047,1.69005136 C13.8001047,1.95405136 13.4249047,2.00845136 13.1593047,1.81085136 C10.8985047,0.118851365 8.50010473,2.45165136 8.39850473,2.55165136 C8.28170473,2.66765136 8.12810473,2.72605136 7.97530473,2.72605136'
                    id='Fill-27'
                    fill='#EF8A33'
                  ></path>
                  <path
                    d='M23.9190647,2.72605136 C23.7662647,2.72605136 23.6126647,2.66765136 23.4958647,2.55165136 C23.3950647,2.45165136 20.9990647,0.118051365 18.7350647,1.81085136 C18.4694647,2.00925136 18.0934647,1.95565136 17.8950647,1.69005136 C17.6966647,1.42445136 17.7502647,1.04845136 18.0158647,0.850051365 C20.4950647,-1.00674864 23.2382647,0.596451365 24.3430647,1.70285136 C24.5774647,1.93725136 24.5766647,2.31565136 24.3430647,2.55005136 C24.2262647,2.66765136 24.0726647,2.72605136 23.9190647,2.72605136'
                    id='Fill-29'
                    fill='#EF8A33'
                  ></path>
                  <path
                    d='M15.8136247,3.79485136 C15.8136247,3.79485136 18.7584247,7.41245136 18.8760247,13.8612514 L14.8032247,13.6388514'
                    id='Fill-31'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M19.2839447,14.2846914 L14.7815447,14.0374914 C14.5615447,14.0262914 14.3919447,13.8374914 14.4039447,13.6166914 C14.4167447,13.3966914 14.5959447,13.2214914 14.8255447,13.2390914 L18.4639447,13.4382914 C18.2319447,7.46789136 15.5319447,4.08149136 15.5031447,4.04709136 C15.3639447,3.87589136 15.3895447,3.62389136 15.5615447,3.48469136 C15.7327447,3.34549136 15.9839447,3.36949136 16.1239447,3.54229136 C16.2479447,3.69429136 19.1567447,7.34229136 19.2759447,13.8542914 L19.2839447,14.2846914 Z'
                    id='Fill-33'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M18.1089047,20.6889314 C18.2345047,21.5633314 17.6273047,22.3745314 16.7537047,22.5001314 C15.8785047,22.6257314 15.0673047,22.0185314 14.9417047,21.1441314 C14.8169047,20.2697314 15.4241047,19.4585314 16.2977047,19.3329314 C17.1729047,19.2073314 17.9833047,19.8145314 18.1089047,20.6889314'
                    id='Fill-39'
                    fill='#1F1E1E'
                  ></path>
                </g>
              </g>
              <g id='ERROR' transform='translate(108.000000, 9.833333)'>
                <circle
                  id='Oval'
                  fill='#FFFFFF'
                  cx='14'
                  cy='14.1666667'
                  r='12'
                ></circle>
                <g id='00.Token/Icons/Solid/32x32/cross' fill='#BF0238'>
                  <path
                    d='M14,0.875 C6.741875,0.875 0.875,6.741875 0.875,14 C0.875,21.258125 6.741875,27.125 14,27.125 C21.258125,27.125 27.125,21.258125 27.125,14 C27.125,6.741875 21.258125,0.875 14,0.875 Z M19.64375,19.64375 C19.131875,20.155625 18.305,20.155625 17.793125,19.64375 L14,15.850625 L10.206875,19.64375 C9.695,20.155625 8.868125,20.155625 8.35625,19.64375 C7.844375,19.131875 7.844375,18.305 8.35625,17.793125 L12.149375,14 L8.35625,10.206875 C7.844375,9.695 7.844375,8.868125 8.35625,8.35625 C8.868125,7.844375 9.695,7.844375 10.206875,8.35625 L14,12.149375 L17.793125,8.35625 C18.305,7.844375 19.131875,7.844375 19.64375,8.35625 C20.155625,8.868125 20.155625,9.695 19.64375,10.206875 L15.850625,14 L19.64375,17.793125 C20.1425,18.291875 20.1425,19.131875 19.64375,19.64375 Z'
                    id='color-icono'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default ALERTAMOBILE
