import React from 'react'

const ALERTAMOBILE = () => {
  const elm = (
    <svg
      width='140px'
      height='128px'
      viewBox='0 0 140 128'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <polygon
          id='path-1'
          points='0 0 111.999751 0 111.999751 112 0 112'
        ></polygon>
        <polygon
          id='path-3'
          points='0.241982222 0.483653333 55.7323822 0.483653333 55.7323822 59.1111111 0.241982222 59.1111111'
        ></polygon>
        <polygon id='path-5' points='0 112 112 112 112 0 0 0'></polygon>
      </defs>
      <g
        id='MENSAJERIAS'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='APP-ALERTA' transform='translate(-90.000000, -40.000000)'>
          <g id='ALERTA-APP' transform='translate(90.000000, 40.000000)'>
            <g transform='translate(14.000000, 8.000000)'>
              <g>
                <g id='ROSTRO-Y-CUERPO'>
                  <g id='Group-3'>
                    <mask id='mask-2' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-2'></g>
                    <path
                      d='M111.999751,56 C111.999751,86.9281778 86.9279289,112 55.9997511,112 C25.0721956,112 -0.000248888889,86.9281778 -0.000248888889,56 C-0.000248888889,25.0718222 25.0721956,0 55.9997511,0 C86.9279289,0 111.999751,25.0718222 111.999751,56'
                      id='Fill-1'
                      fill='#EFE6B2'
                      mask='url(#mask-2)'
                    ></path>
                  </g>
                  <path
                    d='M65.9322222,35.2291022 L45.1213778,35.2291022 C44.5265333,35.2291022 44.0436889,34.74688 44.0436889,34.1520356 L44.0436889,27.2086578 C44.0436889,22.02928 48.2424444,17.8305244 53.4218222,17.8305244 L57.6317778,17.8305244 C62.8105333,17.8305244 67.0092889,22.02928 67.0092889,27.2086578 L67.0092889,34.1520356 C67.0092889,34.74688 66.5270667,35.2291022 65.9322222,35.2291022'
                    id='Fill-4'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M53.9228356,53.9316089 L53.9228356,37.2286756 L44.18008,36.5330311 L40.72488,39.4462756 C36.2772356,43.1970311 37.1838133,50.4303644 42.5287022,52.7276089 C45.2615022,53.9023644 48.9531467,54.5314311 53.9228356,53.9316089'
                    id='Fill-6'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M57.1305778,53.9316089 L57.1305778,37.2286756 L66.8733333,36.5330311 L70.3285333,39.4462756 C74.7761778,43.1970311 73.8689778,50.4303644 68.5247111,52.7276089 C65.7919111,53.9023644 62.1002667,54.5314311 57.1305778,53.9316089'
                    id='Fill-8'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M56.4196267,48.3637778 L55.5391822,48.3637778 C51.1525156,48.3637778 47.5965156,44.8077778 47.5965156,40.4217333 L47.5965156,24.3721333 C47.5965156,22.1389778 49.4071822,20.3276889 51.6415822,20.3276889 L59.1710933,20.3276889 C62.0376711,20.3276889 64.3622933,22.6516889 64.3622933,25.5182667 L64.3622933,40.4217333 C64.3622933,44.8077778 60.8062933,48.3637778 56.4196267,48.3637778'
                    id='Fill-10'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M48.5780089,40.5118933 L43.2001422,37.3534933 C42.01232,36.6559822 41.6153422,35.1271822 42.3128533,33.93936 C43.0109867,32.7509156 44.5391644,32.3539378 45.7269867,33.0514489 L51.1048533,36.2098489 L48.5780089,40.5118933 Z'
                    id='Fill-20'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M47.5967022,34.14992 L47.9009689,33.4996978 C49.5542133,29.9716978 52.8327022,27.4156089 56.6892356,26.8686756 C59.6983022,26.4418311 63.0377689,25.1718756 64.3618578,21.7969422 C57.1303911,18.7362311 57.2666578,18.5271644 57.2666578,18.5271644 L48.95688,20.3278756 L46.0772356,24.3399644 L47.5967022,34.14992 Z'
                    id='Fill-24'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M60.746,22.7025867 C61.4416444,24.0938756 64.3623556,35.0356533 64.3623556,35.0356533 L65.6173778,33.1409867 L64.9217333,24.78952 L63.182,21.2944978 L60.746,22.7025867 Z'
                    id='Fill-26'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M63.2859111,40.5118933 L68.6631556,37.3534933 C69.8509778,36.6559822 70.2485778,35.1271822 69.5504444,33.93936 C68.8529333,32.7509156 67.3241333,32.3539378 66.1363111,33.0514489 L60.7590667,36.2098489 L63.2859111,40.5118933 Z'
                    id='Fill-28'
                    fill='#F4B6A8'
                  ></path>
                  <g id='Group-34' transform='translate(28.000000, 52.888889)'>
                    <mask id='mask-4' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-33'></g>
                    <path
                      d='M45.8023378,2.11698667 C44.3830489,1.08907556 42.6601156,0.563297778 40.9091822,0.483653333 L40.9023378,0.483653333 L15.8939822,0.499831111 C7.24944889,0.499831111 0.241982222,7.50729778 0.241982222,16.1518311 L0.241982222,37.17672 L7.16731556,37.17672 L7.16731556,55.1048089 C13.6073156,57.6876533 20.6371822,59.1112978 27.9999378,59.1112978 C34.7000267,59.1112978 41.1238489,57.9328089 47.0791378,55.7749422 L46.8906044,54.3052533 C45.7618933,45.5045422 45.5802044,36.6092533 46.3486489,27.7699644 L46.6846489,28.13832 L50.1323822,31.3564533 L55.7323822,15.9048089 L48.3690044,4.79689778 C47.67336,3.74658667 46.79976,2.83938667 45.8023378,2.11698667'
                      id='Fill-32'
                      fill='#907223'
                      mask='url(#mask-4)'
                    ></path>
                  </g>
                  <polygon
                    id='Fill-35'
                    fill='#F4B6A8'
                    points='61.5778489 55.3230844 50.44256 55.3230844 51.8350933 38.6207733 60.18656 38.6207733'
                  ></polygon>
                  <path
                    d='M66.1078133,89.69968 L56.44968,89.69968 C55.6339467,89.69968 54.9731467,89.03888 54.9731467,88.2243911 L54.9731467,69.39408 C54.9731467,68.5795911 55.6339467,67.9181689 56.44968,67.9181689 L66.1078133,67.9181689 C66.9235467,67.9181689 67.5837244,68.5795911 67.5837244,69.39408 L67.5837244,88.2243911 C67.5837244,89.03888 66.9235467,89.69968 66.1078133,89.69968'
                    id='Fill-41'
                    fill='#F6F3F5'
                  ></path>
                  <path
                    d='M57.4255111,71.4209689 C56.8449778,71.4209689 56.3745778,70.9511911 56.3745778,70.3700356 C56.3745778,69.7895022 56.8449778,69.3197244 57.4255111,69.3197244 C58.0054222,69.3197244 58.4764444,69.7895022 58.4764444,70.3700356 C58.4764444,70.9511911 58.0054222,71.4209689 57.4255111,71.4209689 Z'
                    id='Fill-43'
                    fill='#D4D0D5'
                  ></path>
                  <path
                    d='M34.82472,104.708364 C31.3116533,104.433342 28.2422311,101.528809 28.2422311,97.5957422 L28.2422311,93.58552 L28.2422311,81.1286311 L28.2422311,74.7720089 L38.7521867,74.7720089 L38.7521867,90.2497867 L52.29672,79.7236533 L60.5212533,79.4803644 L61.1248089,84.9092533 L53.5977867,86.9401867 L39.7713867,102.633876 C38.6246311,103.935564 36.9838311,104.750676 35.2484533,104.728276 C35.1096978,104.726409 34.9684533,104.720187 34.82472,104.708364'
                    id='Fill-45'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M68.9091822,53.3726044 L43.8939822,53.3887822 C43.37256,53.3887822 42.85736,53.4155378 42.3490044,53.4653156 C43.7284711,59.7006044 49.28056,64.3672711 55.9302489,64.3672711 C62.5961156,64.3672711 68.1612711,59.6794489 69.5233156,53.42176 C69.3192267,53.3999822 69.1145156,53.3831822 68.9091822,53.3726044 Z'
                    id='Fill-47'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M87.0197067,78.7366222 L86.0104622,62.9054222 L84.4175733,38.4116444 L79.1342844,37.3333333 L78.4884178,45.2679111 L75.2030844,44.5753778 L73.6742844,46.0251556 L78.2046844,48.7573333 L75.8134844,78.1392889 C75.4308178,81.3095111 76.8432622,84.1991111 79.5829067,85.2500444 C80.0993511,85.4479111 80.6518844,85.5362667 81.2044178,85.5580444 C81.9541956,85.5872889 82.71144,85.484 83.4033511,85.1940444 C85.7684178,84.2028444 87.3438844,81.7550222 87.0197067,78.7366222'
                    id='Fill-49'
                    fill='#F4B6A8'
                  ></path>
                  <mask id='mask-6' fill='white'>
                    <use></use>
                  </mask>
                  <g id='Clip-52'></g>
                  <polygon
                    id='Fill-51'
                    fill='#EF8674'
                    mask='url(#mask-6)'
                    points='78.5480889 45.6182222 83.5538667 45.6182222 83.5538667 44.8479111 78.5480889 44.8479111'
                  ></polygon>
                </g>
                <g id='EXPRESIONES' transform='translate(43.386965, 30.140479)'>
                  <path
                    d='M8.94435061,4.29417029 C8.37377283,4.37630363 7.84488394,3.98057029 7.76275061,3.40999252 C7.68061727,2.83941474 8.07697283,2.30990363 8.64817283,2.22777029 C9.21875061,2.14563696 9.74701727,2.54199252 9.82915061,3.11257029 C9.91128394,3.68314807 9.51492838,4.21265918 8.94435061,4.29417029 Z'
                    id='Fill-12'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M16.6000484,4.29417029 C16.0288484,4.37630363 15.5005817,3.98057029 15.4184484,3.40999252 C15.3363151,2.83941474 15.7326706,2.30990363 16.3032484,2.22777029 C16.8738262,2.14563696 17.4027151,2.54199252 17.4848484,3.11257029 C17.5669817,3.68314807 17.1706262,4.21265918 16.6000484,4.29417029 Z'
                    id='Fill-14'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M17.7604928,1.01033029 C16.3592484,0.534330294 15.4377373,0.960552516 15.3985373,0.977974738 C15.2255595,1.06010807 15.0177373,0.989174738 14.9343595,0.816196961 C14.8516039,0.644463627 14.9219151,0.437263627 15.0930262,0.352641405 C15.1409373,0.328374738 16.2957817,-0.222914151 17.9844928,0.351396961 C18.1668039,0.413619183 18.2638706,0.610863627 18.2016484,0.792552516 C18.1524928,0.938152516 18.0168484,1.02899696 17.8724928,1.02899696 C17.8351595,1.02899696 17.7978262,1.02277474 17.7604928,1.01033029 Z'
                    id='Fill-16'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M7.26211061,0.792552516 C7.19988838,0.610863627 7.29695505,0.413619183 7.47926616,0.351396961 C9.16673283,-0.222291928 10.3240662,0.328996961 10.3713551,0.352641405 C10.5437106,0.437885849 10.6140217,0.646330294 10.5300217,0.818685849 C10.4447773,0.990419183 10.2381995,1.06135252 10.0658439,0.977974738 C10.0272662,0.960552516 9.10637727,0.534330294 7.70326616,1.01033029 C7.66593283,1.02277474 7.62859949,1.02899696 7.59126616,1.02899696 C7.44628838,1.02899696 7.31126616,0.938152516 7.26211061,0.792552516 Z'
                    id='Fill-18'
                    fill='#D95E76'
                  ></path>
                  <path
                    d='M3.35430616,6.70229474 L0.199017273,5.21020585 C0.0254172725,5.12745029 -0.0486271719,4.92025029 0.0335061614,4.74665029 C0.115639495,4.57242807 0.324083939,4.49962807 0.497061717,4.58051696 L3.65172838,6.07322807 C3.82595061,6.1553614 3.89999505,6.3625614 3.81786172,6.53678363 C3.75812838,6.66247252 3.63306172,6.73589474 3.50301727,6.73589474 C3.45323949,6.73589474 3.40221727,6.72469474 3.35430616,6.70229474 Z'
                    id='Fill-22'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M21.2721284,6.53678363 C21.1899951,6.3625614 21.2640395,6.1553614 21.4382617,6.07322807 L24.5929284,4.58051696 C24.7652839,4.49900585 24.9737284,4.57242807 25.0564839,4.74665029 C25.1386173,4.92025029 25.0645728,5.12745029 24.8909728,5.21020585 L21.7356839,6.70229474 C21.6877728,6.72469474 21.6367506,6.73589474 21.5869728,6.73589474 C21.4569284,6.73589474 21.3318617,6.66247252 21.2721284,6.53678363 Z'
                    id='Fill-30'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M11.6248217,8.72252585 C11.5706884,8.53834807 11.6764662,8.34421474 11.8612662,8.2900814 L13.8249995,7.71514807 C13.4491773,6.88261474 12.6234884,4.80190363 12.6234884,2.56501474 C12.6234884,2.37337029 12.7796662,2.21719252 12.9719328,2.21719252 C13.1641995,2.21719252 13.3203773,2.37337029 13.3203773,2.56501474 C13.3203773,5.2268814 14.6108662,7.74812585 14.6233106,7.77301474 L14.8180662,8.14883696 L12.0566439,8.95897029 C12.0236662,8.9676814 11.9913106,8.97265918 11.9589551,8.97265918 C11.8083773,8.97265918 11.6696217,8.87434807 11.6248217,8.72252585 Z'
                    id='Fill-37'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M14.3656484,12.9512103 C12.6545373,12.521877 11.2004039,12.8628547 11.1873373,12.8659658 C10.9994262,12.9113881 10.8121373,12.7968992 10.7667151,12.6102325 C10.7212928,12.4241881 10.8351595,12.2356547 11.0218262,12.1902325 C11.0871595,12.1740547 12.6476928,11.8025881 14.5361373,12.2767214 C14.7221817,12.3240103 14.8354262,12.5125436 14.7881373,12.6992103 C14.7489373,12.8572547 14.6070706,12.9624103 14.4508928,12.9624103 C14.4228928,12.9624103 14.3942706,12.958677 14.3656484,12.9512103 Z'
                    id='Fill-39'
                    fill='#D85468'
                  ></path>
                </g>
              </g>
              <g id='ALERTA' transform='translate(84.000000, 6.000000)'>
                <path
                  d='M10.8516583,4.07144476 L18.0620939,15.7884026 C18.3515449,16.2587605 18.2048909,16.8747073 17.734533,17.1641583 C17.5769253,17.2611477 17.3954954,17.3125 17.2104356,17.3125 L2.78956442,17.3125 C2.23727967,17.3125 1.78956442,16.8647847 1.78956442,16.3125 C1.78956442,16.1274402 1.84091676,15.9460103 1.93790611,15.7884026 L9.14834168,4.07144476 C9.4377927,3.60108686 10.0537395,3.45443286 10.5240974,3.74388387 C10.6574179,3.82592722 10.769615,3.93812432 10.8516583,4.07144476 Z'
                  id='Triangle'
                  fill='#FFFFFF'
                ></path>
                <g id='00.Token/Icons/Solid/16x16/alert' fill='#8C6700'>
                  <path
                    d='M2.10461987,17.5020833 L17.8953801,17.5020833 C18.5515531,17.5020833 18.960596,16.8104167 18.6367704,16.2520833 L10.7371294,2.91875 C10.404782,2.36041667 9.58669624,2.36041667 9.26287061,2.91875 L1.36322962,16.2520833 C1.03940399,16.8104167 1.44844689,17.5020833 2.10461987,17.5020833 Z M10.0231002,15.0020833 L9.98542149,15.0020833 C9.5251842,15.0020833 9.15208816,14.6289873 9.15208816,14.16875 C9.15208816,13.7085127 9.5251842,13.3354167 9.98542149,13.3354167 L10.0231002,13.3354167 C10.4833375,13.3354167 10.8564336,13.7085127 10.8564336,14.16875 C10.8564336,14.6289873 10.4833375,15.0020833 10.0231002,15.0020833 Z M10.0042609,11.66875 C9.53556587,11.66875 9.15208816,11.29375 9.15208816,10.8354167 L9.15208816,9.16875 C9.15208816,8.71041667 9.53556587,8.33541667 10.0042609,8.33541667 C10.4729559,8.33541667 10.8564336,8.71041667 10.8564336,9.16875 L10.8564336,10.8354167 C10.8564336,11.29375 10.4729559,11.66875 10.0042609,11.66875 Z'
                    id='color-icono'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default ALERTAMOBILE
