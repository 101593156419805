import React, { useState, useEffect } from 'react'

import IconDoneStep from './assets/IconDoneStep'
import IconProgressStep from './assets/IconProgressStep'
import IconTodoStep from './assets/IconTodoStep'

import styles from './_step.scss'

const detailsStep = {
  done: { icon: <IconDoneStep />, progress: 100 },
  progress: { icon: <IconProgressStep />, progress: 100 },
  todo: { icon: <IconTodoStep />, progress: 0 }
}

const Step = (props) => {
  const { iteratedStep, currentStep, isLast } = props

  const [statusStep, setStatusStep] = useState('todo')

  useEffect(() => {
    if (parseInt(currentStep, 10) > iteratedStep.step) setStatusStep('done')
    else if (parseInt(currentStep, 10) < iteratedStep.step)
      setStatusStep('todo')
    else if (isLast) setStatusStep('done')
    else setStatusStep('progress')
  }, [currentStep, iteratedStep])

  return (
    <div className={`${styles.step} d-flex flex-column flex-grow-1`}>
      <div
        className={`${styles.stepIndicator} w-100 d-flex justify-content-lg-between align-items-center`}
      >
        <div className={styles.progressBar}>
          <div className={styles.barContainer}>
            <div
              className={styles.bar}
              data-progress={detailsStep[statusStep].progress}
              style={{ width: `${detailsStep[statusStep].progress}%` }}
            />
          </div>
        </div>
      </div>
      <div
        className={`${styles.stepNameContainer} d-flex justify-content-between mt-1`}
      >
        <span
          className={`${styles.stepName}  ${
            statusStep === 'todo' ? styles.stepTodo : ''
          }`}
        >
          {`${iteratedStep.step}. ${iteratedStep.name}`}
        </span>
      </div>
    </div>
  )
}

export default Step
