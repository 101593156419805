import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DBlackCard from './svgs/desktop/black'
import MBlackCard from './svgs/mobile/black'
import MRedCar from './svgs/mobile/red'
import DRedCar from './svgs/desktop/red'

const PlasticCard = ({
  type,
  width,
  cardTitle,
  cardType,
  owner,
  state,
  cardNumber,
  forceDesktop
}) => {
  let bankCard

  const [component, setComponent] = useState(null)

  const getCard = () => {
    const myWidth = window.screen.width
    if (myWidth < 599 && !forceDesktop) {
      switch (type) {
        case 'debit':
          bankCard = (
            <MBlackCard
              width={width}
              cardType={cardType}
              cardTitle={cardTitle}
              owner={owner}
              state={state}
              cardNumber={cardNumber}
            />
          )
          break
        case 'credit':
          bankCard = (
            <MRedCar
              width={width}
              cardType={cardType}
              cardTitle={cardTitle}
              owner={owner}
              state={state}
              cardNumber={cardNumber}
            />
          )
          break
        default:
          bankCard = <React.Fragment></React.Fragment>
      }
    } else {
      switch (type) {
        case 'debit':
          bankCard = (
            <DBlackCard
              width={width}
              cardType={cardType}
              cardTitle={cardTitle}
              owner={owner}
              state={state}
              cardNumber={cardNumber}
            />
          )
          break
        case 'credit':
          bankCard = (
            <DRedCar
              width={width}
              cardType={cardType}
              cardTitle={cardTitle}
              owner={owner}
              state={state}
              cardNumber={cardNumber}
            />
          )
          break
        default:
          bankCard = <React.Fragment></React.Fragment>
      }
    }
    setComponent(bankCard)
  }

  useEffect(() => {
    window.addEventListener('resize', getCard)
    return () => {
      window.removeEventListener('resize', getCard)
    }
  }, [window])

  useEffect(() => {
    getCard()
    return () => {}
  }, [])

  return <React.Fragment>{component}</React.Fragment>
}

PlasticCard.defaultProps = {
  width: '275',
  type: 'debit',
  cardTitle: '',
  cardType: 'debit',
  owner: '',
  state: 'Active',
  cardNumber: '',
  forceDesktop: false
}

PlasticCard.propTypes = {
  width: PropTypes.string,
  cardTitle: PropTypes.string,
  type: PropTypes.string,
  cardType: PropTypes.string,
  owner: PropTypes.string,
  state: PropTypes.string,
  cardNumber: PropTypes.string,
  forceDesktop: PropTypes.bool
}

export default PlasticCard
