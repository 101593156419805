import React, { Fragment, useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import TabButton from './tabButton'
import Icon from '../icons'

import { sumFromArray } from '../common/common-functions'
import {
  getActiveButton,
  makeActiveButton,
  slideButtons,
  slideNav
} from '../common/tabs-functions'

import styles from './_tab.scss'
import TabWrapper from './tabWrapper'


function Tab({ tabs }) {

  // UseState -------------------------------------------
  const [activeButtons, setActiveButtons] = useState(() => getActiveButton(tabs));
  const [activeIndex, setActiveIndex] = useState(0);
  const [moveButtons, setMoveButtons] = useState({ left: '0' });

  const [navOn, setNavOn] = useState(false);
  const [navLeftOn, setNavLeftOn] = useState(false);
  const [navRightOn, setNavRightOn] = useState(false);

  // UseRef -------------------------------------------
  const filterContainer = useRef()
  const filterContainerWrapper = useRef()


  // Events -------------------------------------------
  const handleButtonClick = (i) => {
    setActiveButtons(makeActiveButton(activeButtons, i));

    // Get SlideData
    const slideData = slideButtons(i, filterContainerWrapper);

    setMoveButtons({ left: slideData.moveButtons })
    setNavLeftOn(slideData.navLeftOn)
    setNavRightOn(slideData.navRightOn)

    setActiveIndex(slideData.activeIndex)
  }

  const handleNavClick = (backwards) => {
    // Get SlideData
    const slideData = slideNav(filterContainerWrapper, activeIndex, backwards)

    setMoveButtons({ left: slideData.moveButtons })
    setNavLeftOn(slideData.navLeftOn)
    setNavRightOn(slideData.navRightOn)

    setActiveIndex(slideData.activeIndex);
  }

  const handleResize = (first = false) => {
    const filterContainerWrapperW = navOn ? filterContainerWrapper.current.offsetWidth - 40 : filterContainerWrapper.current.offsetWidth;
    const filterButtons = filterContainerWrapper.current.children;
    const filterButtonsTotalW = sumFromArray(filterButtons);

    if(filterContainerWrapperW < filterButtonsTotalW){
      setNavOn(true)
    } else {
      setNavOn(false)
    }

    //
    if(window.innerWidth <= 1280) {
      const activeIndex = activeButtons.findIndex(el => el.active);

      // Get SlideData
      const slideData = slideButtons(activeIndex, filterContainerWrapper, first);

      setMoveButtons({ left: slideData.moveButtons })
      setNavLeftOn(slideData.navLeftOn)
      setNavRightOn(slideData.navRightOn)

      setActiveIndex(activeIndex);
    } else {
      setNavOn(false)
    }

  }

  // UseEffect -------------------------------------------
  useEffect(() => {
    window.removeEventListener('resize', () => handleResize())
    window.addEventListener('resize', () => handleResize())
  }, [activeButtons])

  useEffect(() => {
    handleResize(true)
  }, [])

  // Render -------------------------------------------
  return (
    <>
      <div
        className={classNames(
            styles.filterContainer,
            navOn ? styles.navOn : ''
          )
        }
        ref={filterContainer}
      >
        {
          navOn &&
          <div
            className={classNames(
              styles.filterNav,
              styles.filterNavLeft,
              (navLeftOn ? styles.disabled : '')
              )
            }
            onClick={() => {
              if(!navLeftOn) {
                handleNavClick(true)
              }
            }}
          >
            <Icon name="old-arrow-left" />
          </div>
        }
        <div
          className={
            classNames(
              styles.filterButtonsWrapper,
              navOn ? '' : styles.wrapperLeftZero
              )
            }
          ref={filterContainerWrapper}
          style={moveButtons}
        >
          {
            activeButtons.map( (button, index) => (
              <TabButton
                key={`filter_button-${index + 1}`}
                onClick={ () => handleButtonClick(index) }
                active={button.active}
                label={button.label}
                name={button.icon}
                className={navOn ? '' : 'w-100'}
              />
            ))
          }
        </div>
        {
          navOn &&
          <div
            className={classNames(
              styles.filterNav,
              styles.filterNavRight,
              (navRightOn ? styles.disabled : '')
              )
            }
            onClick={() => {
              if(!navRightOn) {
                handleNavClick(false)
              }
            }}
          >
            <Icon name="old-arrow-right" />
          </div>
        }
      </div>
      <div className='tabsContainer'>
        {
          activeButtons.map( (button, index) => (
            <TabWrapper
              key={`tab_wrapper-${index + 1}`}
              active={activeButtons[index].active}
            >
              {button.body}
            </TabWrapper>
          ))
        }
      </div>
    </>
  )
}

export default Tab