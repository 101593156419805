import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '../icons'
import styles from './_buttons.scss'

const Button = ({
  prefix,
  suffix,
  color,
  variant,
  disabled,
  loading,
  children,
  className,
  onClick
}) => {
  const btnStyles = classNames(
    styles.btn,
    styles[`btn-${color}`],
    {
      [styles[`btn-${color}-${variant}`]]: variant !== '',
      [styles.loading]: loading
    },
    className
  )

  const getAppliedChildren = () => {
    let appliedChildren = children

    if (!prefix && !suffix) appliedChildren = <span>{children}</span>

    return appliedChildren
  }

  return (
    <button
      type='button'
      className={btnStyles}
      disabled={disabled}
      onClick={onClick}
    >
      {prefix && <Icon className={styles.prefix} name={prefix} />}
      {getAppliedChildren()}
      {suffix && <Icon className={styles.suffix} name={suffix} />}
    </button>
  )
}

Button.defaultProps = {
  prefix: '',
  suffix: '',
  color: 'primary',
  variant: 'text',
  disabled: false,
  loading: false,
  className: '',
  onClick: () => undefined
}

Button.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', '']),
  variant: PropTypes.oneOf(['outline', 'text', 'link']),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node
}

export default Button
