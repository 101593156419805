import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_voucherfooter.scss'
import { ArrayUtils } from '../../utils'
import Bullets from '../../bullets'

const VoucherFooter = ({ items, breakColumn }) => {
  const containerClass = [styles.voucherFooterContainer]
  let newItems
  if (breakColumn) {
    newItems = ArrayUtils.listToMatrix(items.bullets, breakColumn)
  }

  return (
    <React.Fragment>
      <div className={containerClass.join(' ')}>
        <div>
        <h5 className={styles.titleItem}>{items.title}</h5>
        </div> 

        {/* <div className={styles.borderSectionBottom} /> */}
        <div className={styles.borderSectionTop} />


        <div className={styles.voucherFooterContentContainer}>
          {breakColumn && (
            <React.Fragment>
              {newItems.map((newItem) => (
                <div
                  className={styles.voucherFooterSection}
                  key={`newitem-${nanoid()}`}
                >
                  <Bullets typeList={items.typeList} items={newItem} />
                </div>
              ))}
            </React.Fragment>
          )}
          {breakColumn == null && (
            <div className={styles.voucherFooterSection}>
              <Bullets typeList={items.typeList} items={items.bullets} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default VoucherFooter

VoucherFooter.defaultProps = {
  items: [],
  title: 'Default text',
  typeList: 'unorder'
}

VoucherFooter.prototype = {
  breakColumn: PropTypes.number,
  title: PropTypes.number,
  typeList: PropTypes.string,
  bullets: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      prefixType: PropTypes.string,
      contentType: PropTypes.string
    })
  )
}
