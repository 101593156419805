import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './_cardFooter.scss'

const CardFooter = ({ children, level, className }) => (
  <div
   className={classNames(
    styles.cardFooter,
    styles[`cardFooterLevel${level}`],
    className
  )}
  >
    {children}
  </div>
)

CardFooter.defaultProps = {
  level: 1,
  className: ''
}
CardFooter.propTypes = {
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}

export default CardFooter
