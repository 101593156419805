import React from 'react'
import PropTypes from 'prop-types'
import styles from './_bullet.scss'
import Icon from '../../icons'

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return 'old-check'
    case 'info':
      return 'old-information'
    case 'error':
      return 'old-cross'
    case 'warning':
      return 'old-alert'
    default:
      return ''
  }
}

const BulletElement = ({
  count,
  disabled,
  text,
  typeList,
  prefixType,
  contentType,
  type,
  icon
}) => {
  const numberList = count + 1

  let styleContainer = [styles.bulletElement]
  let stylePrefixContainer = [styles.prefixContainer]
  let styleBoxCircle = [styles.boxCircle]
  let styleTextContainer = [styles.textContainer]

  const setStyles = (typeParam, typeListParam, prefixParam, contentParam) => {
    if (disabled !== undefined && disabled === true) {
      styleContainer = styleContainer.concat(styles.disabled)
      return
    }
    const styleTypeContainer = [styles[`${typeParam}`]]
    if (type !== undefined && type !== '') {
      styleContainer = styleContainer.concat(styleTypeContainer)
      if (typeList !== undefined) {
        styleBoxCircle = styleBoxCircle.concat(styleTypeContainer)
      }
    } else if (prefixType !== undefined || contentType !== undefined) {
      const stylePrefix = [styles[`${prefixParam}`]]
      const styleContent = [styles[`${contentParam}`]]
      styleBoxCircle = [styles.boxCircle, styles[`${prefixParam}`]]
      if (prefixType !== undefined) {
        if (typeListParam !== 'unorder') {
          stylePrefixContainer = stylePrefixContainer.concat(stylePrefix)
        }
      }
      if (contentType !== undefined) {
        styleTextContainer = styleTextContainer.concat(styleContent)
      }
    }
  }

  setStyles(type, typeList, prefixType, contentType)

  const OrderList = () => (
    <div
      data-testid='test-prefix-bullet-order'
      className={stylePrefixContainer.join(' ')}
    >
      {numberList}.
    </div>
  )

  const UnorderList = () => (
    <div
      data-testid='test-prefix-bullet-unorder'
      className={stylePrefixContainer.join(' ')}
    >
      <div
        data-testid='test-prefix-bullet'
        className={styleBoxCircle.join(' ')}
      />
    </div>
  )

  const IconsList = () => (
    <div
      data-testid='test-prefix-bullet-icons'
      className={stylePrefixContainer.join(' ')}
    >
      <Icon size='1' name={getIcon(icon)} />
    </div>
  )

  const prefixByTypeList = {
    order: <OrderList />,
    unorder: <UnorderList />,
    icons: <IconsList />
  }

  const defaultPrefix = prefixByTypeList[typeList]

  return (
    <div
      data-testid={`test-bullet-${typeList}`}
      className={styleContainer.join(' ')}
    >
      {defaultPrefix}
      <p
        data-testid='test-bullet-content'
        className={styleTextContainer.join(' ')}
      >
        {text}
      </p>
    </div>
  )
}

export default BulletElement

BulletElement.propTypes = {
  text: PropTypes.string.isRequired,
  count: PropTypes.number,
  typeList: PropTypes.oneOf(['order', 'unorder', 'icons']),
  icon: PropTypes.string,
  contentType: PropTypes.string,
  disabled: PropTypes.bool,
  prefixType: (props) => {
    let error
    if (props.type !== undefined && props.prefixType !== undefined) {
      error = new Error('If prefixType prop it is defined not define type')
    }
    return error
  },
  type: (props) => {
    let error
    if (props.prefixType !== undefined && props.type !== undefined) {
      error = new Error('If prefixType prop it is defined not define type')
    }
    if (props.contentType !== undefined && props.type !== undefined) {
      error = new Error('If contentType prop it is defined not define type')
    }
    return error
  }
}
