import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Icon from '../../icons'
import InputCore from '../core'
import Label from '../_commons/label'
import styles from './_counter.scss'

const InputCounter = ({ onChange, ...props }) => {

  const isValidRange = (val) => {
    const tempValue = parseInt(val)
    return tempValue >= props.minValue && tempValue <= props.maxValue
  }

  const handleChangeValue = (val) => {
    onChange(val.trim());
  }

  const handleIncreaseValue = (val) => {
    const valueToChange = isValidRange(parseInt(val) + 1) ? parseInt(val) + 1 : parseInt(val);
    onChange(valueToChange);
  }

  const handleDecreaseValue = (val) => {
    const valueToChange = isValidRange(parseInt(val) - 1) ? parseInt(val) - 1 : parseInt(val);
    onChange(valueToChange);
  }

  const handleOnBlur = (val) => {
    if (val === '')
      onChange(props.minValue);
    else if (parseInt(val) < props.minValue)
      onChange(props.minValue);
    else if (parseInt(val) > props.maxValue)
      onChange(props.maxValue);
  }

  useEffect(() => {
    handleOnBlur(props.value);
  }, [])

  return (
    <div>
      <Label label={props.label} disabled={props.disabled} />
      <div
        className={[
          styles.container,
          props.disabled === true ? styles.disabled : ''
        ]
          .join(' ')
          .trim()}
      >
        <Icon
          name='old-minus'
          size={4}
          className={`${
            parseInt(props.value) === props.minValue ? styles.iconDisabled : ''
          } ${styles.minusIcon}`}
          onClick={() => handleDecreaseValue(props.value)}
        />
        <InputCore
          value={props.value}
          onChange={(val) => handleChangeValue(val)}
          onBlur={(val) => handleOnBlur(val.currentTarget.value)}
          onlyNumber
          prefix=''
          suffix=''
          readOnly={props.readOnly}
        />
        <Icon
          name='old-plus'
          size={4}
          className={`${
            parseInt(props.value) === props.maxValue ? styles.iconDisabled : ''
          } ${styles.plusIcon}`}
          onClick={() => handleIncreaseValue(props.value)}
        />
      </div>
    </div>
  )
}

InputCounter.defaultProps = {
  label: ''
}

InputCounter.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired
}

export default InputCounter
