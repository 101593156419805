import React from 'react'
import PropTypes from 'prop-types'
import styles from './_cardheader.scss'

const CardHeader = ({ className, children }) => {
  const cardHeaderStyle = [styles.cardHeaderContainer]

  return (
    <div className={cardHeaderStyle.concat(className).join(' ')}>
      {children}
    </div>
  )
}

CardHeader.defaultProps = {
  className: ''
}

CardHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default CardHeader
