import React from 'react'
import PropTypes from 'prop-types'
import Styles from './_header.scss'
import Icon from '../icons'

import Grid from '../grid'
import HeaderProfileItem from './headerProfileItem'

const Header = ({ children, logo, logOut, userData, mobile, className }) => {
  const headerStyleContainer = [Styles.header].concat(className).join(' ')

  return (
    <div>
      <Grid
        row
        className={[headerStyleContainer, Styles.headerDesktop]
          .join(' ')
          .trim()}
      >
        <Grid col xl={8} className={Styles.headerOptionsContainer}>
          <Grid row className={Styles.headerLeftArea}>
            {logo && (
              <Grid col={3} xl={4} className={Styles.headerLogoContainer}>
                {logo}
              </Grid>
            )}
            {children}
          </Grid>
        </Grid>
        {userData && (
          <>
            <Grid col={3} lg={4} xl={3} className={Styles.profileItemContainer}>
              <HeaderProfileItem {...userData} />
            </Grid>
            {logOut && (
              <Grid col={1} className={Styles.flex}>
                {logOut}
              </Grid>
            )}
          </>
        )}
      </Grid>

      <Grid row className={Styles.headerMobile}>
        {mobile.onBurgerClick && (
          <Icon
            name='old-menu-burger'
            onClick={mobile.onBurgerClick}
            size={3}
            className={[Styles.burgerIcon, Styles.option].join(' ').trim()}
          />
        )}

        {mobile.section ? (
          <Grid col className={Styles.sectionContent}>
            {mobile.onClickBack && (
              <Icon
                name='old-arrow-left'
                onClick={mobile.onClickBack}
                className={Styles.backButton}
                size={3}
              />
            )}
            {mobile.section}
          </Grid>
        ) : (
          <Grid col>{logo}</Grid>
        )}

        <div className={[Styles.option].join(' ').trim()}>
          {mobile.leftElement}
        </div>
      </Grid>
    </div>
  )
}

Header.defaultProps = {
  mobile: {},
  userData: undefined
}

Header.propTypes = {
  mobile: PropTypes.shape({
    section: PropTypes.element,
    leftElement: PropTypes.element,
    onBurgerClick: PropTypes.func,
    onClickBack: PropTypes.func
  }),
  logo: PropTypes.element,
  logOut: PropTypes.element,
  userData: PropTypes.shape({
    name: PropTypes.string,
    date: PropTypes.instanceOf(Date)
  }),
  className: PropTypes.string
}

export default Header
