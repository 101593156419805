import React from 'react'
import { nanoid } from 'nanoid'
// import ReactHtmlParser from 'react-html-parser'
import PropTypes from 'prop-types'
import styles from '../_publicFooterBottomBar.scss'

const PublicFooterBottomBarItem = ({ children, items, className }) => {
  const splitClasses = (txtToSplit) => {
    const classes = txtToSplit.split(' ')

    const newStyles = []
    classes.forEach((thisClass) => {
      newStyles.push(styles[thisClass])
    })

    return newStyles
  }

  return (
    <div className={styles.columnWrapper}>
      {children}
      {items ? (
        items.map((el) => {
          let text = `${el.text}`

          if (el.strong) {
            if (el.strong.length >= 3) {
              if (Array.isArray(el.strong)) {
                for (let i = 0; i < el.strong.length; i += 1) {
                  const strong = el.strong[i]

                  text = text.replace(strong, `<strong>${strong}</strong>`)
                }
              } else {
                text = text.replace(el.strong, `<strong>${el.strong}</strong>`)
              }
            }
          } else if (el.link) {
            if (el.link.length >= 3) {
              if (Array.isArray(el.link)) {
                for (let i = 0; i < el.link.length; i += 1) {
                  const link = el.link[i]

                  text = text.replace(link, `<a href="${link}">${link}</a>`)
                }
              } else {
                text = text.replace(
                  el.link,
                  `<a href="${el.link}">${el.link}</a>`
                )
              }
            }
          }

          let newStyles = []
          if (className) {
            newStyles = splitClasses(className)
          }

          return (
            <p className={newStyles.join(' ')} key={nanoid()}>
              {/* {ReactHtmlParser(text)} */}
            </p>
          )
        })
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </div>
  )
}

PublicFooterBottomBarItem.defaultProps = {}

PublicFooterBottomBarItem.propTypes = {
  className: PropTypes.string
}

export default PublicFooterBottomBarItem
