import React from 'react'
import PropTypes from 'prop-types'
import styles from './_contentAction.scss'
import Icon from '../icons'
import IconListModel from '../icons/models/icon-list.model'

const ContentAction = ({ label, icon, disabled, onClick }) => {
  let containerClass = [styles.contentAction]

  if (disabled) {
    containerClass = [containerClass, styles.disabled]
  }

  return (
    <div
      data-testid='test-container-content-action'
      onKeyDown={disabled ? null : onClick}
      onClick={disabled ? null : onClick}
      className={containerClass.join(' ')}
    >
      <div>
        <Icon size='4' name={icon} />
      </div>
      <div className={styles.label}>
        <button type='button'>{label}</button>
      </div>
    </div>
  )
}

export default ContentAction

ContentAction.defaultProps = {}

ContentAction.propTypes = {
  icon: PropTypes.oneOf(IconListModel.iconList).isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool
}
