import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'
import Icon from '../../icons'
import AssistText from '../_commons/assistText'
import Label from '../_commons/label'

import inputStyles from './_select.scss'

const InputSelect = ({
  label,
  assistText,
  options,
  value,
  placeholder,
  onSelect,
  ...props
}) => {
  const [optionOpen, setOptionOpen] = useState(false)
  const [optionOpenStyle, setOptionOpenStyle] = useState('')

  const inputStyle = classnames(inputStyles.input, inputStyles.hasSuffix)
  let validateStyle = []

  if (props.disabled) validateStyle = validateStyle.concat(inputStyles.disabled)

  const handlerOpen = () => {
    if (optionOpen || props.disabled) return
    setOptionOpen(true)
  }
  
  const optionClickHandler = (optionValue) => onSelect(optionValue);

  

  useEffect(() => {
    const clickHandler = () => {
      setOptionOpen(false)
      setTimeout(() => document.removeEventListener('click', clickHandler), 10)
    }

    if (optionOpen) {
      // For all document adds click event
      document.addEventListener('click', clickHandler)

      // Stops the event propagation on all option list
      const parent = document.getElementById('scrollBar')
      // parent.addEventListener('click', (e) => e.stopPropagation())

      // For each option adds click event
      parent.childNodes[0].childNodes.forEach((item) => {
        item.addEventListener('click', clickHandler)
      })
    }

    setOptionOpenStyle(!optionOpen ? inputStyles.optionContainerClosed : '');
  }, [optionOpen])


  const SelectOption = ({el, onClick}) => {
    const isDisabled = el.disabled && el.disabled ? inputStyles.disabled : ''

    return (
      <div
        role='button'
        className={classnames(inputStyles.optionRow, isDisabled)}
        tabIndex='0'
        onClick={onClick}
        onKeyDown={onClick}
      >
        {el.prefix && (
          <div className={inputStyles.prefixContainer}>
            <Icon className={inputStyles.icon} size={2} name={el.prefix} />
          </div>
        )}
        {el.text}
      </div>
    )
  }



  const getSelectedOption = (el) => {
    try {
      const isDisabled = el.disabled && el.disabled ? inputStyles.disabled : ''

      return (
        <div
          role='button'
          className={classnames(inputStyles.optionRow, isDisabled)}
          tabIndex='0'
        >
          {el.prefix && (
            <div className={inputStyles.prefixContainer}>
              <Icon className={inputStyles.icon} size={2} name={el.prefix} />
            </div>
          )}
          {el.text}
        </div>
      )
    } catch (e) {
      return <div className={inputStyles.optionRow} />
    }
  }

  return (
    <div className={classnames(inputStyles.inputContainer, validateStyle)}>
      {optionOpen}

      {label && <Label label={label} disabled={props.disabled} />}

      <div className={inputStyle}>
        <div
          role='button'
          className={inputStyles.currentElement}
          tabIndex='0'
          onClick={handlerOpen}
          onKeyDown={handlerOpen}
        >
          {value ? (
            getSelectedOption(options.find((ob) => ob.value === value))
          ) : (
            <div
              className={classnames(
                inputStyles.optionRow,
                inputStyles.placeholder
              )}
            >
              {placeholder}
            </div>
          )}
        </div>

        <div className={inputStyles.suffixContainer}>
          <Icon
            size={3}
            name={optionOpen ? 'old-arrow-up' : 'old-arrow-down'}
          />
        </div>
      </div>

      {/* OPCIONES */}
      <div className={inputStyles.bellowContainer}>
        <div className={classnames(inputStyles.optionContainer, optionOpenStyle)}>
          <Scrollbars
          autoHeight
            id='scrollBar'
            className={inputStyles.scrollBar}
          >
            {options.filter((el) => el.disabled !== true).map((el, index) => <SelectOption key={`select-option_${index}`} el={el} index={index} onClick={() => optionClickHandler(el.value)} />)}
          </Scrollbars>
        </div>
        
      </div>

      {assistText && (
        <AssistText assistText={assistText} disabled={props.disabled} />
      )}
    </div>
  )
}

InputSelect.defaultProps = {
  value: '',
  disabled: false,
  options: [],
  placeholder: '',
  onSelect: () => null
}

InputSelect.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  assistText: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      prefix: PropTypes.string,
      text: PropTypes.string,
      value: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })
  ),
  placeholder: PropTypes.string,
  onSelect: PropTypes.func
}

export default InputSelect
