import React from 'react'
import classNames from 'classnames'
import styles from './_mobileStepperOld.scss'

const MobileStepperOld = (props) => {
  const { steps, currentStep, color } = props

  const { name } = steps.filter((s) => s.step === parseInt(currentStep, 10))[0]
  const qtySteps = steps.length > 6 ? 6 : steps.length
  const percentage = (100 / (qtySteps - 1)) * (parseInt(currentStep, 10) - 1)

  return (
    <div
      className={classNames(styles.mobileStepperContainer)}
      style={{
        '--colorStepper': color,
      }} 
    >
      <div className={styles.barContainer}>
        <div
          className={styles.bar}
          data-progress={percentage}
          style={{ width: `${percentage}%` }}
        />
      </div>
      <div
        className={`${styles.stepNameContainer} d-flex justify-content-between mt-1`}
      >
        <span
          className={classNames(
            styles.stepName,
            'text-primary font-weight-medium'
          )}
          style={{
        '--colorStepper': color,
      }} 
        >{`${currentStep}. ${name}`}</span>
        <span
          className={classNames(
            styles.stepIndicator,
          )}
        >{`${currentStep}/${qtySteps}`}</span>
      </div>
    </div>
  )
}

export default MobileStepperOld
