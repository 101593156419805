import React from 'react'
import classnames from 'classnames'
import styles from '../_tab.scss'

const TabBody = ({ children, active }) => (
  <div className={classnames(styles.tabContainer, { [styles.active]: active })}>
    {children}
  </div>
)

export default TabBody
