import React from 'react'

const ERRORMOBILE = () => {
  const elm = (
    <svg
      width='160px'
      height='147px'
      viewBox='0 0 160 147'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <path
          d='M70.6838756,102.464782 C66.9185422,102.601316 63.8813867,105.63776 63.7455644,109.403093 L63.7455644,109.403093 C63.7391644,109.546738 63.8557867,109.668338 64.0008533,109.668338 L64.0008533,109.668338 L69.4209422,109.668338 C70.2650311,109.668338 70.9484089,108.98496 70.9484089,108.140871 L70.9484089,108.140871 L70.9484089,102.720071 C70.9484089,102.57856 70.8332089,102.464782 70.6938311,102.464782 L70.6938311,102.464782 C70.6902756,102.464782 70.6874311,102.464782 70.6838756,102.464782 L70.6838756,102.464782 Z'
          id='path-1'
        ></path>
        <path
          d='M70.6838756,102.464782 C66.9185422,102.601316 63.8813867,105.63776 63.7455644,109.403093 L63.7455644,109.403093 C63.7391644,109.546738 63.8557867,109.668338 64.0008533,109.668338 L64.0008533,109.668338 L69.4209422,109.668338 C70.2650311,109.668338 70.9484089,108.98496 70.9484089,108.140871 L70.9484089,108.140871 L70.9484089,102.720071 C70.9484089,102.57856 70.8332089,102.464782 70.69312,102.464782 L70.69312,102.464782 C70.6902756,102.464782 70.6874311,102.464782 70.6838756,102.464782 L70.6838756,102.464782 Z'
          id='path-3'
        ></path>
        <path
          d='M62.7876978,96.7019378 C60.13312,96.7019378 57.9820089,98.8530489 57.9820089,101.506916 L57.9820089,101.506916 L57.9820089,108.160071 C57.9820089,108.992782 58.6568533,109.668338 59.4895644,109.668338 L59.4895644,109.668338 L60.3876978,109.668338 C60.5249422,109.668338 60.6358756,109.558116 60.6365867,109.421582 L60.6365867,109.421582 C60.7652978,103.928249 65.2111644,99.4830933 70.7044978,99.3558044 L70.7044978,99.3558044 C70.8396089,99.3536711 70.9484089,99.2427378 70.9484089,99.1083378 L70.9484089,99.1083378 L70.9484089,98.2087822 C70.9484089,97.3760711 70.2735644,96.7019378 69.4408533,96.7019378 L69.4408533,96.7019378 L62.7876978,96.7019378 Z'
          id='path-5'
        ></path>
      </defs>
      <g
        id='PROPUESTA-1'
        stroke='none'
        stroke-width='1'
        fill='none'
        fill-rule='evenodd'
      >
        <g id='EXITO-MOBILE' transform='translate(16.000000, 9.142857)'>
          <g id='EXITO-RESPONSIVE'>
            <g id='ROSTRO-Y-CUERPO'>
              <path
                d='M128,64 C128,99.3464889 99.3464889,128 64,128 C28.6535111,128 0,99.3464889 0,64 C0,28.6535111 28.6535111,0 64,0 C99.3464889,0 128,28.6535111 128,64'
                id='Fill-1'
                fill='#D3F0C5'
              ></path>
              <path
                d='M81.1631644,51.7215289 C80.3460978,51.4413511 79.9592533,50.5481956 80.2892089,49.7503289 C80.2920533,49.74464 80.2941867,49.7389511 80.29632,49.7332622 C81.8458311,45.9714844 80.7734756,41.6386844 77.6481422,39.0338844 L74.3663644,36.2996622 L64.4108089,37.0107733 L64.4108089,55.4996622 L67.2552533,55.4996622 L67.2552533,56.9986844 C67.2552533,61.79584 69.9802311,66.2857956 74.3869867,68.1816178 C80.8950756,70.9826844 87.1663644,66.2843733 87.1663644,60.1218844 C87.1663644,56.2235733 84.65472,52.9183289 81.1631644,51.7215289'
                id='Fill-3'
                fill='#702C7E'
              ></path>
              <path
                d='M60.9770667,37.0111289 L51.0215111,36.3000178 L47.7404444,39.03424 C44.6144,41.6383289 43.5413333,45.9711289 45.0908444,49.7336178 L45.1057778,49.7677511 C45.4364444,50.5627733 45.0389333,51.4424178 44.224,51.7218844 C39.6586667,53.28704 36.7694222,58.4553956 38.9774222,63.8413511 C40.2801778,67.0178844 43.4688,69.0111289 46.9013333,69.0111289 L47.0897778,69.0111289 C53.1960889,69.0111289 58.1326222,64.0760178 58.1326222,57.9889067 L58.1326222,55.5000178 L60.9770667,55.5000178 L60.9770667,37.0111289 Z'
                id='Fill-5'
                fill='#702C7E'
              ></path>
              <path
                d='M75.7888711,34.8444444 L50.5444267,34.8444444 L50.5444267,26.5265778 C50.5444267,21.3020444 54.7798044,17.0666667 60.0050489,17.0666667 L66.32896,17.0666667 C71.5534933,17.0666667 75.7888711,21.3020444 75.7888711,26.5265778 L75.7888711,34.8444444 Z'
                id='Fill-7'
                fill='#702C7E'
              ></path>
              <path
                d='M63.6770844,48.6427022 L62.9538844,48.6427022 C58.4411733,48.6427022 54.7825067,44.9840356 54.7825067,40.4706133 L54.7825067,23.6485689 C54.7825067,21.3502578 56.6456178,19.4871467 58.9439289,19.4871467 L66.5080178,19.4871467 C69.4577067,19.4871467 71.8491733,21.8779022 71.8491733,24.8275911 L71.8491733,40.4706133 C71.8491733,44.9840356 68.1905067,48.6427022 63.6770844,48.6427022'
                id='Fill-9'
                fill='#F4B6A8'
              ></path>
              <path
                d='M74.1632,24.6423467 L72.1336889,25.0739911 L70.9944889,18.5225244 L61.3333333,17.8114133 C55.2853333,17.8114133 50.4206222,22.0745244 50.5550222,28.1211022 L50.6659556,33.1003022 L54.5770667,34.87808 L56.7104,24.9225244 C56.7104,24.9225244 62.2776889,29.1557689 70.0558222,27.8160356 L71.6437333,34.1669689 L74.2072889,33.4558578 C74.5472,29.8555022 74.4576,26.9491911 74.1632,24.6423467'
                id='Fill-11'
                fill='#702C7E'
              ></path>
              <path
                d='M70.8298667,40.3652978 L76.3253333,37.1382756 C77.5392,36.4250311 77.9445333,34.8634311 77.232,33.6495644 C76.5187556,32.4356978 74.9571556,32.0296533 73.7432889,32.7421867 L68.2478222,35.9692089 L70.8298667,40.3652978 Z'
                id='Fill-13'
                fill='#F4B6A8'
              ></path>
              <path
                d='M73.9111111,38.8169956 C73.9111111,39.4065067 73.4339556,39.8836622 72.8444444,39.8836622 C72.2556444,39.8836622 71.7777778,39.4065067 71.7777778,38.8169956 C71.7777778,38.2281956 72.2556444,37.7503289 72.8444444,37.7503289 C73.4339556,37.7503289 73.9111111,38.2281956 73.9111111,38.8169956'
                id='Fill-17'
                fill='#FFFFFF'
              ></path>
              <path
                d='M55.8013867,40.3652978 L50.3066311,37.1382756 C49.0927644,36.4250311 48.68672,34.8634311 49.3999644,33.6495644 C50.1124978,32.4356978 51.6740978,32.0296533 52.8879644,32.7421867 L58.3834311,35.9692089 L55.8013867,40.3652978 Z'
                id='Fill-19'
                fill='#F4B6A8'
              ></path>
              <path
                d='M54.8625067,38.7889067 C54.8625067,39.3777067 54.3853511,39.8555733 53.79584,39.8555733 C53.20704,39.8555733 52.7291733,39.3777067 52.7291733,38.7889067 C52.7291733,38.1993956 53.20704,37.72224 53.79584,37.72224 C54.3853511,37.72224 54.8625067,38.1993956 54.8625067,38.7889067'
                id='Fill-23'
                fill='#FFFFFF'
              ></path>
              <path
                d='M100.673564,53.8024533 L74.20032,54.0442311 L77.5681422,67.2381867 L100.053476,64.6141867 C102.235876,64.3588978 104.276764,63.4017422 105.868231,61.8856533 L117.108053,51.1514311 C120.97152,47.4614756 124.426809,43.3661867 127.414187,38.9366756 L126.016853,31.1876978 L120.270364,32.3802311 L120.997831,35.5439644 L117.474987,37.1311644 L118.941298,41.7484089 L100.673564,53.8024533 Z'
                id='Fill-29'
                fill='#F4B6A8'
              ></path>
              <polygon
                id='Fill-31'
                fill='#EF8674'
                points='122.67776 40.3371378 120.750649 34.59136 121.380693 34.38016 123.083804 39.4610489 126.707627 38.0224711 126.95296 38.6397156'
              ></polygon>
              <path
                d='M91.9784533,53.8708622 L76.16192,54.02944 L76.1612089,54.0273067 L76.16192,54.02944 L74.6806756,54.0443733 L50.57472,54.0443733 C41.7313422,54.0443733 34.5626311,61.2137956 34.5626311,70.0571733 L34.5626311,91.5675733 L80.7116089,91.5675733 L82.6941867,68.1449956 L91.9784533,67.0612622 L91.9784533,53.8708622 Z'
                id='Fill-33'
                fill='#3E872E'
              ></path>
              <polygon
                id='Fill-45'
                fill='#F4B6A8'
                points='69.0048 57.6066133 57.6270222 57.6066133 59.7603556 44.8002133 66.8714667 44.8002133'
              ></polygon>
              <path
                d='M76.89024,54.1184 C76.3881956,54.0714667 75.8804622,54.0444444 75.3656178,54.0444444 L50.5748622,54.0444444 C50.0607289,54.0444444 49.5529956,54.0714667 49.0509511,54.1184 C50.3970844,60.5731556 56.1165511,65.4222222 62.97024,65.4222222 C69.8239289,65.4222222 75.5433956,60.5731556 76.89024,54.1184'
                id='Fill-47'
                fill='#F4B6A8'
              ></path>
              <path
                d='M45.07008,121.599929 L45.07008,121.599929 C39.2667022,121.599929 34.5627022,116.895929 34.5627022,111.092551 L34.5627022,76.2481067 C34.5627022,76.1599289 34.6338133,76.0888178 34.72128,76.0888178 L45.07008,76.0888178 C45.1575467,76.0888178 45.2293689,76.1599289 45.2293689,76.2481067 L45.2293689,121.44064 C45.2293689,121.528107 45.1575467,121.599929 45.07008,121.599929'
                id='Fill-49'
                fill='#F4B6A8'
              ></path>
              <path
                d='M106.806258,127.999929 L21.1927467,127.999929 C21.1927467,124.916551 23.6930133,122.416284 26.7771022,122.416284 L101.222613,122.416284 C104.306702,122.416284 106.806258,124.916551 106.806258,127.999929'
                id='Fill-51'
                fill='#3E872E'
              ></path>
              <path
                d='M94.5645511,122.416569 L33.4353067,122.416569 L33.4353067,88.5733689 C33.4353067,86.0211911 35.5039289,83.9525689 38.0561067,83.9525689 L89.9437511,83.9525689 C92.4959289,83.9525689 94.5645511,86.0211911 94.5645511,88.5733689 L94.5645511,122.416569 Z'
                id='Fill-53'
                fill='#F6F3F5'
              ></path>
              <polygon
                id='Fill-55'
                fill='#D4D0D5'
                points='33.4351644 122.416427 94.5644089 122.416427 94.5644089 118.87936 33.4351644 118.87936'
              ></polygon>
              <path
                d='M37.1777422,120.647822 C37.1777422,121.257956 36.7126756,121.7536 36.1380978,121.7536 C35.56352,121.7536 35.0984533,121.257956 35.0984533,120.647822 C35.0984533,120.037689 35.56352,119.542756 36.1380978,119.542756 C36.7126756,119.542756 37.1777422,120.037689 37.1777422,120.647822'
                id='Fill-57'
                fill='#9C9A9F'
              ></path>
              <path
                d='M40.9201067,120.647822 C40.9201067,121.257956 40.45504,121.7536 39.8804622,121.7536 C39.3065956,121.7536 38.8408178,121.257956 38.8408178,120.647822 C38.8408178,120.037689 39.3065956,119.542756 39.8804622,119.542756 C40.45504,119.542756 40.9201067,120.037689 40.9201067,120.647822'
                id='Fill-58'
                fill='#9C9A9F'
              ></path>
              <path
                d='M70.6840178,102.464498 C66.9186844,102.601031 63.88224,105.638187 63.7449956,109.402809 C63.7393067,109.547164 63.8552178,109.668053 64.0009956,109.668053 L69.4217956,109.668053 C70.2651733,109.668053 70.9485511,108.984676 70.9485511,108.140587 L70.9485511,102.719787 C70.9485511,102.57472 70.8283733,102.458809 70.6840178,102.464498'
                id='Fill-59'
                fill='#D4D0D5'
              ></path>
              <mask id='mask-2' fill='white'>
                {/* <use xlink:href='#path-1'></use> */}
              </mask>
              <g id='Clip-62'></g>
              <mask id='mask-4' fill='white'>
                {/* <use xlink:href='#path-3'></use> */}
              </mask>
              <g id='Clip-65'></g>
              <polygon
                id='Fill-64'
                fill='#D4D0D5'
                mask='url(#mask-4)'
                points='61.5268978 111.884871 73.1663644 111.884871 73.1663644 100.246116 61.5268978 100.246116'
              ></polygon>
              <path
                d='M69.4409244,96.7012978 L62.7877689,96.7012978 C60.1331911,96.7012978 57.98208,98.8524089 57.98208,101.506987 L57.98208,108.160142 C57.98208,108.992853 58.6569244,109.667698 59.4896356,109.667698 L60.38848,109.667698 C60.5250133,109.667698 60.6352356,109.558187 60.6359467,109.422364 C60.7653689,103.92832 65.2112356,99.4831644 70.7045689,99.3551644 C70.83968,99.3530311 70.94848,99.2428089 70.94848,99.1084089 L70.94848,98.2088533 C70.94848,97.3761422 70.2736356,96.7012978 69.4409244,96.7012978'
                id='Fill-67'
                fill='#D4D0D5'
                mask='url(#mask-4)'
              ></path>
              <mask id='mask-6' fill='white'>
                {/* <use xlink:href='#path-5'></use> */}
              </mask>
              <g id='Clip-70'></g>
              <polygon
                id='Fill-71'
                fill='#D4D0D5'
                mask='url(#mask-6)'
                points='55.7640533 111.885582 73.1670756 111.885582 73.1670756 94.4832711 55.7640533 94.4832711'
              ></polygon>
            </g>
            <g id='EXPRESIONES' transform='translate(50.496902, 29.860580)'>
              <path
                d='M22.0579064,6.79008713 C21.9249287,6.79008713 21.7976398,6.71542046 21.7364842,6.58670935 C21.6525731,6.40893157 21.7279509,6.19702046 21.9057287,6.11310935 L25.1299064,4.58777602 C25.3055509,4.50457602 25.5188842,4.57924268 25.6035064,4.75773157 C25.6874175,4.93479824 25.6120398,5.14670935 25.434262,5.23133157 L22.2100842,6.7559538 C22.1610175,6.77942046 22.1091064,6.79008713 22.0579064,6.79008713'
                id='Fill-15'
                fill='#EF8674'
              ></path>
              <path
                d='M3.57947087,6.79008713 C3.52827087,6.79008713 3.47635976,6.77942046 3.42729309,6.7559538 L0.203826428,5.23133157 C0.0260486503,5.14670935 -0.0500402386,4.93479824 0.0345819836,4.75773157 C0.119204206,4.57924268 0.331115317,4.50528713 0.508181984,4.58777602 L3.73164865,6.11310935 C3.90942643,6.19702046 3.98480421,6.40893157 3.90089309,6.58670935 C3.83973754,6.71542046 3.71244865,6.79008713 3.57947087,6.79008713'
                id='Fill-21'
                fill='#EF8674'
              ></path>
              <path
                d='M12.8190087,14.5838649 C14.5861198,14.5838649 16.0190087,13.150976 16.0190087,11.3838649 L9.61900865,11.3838649 C9.61900865,13.150976 11.0518975,14.5838649 12.8190087,14.5838649'
                id='Fill-25'
                fill='#1F1E1E'
              ></path>
              <path
                d='M9.70448421,12.094976 L15.9331064,12.094976 C15.9857287,11.8652871 16.0191509,11.6291982 16.0191509,11.3838649 L9.61915087,11.3838649 C9.61915087,11.6291982 9.65257309,11.8652871 9.70448421,12.094976'
                id='Fill-27'
                fill='#FFFFFF'
              ></path>
              <path
                d='M11.6148842,9.07552713 C11.4605731,9.07552713 11.319062,8.97526046 11.274262,8.82023824 C11.2180842,8.6317938 11.3268842,8.43410491 11.5146175,8.37863824 L13.5832398,7.77206046 L12.3082175,3.33757157 C12.253462,3.14912713 12.3629731,2.95214935 12.5514175,2.8973938 C12.7391509,2.84334935 12.9368398,2.95214935 12.9915953,3.14130491 L14.4621731,8.25561602 L11.7151509,9.06130491 C11.6817287,9.07126046 11.6475953,9.07552713 11.6148842,9.07552713'
                id='Fill-35'
                fill='#EF8674'
              ></path>
              <path
                d='M10.0350087,3.08768713 C10.1189198,3.67079824 9.71429754,4.2119538 9.13118643,4.2951538 C8.54807532,4.37906491 8.00691976,3.97444268 7.92371976,3.39133157 C7.83980865,2.80822046 8.24443087,2.26777602 8.82825309,2.18386491 C9.41065309,2.0999538 9.95180865,2.50457602 10.0350087,3.08768713'
                id='Fill-37'
                fill='#1F1E1E'
              ></path>
              <path
                d='M7.63778198,0.958549351 C7.48987087,0.958549351 7.35191532,0.865393796 7.30071532,0.717482685 C7.23813754,0.531171573 7.33698198,0.329927129 7.52329309,0.26663824 C9.25058198,-0.320739538 10.4288931,0.243882685 10.4779598,0.267349351 C10.6543153,0.354104907 10.7268487,0.56743824 10.6400931,0.743793796 C10.5540487,0.918727129 10.3421375,0.991971573 10.165782,0.90663824 C10.1245375,0.886727129 9.18160421,0.453660462 7.75227087,0.939349351 C7.71387087,0.952860462 7.67618198,0.958549351 7.63778198,0.958549351'
                id='Fill-39'
                fill='#702C7E'
              ></path>
              <path
                d='M17.9995953,0.958549351 C17.9611953,0.958549351 17.9235064,0.952860462 17.8851064,0.939349351 C16.4522175,0.452949351 15.5114175,0.888149351 15.4708842,0.90663824 C15.2938175,0.989127129 15.0819064,0.916593796 14.9972842,0.740949351 C14.9119509,0.565304907 14.9837731,0.354104907 15.1594175,0.267349351 C15.2084842,0.243171573 16.3867953,-0.321450649 18.1140842,0.26663824 C18.3003953,0.329927129 18.3992398,0.531171573 18.336662,0.717482685 C18.285462,0.865393796 18.1475064,0.958549351 17.9995953,0.958549351'
                id='Fill-41'
                fill='#702C7E'
              ></path>
              <path
                d='M16.657942,4.2727538 L16.657942,4.2727538 C16.069142,4.2727538 15.5912753,3.79559824 15.5912753,3.20608713 C15.5912753,2.61657602 16.069142,2.13942046 16.657942,2.13942046 C17.2474531,2.13942046 17.7246087,2.61657602 17.7246087,3.20608713 C17.7246087,3.79559824 17.2474531,4.2727538 16.657942,4.2727538'
                id='Fill-43'
                fill='#1F1E1E'
              ></path>
            </g>
          </g>
          <g id='EXITO' transform='translate(96.000000, 9.142857)'>
            <circle
              id='Oval'
              fill='#FFFFFF'
              cx='11.4285714'
              cy='11.4285714'
              r='9.14285714'
            ></circle>
            <g id='00.Token/Icons/Solid/16x16/check' fill='#006B14'>
              <path
                d='M11.4288571,0.714428571 C5.521,0.714428571 0.714571429,5.52014286 0.714571429,11.4287143 C0.714571429,17.3365714 5.521,22.143 11.4288571,22.143 C17.3367143,22.143 22.1431429,17.3365714 22.1431429,11.4287143 C22.1431429,5.52014286 17.3367143,0.714428571 11.4288571,0.714428571 M14.1936678,7.86588792 C14.5833272,7.42056296 15.2602157,7.37543706 15.7055407,7.7650964 C16.1103815,8.11933216 16.1844794,8.71094742 15.9018491,9.15016549 L15.8063322,9.27696922 L10.0521893,15.8531325 L6.38524273,12.1861858 C5.96682385,11.767767 5.96682385,11.0893759 6.38524273,10.670957 C6.76562354,10.2905762 7.36085737,10.2559961 7.78030236,10.5672168 L7.90047155,10.670957 L9.94714286,12.7171429 L14.1936678,7.86588792 Z'
                id='color-icono'
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
  return <React.Fragment>{elm}</React.Fragment>
}

export default ERRORMOBILE
