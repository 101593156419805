import React from 'react'
import PropTypes from 'prop-types'
import inputStyles from './_core.scss'

const InputCore = ({
  id,
  name,
  value,
  type,
  prefix,
  suffix,
  variant,
  onlyNumber,
  maxLength,
  disabled,
  autoComplete,
  placeholder,
  readOnly,
  allowSpaces,
  rows,
  onChange,
  onBlur,
}) => {
  let inputStyle = [inputStyles.input]

  if (disabled && disabled === true)
    inputStyle = inputStyle.concat(inputStyles.disabled)

  if (prefix) inputStyle = inputStyle.concat(inputStyles.hasPrefix)

  if (suffix) inputStyle = inputStyle.concat(inputStyles.hasSuffix)

  const handleChange = (e) => {

    const updatedValue = allowSpaces ? e.target.value : e.target.value.trim();

    const isValidOnOnlyNumber = onlyNumber ? !Number.isNaN(+updatedValue) : true
    const isValidLength = maxLength ? updatedValue.length <= maxLength : true

    if (!isValidOnOnlyNumber || !isValidLength) return

    if (onChange) onChange(updatedValue)
  }

  return (
    <div className={inputStyle.join(' ')}>
      {prefix && <div className={inputStyles.prefixContainer}>{prefix}</div>}

      {variant && variant === 'textarea' ? (
        <textarea
          id={id}
          name={name}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={handleChange}
          onBlur={onBlur}
          rows={rows}
        />
      ) : (
        <input
          id={id}
          name={name}
          value={value}
          type={type}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          readOnly={readOnly}
        />
      )}

      {suffix && <div className={inputStyles.suffixContainer}>{suffix}</div>}
    </div>
  )
}

InputCore.propTypes = {
  id: undefined,
  name: undefined,
  value: '',
  type: 'text',
  suffix: undefined,
  prefix: undefined,
  onlyNumber: false,
  variant: undefined,
  maxLength: 200,
  disabled: false,
  autoComplete: 'on',
  placeholder: undefined,
  rows: 1,
  onChange: () => {},
  onBlur: () => {}
}
InputCore.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onlyNumber: PropTypes.bool,
  variant: PropTypes.string,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func
}

export default InputCore
