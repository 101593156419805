import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_privateFooter.scss'
import CoopeuchGris from './assets/CoopeuchGris'

const PrivateFooter = ({ items, icon, label, className }) => (
  <div>
    <div
      className={[styles.privateFooter, styles.footerDesktop]
        .concat(className)
        .join(' ')}
    >
      <div className={styles.footerLeftArea}>
        <div className={styles.footerLogoContainer}>
          <img src={CoopeuchGris} alt="imagen doesn't found" />
        </div>
        <div className={styles.items}>
          {items &&
            items.map((item) => (
              <p key={`${item.text}-${nanoid()}`}>
                <a className={styles.link} href={item.link}>
                  {item.text}
                </a>
              </p>
            ))}
        </div>
      </div>
      <div className={styles.end}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.textLabel}>
          <span>{label}</span>
        </div>
      </div>
    </div>
    <div className={styles.footerMobile}>
      <img src={CoopeuchGris} alt="imagen doesn't found" />
    </div>
  </div>
)

PrivateFooter.defaultProps = {}

PrivateFooter.propTypes = {
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string
      })
    ),
    PropTypes.string
  ]),
  label: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.element
}

export default PrivateFooter
