import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MobileTracker from './mobileTracker'
import DesktopTracker from './desktopTracker'

const Tracker = ({ steps, currentStep }) => (
  <div>
    <MobileTracker steps={steps} currentStep={currentStep} />
    <DesktopTracker steps={steps} currentStep={currentStep} />
  </div>
)

Tracker.defaultProps = {
  currentStep: 1
}

Tracker.propTypes = {
  currentStep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired
}

export default Tracker
