
import * as React from "react"

const ExitoDesk = (props) => (
  <svg
    {...props}
    width="164px"
    height="160px"
    viewBox="0 0 164 160"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M8.55622857,0.750628571 C4.32022857,0.904228571 0.903428571,4.32022857 0.750628571,8.55622857 L0.750628571,8.55622857 C0.743428571,8.71782857 0.874628571,8.85462857 1.03782857,8.85462857 L1.03782857,8.85462857 L7.13542857,8.85462857 C8.08502857,8.85462857 8.85382857,8.08582857 8.85382857,7.13622857 L8.85382857,7.13622857 L8.85382857,1.03782857 C8.85382857,0.878628571 8.72422857,0.750628571 8.56742857,0.750628571 L8.56742857,0.750628571 C8.56342857,0.750628571 8.56022857,0.750628571 8.55622857,0.750628571 L8.55622857,0.750628571 Z"
        id="path-1"
      />
      <path
        d="M8.55622857,0.750628571 C4.32022857,0.904228571 0.903428571,4.32022857 0.750628571,8.55622857 L0.750628571,8.55622857 C0.743428571,8.71782857 0.874628571,8.85462857 1.03782857,8.85462857 L1.03782857,8.85462857 L7.13542857,8.85462857 C8.08502857,8.85462857 8.85382857,8.08582857 8.85382857,7.13622857 L8.85382857,7.13622857 L8.85382857,1.03782857 C8.85382857,0.878628571 8.72422857,0.750628571 8.56662857,0.750628571 L8.56662857,0.750628571 C8.56342857,0.750628571 8.56022857,0.750628571 8.55622857,0.750628571 L8.55622857,0.750628571 Z"
        id="path-3"
      />
      <path
        d="M-0.326971429,-5.73257143 C-3.31337143,-5.73257143 -5.73337143,-3.31257143 -5.73337143,-0.326971429 L-5.73337143,-0.326971429 L-5.73337143,7.15782857 C-5.73337143,8.09462857 -4.97417143,8.85462857 -4.03737143,8.85462857 L-4.03737143,8.85462857 L-3.02697143,8.85462857 C-2.87257143,8.85462857 -2.74777143,8.73062857 -2.74697143,8.57702857 L-2.74697143,8.57702857 C-2.60217143,2.39702857 2.39942857,-2.60377143 8.57942857,-2.74697143 L8.57942857,-2.74697143 C8.73142857,-2.74937143 8.85382857,-2.87417143 8.85382857,-3.02537143 L8.85382857,-3.02537143 L8.85382857,-4.03737143 C8.85382857,-4.97417143 8.09462857,-5.73257143 7.15782857,-5.73257143 L7.15782857,-5.73257143 L-0.326971429,-5.73257143 Z"
        id="path-5"
      />
    </defs>
    <g
      id="Molecula-exito-desktop"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="EXITO-DESKTOP" transform="translate(0.000000, 0.000000)">
        <g transform="translate(10.000000, 8.000000)">
          <g id="EXITO-RESPONSIVE">
            <g id="ROSTRO-Y-CUERPO">
              <path
                d="M144,72 C144,111.7648 111.7648,144 72,144 C32.2352,144 0,111.7648 0,72 C0,32.2352 32.2352,0 72,0 C111.7648,0 144,32.2352 144,72"
                id="Fill-1"
                fill="#CCF1C1"
              />
              <path
                d="M91.30856,58.18672 C90.38936,57.87152 89.95416,56.86672 90.32536,55.96912 C90.32856,55.96272 90.33096,55.95632 90.33336,55.94992 C92.07656,51.71792 90.87016,46.84352 87.35416,43.91312 L83.66216,40.83712 L72.46216,41.63712 L72.46216,62.43712 L75.66216,62.43712 L75.66216,64.12352 C75.66216,69.52032 78.72776,74.57152 83.68536,76.70432 C91.00696,79.85552 98.06216,74.56992 98.06216,67.63712 C98.06216,63.25152 95.23656,59.53312 91.30856,58.18672"
                id="Fill-3"
                fill="#702C7E"
              />
              <path
                d="M68.5992,41.63752 L57.3992,40.83752 L53.708,43.91352 C50.1912,46.84312 48.984,51.71752 50.7272,55.95032 L50.744,55.98872 C51.116,56.88312 50.6688,57.87272 49.752,58.18712 C44.616,59.94792 41.3656,65.76232 43.8496,71.82152 C45.3152,75.39512 48.9024,77.63752 52.764,77.63752 L52.976,77.63752 C59.8456,77.63752 65.3992,72.08552 65.3992,65.23752 L65.3992,62.43752 L68.5992,62.43752 L68.5992,41.63752 Z"
                id="Fill-5"
                fill="#702C7E"
              />
              <path
                d="M85.26248,39.2 L56.86248,39.2 L56.86248,29.8424 C56.86248,23.9648 61.62728,19.2 67.50568,19.2 L74.62008,19.2 C80.49768,19.2 85.26248,23.9648 85.26248,29.8424 L85.26248,39.2 Z"
                id="Fill-7"
                fill="#702C7E"
              />
              <path
                d="M71.63672,54.72304 L70.82312,54.72304 C65.74632,54.72304 61.63032,50.60704 61.63032,45.52944 L61.63032,26.60464 C61.63032,24.01904 63.72632,21.92304 66.31192,21.92304 L74.82152,21.92304 C78.13992,21.92304 80.83032,24.61264 80.83032,27.93104 L80.83032,45.52944 C80.83032,50.60704 76.71432,54.72304 71.63672,54.72304"
                id="Fill-9"
                fill="#FFB3A5"
              />
              <path
                d="M83.4336,27.72264 L81.1504,28.20824 L79.8688,20.83784 L69,20.03784 C62.196,20.03784 56.7232,24.83384 56.8744,31.63624 L56.9992,37.23784 L61.3992,39.23784 L63.7992,28.03784 C63.7992,28.03784 70.0624,32.80024 78.8128,31.29304 L80.5992,38.43784 L83.4832,37.63784 C83.8656,33.58744 83.7648,30.31784 83.4336,27.72264"
                id="Fill-11"
                fill="#702C7E"
              />
              <path
                d="M79.6836,45.41096 L85.866,41.78056 C87.2316,40.97816 87.6876,39.22136 86.886,37.85576 C86.0836,36.49016 84.3268,36.03336 82.9612,36.83496 L76.7788,40.46536 L79.6836,45.41096 Z"
                id="Fill-13"
                fill="#FFB3A5"
              />
              <path
                d="M83.15,43.66912 C83.15,44.33232 82.6132,44.86912 81.95,44.86912 C81.2876,44.86912 80.75,44.33232 80.75,43.66912 C80.75,43.00672 81.2876,42.46912 81.95,42.46912 C82.6132,42.46912 83.15,43.00672 83.15,43.66912"
                id="Fill-17"
                fill="#FFFFFF"
              />
              <path
                d="M62.77656,45.41096 L56.59496,41.78056 C55.22936,40.97816 54.77256,39.22136 55.57496,37.85576 C56.37656,36.49016 58.13336,36.03336 59.49896,36.83496 L65.68136,40.46536 L62.77656,45.41096 Z"
                id="Fill-19"
                fill="#FFB3A5"
              />
              <path
                d="M61.72032,43.63752 C61.72032,44.29992 61.18352,44.83752 60.52032,44.83752 C59.85792,44.83752 59.32032,44.29992 59.32032,43.63752 C59.32032,42.97432 59.85792,42.43752 60.52032,42.43752 C61.18352,42.43752 61.72032,42.97432 61.72032,43.63752"
                id="Fill-23"
                fill="#FFFFFF"
              />
              <path
                d="M113.25776,60.52776 L83.47536,60.79976 L87.26416,75.64296 L112.56016,72.69096 C115.01536,72.40376 117.31136,71.32696 119.10176,69.62136 L131.74656,57.54536 C136.09296,53.39416 139.98016,48.78696 143.34096,43.80376 L141.76896,35.08616 L135.30416,36.42776 L136.12256,39.98696 L132.15936,41.77256 L133.80896,46.96696 L113.25776,60.52776 Z"
                id="Fill-29"
                fill="#FFB3A5"
              />
              <polygon
                id="Fill-31"
                fill="#FF7F6E"
                points="138.01248 45.37928 135.84448 38.91528 136.55328 38.67768 138.46928 44.39368 142.54608 42.77528 142.82208 43.46968"
              />
              <path
                d="M103.47576,60.60472 L85.68216,60.78312 L85.68136,60.78072 L85.68216,60.78312 L84.01576,60.79992 L56.89656,60.79992 C46.94776,60.79992 38.88296,68.86552 38.88296,78.81432 L38.88296,103.01352 L90.80056,103.01352 L93.03096,76.66312 L103.47576,75.44392 L103.47576,60.60472 Z"
                id="Fill-33"
                fill="#0E891A"
              />
              <polygon
                id="Fill-45"
                fill="#FFB3A5"
                points="77.6304 64.80744 64.8304 64.80744 67.2304 50.40024 75.2304 50.40024"
              />
              <path
                d="M86.50152,60.8832 C85.93672,60.8304 85.36552,60.8 84.78632,60.8 L56.89672,60.8 C56.31832,60.8 55.74712,60.8304 55.18232,60.8832 C56.69672,68.1448 63.13112,73.6 70.84152,73.6 C78.55192,73.6 84.98632,68.1448 86.50152,60.8832"
                id="Fill-47"
                fill="#FFB3A5"
              />
              <path
                d="M50.70384,136.79992 L50.70384,136.79992 C44.17504,136.79992 38.88304,131.50792 38.88304,124.97912 L38.88304,85.77912 C38.88304,85.67992 38.96304,85.59992 39.06144,85.59992 L50.70384,85.59992 C50.80224,85.59992 50.88304,85.67992 50.88304,85.77912 L50.88304,136.62072 C50.88304,136.71912 50.80224,136.79992 50.70384,136.79992"
                id="Fill-49"
                fill="#FFB3A5"
              />
              <path
                d="M120.15704,143.99992 L23.84184,143.99992 C23.84184,140.53112 26.65464,137.71832 30.12424,137.71832 L113.87544,137.71832 C117.34504,137.71832 120.15704,140.53112 120.15704,143.99992"
                id="Fill-51"
                fill="#0E891A"
              />
              <path
                d="M106.38512,137.71864 L37.61472,137.71864 L37.61472,99.64504 C37.61472,96.77384 39.94192,94.44664 42.81312,94.44664 L101.18672,94.44664 C104.05792,94.44664 106.38512,96.77384 106.38512,99.64504 L106.38512,137.71864 Z"
                id="Fill-53"
                fill="#F6F3F5"
              />
              <polygon
                id="Fill-55"
                fill="#D4D0D5"
                points="37.61456 137.71848 106.38496 137.71848 106.38496 133.73928 37.61456 133.73928"
              />
              <path
                d="M41.82496,135.7288 C41.82496,136.4152 41.30176,136.9728 40.65536,136.9728 C40.00896,136.9728 39.48576,136.4152 39.48576,135.7288 C39.48576,135.0424 40.00896,134.4856 40.65536,134.4856 C41.30176,134.4856 41.82496,135.0424 41.82496,135.7288"
                id="Fill-57"
                fill="#9C9A9F"
              />
              <path
                d="M46.03512,135.7288 C46.03512,136.4152 45.51192,136.9728 44.86552,136.9728 C44.21992,136.9728 43.69592,136.4152 43.69592,135.7288 C43.69592,135.0424 44.21992,134.4856 44.86552,134.4856 C45.51192,134.4856 46.03512,135.0424 46.03512,135.7288"
                id="Fill-58"
                fill="#9C9A9F"
              />
              <path
                d="M79.51952,115.27256 C75.28352,115.42616 71.86752,118.84296 71.71312,123.07816 C71.70672,123.24056 71.83712,123.37656 72.00112,123.37656 L78.09952,123.37656 C79.04832,123.37656 79.81712,122.60776 79.81712,121.65816 L79.81712,115.55976 C79.81712,115.39656 79.68192,115.26616 79.51952,115.27256"
                id="Fill-59"
                fill="#D4D0D5"
              />
              <g id="LOGO" transform="translate(70.963131, 114.522251)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <g id="Clip-62" />
                <mask id="mask-4" fill="white">
                  <use xlinkHref="#path-3" />
                </mask>
                <g id="Clip-65" />
                <polygon
                  id="Fill-64"
                  fill="#D4D0D5"
                  mask="url(#mask-4)"
                  points="-1.74537143 11.3482286 11.3490286 11.3482286 11.3490286 -1.74537143 -1.74537143 -1.74537143"
                />
                <path
                  d="M7.15790857,-5.73329143 L-0.326891429,-5.73329143 C-3.31329143,-5.73329143 -5.73329143,-3.31329143 -5.73329143,-0.326891429 L-5.73329143,7.15790857 C-5.73329143,8.09470857 -4.97409143,8.85390857 -4.03729143,8.85390857 L-3.02609143,8.85390857 C-2.87249143,8.85390857 -2.74849143,8.73070857 -2.74769143,8.57790857 C-2.60209143,2.39710857 2.39950857,-2.60369143 8.57950857,-2.74769143 C8.73150857,-2.75009143 8.85390857,-2.87409143 8.85390857,-3.02529143 L8.85390857,-4.03729143 C8.85390857,-4.97409143 8.09470857,-5.73329143 7.15790857,-5.73329143"
                  id="Fill-67"
                  fill="#D4D0D5"
                  mask="url(#mask-4)"
                />
                <mask id="mask-6" fill="white">
                  <use xlinkHref="#path-5" />
                </mask>
                <g id="Clip-70" />
                <polygon
                  id="Fill-71"
                  fill="#D4D0D5"
                  mask="url(#mask-6)"
                  points="-8.22857143 11.3490286 11.3498286 11.3490286 11.3498286 -8.22857143 -8.22857143 -8.22857143"
                />
              </g>
            </g>
            <g id="EXPRESIONES" transform="translate(56.809015, 33.593152)">
              <path
                d="M24.8151447,7.63884802 C24.6655447,7.63884802 24.5223447,7.55484802 24.4535447,7.41004802 C24.3591447,7.21004802 24.4439447,6.97164802 24.6439447,6.87724802 L28.2711447,5.16124802 C28.4687447,5.06764802 28.7087447,5.15164802 28.8039447,5.35244802 C28.8983447,5.55164802 28.8135447,5.79004802 28.6135447,5.88524802 L24.9863447,7.60044802 C24.9311447,7.62684802 24.8727447,7.63884802 24.8151447,7.63884802"
                id="Fill-15"
                fill="#FF7F6E"
              />
              <path
                d="M4.02690473,7.63884802 C3.96930473,7.63884802 3.91090473,7.62684802 3.85570473,7.60044802 L0.229304732,5.88524802 C0.0293047315,5.79004802 -0.0562952685,5.55164802 0.0389047315,5.35244802 C0.134104732,5.15164802 0.372504732,5.06844802 0.571704732,5.16124802 L4.19810473,6.87724802 C4.39810473,6.97164802 4.48290473,7.21004802 4.38850473,7.41004802 C4.31970473,7.55484802 4.17650473,7.63884802 4.02690473,7.63884802"
                id="Fill-21"
                fill="#FF7F6E"
              />
              <path
                d="M14.4213847,16.406848 C16.4093847,16.406848 18.0213847,14.794848 18.0213847,12.806848 L10.8213847,12.806848 C10.8213847,14.794848 12.4333847,16.406848 14.4213847,16.406848"
                id="Fill-25"
                fill="#1F1E1E"
              />
              <path
                d="M10.9175447,13.606848 L17.9247447,13.606848 C17.9839447,13.348448 18.0215447,13.082848 18.0215447,12.806848 L10.8215447,12.806848 C10.8215447,13.082848 10.8591447,13.348448 10.9175447,13.606848"
                id="Fill-27"
                fill="#FFFFFF"
              />
              <path
                d="M13.0667447,10.209968 C12.8931447,10.209968 12.7339447,10.097168 12.6835447,9.92276802 C12.6203447,9.71076802 12.7427447,9.48836802 12.9539447,9.42596802 L15.2811447,8.74356802 L13.8467447,3.75476802 C13.7851447,3.54276802 13.9083447,3.32116802 14.1203447,3.25956802 C14.3315447,3.19876802 14.5539447,3.32116802 14.6155447,3.53396802 L16.2699447,9.28756802 L13.1795447,10.193968 C13.1419447,10.205168 13.1035447,10.209968 13.0667447,10.209968"
                id="Fill-35"
                fill="#FF7F6E"
              />
              <path
                d="M11.2893847,3.47364802 C11.3837847,4.12964802 10.9285847,4.73844802 10.2725847,4.83204802 C9.61658473,4.92644802 9.00778473,4.47124802 8.91418473,3.81524802 C8.81978473,3.15924802 9.27498473,2.55124802 9.93178473,2.45684802 C10.5869847,2.36244802 11.1957847,2.81764802 11.2893847,3.47364802"
                id="Fill-37"
                fill="#1F1E1E"
              />
              <path
                d="M8.59250473,1.07836802 C8.42610473,1.07836802 8.27090473,0.97356802 8.21330473,0.80716802 C8.14290473,0.59756802 8.25410473,0.37116802 8.46370473,0.29996802 C10.4069047,-0.36083198 11.7325047,0.27436802 11.7877047,0.30076802 C11.9861047,0.39836802 12.0677047,0.63836802 11.9701047,0.83676802 C11.8733047,1.03356802 11.6349047,1.11596802 11.4365047,1.01996802 C11.3901047,0.99756802 10.3293047,0.51036802 8.72130473,1.05676802 C8.67810473,1.07196802 8.63570473,1.07836802 8.59250473,1.07836802"
                id="Fill-39"
                fill="#702C7E"
              />
              <path
                d="M20.2495447,1.07836802 C20.2063447,1.07836802 20.1639447,1.07196802 20.1207447,1.05676802 C18.5087447,0.50956802 17.4503447,0.99916802 17.4047447,1.01996802 C17.2055447,1.11276802 16.9671447,1.03116802 16.8719447,0.83356802 C16.7759447,0.63596802 16.8567447,0.39836802 17.0543447,0.30076802 C17.1095447,0.27356802 18.4351447,-0.36163198 20.3783447,0.29996802 C20.5879447,0.37116802 20.6991447,0.59756802 20.6287447,0.80716802 C20.5711447,0.97356802 20.4159447,1.07836802 20.2495447,1.07836802"
                id="Fill-41"
                fill="#702C7E"
              />
              <path
                d="M18.7401847,4.80684802 L18.7401847,4.80684802 C18.0777847,4.80684802 17.5401847,4.27004802 17.5401847,3.60684802 C17.5401847,2.94364802 18.0777847,2.40684802 18.7401847,2.40684802 C19.4033847,2.40684802 19.9401847,2.94364802 19.9401847,3.60684802 C19.9401847,4.27004802 19.4033847,4.80684802 18.7401847,4.80684802"
                id="Fill-43"
                fill="#1F1E1E"
              />
            </g>
          </g>
          <g id="EXITO" transform="translate(108.000000, 7.888889)">
            <circle id="Oval" fill="#FFFFFF" cx={14} cy={14.1111111} r={12} />
            <g
              id="00.Token/Icons/Solid/32x32/check"
              transform="translate(0.875350, 0.875175)"
              fill="#006B14"
            >
              <path
                d="M13.125,0 C5.887875,0 0,5.887 0,13.125 C0,20.362125 5.887875,26.25 13.125,26.25 C20.362125,26.25 26.25,20.362125 26.25,13.125 C26.25,5.887 20.362125,0 13.125,0 M16.5118931,8.7605377 C16.9892258,8.21501462 17.8184142,8.15973539 18.3639373,8.63706809 C18.8598674,9.0710069 18.9506372,9.79573558 18.6044151,10.3337777 L18.4874069,10.4891123 L11.438582,18.5449123 L6.94657235,14.0529027 C6.43400922,13.5403395 6.43400922,12.7093105 6.94657235,12.1967473 C7.41253883,11.7307809 8.14170028,11.6884203 8.65552039,12.0696656 L8.80272765,12.1967473 L11.3099,14.703325 L16.5118931,8.7605377 Z"
                id="color-icono"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ExitoDesk
