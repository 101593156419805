import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_publicFooterItem.scss'

const PublicFooterItem = ({ children, link, subItems, className }) => (
  <React.Fragment>
    <p className={[styles.item].concat(className).join(' ')}>
      <a href={link}>{children}</a>
    </p>
    <div className={styles.subItems}>
      {subItems &&
        subItems.map((item) => (
          <p key={`${item.text}-${nanoid()}`} className={styles.subItem}>
            <a title={item.text} href={item.link}>
              {item.text}
            </a>
          </p>
        ))}
    </div>
  </React.Fragment>
)

PublicFooterItem.defaultProps = {}

PublicFooterItem.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
  subItems: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string
      })
    ),
    PropTypes.string
  ])
}

export default PublicFooterItem
