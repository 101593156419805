import * as React from "react"

const AlertaApp = (props) => (
  <svg
    {...props}
    width="140px"
    height="128px"
    viewBox="0 0 140 128"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon id="path-1" points="0 0 111.999751 0 111.999751 112 0 112" />
      <polygon
        id="path-3"
        points="0 0 55.4904 0 55.4904 58.6274578 0 58.6274578"
      />
    </defs>
    <g
      id="Molecula-app-ilustracion-alerta"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="Group-3" transform="translate(14.000000, 8.000000)">
        <mask id="mask-2" fill="white">
          <use xlinkHref="#path-1" />
        </mask>
        <g id="Clip-2" />
        <path
          d="M111.999751,56 C111.999751,86.9281778 86.9279289,112 55.9997511,112 C25.0721956,112 -0.000248888889,86.9281778 -0.000248888889,56 C-0.000248888889,25.0718222 25.0721956,0 55.9997511,0 C86.9279289,0 111.999751,25.0718222 111.999751,56"
          id="Fill-1"
          fill="#EFE6B2"
          mask="url(#mask-2)"
        />
      </g>
      <path
        d="M79.9322222,43.2291022 L59.1213778,43.2291022 C58.5265333,43.2291022 58.0436889,42.74688 58.0436889,42.1520356 L58.0436889,35.2086578 C58.0436889,30.02928 62.2424444,25.8305244 67.4218222,25.8305244 L71.6317778,25.8305244 C76.8105333,25.8305244 81.0092889,30.02928 81.0092889,35.2086578 L81.0092889,42.1520356 C81.0092889,42.74688 80.5270667,43.2291022 79.9322222,43.2291022"
        id="Fill-4"
        fill="#EA5474"
      />
      <path
        d="M67.9228356,61.9316089 L67.9228356,45.2286756 L58.18008,44.5330311 L54.72488,47.4462756 C50.2772356,51.1970311 51.1838133,58.4303644 56.5287022,60.7276089 C59.2615022,61.9023644 62.9531467,62.5314311 67.9228356,61.9316089"
        id="Fill-6"
        fill="#EA5474"
      />
      <path
        d="M71.1305778,61.9316089 L71.1305778,45.2286756 L80.8733333,44.5330311 L84.3285333,47.4462756 C88.7761778,51.1970311 87.8689778,58.4303644 82.5247111,60.7276089 C79.7919111,61.9023644 76.1002667,62.5314311 71.1305778,61.9316089"
        id="Fill-8"
        fill="#EA5474"
      />
      <path
        d="M70.4196267,56.3637778 L69.5391822,56.3637778 C65.1525156,56.3637778 61.5965156,52.8077778 61.5965156,48.4217333 L61.5965156,32.3721333 C61.5965156,30.1389778 63.4071822,28.3276889 65.6415822,28.3276889 L73.1710933,28.3276889 C76.0376711,28.3276889 78.3622933,30.6516889 78.3622933,33.5182667 L78.3622933,48.4217333 C78.3622933,52.8077778 74.8062933,56.3637778 70.4196267,56.3637778"
        id="Fill-10"
        fill="#FFB3A5"
      />
      <path
        d="M62.5780089,48.5118933 L57.2001422,45.3534933 C56.01232,44.6559822 55.6153422,43.1271822 56.3128533,41.93936 C57.0109867,40.7509156 58.5391644,40.3539378 59.7269867,41.0514489 L65.1048533,44.2098489 L62.5780089,48.5118933 Z"
        id="Fill-20"
        fill="#FFB3A5"
      />
      <path
        d="M61.5967022,42.14992 L61.9009689,41.4996978 C63.5542133,37.9716978 66.8327022,35.4156089 70.6892356,34.8686756 C73.6983022,34.4418311 77.0377689,33.1718756 78.3618578,29.7969422 C71.1303911,26.7362311 71.2666578,26.5271644 71.2666578,26.5271644 L62.95688,28.3278756 L60.0772356,32.3399644 L61.5967022,42.14992 Z"
        id="Fill-24"
        fill="#EA5474"
      />
      <path
        d="M74.746,30.7025867 C75.4416444,32.0938756 78.3623556,43.0356533 78.3623556,43.0356533 L79.6173778,41.1409867 L78.9217333,32.78952 L77.182,29.2944978 L74.746,30.7025867 Z"
        id="Fill-26"
        fill="#EA5474"
      />
      <path
        d="M77.2859111,48.5118933 L82.6631556,45.3534933 C83.8509778,44.6559822 84.2485778,43.1271822 83.5504444,41.93936 C82.8529333,40.7509156 81.3241333,40.3539378 80.1363111,41.0514489 L74.7590667,44.2098489 L77.2859111,48.5118933 Z"
        id="Fill-28"
        fill="#FFB3A5"
      />
      <g id="Group-34" transform="translate(42.241982, 61.372542)">
        <mask id="mask-4" fill="white">
          <use xlinkHref="#path-3" />
        </mask>
        <g id="Clip-33" />
        <path
          d="M45.5603556,1.63333333 C44.1410667,0.605422222 42.4181333,0.0796444444 40.6672,0 L40.6603556,0 L15.652,0.0161777778 C7.00746667,0.0161777778 0,7.02364444 0,15.6681778 L0,36.6930667 L6.92533333,36.6930667 L6.92533333,54.6211556 C13.3653333,57.204 20.3952,58.6276444 27.7579556,58.6276444 C34.4580444,58.6276444 40.8818667,57.4491556 46.8371556,55.2912889 L46.6486222,53.8216 C45.5199111,45.0208889 45.3382222,36.1256 46.1066667,27.2863111 L46.4426667,27.6546667 L49.8904,30.8728 L55.4904,15.4211556 L48.1270222,4.31324444 C47.4313778,3.26293333 46.5577778,2.35573333 45.5603556,1.63333333"
          id="Fill-32"
          fill="#967100"
          mask="url(#mask-4)"
        />
      </g>
      <polygon
        id="Fill-35"
        fill="#FFB3A5"
        points="75.5778489 63.3230844 64.44256 63.3230844 65.8350933 46.6207733 74.18656 46.6207733"
      />
      <path
        d="M80.1078133,97.69968 L70.44968,97.69968 C69.6339467,97.69968 68.9731467,97.03888 68.9731467,96.2243911 L68.9731467,77.39408 C68.9731467,76.5795911 69.6339467,75.9181689 70.44968,75.9181689 L80.1078133,75.9181689 C80.9235467,75.9181689 81.5837244,76.5795911 81.5837244,77.39408 L81.5837244,96.2243911 C81.5837244,97.03888 80.9235467,97.69968 80.1078133,97.69968"
        id="Fill-41"
        fill="#F6F3F5"
      />
      <path
        d="M71.4255111,79.4209689 C70.8449778,79.4209689 70.3745778,78.9511911 70.3745778,78.3700356 C70.3745778,77.7895022 70.8449778,77.3197244 71.4255111,77.3197244 C72.0054222,77.3197244 72.4764444,77.7895022 72.4764444,78.3700356 C72.4764444,78.9511911 72.0054222,79.4209689 71.4255111,79.4209689 Z"
        id="Fill-43"
        fill="#D4D0D5"
      />
      <path
        d="M42.2422311,105.595742 L42.2422311,101.58552 L42.2422311,89.1286311 L42.2422311,82.7720089 L52.7521867,82.7720089 L52.7521867,98.2497867 L66.29672,87.7236533 L74.5212533,87.4803644 L75.1248089,92.9092533 L67.5977867,94.9401867 L53.7713867,110.633876 C52.6246311,111.935564 50.9838311,112.750676 49.2484533,112.728276 C49.1096978,112.726409 48.9684533,112.720187 48.82472,112.708364 C45.3116533,112.433342 42.2422311,109.528809 42.2422311,105.595742 Z"
        id="Fill-45"
        fill="#FFB3A5"
      />
      <path
        d="M82.9091822,61.3726044 L57.8939822,61.3887822 C57.37256,61.3887822 56.85736,61.4155378 56.3490044,61.4653156 C57.7284711,67.7006044 63.28056,72.3672711 69.9302489,72.3672711 C76.5961156,72.3672711 82.1612711,67.6794489 83.5233156,61.42176 C83.3192267,61.3999822 83.1145156,61.3831822 82.9091822,61.3726044 Z"
        id="Fill-47"
        fill="#FFB3A5"
      />
      <path
        d="M101.019707,86.7366222 L100.010462,70.9054222 L98.4175733,46.4116444 L93.1342844,45.3333333 L92.4884178,53.2679111 L89.2030844,52.5753778 L87.6742844,54.0251556 L92.2046844,56.7573333 L89.8134844,86.1392889 C89.4308178,89.3095111 90.8432622,92.1991111 93.5829067,93.2500444 C94.0993511,93.4479111 94.6518844,93.5362667 95.2044178,93.5580444 C95.9541956,93.5872889 96.71144,93.484 97.4033511,93.1940444 C99.7684178,92.2028444 101.343884,89.7550222 101.019707,86.7366222"
        id="Fill-49"
        fill="#FFB3A5"
      />
      <polygon
        id="Fill-51"
        fill="#EF8674"
        points="92.5480889 53.6182222 97.5538667 53.6182222 97.5538667 52.8479111 92.5480889 52.8479111"
      />
      <g id="EXPRESIONES" transform="translate(57.386965, 38.140479)">
        <path
          d="M8.94435061,4.29417029 C8.37377283,4.37630363 7.84488394,3.98057029 7.76275061,3.40999252 C7.68061727,2.83941474 8.07697283,2.30990363 8.64817283,2.22777029 C9.21875061,2.14563696 9.74701727,2.54199252 9.82915061,3.11257029 C9.91128394,3.68314807 9.51492838,4.21265918 8.94435061,4.29417029 Z"
          id="Fill-12"
          fill="#1F1E1E"
        />
        <path
          d="M16.6000484,4.29417029 C16.0288484,4.37630363 15.5005817,3.98057029 15.4184484,3.40999252 C15.3363151,2.83941474 15.7326706,2.30990363 16.3032484,2.22777029 C16.8738262,2.14563696 17.4027151,2.54199252 17.4848484,3.11257029 C17.5669817,3.68314807 17.1706262,4.21265918 16.6000484,4.29417029 Z"
          id="Fill-14"
          fill="#1F1E1E"
        />
        <path
          d="M17.7604928,1.01033029 C16.3592484,0.534330294 15.4377373,0.960552516 15.3985373,0.977974738 C15.2255595,1.06010807 15.0177373,0.989174738 14.9343595,0.816196961 C14.8516039,0.644463627 14.9219151,0.437263627 15.0930262,0.352641405 C15.1409373,0.328374738 16.2957817,-0.222914151 17.9844928,0.351396961 C18.1668039,0.413619183 18.2638706,0.610863627 18.2016484,0.792552516 C18.1524928,0.938152516 18.0168484,1.02899696 17.8724928,1.02899696 C17.8351595,1.02899696 17.7978262,1.02277474 17.7604928,1.01033029 Z"
          id="Fill-16"
          fill="#EA5474"
        />
        <path
          d="M7.26211061,0.792552516 C7.19988838,0.610863627 7.29695505,0.413619183 7.47926616,0.351396961 C9.16673283,-0.222291928 10.3240662,0.328996961 10.3713551,0.352641405 C10.5437106,0.437885849 10.6140217,0.646330294 10.5300217,0.818685849 C10.4447773,0.990419183 10.2381995,1.06135252 10.0658439,0.977974738 C10.0272662,0.960552516 9.10637727,0.534330294 7.70326616,1.01033029 C7.66593283,1.02277474 7.62859949,1.02899696 7.59126616,1.02899696 C7.44628838,1.02899696 7.31126616,0.938152516 7.26211061,0.792552516 Z"
          id="Fill-18"
          fill="#EA5474"
        />
        <path
          d="M3.35430616,6.70229474 L0.199017273,5.21020585 C0.0254172725,5.12745029 -0.0486271719,4.92025029 0.0335061614,4.74665029 C0.115639495,4.57242807 0.324083939,4.49962807 0.497061717,4.58051696 L3.65172838,6.07322807 C3.82595061,6.1553614 3.89999505,6.3625614 3.81786172,6.53678363 C3.75812838,6.66247252 3.63306172,6.73589474 3.50301727,6.73589474 C3.45323949,6.73589474 3.40221727,6.72469474 3.35430616,6.70229474 Z"
          id="Fill-22"
          fill="#FF7F6E"
        />
        <path
          d="M21.2721284,6.53678363 C21.1899951,6.3625614 21.2640395,6.1553614 21.4382617,6.07322807 L24.5929284,4.58051696 C24.7652839,4.49900585 24.9737284,4.57242807 25.0564839,4.74665029 C25.1386173,4.92025029 25.0645728,5.12745029 24.8909728,5.21020585 L21.7356839,6.70229474 C21.6877728,6.72469474 21.6367506,6.73589474 21.5869728,6.73589474 C21.4569284,6.73589474 21.3318617,6.66247252 21.2721284,6.53678363 Z"
          id="Fill-30"
          fill="#FF7F6E"
        />
        <path
          d="M11.6248217,8.72252585 C11.5706884,8.53834807 11.6764662,8.34421474 11.8612662,8.2900814 L13.8249995,7.71514807 C13.4491773,6.88261474 12.6234884,4.80190363 12.6234884,2.56501474 C12.6234884,2.37337029 12.7796662,2.21719252 12.9719328,2.21719252 C13.1641995,2.21719252 13.3203773,2.37337029 13.3203773,2.56501474 C13.3203773,5.2268814 14.6108662,7.74812585 14.6233106,7.77301474 L14.8180662,8.14883696 L12.0566439,8.95897029 C12.0236662,8.9676814 11.9913106,8.97265918 11.9589551,8.97265918 C11.8083773,8.97265918 11.6696217,8.87434807 11.6248217,8.72252585 Z"
          id="Fill-37"
          fill="#FF7F6E"
        />
        <path
          d="M14.3656484,12.9512103 C12.6545373,12.521877 11.2004039,12.8628547 11.1873373,12.8659658 C10.9994262,12.9113881 10.8121373,12.7968992 10.7667151,12.6102325 C10.7212928,12.4241881 10.8351595,12.2356547 11.0218262,12.1902325 C11.0871595,12.1740547 12.6476928,11.8025881 14.5361373,12.2767214 C14.7221817,12.3240103 14.8354262,12.5125436 14.7881373,12.6992103 C14.7489373,12.8572547 14.6070706,12.9624103 14.4508928,12.9624103 C14.4228928,12.9624103 14.3942706,12.958677 14.3656484,12.9512103 Z"
          id="Fill-39"
          fill="#EA5474"
        />
      </g>
      <g id="ALERTA" transform="translate(98.000000, 14.000000)">
        <path
          d="M10.8516583,4.07144476 L18.0620939,15.7884026 C18.3515449,16.2587605 18.2048909,16.8747073 17.734533,17.1641583 C17.5769253,17.2611477 17.3954954,17.3125 17.2104356,17.3125 L2.78956442,17.3125 C2.23727967,17.3125 1.78956442,16.8647847 1.78956442,16.3125 C1.78956442,16.1274402 1.84091676,15.9460103 1.93790611,15.7884026 L9.14834168,4.07144476 C9.4377927,3.60108686 10.0537395,3.45443286 10.5240974,3.74388387 C10.6574179,3.82592722 10.769615,3.93812432 10.8516583,4.07144476 Z"
          id="Triangle"
          fill="#FFFFFF"
        />
        <g
          id="00.Token/Icons/Solid/16x16/alert"
          transform="translate(1.250000, 2.500000)"
          fill="#8C6700"
        >
          <path
            d="M0.854619875,15.0020833 L16.6453801,15.0020833 C17.3015531,15.0020833 17.710596,14.3104167 17.3867704,13.7520833 L9.48712939,0.41875 C9.15478204,-0.139583333 8.33669624,-0.139583333 8.01287061,0.41875 L0.11322962,13.7520833 C-0.210596008,14.3104167 0.198446891,15.0020833 0.854619875,15.0020833 Z M8.77310024,12.5020833 L8.73542149,12.5020833 C8.2751842,12.5020833 7.90208816,12.1289873 7.90208816,11.66875 C7.90208816,11.2085127 8.2751842,10.8354167 8.73542149,10.8354167 L8.77310024,10.8354167 C9.23333753,10.8354167 9.60643357,11.2085127 9.60643357,11.66875 C9.60643357,12.1289873 9.23333753,12.5020833 8.77310024,12.5020833 Z M8.75426086,9.16875 C8.28556587,9.16875 7.90208816,8.79375 7.90208816,8.33541667 L7.90208816,6.66875 C7.90208816,6.21041667 8.28556587,5.83541667 8.75426086,5.83541667 C9.22295585,5.83541667 9.60643357,6.21041667 9.60643357,6.66875 L9.60643357,8.33541667 C9.60643357,8.79375 9.22295585,9.16875 8.75426086,9.16875 Z"
            id="color-icono"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default AlertaApp
