import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Label from '../_commons/label'
import InputCore from '../core'
import AssistText from '../_commons/assistText'
import Icon from '../../icons'
import styles from './_password.scss'

const InputPassword = ({
  value,
  label,
  assistText,
  error,
  success,
  maxLength,
  variant,
  disabled,
  placeholder,
  onlyNumber,
  autoComplete,
  onChange
}) => {
  const [eyeState, setEyeState] = useState('old-eye-close')
  const [mode, setMode] = useState('password')

  useEffect(() => {
    if (eyeState === 'old-eye-close') setMode('password')
    else setMode('text')
  }, [eyeState])

  const switchEye = (state) => {
    if (state === 'old-eye-close') setEyeState('old-eye-open')
    else setEyeState('old-eye-close')
  }

  return (
    <div className={styles.inputContainer}>
      {label && <Label label={label} />}

      <InputCore
        value={value}
        type={mode}
        prefix={<Icon name='old-line-lock' />}
        suffix={
          <Icon
            className={styles.pointer}
            name={eyeState}
            onClick={() => switchEye(eyeState)}
          />
        }
        error={error}
        success={success}
        maxLength={maxLength}
        onlyNumber={onlyNumber}
        variant={variant}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        onChange={onChange}
      />

      {assistText && <AssistText assistText={assistText} />}
    </div>
  )
}

InputPassword.defaultProps = {
  value: '',
  autoComplete: 'on',
  onChange: () => {}
}

InputPassword.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  assistText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.oneOf(['assist', 'success', 'error'])
      })
    ),
    PropTypes.string
  ]),
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onlyNumber: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func
}

export default InputPassword
