const currencyFormat = (number) =>
  new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP'
  }).format(number)

const numberFormat = (number) =>
  new Intl.NumberFormat('es-ES', { style: 'decimal' }).format(number)

export default { currencyFormat, numberFormat }
