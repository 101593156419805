import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import Icon from '../../icons'
import Card from '../../card'
import headerStyle from './_headerProfileItem.scss'

const HeaderProfileItem = ({ name, date, menu, onClick }) => {
  const [open, setOpen] = useState(false)
  const options = { day: 'numeric', year: 'numeric', month: 'short' }
  const circlerContent = name
    .split(' ')
    .reduce((acc, text) => acc.concat(text[0]), [])
    .join('')
    .toUpperCase()

  const handlerOpen = () => {
    if (onClick) onClick()

    if (open || menu.length === 0) return

    setOpen(true)

    const globalClick = () => {
      setOpen(false)
      setTimeout(() => document.removeEventListener('click', globalClick), 10)
    }

    document.addEventListener('click', globalClick)
  }

  return (
    <div
      role='button'
      className={`${headerStyle.profileItemContainer}`}
      tabIndex='0'
      onClick={handlerOpen}
      onKeyDown={handlerOpen}
    >
      <div>
        <div className={headerStyle.circleAvatar}>{circlerContent}</div>
      </div>
      <div className={headerStyle.profileBox}>
        <span className={headerStyle.profileBoxUsername}>{name}</span>
        <p className={headerStyle.date}>
          <small>
            Último acceso {date.toLocaleDateString('es-ES', options)} -{' '}
            {`${date.getHours()}:${date.getMinutes()}`} hrs{' '}
          </small>
        </p>
      </div>
      <Icon name='old-arrow-right' className='px-1' />

      {open && (
        <Card border className={headerStyle.manuDropdown}>
          {menu.map((el) => (
            <div
              role='button'
              key={`header-option${nanoid()}`}
              onClick={el.action}
              onKeyDown={el.action}
              tabIndex='0'
              className={headerStyle.manuDropdownItem}
            >
              {el.text}
            </div>
          ))}
        </Card>
      )}
    </div>
  )
}

HeaderProfileItem.defaultProps = {
  menu: [],
  date: new Date()
}

HeaderProfileItem.propTypes = {
  name: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      action: PropTypes.func.isRequired
    })
  )
}

export default HeaderProfileItem
