import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './_cardTitlePrimary.scss'

const CardTitlePrimary = ({ title, subtitle, level, className }) => (
  <div
    className={classNames(
      styles.cardTitle,
      styles[`cardTitleLevel${level}`],
      className
    )}
  >
    {typeof title === 'string' ? (
      <h1 className={styles.cardTitleTextTitle}>{title}</h1>
    ) : (
      title
    )}
    {subtitle &&
      (typeof subtitle === 'string' ? (
        <h2 className={styles.cardTitleTextSubtitle}>{subtitle}</h2>
      ) : (
        subtitle
      ))}
  </div>
)

CardTitlePrimary.defaultProps = {
  title: '',
  subtitle: '',
  level: 1,
  className: ''
}

CardTitlePrimary.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}

export default CardTitlePrimary
