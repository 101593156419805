import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_vouchercolumndata.scss'
import ArrayUtils from '../../utils/arrayUtils'
import Button  from '../../buttons'

const VoucherColumnData = ({ items }) => {
  const containerClass = [styles.voucherColumnDataContainer]

  const newItems = ArrayUtils.listToMatrix(items, 2)

  return (
    <React.Fragment>
      {newItems.map((nItems, index) => (
        <div className={containerClass.join(' ')} key={`items-${nanoid()}`}>
          <div className={styles.voucherColumnDataSection}>
            {index === 0 && <div className={styles.borderSectionTop} />}
            {nItems.map((item) => (
              <div className={styles.itemColumn} key={`item-${nanoid()}`}>
                <div className={styles.itemColumnRow}>
                <h5 className={styles.titleItem}>
                  {item.title}  
                </h5>
                 { item.button && <Button className={styles.buttonfix} onClick={ item.button.onClick ? item.button.onClick : () => {} } variant="text">{item.button.text}</Button>}
                </div>
                <p className={styles.itemColumnContent}>  {item.content}</p>
              </div>
            ))}
            {/* <div className={styles.borderSectionBottom} /> */}
          </div>
        </div>
      ))}
    </React.Fragment>
  )
}

export default VoucherColumnData

VoucherColumnData.defaultProps = {
  items: [],
  button: ''
}

VoucherColumnData.prototype = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      button: PropTypes.object
    })
  )
}
