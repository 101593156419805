import React, { Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import CardTitlePrimary from './cardHeader/cardTitlePrimary'
import CardRibbons from './cardRibbons'
import styles from './_card.scss'

const Card = ({ title, subtitle, level, className, children, ribbon }) => {
  const cardStyles = [styles.card, styles[`cardLevel${level}`]];

  const findElementInChildren = (el, searchText) => {
    if(typeof el !== 'object') { return -1 }
    return el.type.toString().split('{', el.type.toString().indexOf('{'))[0].indexOf(searchText)
  };

  const getCardChildren = (baseChildren) => {
    if(Array.isArray(baseChildren)){
      return {
        cardChildren: baseChildren.filter(el => findElementInChildren(el, 'CardTitlePrimary') < 0 && findElementInChildren(el, 'CardFooter') < 0),
        cardHeader: baseChildren.find(el => findElementInChildren(el, 'CardTitlePrimary') > 0),
        cardFooter: baseChildren.find(el => findElementInChildren(el, 'CardFooter') > 0),
      }
    }

    return {
      cardChildren: findElementInChildren(baseChildren, 'CardTitlePrimary') < 0 && findElementInChildren(baseChildren, 'CardFooter') < 0 ? baseChildren : '',
      cardHeader: findElementInChildren(baseChildren, 'CardTitlePrimary') > 0 ? baseChildren : '',
      cardFooter: findElementInChildren(baseChildren, 'CardFooter') > 0 ? baseChildren : ''
    }
  }

  return (

    <div style={{ position: 'relative' }}>

{ ribbon &&   <CardRibbons title={ribbon.title} position={ribbon.position} color={ribbon.color}/>
}
    <div className={cardStyles.concat(className).join(' ')}>
      { title || subtitle ?
        <CardTitlePrimary
          subtitle={subtitle}
          title={title}
          level={level}
        />
        :
        getCardChildren(children).cardHeader
      }
      
      <div
        className={classNames(
          styles.cardBody,
          styles[`cardBodyLevel${level}`],
          className
        )}
      >
        {getCardChildren(children).cardChildren}
      </div>

      {getCardChildren(children).cardFooter}
    </div>
    </div>

  )
}

Card.defaultProps = {
  className: ''
}

Card.propTypes = {
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
}

export default Card
