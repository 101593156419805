import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import SidebarElement from './sidebarElement'
import styles from './_sidebar.scss'

const Sidebar = ({ items }) => (
  <div className={[styles.sidebar].join(' ')}>
    {items.map((element) => (
      <SidebarElement key={nanoid()} {...element} />
    ))}
  </div>
)

const levelProps = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  text: PropTypes.string
}

Sidebar.defaultProps = {
  items: []
}

Sidebar.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
      text: PropTypes.string,
      notification: PropTypes.bool,
      open: PropTypes.bool,
      sublevel: PropTypes.arrayOf(
        PropTypes.shape({
          ...levelProps,
          sublevel: PropTypes.arrayOf(
            PropTypes.shape({
              ...levelProps,
              sublevel: []
            })
          )
        })
      )
    })
  )
}

export default Sidebar
