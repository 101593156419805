import React from 'react'
import PropTypes from 'prop-types'
import styles from './_columncard.scss'
import Card from '../card'

const ColumnCard = ({ children, className }) => (
  <Card className={[styles.columnCard, className].join(' ').trim()}>
    {children}
  </Card>
)

ColumnCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default ColumnCard
