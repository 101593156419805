import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars'
import inputStyles from './_phone.scss'
import Label from '../_commons/label'
import InputCore from '../core'
import AssistText from '../_commons/assistText'
import Icon from '../../icons'
import countries from './_contiesList'

const InputPhone = ({
  id,
  label,
  name,
  value,
  assistText,
  error,
  success,
  code,
  maxLength,
  disabled,
  onBlur,
  onChange,
  onCodeChange
}) => {
  const [open, setOpen] = useState(false)
  let validateStyle = []

  if (error) validateStyle = validateStyle.concat(inputStyles.error)
  else if (success) validateStyle = validateStyle.concat(inputStyles.success)

  if (disabled) validateStyle = validateStyle.concat(inputStyles.disabled)

  const handlerOpen = () => {
    if (open || disabled) return

    setOpen(true)

    const globalClick = () => {
      setOpen(false)
      setTimeout(() => document.removeEventListener('click', globalClick), 10)
    }

    document.addEventListener('click', globalClick)
  }

  return (
    <div className={[inputStyles.inputContainer, validateStyle].join(' ')}>
      {label && <Label htmlFor={name} label={label} />}

      <div className={inputStyles.inputPhoneRow}>
        <div
          role='button'
          className={inputStyles.countryCode}
          tabIndex={0}
          onClick={handlerOpen}
          onKeyDown={handlerOpen}
        >
          {`+${code}`}
          <Icon
            className={inputStyles.icon}
            name={open ? 'old-arrow-up' : 'old-arrow-down'}
            size={1}
          />
        </div>

        <InputCore
          id={id}
          name={name}
          value={value}
          maxLength={maxLength}
          onBlur={onBlur}
          disabled={disabled}
          onChange={onChange}
          onlyNumber
        />
        <div className={inputStyles.bellowContainer}>
          {open && (
            <div className={inputStyles.optionContainer}>
              <Scrollbars
                className={inputStyles.scrollBar}
                style={{ height: 240 }}
              >
                {countries.map((el) => (
                  <button
                    type='button'
                    key={el.CODE}
                    className={inputStyles.area}
                    onClick={() => onCodeChange(el.CODE)}
                  >
                    <div className={inputStyles.code}>+{el.CODE}</div> {el.AREA}
                  </button>
                ))}
              </Scrollbars>
            </div>
          )}
        </div>
      </div>

      {assistText && <AssistText assistText={assistText} />}
    </div>
  )
}

InputPhone.defaultProps = {
  id: undefined,
  name: undefined,
  label: '',
  assistText: '',
  error: false,
  success: false,
  code: 56,
  maxLength: undefined,
  disabled: false,
  onBlur: () => {},
  onChange: () => {},
  onCodeChange: () => {}
}

InputPhone.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  assistText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.oneOf(['success', 'assist', 'error'])
      })
    ),
    PropTypes.string
  ]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  code: (props) => {
    let error
    if (props.code === undefined)
      error = new Error(
        'Failed prop type: The prop `code` is marked as required in `InputPhone`, but its value is `undefined`'
      )

    const validCodes = countries.map((c) => c.CODE)
    if (!validCodes.find((arr) => props.code === arr))
      error = new Error(`Invalid \`code\` value it must be valid code area.
       code ${props.code} isn't into [${validCodes.join(', ')}]
      `)

    return error
  },
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onCodeChange: PropTypes.func
}

export default InputPhone
