import React from 'react'
import PropTypes from 'prop-types'
import styles from './_taglink.scss'

const TagLink = ({ label, onClick, className }) => (
  <button
    type='button'
    data-testid='test-taglink'
    className={[styles.tagLink, className].join(' ')}
    onClick={onClick}
  >
    <p>{label}</p>
  </button>
)

TagLink.defaultProps = {
  onClick: /* istanbul ignore next */ () => null,
  label: '',
  className: ''
}

TagLink.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string
}

export default TagLink
