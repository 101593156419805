import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './_bar.scss'

const Bar = ({ min, max, step, value, onChange }) => {
  const [percentage, setPercentage] = useState(0)

  const handleInput = () => {
    let percent = 0

    if (
      parseInt(value, 10) <= parseInt(max, 10) &&
      parseInt(value, 10) > parseInt(min, 10)
    ) {
      percent = parseFloat(((value - min) * 100) / (max - min))
    }

    setPercentage(percent)
  }
  useEffect(() => {
    handleInput()
  }, [value])

  return (
    <div className={styles.rangeWrap}>
      <input
        type='range'
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={onChange}
        onInput={handleInput}
      />
      <div
        className={styles.rangeFilled}
        style={{ width: `calc(${percentage}%)` }}
      />
    </div>
  )
}

Bar.defaultProps = {
  onChange: /* istanbul ignore next */ () => null
}

Bar.propTypes = {
  min: PropTypes.string,
  max: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default Bar
