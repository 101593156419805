import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_voucherdata.scss'

const VoucherData = ({ items }) => (
  <div className={styles.voucherDataContainer}>
    {/* {items.map((section) => ( */}
      <div className={styles.voucherDataSection} key={`section-${nanoid()}`}>
<div className={styles.borderSectionTop} />
        {/* <div>
          <h5 className={styles.titleSection}>{section.title}</h5>
        </div> */}
        <div className={styles.contentSection}>
          
          {items.map((sectionContent) => (
            <div
              className={styles.itemSection}
              key={`sectioncontent-${nanoid()}`}
            >
              <div className={styles.itemPrefix}>
                <p style={{ flex: 1 }}>{sectionContent.label}</p>
              </div>
              <div className={styles.itemSuffix}>
                <p>{sectionContent.content}</p>
              </div>
            </div>
          ))}
        </div>
        {/* {index === 0 && <div className={styles.borderSectionTop} />} */}
        {/* <div className={styles.borderSectionBottom} /> */}
      </div>
    {/* // ))} */}

  </div>
)

export default VoucherData

VoucherData.defaultProps = {
  items: []
}

VoucherData.prototype = {
  // title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      content: PropTypes.string,
      flex: PropTypes.number,
      number: PropTypes.number,
      currency: PropTypes.bool
    })
  )
}
