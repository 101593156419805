import React from 'react'
import PropTypes from 'prop-types'
import cardStyles from './_cardBenefits.scss'

const CardBenefits = ({
  topText,
  title,
  description,
  image,
  style,
  logo,
  className
}) => (
  <figure
    className={[cardStyles.figure, className].join(' ').trim()}
    style={{
      ...style,
      backgroundImage: `url(${image})`
    }}
  >
    <div className={cardStyles.topContent}>
      {logo && <div className={cardStyles.logoContainer}>{logo}</div>}
    </div>
    <figcaption className={cardStyles.content}>
      <p>{topText}</p>
      <h1>{title}</h1>
      <p>{description}</p>
    </figcaption>
  </figure>
)

CardBenefits.propTypes = {
  topText: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  logo: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.string,
  className: PropTypes.string
}

export default CardBenefits
