import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_assistText.scss'
import Icon from '../../../icons'

const AssistText = ({ assistText, disabled }) => {
  const getHelperText = (element) => {
    switch (element.type) {
      case 'success':
        return (
          <div
            className={styles.successText}
            key={`${element.text}-${nanoid()}`}
          >
            <Icon data-testid='successIcon' name='old-check' size={1} />
            <span className={styles.assistSpan}>{element.text}</span>
          </div>
        )
      case 'error':
        return (
          <div className={styles.errorText} key={`${element.text}-${nanoid()}`}>
            <Icon data-testid='errorIcon' name='old-cross' size={1} />
            <span className={styles.assistSpan}>{element.text}</span>
          </div>
        )
      case 'assist':
        return (
          <div key={`${element.text}-${nanoid()}`}>
            <span className={styles.assistSpan}>{element.text}</span>
          </div>
        )
      default:
        return <div> </div>
    }
  }

  return (
    <div className={`${styles.assistText} ${disabled ? styles.disabled : ''}`}>
      {Array.isArray(assistText) && assistText.length > 0
        ? assistText.map((el) => getHelperText(el))
        : assistText}
    </div>
  )
}

AssistText.defaultProps = {
  assistText: [],
  disabled: false
}

AssistText.propTypes = {
  assistText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.oneOf(['assist', 'success', 'error'])
      })
    ),
    PropTypes.string,
    PropTypes.element
  ]),
  disabled: PropTypes.bool
}

export default AssistText
