import React from 'react'
import PropTypes from 'prop-types'
import styles from './_switch.scss'

const Switch = ({ status, disabled, label, onChange }) => {
  let switchClass = [styles.switchBar]
  const containerClass = [
    styles.switchContainer,
    disabled ? styles.disabled : null
  ]

  if (status) switchClass = switchClass.concat(styles.active)

  const handleClick = () => {
    onChange(!status)
  }

  return (
    <section className='d-flex align-items-center'>
      {label && label !== '' && (
        <label htmlFor="">{label}</label>
      )}
      <div 
        data-status={status ? 'on' : 'off'}
        className={containerClass.join(' ')}
      >
        <div
          aria-hidden='true'
          onClick={handleClick}
          className={switchClass.join(' ')}
        >
          <div className={styles.circle} />
        </div>
      </div>
    </section>

  )
}

Switch.defaultProps = {
  status: false,
  disabled: false,
  onChange: () => {}
}

Switch.propTypes = {
  status: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default Switch
