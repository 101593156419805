import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_progressbar.scss'

const ProgressBar = ({
  label,
  progress,
  assistText,
  percent,
  helperAlign,
  reverse,
  className
}) => {
  const alignment = assistText && percent ? styles.between : styles[helperAlign]
  const direction = reverse ? styles.reverse : null
  const bellowClasses = [styles.bellowContent, alignment, direction]

  return (
    <div className={[styles.progressBar, className].join(' ')}>
      {label &&
        (!Array.isArray(label) ? (
          <div className={styles.simpleLabel}>{label}</div>
        ) : (
          <div className={styles.multiHeader}>
            {label.map((el) => (
              <div key={nanoid()}>
                <label className={styles.multiHeaderLabel} htmlFor={label}>
                  {el.label}
                </label>
                <div className={styles.multiHeaderHeading}>{el.text}</div>
              </div>
            ))}
          </div>
        ))}
      <div className={styles.barContainer}>
        <div
          className={styles.bar}
          data-progress={progress}
          style={{ width: `${progress}%` }}
        />
      </div>
      {assistText || percent ? (
        <div className={bellowClasses.join(' ').trim()}>
          {assistText}
          {percent && (
            <div
              className={Number(progress) === 100 ? styles.full : null}
            >{`${progress}%`}</div>
          )}
        </div>
      ) : null}
    </div>
  )
}

ProgressBar.defaultProps = {
  label: '',
  assistText: '',
  progress: 0,
  percent: false,
  helperAlign: 'start',
  reverse: false
}

ProgressBar.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        text: PropTypes.string
      })
    )
  ]),
  assistText: PropTypes.string,
  progress: PropTypes.number,
  percent: PropTypes.bool,
  reverse: PropTypes.bool,
  helperAlign: PropTypes.oneOf(['start', 'between', 'end'])
}

export default ProgressBar
