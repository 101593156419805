import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Input from '..'
import Bar from '../bar'

import AssistText from '../_commons/assistText'


const InputRange = ({
  placeholder,
  label,
  min,
  max,
  step,
  onChange,
  value,
  assistText,
  error


}) => (
  <React.Fragment>
    <Input
      label={label}
      type='text'
      placeholder={placeholder}
      value={value}
      name='holi'
      error={error}

    />

  {assistText && <AssistText assistText={assistText} />}

    <Bar
      type='range'
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
    />
  </React.Fragment>
)

InputRange.defaultProps = {
  onChange: () => {}
}

InputRange.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  error: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  assistText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.oneOf(['assist', 'success', 'error'])
      })
    ),
    PropTypes.string
  ])
}

export default InputRange
