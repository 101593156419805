import React from 'react'
import PropTypes from 'prop-types'
import styles from './_cardimage.scss'

const CardImage = ({ imgUrl, alt, className }) => {
  const cardImage = [styles.imgCard]

  return (
    <img
      src={imgUrl}
      className={cardImage.concat(className).join(' ')}
      role='presentation'
      alt={alt}
    />
  )
}

CardImage.defaultProps = {
  imgUrl: '',
  alt: '',
  className: ''
}

CardImage.propTypes = {
  imgUrl: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string
}

export default CardImage
