/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import React, { Fragment, CSSProperties } from 'react'
import classNames from 'classnames'
import ExternalSelectAutocomplete from 'react-select'

// import PropTypes from 'prop-types'
import { ArrayUtils } from '../utils'
import styles from './_selectAutocomplete.scss'
import AssistText from '../input/_commons/assistText'
import Icon from '../icons'


const SelectAutocomplete = ({
  options,
  selectedValue,
  width,
  placeholder,
  className,
  onChange,
  noOptionsMessage,
  keyPar,
  label,
  assistText,
  ...props
}) => {
  const style: { [key: string]: CSSProperties } = {
    label: {
      fontSize: '14px',
      fontWeight: 'bold',
      lineHeight: '20px',
      marginBottom: '4px'
    }
  }
  const customTheme = (theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary50: 'rgba(0,161,223,0.25)',
      neutral0: 'white',
    },
  });
  const customStyles = {
    control: () => ({
      // none of react-select's styles are passed to <Control />
      border: '1px solid #646569',
      // padding: '12px 8px',
      borderRadius: '4px',
      display: 'flex',
      fontSize: '14px',
      height: '48px',
      boxSizing: 'border-box',
      width,
      '&:hover': {
        borderColor: '#007DB7',
        cursor: 'pointer'
      }
    }),
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width || '100%',
      zIndex: '9',
      boxShadow: '0px 1px 5px 0px rgb(30, 30, 30, 0.35)',
      paddingTop: 0,
    }),
    menuList: (provided) => ({
      ...provided,
      paddingTop: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '#E7E7E7 0.5px solid',
      color: state.isDisabled ? '#9c9a9f' : '#383838',
      fontSize: '14px',
      backgroundColor: state.isSelected
        ? 'rgba(0,161,223,0.25)'
        : state.isFocused
        ? '#EFFAFF'
        : 'transparent',
      padding: '14px 16px'
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: '14px',
      fontColor: '#9c9a9f',
      fontWeight: 400,
      padding: 0,
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#007db7',
      borderRadius: '24px',
      color: '#ffffff',
      height: '32px',
      display: 'flex',
      flexDirection: 'row',
      alignItems:'center',
         '&:hover': {
        backgroundColor: '#005D8F',
        cursor: 'pointer'
      }
    }),
    multiValueLabel: (base) => ({
      ...base,

      color: '#ffffff',
      fontSize: '14px',
      padding: '6px 12px',
      paddingLeft: '12px'
      

    }),

    multiValueRemove: (base) =>({
      ...base,
      backgroundColor: '#ffffff',
      borderRadius: '100px',
      padding: '4px',
      margin:'6px',
      color: '#007db7',
      '&:hover': {
        color: '#005D8F',
        backgroundColor: '#ffffff',
        cursor: 'pointer'
      }


}),


    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  }
  const handleChange = (item) => {
    if (!keyPar) onChange(item)
    else {
      if (!props.isMulti) {
        if (!item) onChange(null)
        else {
          const { value, label, ...itemClone } = {
            ...item,
            [keyPar[0]]: item.value,
            [keyPar[1]]: item.label
          }
          onChange(itemClone)
        }
      } else {
        onChange(
          ArrayUtils.changeNameKeysInArrayItems(item, [
            ['value', keyPar[0]],
            ['label', keyPar[1]]
          ])
        )
      }
    }
  }

  const CustomOption = ({
    label,
    // value,
    icon,
    innerRef,
    innerProps
  }) => (
    <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
      { icon && 
        <Icon name={icon} className="pr-2" size={2} />
      }
      {label}
    </div>
  )

  const getOptions = (opts) => {
    if (!keyPar) {
      const isCorrectStructure = opts.every((el) => el.value && el.label)
      if (isCorrectStructure) return opts
      return [
        {
          value: 'error',
          label: 'Ingrese parámetro keyPar o corrija estructura de archivo'
        }
      ]
    }
    const isIncorrectKeyPar = opts.some(
      (el) => !(el[keyPar[0]] && el[keyPar[1]])
    )
    if (!isIncorrectKeyPar)
      return ArrayUtils.changeNameKeysInArrayItems(opts, [
        [keyPar[0], 'value'],
        [keyPar[1], 'label']
      ])
    return [{ value: 'error', label: 'Alguna cadena keyPar está incorrecta' }]
  }

  const getArrayOptionsSelected = (arrayValues) => {
    const keyNameValue = keyPar ? keyPar[0] : 'value'
    const objectValues = options.filter((item) =>
      arrayValues.some((val) => val === item[keyNameValue])
    )
    if (!keyPar) return objectValues
    return ArrayUtils.changeNameKeysInArrayItems(objectValues, [
      [keyPar[0], 'value'],
      [keyPar[1], 'label']
    ])
  }

  return (
    <>
      {label && label !== '' && (
        <label
          style={style.label}
          id='aria-label-select-autocomplete'
          htmlFor={`aria-${className}-input`}
        >
          {label}
        </label>
      )}
      <ExternalSelectAutocomplete
        options={getOptions(options)}
        defaultValue={
          selectedValue ? getArrayOptionsSelected(selectedValue) : selectedValue
        }
        styles={customStyles}
        placeholder={placeholder}
        className={classNames(className, styles.selectAutocomplete)}
        classNamePrefix={className}
        onChange={handleChange}
        noOptionsMessage={noOptionsMessage}
        isMulti={props.isMulti}
        isDisabled={props.isDisabled}
        isLoading={props.isLoading}
        isClearable={props.isClearable}
        isSearchable={
          props.isSearchable !== undefined ? props.isSearchable : true
        }
        inputId={`aria-${className}-input`}
        aria-labelledby="aria-label-select-autocomplete"
        width={width}
        theme={customTheme}
        formatOptionLabel={CustomOption}
      />
      {assistText && <AssistText assistText={assistText} disabled={props.isDisabled} />}
    </>
  )
}

/* InputSelectAutocomplete.defaultProps = {
}

InputSelectAutocomplete.PropTypes = {
} */

export default SelectAutocomplete
