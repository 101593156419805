import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_cardbank.scss'
import Card from '../index'
import IconCard from '../../icons/iconCards'
import Button from '../../buttons'

const CardBank = ({
  cardType,
  cardNumber,
  buttons,
  title,
  children,
  className
}) => {
  let cardStyle = [styles.card]
  cardStyle = cardStyle.concat(styles.border)

  let cardHeader = styles.header

  if (cardType) {
    if (cardType === 'default') {
      cardHeader = [cardHeader, styles.default]
    }
    if (cardType === 'debit') {
      cardHeader = [cardHeader, styles.debit]
    }
    if (cardType === 'gold') {
      cardHeader = [cardHeader, styles.gold]
    }
    if (cardType === 'international') {
      cardHeader = [cardHeader, styles.international]
    }
  }

  const getButtons = () => (
    <React.Fragment>
      {buttons.map((item) => (
        <Button
          key={`button-${nanoid()}`}
          style={{ flex: 1, justifyContent: 'center' }}
          prefix={item.prefix}
          onClick={item.onClick}
          variant='text'
        >
          {item.label}
        </Button>
      ))}
    </React.Fragment>
  )

  return (
    <Card className={cardStyle.concat(className).join(' ')}>
      <div className={cardHeader.join(' ')}>
        <div className={styles.prefix}>
          <div>
            <p className={styles.title}>{title}</p>
          </div>
          {cardType !== 'default' && cardType && (
            <div className={styles.titleNumberCard}>
              <p>{cardNumber}</p>
            </div>
          )}
        </div>
        {cardType !== 'default' && cardType && (
          <div className={styles.cardIconSuffix}>
            <IconCard variant='mastercard' />
          </div>
        )}
      </div>
      <div className={styles.content}>{children}</div>
      {buttons && <div className={styles.actions}>{getButtons()}</div>}
    </Card>
  )
}

CardBank.defaultProps = {
  cardType: 'default',
  cardNumber: '',
  className: '',
  buttons: [],
  title: ''
}

CardBank.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      prefix: PropTypes.string,
      suffix: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool
    })
  ),
  children: PropTypes.node,
  cardNumber: PropTypes.string,
  className: PropTypes.string,
  cardType: PropTypes.string,
  title: PropTypes.string
}

export default CardBank
