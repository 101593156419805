import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './_chips.scss'
import Icon from '../icons'

function Chips({
    label, 
    disabled, 
    variant, 
    closed, 
    onClickClose
}) {
    const chipStyles = classNames(
        styles.chip,
        {
            [styles[`chip-${variant}`]]: variant !== '',
            
        },
        disabled ? styles[`disabled`] : '',
        classNames
    )


    const clickHandler = () => {
       if (!disabled)  onClickClose(!closed)
    }

    return (
        <>
        { !closed &&
        <div className={chipStyles} disabled={disabled}>
            <div className={styles.label}> {label} </div>
            <div className={styles.action} >
                <Icon
                    name="old-cross"
                    onClick={clickHandler}
                    size={4} />
            </div>
        </div>
        }
        </>
    )
}

Chips.defaultProps = {
  label: '',
  variant: 'text',
  disabled: false,
  onClickClose: undefined
  
}

Chips.propTypes = {
  label:PropTypes.string,
  variant: PropTypes.oneOf(['outline', 'text']),
  disabled: PropTypes.bool,
  onClickClose: PropTypes.func,
  closed: PropTypes.bool
}

export default Chips
