import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './_voucher.scss'
import Card from '../card'
import Stamp from '../stamp'

const Voucher = ({ children, stampdirection }) => {
  const cardContainer = [styles.cardContainer, styles.card]


  const handleResize = (e) => {console.log(e)
    if(e < 740 ){
      setfirst("80px")
    } else{
      setfirst("114px")
    }
    }
  
    const [first, setfirst] = useState("114px")
  
    useEffect(() => {
      window.removeEventListener('resize', e => handleResize(e.target.innerWidth))
      window.addEventListener('resize', e => handleResize(e.target.innerWidth))
      handleResize(window.innerWidth)
    }, [])

  let svgContainer = [styles.stamp]

  if (stampdirection === 'top') {
    svgContainer = [svgContainer, styles.top]
  } else if (stampdirection === 'bottom') {
    svgContainer = [svgContainer, styles.bottom]
  }

  const containerClass = [styles.voucherContainer]

  return (
    <div className={containerClass.join(' ')}>
      <Card border className={cardContainer.join(' ')} level="1">
        <div data-placement={stampdirection} className={svgContainer.join(' ')}>
          <Stamp type="check" width={first} height={first} />
        </div>
        <div>
        {children}
        </div>
      </Card>
    </div>
  )
}

Voucher.defaultProps = {
  /** Some description here */
  stampdirection: 'bottom'
}

Voucher.prototype = {
  /** Some description here */
  stampdirection: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string
}

export default Voucher
