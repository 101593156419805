import React from 'react'

const Nube = () => {
  const elm = (
    <svg
      width='155px'
      height='71px'
      viewBox='0 0 155 71'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>EmptyState</title>
      <g stroke='none' stroke-width='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-813.000000, -453.000000)'>
          <g transform='translate(758.000000, 430.000000)'>
            <g transform='translate(55.000000, 24.000000)'>
              <path
                d='M85.3162768,14 C88.0725868,14 90.3070175,16.2385763 90.3070175,19 C90.3070175,21.7614237 88.0725868,24 85.3162768,24 L130.945906,24 C133.702216,24 135.936647,26.2385763 135.936647,29 C135.936647,31.7614237 133.702216,34 130.945906,34 L146.631092,34 C149.387402,34 151.621832,36.2385763 151.621832,39 C151.621832,41.7614237 149.387402,44 146.631092,44 L133.084795,44 C130.328485,44 128.094055,46.2385763 128.094055,49 C128.094055,51.7614237 130.328485,54 133.084795,54 L137.362573,54 C140.118883,54 142.353314,56.2385763 142.353314,59 C142.353314,61.7614237 140.118883,64 137.362573,64 L33.2699805,64 C30.5136705,64 28.2792398,61.7614237 28.2792398,59 C28.2792398,56.2385763 30.5136705,54 33.2699805,54 L5.46442495,54 C2.70811495,54 0.473684211,51.7614237 0.473684211,49 C0.473684211,46.2385763 2.70811495,44 5.46442495,44 L33.9829435,44 C36.7392535,44 38.9736842,41.7614237 38.9736842,39 C38.9736842,36.2385763 36.7392535,34 33.9829435,34 L16.1588694,34 C13.4025594,34 11.1681287,31.7614237 11.1681287,29 C11.1681287,26.2385763 13.4025594,24 16.1588694,24 L44.6773879,24 C41.9210779,24 39.6866472,21.7614237 39.6866472,19 C39.6866472,16.2385763 41.9210779,14 44.6773879,14 L85.3162768,14 Z M149.482943,54 C152.239253,54 154.473684,56.2385763 154.473684,59 C154.473684,61.7614237 152.239253,64 149.482943,64 C146.726633,64 144.492203,61.7614237 144.492203,59 C144.492203,56.2385763 146.726633,54 149.482943,54 Z'
                fill='#F3F7FF'
              ></path>
              <path
                d='M53.2135216,68.6467066 C44.5206494,68.6467066 37.4736842,61.8451881 37.4736842,53.4550898 C37.4736842,45.0649916 44.5206494,38.2634731 53.2135216,38.2634731 C53.5831767,38.2634731 53.9498555,38.2757721 54.3131428,38.2999694 C54.0607294,36.912337 53.9289688,35.4833412 53.9289688,34.0239521 C53.9289688,20.7558897 64.8197332,10 78.254172,10 C88.9794712,10 98.0835529,16.8552945 101.319197,26.3712623 C102.085822,26.2920972 102.864303,26.251497 103.652546,26.251497 C115.704028,26.251497 125.473684,35.7419879 125.473684,47.4491018 C125.473684,58.5712086 116.656017,67.7474597 105.441164,68.6312922 L105.441164,68.6467066 L65.0239891,68.6467066 M61.7960054,68.6467066 L56.8132024,68.6467066'
                stroke='#75A4FE'
                stroke-width='2.5'
                fill='#FFFFFF'
                stroke-linecap='round'
              ></path>
              <circle
                stroke='#75A4FE'
                stroke-width='2.5'
                cx='78.9736842'
                cy='54.5'
                r='3.5'
              ></circle>
              <polyline
                stroke='#75A4FE'
                stroke-width='2.5'
                stroke-linecap='round'
                stroke-linejoin='round'
                points='67.4736842 46 72.4736842 42.4138547 67.4736842 39'
              ></polyline>
              <polyline
                stroke='#75A4FE'
                stroke-width='2.5'
                stroke-linecap='round'
                stroke-linejoin='round'
                transform='translate(86.973684, 42.500000) scale(-1, 1) translate(-86.973684, -42.500000) '
                points='84.4736842 46 89.4736842 42.4138547 84.4736842 39'
              ></polyline>
              <path
                d='M95.4736842,16 C90.6308623,17.295835 86.8070492,21.1250757 85.4736842,26'
                stroke='#A4C3FE'
                stroke-width='2.5'
                stroke-linecap='round'
                transform='translate(90.473684, 21.000000) scale(-1, 1) translate(-90.473684, -21.000000) '
              ></path>
              <circle
                stroke='#A4C3FE'
                stroke-width='2'
                cx='100.973684'
                cy='2.5'
                r='2.5'
              ></circle>
              <circle fill='#A4C3FE' cx='121.973684' cy='13.5' r='2.5'></circle>
              <path
                d='M112.061058,10.4126264 L106.886311,15.5873736 M106.886311,10.4126264 L112.061058,15.5873736'
                stroke='#A4C3FE'
                stroke-width='2.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></path>
              <path
                d='M43.4736842,22 L38.4736842,27 M38.4736842,22 L43.4736842,27'
                stroke='#A4C3FE'
                stroke-width='2.5'
                stroke-linecap='round'
                stroke-linejoin='round'
              ></path>
              <circle fill='#A4C3FE' cx='48.9736842' cy='15.5' r='2.5'></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default Nube
