import React from 'react'

const IconTodoStep = () => {
  const elm = (
    <svg
      width='22px'
      height='22px'
      viewBox='0 0 22 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <title>Oval Copy</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(-1.000000, -1.000000)'
          stroke='#D4D4D4'
          strokeWidth='2'
        >
          <circle cx='12' cy='12' r='10'></circle>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default IconTodoStep
