import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from '../_publicFooterBottomBar.scss'

const PublicFooterBottomBarColumn = ({ children, fixedWidth, className }) => {
  const [componentClasses, setComponentClasses] = useState([styles.column])

  useEffect(() => {
    if (fixedWidth) {
      setComponentClasses([styles.column, styles.fixedWidth])
    }
  }, [fixedWidth])

  return (
    <div className={componentClasses.concat(className).join(' ')}>
      {children}
    </div>
  )
}

PublicFooterBottomBarColumn.defaultProps = {}

PublicFooterBottomBarColumn.propTypes = {
  className: PropTypes.string
}

export default PublicFooterBottomBarColumn
