const Functions = {
  makeStyles: (style, baseStyle = []) => {
    const newStyle = [...baseStyle]
    newStyle.push(style)

    return newStyle
  }
}

export default Functions
