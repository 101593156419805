import React from 'react'

const IconDoneStep = () => {
  const elm = (
    <svg
      width='22px'
      height='22px'
      viewBox='0 0 22 22'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          transform='translate(-1.000000, -1.000000)'
          stroke='var(--colorStepper)'
          strokeWidth='2'
        >
          <g transform='translate(1.000000, 1.000000)'>
            <circle fill='#FFFFFF' cx='11' cy='11' r='10'></circle>
            <polyline
              strokeLinecap='round'
              strokeLinejoin='round'
              points='6 11.4609131 9.76954345 15.2304565 16.3879815 8.61201854'
            ></polyline>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}
export default IconDoneStep
