import React from 'react'
import PropTypes from 'prop-types'
import Input from '..'
import AssistText from '../_commons/assistText'
import styles from './_coordinate.scss'

const InputCoordinate = ({ coordinates, value, assistText, onChange }) => {
  const handleChangeInputValue = (e, idx) => {
    const _value = e.target.value

    const newValue = [
      ...value.slice(0, idx),
      _value,
      ...value.slice(idx + 1, value.length)
    ]
    onChange(newValue)
  }

  return (
    <div className={styles.inputCoordinate}>
      <div className={styles.containerInputs}>
        <Input
          type='password'
          label={coordinates[0].toUpperCase()}
          value={value[0]}
          maxLength='2'
          onChange={(e) => handleChangeInputValue(e, 0)}
        />
        <Input
          type='password'
          label={coordinates[1].toUpperCase()}
          value={value[1]}
          maxLength='2'
          onChange={(e) => handleChangeInputValue(e, 1)}
        />
        <Input
          type='password'
          label={coordinates[2].toUpperCase()}
          value={value[2]}
          maxLength='2'
          onChange={(e) => handleChangeInputValue(e, 2)}
        />
      </div>
      {assistText && <AssistText assistText={assistText} />}
    </div>
  )
}

InputCoordinate.defaultProps = {
  value: ['', '', ''],
  onChange: () => null
}

InputCoordinate.propTypes = {
  coordinates: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  assistText: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.oneOf(['success', 'assist', 'error'])
      })
    ),
    PropTypes.string
  ]),
  onChange: PropTypes.func
}

export default InputCoordinate
