import * as React from "react"

const ExitoApp = (props) => (
  <svg
    {...props}
    width="140px"
    height="128px"
    viewBox="0 0 140 128"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <polygon
        id="path-1"
        points="0 0 51.9112533 0 51.9112533 35.1673778 0 35.1673778"
      />
    </defs>
    <g
      id="Mol\xE9cula-app-ilustracion-exito"
      stroke="none"
      strokeWidth={1}
      fill="none"
      fillRule="evenodd"
    >
      <g id="EXITO-APP" transform="translate(14.000000, 8.000000)">
        <g>
          <g id="ROSTRO-Y-CUERPO">
            <path
              d="M112,56 C112,86.9281778 86.9281778,112 56,112 C25.0718222,112 0,86.9281778 0,56 C0,25.0718222 25.0718222,0 56,0 C86.9281778,0 112,25.0718222 112,56"
              id="Fill-1"
              fill="#D3F0C5"
            />
            <path
              d="M73.3097867,51.5745689 C72.51272,51.3020356 72.1350311,50.4303022 72.4579644,49.6519022 C72.4604533,49.6463022 72.4629422,49.6413244 72.4648089,49.6357244 C73.9761867,45.9652356 72.9296089,41.73848 69.8800978,39.1973244 L66.6800089,36.53048 L56.9677422,37.22488 L56.9677422,55.2606133 L59.7428533,55.2606133 L59.7428533,56.7228356 C59.7428533,61.4025689 62.4009867,65.7823911 66.6992978,67.6322578 C73.0484533,70.3644356 79.1667644,65.7817689 79.1667644,59.7698578 C79.1667644,55.9674578 76.7164533,52.74248 73.3097867,51.5745689"
              id="Fill-3"
              fill="#702C7E"
            />
            <path
              d="M53.6174489,37.2245689 L43.9058044,36.5307911 L40.7050933,39.1976356 C37.6562044,41.7387911 36.6096267,45.9655467 38.1210044,49.6354133 C38.1259822,49.6466133 38.1297156,49.6578133 38.1346933,49.6690133 C38.4582489,50.4443022 38.0699822,51.3029689 37.2754044,51.57488 C32.8215378,53.1024356 30.0034933,58.1443022 32.1576267,63.3977244 C33.4275822,66.4963911 36.5380711,68.4414578 39.8868711,68.4414578 L40.0710489,68.4414578 C46.0275822,68.4414578 50.84296,63.6273244 50.84296,57.6888356 L50.84296,55.2603022 L53.6174489,55.2603022 L53.6174489,37.2245689 Z"
              id="Fill-5"
              fill="#702C7E"
            />
            <path
              d="M68.0671289,35.1111911 L43.4408178,35.1111911 L43.4408178,26.9967911 C43.4408178,21.9001689 47.5723733,17.7686133 52.6696178,17.7686133 L58.8389511,17.7686133 C63.9355733,17.7686133 68.0671289,21.9001689 68.0671289,26.9967911 L68.0671289,35.1111911 Z"
              id="Fill-7"
              fill="#702C7E"
            />
            <path
              d="M56.2521867,48.5716 L55.5465867,48.5716 C51.1437422,48.5716 47.5752978,45.0025333 47.5752978,40.5990667 L47.5752978,24.1885778 C47.5752978,21.9473333 49.3928089,20.1292 51.6340533,20.1292 L59.0136089,20.1292 C61.8913867,20.1292 64.2240978,22.4619111 64.2240978,25.3390667 L64.2240978,40.5990667 C64.2240978,45.0025333 60.6544089,48.5716 56.2521867,48.5716"
              id="Fill-9"
              fill="#FFB3A5"
            />
            <path
              d="M66.4817689,25.1586844 L64.5012356,25.5805511 L63.3899467,19.18784 L53.9657689,18.4946844 C48.0652356,18.4946844 43.3195467,22.6536178 43.4508356,28.5516622 L43.5591022,33.4093511 L47.3745689,35.1434844 L49.45528,25.4312178 C49.45528,25.4312178 54.8866578,29.5609067 62.4746578,28.2548622 L64.0233689,34.4497067 L66.52408,33.7559289 C66.8563467,30.2441067 66.7679911,27.40864 66.4817689,25.1586844"
              id="Fill-11"
              fill="#702C7E"
            />
            <path
              d="M63.2296622,40.4967111 L68.5894844,37.3482667 C69.7741956,36.6526222 70.1699289,35.1294222 69.4749067,33.9453333 C68.7792622,32.7606222 67.25544,32.3648889 66.0713511,33.0605333 L60.7109067,36.2083556 L63.2296622,40.4967111 Z"
              id="Fill-13"
              fill="#FFB3A5"
            />
            <path
              d="M66.23568,38.98608 C66.23568,39.5610133 65.7696356,40.0270578 65.1947022,40.0270578 C64.6203911,40.0270578 64.1543467,39.5610133 64.1543467,38.98608 C64.1543467,38.4117689 64.6203911,37.9457244 65.1947022,37.9457244 C65.7696356,37.9457244 66.23568,38.4117689 66.23568,38.98608"
              id="Fill-17"
              fill="#FFFFFF"
            />
            <path
              d="M48.5691733,40.4967111 L43.2087289,37.3482667 C42.02464,36.6526222 41.6289067,35.1294222 42.3239289,33.9453333 C43.0195733,32.7606222 44.5427733,32.3648889 45.7274844,33.0605333 L51.0879289,36.2083556 L48.5691733,40.4967111 Z"
              id="Fill-19"
              fill="#FFB3A5"
            />
            <path
              d="M47.6534489,38.9587644 C47.6534489,39.5336978 47.1874044,39.99912 46.6124711,39.99912 C46.03816,39.99912 45.5721156,39.5336978 45.5721156,38.9587644 C45.5721156,38.3838311 46.03816,37.9177867 46.6124711,37.9177867 C47.1874044,37.9177867 47.6534489,38.3838311 47.6534489,38.9587644"
              id="Fill-23"
              fill="#FFFFFF"
            />
            <g id="Group-31" transform="translate(66.517547, 31.543804)">
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1" />
              </mask>
              <g id="Clip-30" />
              <path
                d="M25.8253333,22.0608889 L0,22.2973333 L3.28471111,35.1673778 L25.2199111,32.6075556 C27.3491556,32.3592889 29.3396444,31.4253333 30.8927111,29.9463111 L41.8575111,19.4749333 C45.6263111,15.8753778 48.9968889,11.8807111 51.9113778,7.55937778 L50.5480889,0 L44.9424889,1.16355556 L45.6512,4.24977778 L42.2152889,5.79786667 L43.6457778,10.3021333 L25.8253333,22.0608889 Z"
                id="Fill-29"
                fill="#FFB3A5"
                mask="url(#mask-2)"
              />
            </g>
            <polygon
              id="Fill-32"
              fill="#FF7F6E"
              points="113.807742 40.4693333 111.928009 34.8643556 112.543387 34.6577778 114.20472 39.6144 117.739564 38.2106667 117.978498 38.8129778"
            />
            <path
              d="M68.4298844,53.8246489 L68.4311289,53.8265156 L66.9863289,53.8408267 L43.4706844,53.8408267 C34.8429511,53.8408267 27.8497956,60.8346044 27.8497956,69.4617156 L27.8497956,90.4449156 L34.8441956,90.4449156 L34.8441956,107.861538 C41.3725511,110.527138 48.5131733,111.999938 55.9997511,111.999938 C62.1591289,111.999938 68.0845511,111.003138 73.6279289,109.165716 L73.5296178,106.759582 C73.0903289,95.8937156 73.3292622,85.0110489 74.24704,74.1756711 L74.8033067,67.5962933 L83.8603733,66.5391378 L83.8603733,53.6715822 L68.4311289,53.8265156 L68.4298844,53.8246489 Z"
              id="Fill-44"
              fill="#0E891A"
            />
            <path
              d="M69.1413956,53.9133778 C68.6517067,53.8673333 68.1564178,53.8412 67.6542844,53.8412 L43.4703733,53.8412 C42.9688622,53.8412 42.4735733,53.8673333 41.9838844,53.9133778 C43.2973956,60.2096444 48.87624,64.9404 55.56264,64.9404 C62.24904,64.9404 67.8278844,60.2096444 69.1413956,53.9133778"
              id="Fill-46"
              fill="#FFB3A5"
            />
            <path
              d="M65.7155644,89.69968 L56.0574311,89.69968 C55.2416978,89.69968 54.5808978,89.03888 54.5808978,88.2243911 L54.5808978,69.39408 C54.5808978,68.5795911 55.2416978,67.9181689 56.0574311,67.9181689 L65.7155644,67.9181689 C66.5312978,67.9181689 67.1914756,68.5795911 67.1914756,69.39408 L67.1914756,88.2243911 C67.1914756,89.03888 66.5312978,89.69968 65.7155644,89.69968"
              id="Fill-48"
              fill="#F6F3F5"
            />
            <path
              d="M58.0841956,70.3700356 C58.0841956,70.9511911 57.6131733,71.4209689 57.0332622,71.4209689 C56.4527289,71.4209689 55.9823289,70.9511911 55.9823289,70.3700356 C55.9823289,69.7895022 56.4527289,69.3197244 57.0332622,69.3197244 C57.6131733,69.3197244 58.0841956,69.7895022 58.0841956,70.3700356"
              id="Fill-50"
              fill="#D4D0D5"
            />
            <path
              d="M34.4324711,104.708364 C30.9194044,104.433342 27.8499822,101.528809 27.8499822,97.5957422 L27.8499822,93.58552 L27.8499822,81.1286311 L27.8499822,74.7720089 L38.3599378,74.7720089 L38.3599378,90.2497867 L51.9044711,79.7236533 L60.1290044,79.4803644 L60.73256,84.9092533 L53.2055378,86.9401867 L39.3791378,102.633876 C38.2323822,103.935564 36.5915822,104.750676 34.8562044,104.728276 C34.7174489,104.726409 34.5762044,104.720187 34.4324711,104.708364"
              id="Fill-52"
              fill="#FFB3A5"
            />
            <polygon
              id="Fill-54"
              fill="#FFB3A5"
              points="61.4486756 57.3155644 50.3494756 57.3155644 52.4308089 44.8225867 59.3679644 44.8225867"
            />
          </g>
          <g id="EXPRESIONES" transform="translate(43.394453, 30.248807)">
            <path
              d="M21.5177604,6.62395746 C21.3883382,6.62395746 21.2638937,6.55115746 21.2041604,6.42609079 C21.1220271,6.25249079 21.1960715,6.04529079 21.3696715,5.96315746 L24.5143826,4.47604635 C24.6861159,4.39453524 24.8945604,4.46733524 24.9773159,4.64093524 C25.0588271,4.81453524 24.9847826,5.02173524 24.8118048,5.10324635 L21.6658493,6.59097968 C21.6179382,6.61337968 21.5681604,6.62395746 21.5177604,6.62395746"
              id="Fill-15"
              fill="#FF7F6E"
            />
            <path
              d="M3.49154705,6.62395746 C3.44176928,6.62395746 3.39136928,6.61337968 3.34345817,6.59097968 L0.198747054,5.10324635 C0.0251470542,5.02173524 -0.048275168,4.81453524 0.0332359431,4.64093524 C0.115991499,4.46733524 0.322569276,4.39515746 0.495547054,4.47604635 L3.63963594,5.96315746 C3.81385817,6.04529079 3.88728039,6.25249079 3.80514705,6.42609079 C3.74541372,6.55115746 3.62096928,6.62395746 3.49154705,6.62395746"
              id="Fill-21"
              fill="#FF7F6E"
            />
            <path
              d="M12.5046848,14.2273263 C14.2288626,14.2273263 15.6269959,12.8298152 15.6269959,11.1050152 L9.38299594,11.1050152 C9.38299594,12.8298152 10.7805071,14.2273263 12.5046848,14.2273263"
              id="Fill-25"
              fill="#1F1E1E"
            />
            <path
              d="M9.46649817,11.7991663 L15.5431204,11.7991663 C15.5941426,11.5757886 15.6264982,11.3449441 15.6264982,11.1053886 L9.38312039,11.1053886 C9.38312039,11.3449441 9.41547594,11.5757886 9.46649817,11.7991663"
              id="Fill-27"
              fill="#FFFFFF"
            />
            <path
              d="M11.3307382,8.85337968 C11.1801604,8.85337968 11.0420271,8.75569079 10.9978493,8.60449079 C10.9437159,8.42093524 11.0494937,8.22804635 11.2330493,8.17391301 L13.2502937,7.58217968 L12.0064715,3.25649079 C11.9535826,3.07169079 12.0599826,2.87942413 12.2441604,2.82653524 C12.4277159,2.77364635 12.6199826,2.88004635 12.6728715,3.06422413 L14.1083382,8.05382413 L11.4278048,8.84031301 C11.3954493,8.84902413 11.3624715,8.85337968 11.3307382,8.85337968"
              id="Fill-34"
              fill="#FF7F6E"
            />
            <path
              d="M9.78974261,3.01214413 C9.87125372,3.58147746 9.47614261,4.1091219 8.9074315,4.19063301 C8.33872039,4.27214413 7.81107594,3.87765524 7.72956483,3.30894413 C7.64805372,2.73961079 8.04254261,2.21258857 8.61125372,2.13045524 C9.18058705,2.0483219 9.70760928,2.44343301 9.78974261,3.01214413"
              id="Fill-36"
              fill="#1F1E1E"
            />
            <path
              d="M7.45093372,0.93522857 C7.30657817,0.93522857 7.17155594,0.844384126 7.12240039,0.70002857 C7.06017817,0.518339681 7.15724483,0.322339681 7.33893372,0.260117459 C9.02328928,-0.312949208 10.1731559,0.237717459 10.2210671,0.261361904 C10.3934226,0.345984126 10.4643559,0.553806348 10.3791115,0.725539681 C10.2951115,0.896650792 10.0885337,0.968206348 9.91680039,0.88482857 C9.87635594,0.865539681 8.9567115,0.443050792 7.56293372,0.916561904 C7.52497817,0.92962857 7.48826705,0.93522857 7.45093372,0.93522857"
              id="Fill-38"
              fill="#702C7E"
            />
            <path
              d="M17.5589959,0.93522857 C17.5210404,0.93522857 17.4843293,0.92962857 17.4469959,0.916561904 C16.0494848,0.441806348 15.1317071,0.866784126 15.0918848,0.88482857 C14.9195293,0.965717459 14.7129515,0.894784126 14.6301959,0.723050792 C14.5468182,0.551939681 14.6165071,0.345984126 14.7882404,0.261361904 C14.8361515,0.237095237 15.9860182,-0.31357143 17.6709959,0.260117459 C17.8520626,0.322339681 17.9491293,0.518339681 17.8869071,0.70002857 C17.8377515,0.844384126 17.7027293,0.93522857 17.5589959,0.93522857"
              id="Fill-40"
              fill="#702C7E"
            />
            <path
              d="M16.2501515,4.1684819 L16.2501515,4.1684819 C15.6752182,4.1684819 15.2097959,3.70243746 15.2097959,3.12750413 C15.2097959,2.55319301 15.6752182,2.08714857 16.2501515,2.08714857 C16.8250848,2.08714857 17.2905071,2.55319301 17.2905071,3.12750413 C17.2905071,3.70243746 16.8250848,4.1684819 16.2501515,4.1684819"
              id="Fill-42"
              fill="#1F1E1E"
            />
          </g>
        </g>
        <g id="EXITO" transform="translate(84.000000, 8.000000)">
          <circle id="Oval" fill="#FFFFFF" cx={10} cy={10} r={8} />
          <g
            id="00.Token/Icons/Solid/16x16/check"
            transform="translate(0.625250, 0.625125)"
            fill="#006B14"
          >
            <path
              d="M9.375,0 C4.205625,0 0,4.205 0,9.375 C0,14.544375 4.205625,18.75 9.375,18.75 C14.544375,18.75 18.75,14.544375 18.75,9.375 C18.75,4.205 14.544375,0 9.375,0 M11.7942093,6.25752693 C12.1351613,5.86786759 12.7274387,5.82838242 13.1170981,6.16933435 C13.4713338,6.47929064 13.5361695,6.99695399 13.288868,7.38126981 L13.2052907,7.49222307 L8.17041568,13.2463659 L4.96183739,10.0377876 C4.59572087,9.67167108 4.59572087,9.07807892 4.96183739,8.71196239 C5.2946706,8.37912919 5.8155002,8.34887163 6.18251457,8.6211897 L6.28766261,8.71196239 L8.0785,10.502375 L11.7942093,6.25752693 Z"
              id="color-icono"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default ExitoApp