import React from 'react'
import PropTypes from 'prop-types'
import gridStyles from './_grid.scss'

const Grid = ({
  variant = 'div',
  col,
  row,
  children,
  xs,
  sm,
  md,
  lg,
  xl,
  className,
  ...props
}) => {
  let styles = row ? [gridStyles.row] : [gridStyles.col]

  if (col) styles = styles.concat(gridStyles[`col-${col}`])
  if (xs) styles = styles.concat(gridStyles[`col-${xs}`])
  if (sm) styles = styles.concat(gridStyles[`col-sm-${sm}`])
  if (md) styles = styles.concat(gridStyles[`col-md-${md}`])
  if (lg) styles = styles.concat(gridStyles[`col-lg-${lg}`])
  if (xl) styles = styles.concat(gridStyles[`col-xl-${xl}`])

  return React.createElement(
    variant,
    { ...props, className: styles.concat(className || '').join(' ') },
    children
  )
}

Grid.defaultProps = {
  variant: 'div',
  className: '',
  onClick: () => null
}

Grid.propTypes = {
  variant: PropTypes.string,
  className: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  onClick: PropTypes.func,
  row: PropTypes.bool
}

export default Grid
