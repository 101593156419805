import React, { Fragment } from 'react'
import Step from './mobileStep'

import styles from './_mobileTracker.scss'

const MobileTracker = (props) => {
  const { steps, currentStep } = props

  return (
    <>
      <div className={styles.desktopStepperContainer}>
        {steps.map((s, index) => (
          index < 6 ?
            <Step
              key={`track_mobile_${s.step}`}
              iteratedStep={s}
              isLast={s.step === steps.length}
              currentStep={currentStep}
            />
          :
            <div key={`track_mobile_${s.step}`} />
        ))}
      </div>

      <div
        className={styles.stepNameContainer}
      >
        <span
          className={styles.stepName}
        >
          {`${steps[currentStep - 1].step}. ${steps[currentStep - 1].name}`}
        </span>

      </div>
    </>
  )
}

export default MobileTracker
