export default class IconListModel {
  static iconList = [
    // START OLD ICONS
    'old-calendar',
    'old-menu-kebab',
    'old-alert',
    'old-line-alarm',
    'old-line-alert',
    'old-line-app-notification',
    'old-line-block',
    'old-line-bookmark',
    'old-line-bug',
    'old-line-check-double',
    'old-line-check',
    'old-line-cloud-disable',
    'old-line-cloud-download',
    'old-line-cloud-upload',
    'old-line-cloud',
    'old-line-cogwheel',
    'old-line-copy',
    'old-line-cross',
    'old-line-cut',
    'old-line-dashboard',
    'old-line-down',
    'old-line-download-queue',
    'old-line-download',
    'old-line-draft',
    'old-line-exit-fullscreen',
    'old-line-eye-close',
    'old-line-eye-open',
    'old-line-female-user',
    'old-line-file',
    'old-line-filter',
    'old-line-flag',
    'old-line-fullscreen',
    'old-line-heart',
    'old-line-help',
    'old-line-home',
    'old-line-information',
    'old-line-left',
    'old-line-lifebuoy',
    'old-line-list',
    'old-line-loading',
    'old-line-lock',
    'old-line-login',
    'old-line-logout',
    'old-line-menu-burger',
    'old-line-menu-dots',
    'old-line-microphone-disable',
    'old-line-microphone',
    'old-line-minus',
    'old-line-note',
    'old-line-notification-disable',
    'old-line-notification',
    'old-line-paste',
    'old-line-plus',
    'old-line-power-sign',
    'old-line-print',
    'old-line-puzzle',
    'old-line-refresh',
    'old-line-reply-all',
    'old-line-reply',
    'old-line-right',
    'old-line-save',
    'old-line-screenshot',
    'old-line-search-check',
    'old-line-search-cross',
    'old-line-search-minus',
    'old-line-search-plus',
    'old-line-search',
    'old-line-share',
    'old-line-shield',
    'old-line-snooze',
    'old-line-star',
    'old-line-stars',
    'old-line-sync',
    'old-line-task',
    'old-line-time',
    'old-line-trash-empty',
    'old-line-trash-full',
    'old-line-trash',
    'old-line-umbrella',
    'old-line-unlock',
    'old-line-up',
    'old-line-upload-queue',
    'old-line-upload',
    'old-line-user',
    'old-line-verification-fail',
    'old-line-verification',
    'old-line-wrench',
    'old-line-write',
    'old-menu-kebab',
    'old-arrow-down',
    'old-arrow-left',
    'old-arrow-right',
    'old-arrow-up',
    'old-alarm1',
    'old-alert1',
    'old-app-notification1',
    'old-block',
    'old-bookmark',
    'old-bug',
    'old-check-double',
    'old-check',
    'old-cloud-disable',
    'old-cloud-download',
    'old-cloud-upload',
    'old-cloud',
    'old-cogwheel',
    'old-copy',
    'old-cross',
    'old-cut',
    'old-dashboard',
    'old-down',
    'old-download-queue',
    'old-download',
    'old-draft',
    'old-exit-fullscreen',
    'old-eye-close',
    'old-eye-open',
    'old-female-user',
    'old-file',
    'old-filter',
    'old-flag',
    'old-fullscreen',
    'old-heart',
    'old-help',
    'old-home',
    'old-information',
    'old-left',
    'old-lifebuoy',
    'old-list',
    'old-loading',
    'old-lock',
    'old-login',
    'old-logout',
    'old-menu-burger',
    'old-menu-dots',
    'old-microphone-disable',
    'old-microphone',
    'old-minus',
    'old-note',
    'old-notification-disable',
    'old-notification',
    'old-paste',
    'old-plus',
    'old-power-sign',
    'old-print',
    'old-puzzle',
    'old-refresh',
    'old-reply-all',
    'old-reply',
    'old-right',
    'old-save',
    'old-screenshot',
    'old-search-alt',
    'old-search-check',
    'old-search-cross',
    'old-search-minus',
    'old-search-plus',
    'old-share',
    'old-shield',
    'old-snooze',
    'old-star',
    'old-stars',
    'old-sync',
    'old-task',
    'old-time',
    'old-trash-empty',
    'old-trash-full',
    'old-trash',
    'old-umbrella',
    'old-unlock',
    'old-up',
    'old-upload-queue',
    'old-upload',
    'old-user',
    'old-verification-fail',
    'old-verification',
    'old-wrench',
    'old-write',
    // END OLD ICONS
    'line-claveunica',
    'line-download-2',
    'line-d-glasses',
    'line-d-hologram',
    'line-accounting',
    'line-action-cam',
    'line-activity-log',
    'line-add-contact',
    'line-add-friend',
    'line-ads-manager',
    'line-advertiser',
    'line-advertising',
    'line-affiliate',
    'line-airplane',
    'line-alarm',
    'line-alert',
    'line-align-center',
    'line-align-left',
    'line-align-right',
    'line-all-inboxes',
    'line-ambulance',
    'line-american-football',
    'line-antenna-alt',
    'line-antenna',
    'line-app-notification',
    'line-app-shop',
    'line-apple',
    'line-appointment',
    'line-archery',
    'line-arrival',
    'line-asparagus',
    'line-asterisk',
    'line-attachment',
    'line-audio-player',
    'line-audio',
    'line-augmentative-communication',
    'line-authentication',
    'line-auto-responder',
    'line-avocado',
    'line-back',
    'line-backlink',
    'line-backpack',
    'line-bag-trolley',
    'line-banana',
    'line-bandage',
    'line-bank',
    'line-banner-exchange',
    'line-bar-chart-down',
    'line-bar-chart-up',
    'line-barbell',
    'line-barcode',
    'line-baseball-bat',
    'line-baseball-field',
    'line-baseball',
    'line-basic-kid-phone',
    'line-basket-ring',
    'line-basketball-field',
    'line-basketball-jersey',
    'line-basketball',
    'line-beach',
    'line-bell-pepper',
    'line-bicycle',
    'line-bill',
    'line-billiard-ball',
    'line-binocular-disable',
    'line-binocular',
    'line-bio',
    'line-biscuit',
    'line-black-hat',
    'line-block-group',
    'line-block',
    'line-blocked-user',
    'line-blog',
    'line-blood-donation',
    'line-blood-transfusion',
    'line-blood',
    'line-bluetooth',
    'line-bold',
    'line-bone',
    'line-bones',
    'line-book',
    'line-bookmark',
    'line-boost',
    'line-bot',
    'line-bowling-ball',
    'line-box',
    'line-boxing',
    'line-braille',
    'line-brain',
    'line-bread',
    'line-briefcase',
    'line-bug',
    'line-bulleted-list',
    'line-burger',
    'line-business-account',
    'line-business-growth',
    'line-business-law',
    'line-business-model-canvas',
    'line-business-note',
    'line-business-performance',
    'line-business-plan',
    'line-business-strategy',
    'line-business-to-business',
    'line-business-to-consumer',
    'line-businessman',
    'line-businesswoman',
    'line-cabin-bag',
    'line-cabinet',
    'line-cake',
    'line-calculator',
    'line-calendar-add-event-alt',
    'line-calendar-add-event',
    'line-calendar-alert-alt',
    'line-calendar-alert',
    'line-calendar-alt',
    'line-calendar-favorite-alt',
    'line-calendar-favorite',
    'line-calendar-remove-event-alt',
    'line-calendar-remove-event',
    'line-calendar',
    'line-call-block',
    'line-call',
    'line-camera-lens',
    'line-camera',
    'line-candle-stick-bar',
    'line-canvas',
    'line-car',
    'line-cardiogram',
    'line-carrot',
    'line-cash',
    'line-cassette',
    'line-cctv',
    'line-cereal',
    'line-certificate',
    'line-channel',
    'line-chat-check-double',
    'line-chat-check',
    'line-chat',
    'line-check-double',
    'line-check',
    'line-cheese',
    'line-cheque',
    'line-chicken',
    'line-chili-pepper',
    'line-chocolate',
    'line-classic-phone',
    'line-click',
    'line-close-friend',
    'line-cloud-disable',
    'line-cloud-download',
    'line-cloud-upload',
    'line-cloud',
    'line-cogwheel-alt',
    'line-cogwheel',
    'line-coin',
    'line-coins',
    'line-collections',
    'line-comment',
    'line-company',
    'line-compass',
    'line-confectionery',
    'line-conflict',
    'line-connect',
    'line-consultation',
    'line-contact-list',
    'line-contract',
    'line-conversation-marketing',
    'line-conversation',
    'line-conversion-rate',
    'line-cookie',
    'line-copy',
    'line-copywriting',
    'line-corn',
    'line-cost-per-click',
    'line-cpanel',
    'line-cpu',
    'line-create-group',
    'line-credit-card-alt',
    'line-credit-card',
    'line-credit-control',
    'line-croissant',
    'line-cross',
    'line-crown-alt',
    'line-crown',
    'line-cruise',
    'line-css',
    'line-curriculum',
    'line-customer-service',
    'line-cut',
    'line-dashboard',
    'line-data-communication',
    'line-deal',
    'line-decision-making',
    'line-delivery',
    'line-departure',
    'line-desk-alt',
    'line-desk',
    'line-desktop-alt',
    'line-desktop',
    'line-diamond',
    'line-dictionary',
    'line-direct-linking',
    'line-direction',
    'line-directory',
    'line-disconnect',
    'line-discount',
    'line-dislike',
    'line-diving',
    'line-dna',
    'line-dollar-coin',
    'line-dollar-paper',
    'line-domain',
    'line-donation',
    'line-donut-chart',
    'line-donut',
    'line-down-alt',
    'line-down',
    'line-download-queue',
    'line-download',
    'line-draft-form-library',
    'line-draft',
    'line-drone',
    'line-duct-tape',
    'line-dvd-player',
    'line-dvd',
    'line-earphone-alt',
    'line-earphone',
    'line-edit-profile-alt',
    'line-edit-profile',
    'line-egg',
    'line-eject',
    'line-email-attachment',
    'line-email-draft',
    'line-email-encryption',
    'line-email-save',
    'line-email-search',
    'line-email',
    'line-enema',
    'line-exit-fullscreen',
    'line-export-data',
    'line-external-link',
    'line-eye-chart',
    'line-eye-close',
    'line-eye-open',
    'line-fake-traffic',
    'line-fax',
    'line-feather-pen',
    'line-feedback',
    'line-feeling',
    'line-female-user-alt',
    'line-female-user',
    'line-fencing-helmet',
    'line-fencing',
    'line-file-storage',
    'line-file',
    'line-film',
    'line-filter',
    'line-fish',
    'line-flag-alt',
    'line-flag',
    'line-flash',
    'line-flashdisk',
    'line-flask',
    'line-folder-close-alt',
    'line-folder-close',
    'line-folder-open-alt',
    'line-folder-open',
    'line-follow',
    'line-followers',
    'line-following',
    'line-font',
    'line-footstep',
    'line-forest',
    'line-form-library',
    'line-form',
    'line-free',
    'line-french-fries',
    'line-friend-list',
    'line-ftp',
    'line-fullscreen',
    'line-funnel',
    'line-gameboy',
    'line-gamepad',
    'line-gasoline',
    'line-gift',
    'line-glass-hour',
    'line-globe',
    'line-goal',
    'line-gold',
    'line-grape',
    'line-grocery',
    'line-group',
    'line-gymnastic',
    'line-handicap',
    'line-handsfree',
    'line-handy-talkie',
    'line-hashtag',
    'line-hdd',
    'line-hdmi-port',
    'line-headphone-alt',
    'line-headphone',
    'line-heart-rate',
    'line-heart',
    'line-help',
    'line-hierarchial-structure',
    'line-hieroglyphic',
    'line-hit',
    'line-hiv-ribbon',
    'line-hockey',
    'line-home-alt',
    'line-home-theater',
    'line-home',
    'line-honey',
    'line-horizontal-graphic-alt',
    'line-horizontal-graphic',
    'line-hospital-sign',
    'line-hospital',
    'line-hosting',
    'line-hot-coffee',
    'line-hotel',
    'line-html',
    'line-human-to-human',
    'line-ice-coffee',
    'line-ice-cream-cone',
    'line-ice-skate',
    'line-id-card',
    'line-idea',
    'line-import-data',
    'line-important',
    'line-impression',
    'line-inbox-empty',
    'line-inbox-full',
    'line-incoming-call',
    'line-indent-less',
    'line-indent-more',
    'line-infographic',
    'line-information',
    'line-infusion',
    'line-initiate-money-transfer',
    'line-ink',
    'line-insights',
    'line-intercom',
    'line-internal-link',
    'line-internet-banking',
    'line-investment',
    'line-invite-friends',
    'line-invoice',
    'line-italic',
    'line-joystick',
    'line-juice',
    'line-justify',
    'line-karate',
    'line-kayak',
    'line-key',
    'line-keyboard-alt',
    'line-keyboard',
    'line-keyword-density',
    'line-keyword-research',
    'line-keyword',
    'line-kidney',
    'line-kiwi',
    'line-label',
    'line-language',
    'line-laptop-alt',
    'line-laptop',
    'line-leader',
    'line-leads',
    'line-left-alt',
    'line-left',
    'line-legal',
    'line-lemon',
    'line-lettuce',
    'line-lifebuoy',
    'line-like',
    'line-link-exchange',
    'line-link-farm',
    'line-link',
    'line-linked-accounts',
    'line-list',
    'line-live-video',
    'line-liver',
    'line-loading',
    'line-local-subaquatic-network',
    'line-location-area',
    'line-location-pin',
    'line-lock',
    'line-login',
    'line-logout',
    'line-lollipop',
    'line-luggage',
    'line-lungs',
    'line-magazine',
    'line-magnet',
    'line-mailbox',
    'line-mailing-list',
    'line-map-alt',
    'line-map',
    'line-marketing',
    'line-mastercard',
    'line-medal',
    'line-medical-bag',
    'line-medicine',
    'line-megaphone-alt',
    'line-megaphone',
    'line-melon',
    'line-membership-card',
    'line-menu-burger',
    'line-menu-dots',
    'line-message-bottle',
    'line-message',
    'line-messages',
    'line-meta-tag',
    'line-microphone-disable',
    'line-microphone',
    'line-microscope',
    'line-milk',
    'line-mini-traffic',
    'line-minus',
    'line-mobile-banking',
    'line-mocktail',
    'line-moderator',
    'line-money-exchange',
    'line-monopod',
    'line-morse-code',
    'line-mortar',
    'line-mouse-alt',
    'line-mouse',
    'line-movie-ticket',
    'line-muffin',
    'line-multiple-destinations',
    'line-navigation',
    'line-network',
    'line-neutral-trading',
    'line-news-feed',
    'line-newspaper',
    'line-next',
    'line-nintendo-switch',
    'line-nintendo-wii',
    'line-noodle',
    'line-note',
    'line-notification-disable',
    'line-notification',
    'line-numpad',
    'line-office-alt',
    'line-office-chair',
    'line-office',
    'line-olive-oil',
    'line-onion',
    'line-orange',
    'line-organic',
    'line-organization',
    'line-outbox-empty',
    'line-outbox-full',
    'line-outgoing-call',
    'line-packaging',
    'line-page',
    'line-pager',
    'line-pages',
    'line-paid',
    'line-panel',
    'line-paperplane',
    'line-passport',
    'line-password-alt',
    'line-password',
    'line-pasta',
    'line-paste',
    'line-pause',
    'line-pay-per-click',
    'line-pear',
    'line-pen',
    'line-pepper',
    'line-percentage',
    'line-petri-dish',
    'line-phone-book',
    'line-phone-booth',
    'line-phone-lock',
    'line-phone-support-24-hour',
    'line-phone-unlock',
    'line-php',
    'line-picture',
    'line-pie-chart',
    'line-pills',
    'line-pineapple',
    'line-pizza',
    'line-play',
    'line-playstation',
    'line-plugs',
    'line-plus',
    'line-pocket-knife',
    'line-podium',
    'line-popcorn',
    'line-popsicle',
    'line-post-engagements',
    'line-post',
    'line-potion',
    'line-power-sign',
    'line-presentation-alt',
    'line-presentation',
    'line-primary-inbox',
    'line-print-alt',
    'line-print',
    'line-private-account',
    'line-profile-picture-alt',
    'line-profile-picture',
    'line-profit',
    'line-projector',
    'line-promote',
    'line-public-speaking',
    'line-publish',
    'line-publisher',
    'line-purse',
    'line-push-notification',
    'line-pushpin',
    'line-puzzle',
    'line-qr-code',
    'line-quote',
    'line-racing-flag',
    'line-racing-helmet',
    'line-racket',
    'line-radio',
    'line-ranking',
    'line-reach',
    'line-receipt',
    'line-recommendation',
    'line-record',
    'line-refresh-wifi',
    'line-refresh',
    'line-remote',
    'line-remove-contact',
    'line-remove-friend',
    'line-remove-group',
    'line-repeat',
    'line-reply-all',
    'line-reply',
    'line-rice',
    'line-right-alt',
    'line-right',
    'line-rocket-launch',
    'line-rugby-ball',
    'line-running-shoe',
    'line-sad',
    'line-sale',
    'line-sandals',
    'line-sandwich',
    'line-satellite-alt',
    'line-satellite',
    'line-sauce',
    'line-sausage',
    'line-save',
    'line-savings',
    'line-scale',
    'line-scam',
    'line-scoreboard',
    'line-screenshot',
    'line-script',
    'line-scroll',
    'line-search-alt',
    'line-search-check-alt',
    'line-search-check',
    'line-search-cross-alt',
    'line-search-cross',
    'line-search-engine-result-page',
    'line-search-minus-alt',
    'line-search-minus',
    'line-search-plus-alt',
    'line-search-plus',
    'line-search',
    'line-semaphore',
    'line-send-alt',
    'line-send',
    'line-server',
    'line-share',
    'line-shared-hosting',
    'line-shield',
    'line-ship-flag',
    'line-ship',
    'line-shipping',
    'line-shirt',
    'line-shop-sign',
    'line-shop',
    'line-shopping-bag-alt',
    'line-shopping-bag',
    'line-shopping-cart-alt',
    'line-shopping-cart',
    'line-shorts',
    'line-shredder',
    'line-shuffle',
    'line-shuttlecock',
    'line-signal',
    'line-skull',
    'line-smartphone-alt',
    'line-smartphone',
    'line-smile',
    'line-smoke-signal',
    'line-snooze',
    'line-soccer-ball',
    'line-soccer-field',
    'line-soccer-jersey',
    'line-soccer-shoe',
    'line-soda',
    'line-sort-by-alphabet-a-to-z',
    'line-sort-by-alphabet-z-to-a',
    'line-sort-by-attribute-max-to-min',
    'line-sort-by-attribute-min-to-max',
    'line-sort-by-order-max-to-min',
    'line-sort-by-order-min-to-max',
    'line-sos-panic-button',
    'line-soup',
    'line-spam',
    'line-speech',
    'line-speedometer',
    'line-sperm',
    'line-stamp',
    'line-stapler',
    'line-star',
    'line-stars',
    'line-statistic-alt',
    'line-statistic',
    'line-steak',
    'line-stethoscope',
    'line-stop',
    'line-stopwatch',
    'line-strategy',
    'line-subdomain',
    'line-subject',
    'line-subscribe',
    'line-suitcase',
    'line-sunblock',
    'line-surface-acoustic-wave',
    'line-surfboard',
    'line-swipe',
    'line-sync',
    'line-tag',
    'line-tags',
    'line-tape-recorder',
    'line-target-alt',
    'line-target',
    'line-task',
    'line-tea',
    'line-teamwork',
    'line-tee',
    'line-telegraph',
    'line-telepathy',
    'line-television-alt',
    'line-television',
    'line-template',
    'line-tennis-ball',
    'line-tennis-field',
    'line-tent',
    'line-test-tube',
    'line-text-colour',
    'line-text-lines',
    'line-thermometer',
    'line-tie',
    'line-time',
    'line-timeline',
    'line-tin-cans-phone',
    'line-tomato',
    'line-tooth',
    'line-tracking-code',
    'line-tracks',
    'line-trademark',
    'line-traffic',
    'line-train',
    'line-transmission',
    'line-trash-empty',
    'line-trash-full',
    'line-trash',
    'line-travel-ticket',
    'line-tripod',
    'line-trophy',
    'line-typewriter',
    'line-umbrella',
    'line-underline',
    'line-unfollow',
    'line-unlock',
    'line-unsubscribe',
    'line-up-alt',
    'line-up',
    'line-upload-queue',
    'line-upload',
    'line-usb-port',
    'line-user-alt',
    'line-user',
    'line-vaccine',
    'line-verification-fail',
    'line-verification',
    'line-vga-port',
    'line-video-call',
    'line-video-recorder',
    'line-video',
    'line-vintage-phone',
    'line-viral-marketing',
    'line-virus',
    'line-voicemail',
    'line-volley-ball',
    'line-volume-disable',
    'line-volume-down',
    'line-volume-up',
    'line-volume',
    'line-vr-box',
    'line-wallet',
    'line-watch-alt',
    'line-watch',
    'line-water-bottle',
    'line-water-glass',
    'line-watermelon',
    'line-wave',
    'line-webcam',
    'line-wheelchair',
    'line-white-hat',
    'line-wifi-alt',
    'line-wifi-router-alt',
    'line-wifi-router',
    'line-wifi',
    'line-work',
    'line-workflow',
    'line-wrench',
    'line-write',
    'line-x-ray',
    'line-yogurt',

    // SOLID
    'solid-d-glasses',
    'solid-d-hologram',
    'solid-accounting',
    'solid-action-cam',
    'solid-activity-log',
    'solid-add-contact',
    'solid-add-friend',
    'solid-ads-manager',
    'solid-advertiser',
    'solid-advertising',
    'solid-affiliate',
    'solid-airplane',
    'solid-alarm',
    'solid-alert',
    'solid-align-center',
    'solid-align-left',
    'solid-align-right',
    'solid-all-inboxes',
    'solid-ambulance',
    'solid-american-football',
    'solid-antenna-alt',
    'solid-antenna',
    'solid-app-notification',
    'solid-app-shop',
    'solid-apple',
    'solid-appointment',
    'solid-archery',
    'solid-arrival',
    'solid-asparagus',
    'solid-asterisk',
    'solid-attachment',
    'solid-audio-player',
    'solid-audio',
    'solid-augmentative-communication',
    'solid-authentication',
    'solid-auto-responder',
    'solid-avocado',
    'solid-back',
    'solid-backlink',
    'solid-backpack',
    'solid-bag-trolley',
    'solid-banana',
    'solid-bandage',
    'solid-bank',
    'solid-banner-exchange',
    'solid-bar-chart-down',
    'solid-bar-chart-up',
    'solid-barbell',
    'solid-barcode',
    'solid-baseball-bat',
    'solid-baseball-field',
    'solid-baseball',
    'solid-basic-kid-phone',
    'solid-basket-ring',
    'solid-basketball-field',
    'solid-basketball-jersey',
    'solid-basketball',
    'solid-beach',
    'solid-bell-pepper',
    'solid-bicycle',
    'solid-bill',
    'solid-billiard-ball',
    'solid-binocular-disable',
    'solid-binocular',
    'solid-bio',
    'solid-biscuit',
    'solid-black-hat',
    'solid-block-group',
    'solid-block',
    'solid-blocked-user',
    'solid-blog',
    'solid-blood-donation',
    'solid-blood-transfusion',
    'solid-blood',
    'solid-bluetooth',
    'solid-bold',
    'solid-bone',
    'solid-bones',
    'solid-book',
    'solid-bookmark',
    'solid-boost',
    'solid-bot',
    'solid-bowling-ball',
    'solid-box',
    'solid-boxing',
    'solid-braille',
    'solid-brain',
    'solid-bread',
    'solid-briefcase',
    'solid-bug',
    'solid-bulleted-list',
    'solid-burger',
    'solid-business-account',
    'solid-business-growth',
    'solid-business-law',
    'solid-business-model-canvas',
    'solid-business-note',
    'solid-business-performance',
    'solid-business-plan',
    'solid-business-strategy',
    'solid-business-to-business',
    'solid-business-to-consumer',
    'solid-businessman',
    'solid-businesswoman',
    'solid-cabin-bag',
    'solid-cabinet',
    'solid-cake',
    'solid-calculator',
    'solid-calendar-add-event-alt',
    'solid-calendar-add-event',
    'solid-calendar-alert-alt',
    'solid-calendar-alert',
    'solid-calendar-alt',
    'solid-calendar-favorite-alt',
    'solid-calendar-favorite',
    'solid-calendar-remove-event-alt',
    'solid-calendar-remove-event',
    'solid-calendar',
    'solid-call-block',
    'solid-call',
    'solid-camera-lens',
    'solid-camera',
    'solid-candle-stick-chart',
    'solid-canvas',
    'solid-car',
    'solid-cardiogram',
    'solid-carrot',
    'solid-cash',
    'solid-cassette',
    'solid-cctv',
    'solid-cereal',
    'solid-certificate',
    'solid-channel',
    'solid-chat-check-double',
    'solid-chat-check',
    'solid-chat',
    'solid-check-double',
    'solid-check',
    'solid-cheese',
    'solid-cheque',
    'solid-chicken',
    'solid-chili-pepper',
    'solid-chocolate',
    'solid-classic-phone',
    'solid-click',
    'solid-close-friend',
    'solid-cloud-disable',
    'solid-cloud-download',
    'solid-cloud-upload',
    'solid-cloud',
    'solid-cogwheel-alt',
    'solid-cogwheel',
    'solid-coin',
    'solid-coins',
    'solid-collections',
    'solid-comment',
    'solid-company',
    'solid-compass',
    'solid-confectionery',
    'solid-conflict',
    'solid-connect',
    'solid-consultation',
    'solid-contact-list',
    'solid-contract',
    'solid-conversation-marketing',
    'solid-conversation',
    'solid-conversion-rate',
    'solid-cookie',
    'solid-copy',
    'solid-copywriting',
    'solid-corn',
    'solid-cost-per-click',
    'solid-cpanel',
    'solid-cpu',
    'solid-create-group',
    'solid-credit-card-alt',
    'solid-credit-card',
    'solid-credit-control',
    'solid-croissant',
    'solid-cross',
    'solid-crown-alt',
    'solid-crown',
    'solid-cruise',
    'solid-css',
    'solid-curriculum',
    'solid-customer-service',
    'solid-cut',
    'solid-dashboard',
    'solid-data-communication',
    'solid-deal',
    'solid-decision-making',
    'solid-delivery',
    'solid-departure',
    'solid-desk-alt',
    'solid-desk',
    'solid-desktop-alt',
    'solid-desktop',
    'solid-diamond',
    'solid-dictionary',
    'solid-direct-linking',
    'solid-direction',
    'solid-directory',
    'solid-disconnect',
    'solid-discount',
    'solid-dislike',
    'solid-diving',
    'solid-dna',
    'solid-dollar-coin',
    'solid-dollar-paper',
    'solid-domain',
    'solid-donation',
    'solid-donut-chart',
    'solid-donut',
    'solid-down-alt',
    'solid-down',
    'solid-download-queue',
    'solid-download',
    'solid-draft-form-library',
    'solid-draft',
    'solid-drone',
    'solid-duct-tape',
    'solid-dvd-player',
    'solid-dvd',
    'solid-earphone-alt',
    'solid-earphone',
    'solid-edit-profile-alt',
    'solid-edit-profile',
    'solid-egg',
    'solid-eject',
    'solid-email-attachment',
    'solid-email-draft',
    'solid-email-encryption',
    'solid-email-save',
    'solid-email-search',
    'solid-email',
    'solid-enema',
    'solid-exit-fullscreen',
    'solid-export-data',
    'solid-external-link',
    'solid-eye-chart',
    'solid-eye-close',
    'solid-eye-open',
    'solid-fake-traffic',
    'solid-fax',
    'solid-feather-pen',
    'solid-feedback',
    'solid-feeling',
    'solid-female-user-alt',
    'solid-female-user',
    'solid-fencing-helmet',
    'solid-fencing',
    'solid-file-storage',
    'solid-file',
    'solid-film',
    'solid-filter',
    'solid-fish',
    'solid-flag-alt',
    'solid-flag',
    'solid-flash',
    'solid-flashdisk',
    'solid-flask',
    'solid-folder-close-alt',
    'solid-folder-close',
    'solid-folder-open-alt',
    'solid-folder-open',
    'solid-follow',
    'solid-followers',
    'solid-following',
    'solid-font',
    'solid-footstep',
    'solid-forest',
    'solid-form-library',
    'solid-form',
    'solid-free',
    'solid-french-fries',
    'solid-friend-list',
    'solid-ftp',
    'solid-fullscreen',
    'solid-funnel',
    'solid-gameboy',
    'solid-gamepad',
    'solid-gasoline',
    'solid-gift',
    'solid-glass-hour',
    'solid-globe',
    'solid-goal',
    'solid-gold',
    'solid-grape',
    'solid-grocery',
    'solid-group',
    'solid-gymnastic',
    'solid-handicap',
    'solid-handsfree',
    'solid-handy-talkie',
    'solid-hashtag',
    'solid-hdd',
    'solid-hdmi-port',
    'solid-headphone-alt',
    'solid-headphone',
    'solid-heart-rate',
    'solid-heart',
    'solid-help',
    'solid-hierarchial-structure',
    'solid-hieroglyphic',
    'solid-hit',
    'solid-hiv-ribbon',
    'solid-hockey',
    'solid-home-alt',
    'solid-home-theater',
    'solid-home',
    'solid-honey',
    'solid-horizontal-graphic-alt',
    'solid-horizontal-graphic',
    'solid-hospital-sign',
    'solid-hospital',
    'solid-hosting',
    'solid-hot-coffee',
    'solid-hotel',
    'solid-html',
    'solid-human-to-human',
    'solid-ice-coffee',
    'solid-ice-cream-cone',
    'solid-ice-skate',
    'solid-id-card',
    'solid-idea',
    'solid-import-data',
    'solid-important',
    'solid-impression',
    'solid-inbox-empty',
    'solid-inbox-full',
    'solid-incoming-call',
    'solid-indent-less',
    'solid-indent-more',
    'solid-infographic',
    'solid-information',
    'solid-infusion',
    'solid-initiate-money-transfer',
    'solid-ink',
    'solid-insights',
    'solid-intercom',
    'solid-internal-link',
    'solid-internet-banking',
    'solid-investment',
    'solid-invite-friends',
    'solid-invoice',
    'solid-italic',
    'solid-joystick',
    'solid-juice',
    'solid-justify',
    'solid-karate',
    'solid-kayak',
    'solid-key',
    'solid-keyboard-alt',
    'solid-keyboard',
    'solid-keyword-density',
    'solid-keyword-research',
    'solid-keyword',
    'solid-kidney',
    'solid-kiwi',
    'solid-label',
    'solid-language',
    'solid-laptop-alt',
    'solid-laptop',
    'solid-leader',
    'solid-leads',
    'solid-left-alt',
    'solid-left',
    'solid-legal',
    'solid-lemon',
    'solid-lettuce',
    'solid-lifebuoy',
    'solid-like',
    'solid-link-exchange',
    'solid-link-farm',
    'solid-link',
    'solid-linked-accounts',
    'solid-list',
    'solid-live-video',
    'solid-liver',
    'solid-loading',
    'solid-local-subaquatic-network',
    'solid-location-area',
    'solid-location-pin',
    'solid-lock',
    'solid-login',
    'solid-logout',
    'solid-lollipop',
    'solid-luggage',
    'solid-lungs',
    'solid-magazine',
    'solid-magnet',
    'solid-mailbox',
    'solid-mailing-list',
    'solid-map-alt',
    'solid-map',
    'solid-marketing',
    'solid-mastercard',
    'solid-medal',
    'solid-medical-bag',
    'solid-medicine',
    'solid-megaphone-alt',
    'solid-megaphone',
    'solid-melon',
    'solid-membership-card',
    'solid-menu-burger',
    'solid-menu-dots',
    'solid-message-bottle',
    'solid-message',
    'solid-messages',
    'solid-meta-tag',
    'solid-microphone-disable',
    'solid-microphone',
    'solid-microscope',
    'solid-milk',
    'solid-mini-traffic',
    'solid-minus',
    'solid-mobile-banking',
    'solid-mocktail',
    'solid-moderator',
    'solid-money-exchange',
    'solid-monopod',
    'solid-morse-code',
    'solid-mortar',
    'solid-mouse-alt',
    'solid-mouse',
    'solid-movie-ticket',
    'solid-muffin',
    'solid-multiple-destinations',
    'solid-navigation',
    'solid-network',
    'solid-neutral-trading',
    'solid-news-feed',
    'solid-newspaper',
    'solid-next',
    'solid-nintendo-switch',
    'solid-nintendo-wii',
    'solid-noodle',
    'solid-note',
    'solid-notification-disable',
    'solid-notification',
    'solid-numpad',
    'solid-office-alt',
    'solid-office-chair',
    'solid-office',
    'solid-olive-oil',
    'solid-onion',
    'solid-orange',
    'solid-organic',
    'solid-organization',
    'solid-outbox-empty',
    'solid-outbox-full',
    'solid-outgoing-call',
    'solid-packaging',
    'solid-page',
    'solid-pager',
    'solid-pages',
    'solid-paid',
    'solid-panel',
    'solid-paperplane',
    'solid-passport',
    'solid-password-alt',
    'solid-password',
    'solid-pasta',
    'solid-paste',
    'solid-pause',
    'solid-pay-per-click',
    'solid-pear',
    'solid-pen',
    'solid-pepper',
    'solid-percentage',
    'solid-petri-dish',
    'solid-phone-book',
    'solid-phone-booth',
    'solid-phone-lock',
    'solid-phone-support-24-hour',
    'solid-phone-unlock',
    'solid-php',
    'solid-picture',
    'solid-pie-chart',
    'solid-pills',
    'solid-pineapple',
    'solid-pizza',
    'solid-play',
    'solid-playstation',
    'solid-plugs',
    'solid-plus',
    'solid-pocket-knife',
    'solid-podium',
    'solid-popcorn',
    'solid-popsicle',
    'solid-post-engagements',
    'solid-post',
    'solid-potion',
    'solid-power-sign',
    'solid-presentation-alt',
    'solid-presentation',
    'solid-primary-inbox',
    'solid-print-alt',
    'solid-print',
    'solid-private-account',
    'solid-profile-picture-alt',
    'solid-profile-picture',
    'solid-profit',
    'solid-projector',
    'solid-promote',
    'solid-public-speaking',
    'solid-publish',
    'solid-publisher',
    'solid-purse',
    'solid-push-notification',
    'solid-pushpin',
    'solid-puzzle',
    'solid-qr-code',
    'solid-quote',
    'solid-racing-flag',
    'solid-racing-helmet',
    'solid-racket',
    'solid-radio',
    'solid-ranking',
    'solid-reach',
    'solid-receipt',
    'solid-recommendation',
    'solid-record',
    'solid-refresh-wifi',
    'solid-refresh',
    'solid-remote',
    'solid-remove-contact',
    'solid-remove-friend',
    'solid-remove-group',
    'solid-repeat',
    'solid-reply-all',
    'solid-reply',
    'solid-rice',
    'solid-right-alt',
    'solid-right',
    'solid-rocket-launch',
    'solid-rugby-ball',
    'solid-running-shoe',
    'solid-sad',
    'solid-sale',
    'solid-sandals',
    'solid-sandwich',
    'solid-satellite-alt',
    'solid-satellite',
    'solid-sauce',
    'solid-sausage',
    'solid-save',
    'solid-savings',
    'solid-scale',
    'solid-scam',
    'solid-scoreboard',
    'solid-screenshot',
    'solid-script',
    'solid-scroll',
    'solid-search-alt',
    'solid-search-check-alt',
    'solid-search-check',
    'solid-search-cross-alt',
    'solid-search-cross',
    'solid-search-engine-result-page',
    'solid-search-minus-alt',
    'solid-search-minus',
    'solid-search-plus-alt',
    'solid-search-plus',
    'solid-search',
    'solid-semaphore',
    'solid-send-alt',
    'solid-send',
    'solid-server',
    'solid-share',
    'solid-shared-hosting',
    'solid-shield',
    'solid-ship-flag',
    'solid-ship',
    'solid-shipping',
    'solid-shirt',
    'solid-shop-sign',
    'solid-shop',
    'solid-shopping-bag-alt',
    'solid-shopping-bag',
    'solid-shopping-cart-alt',
    'solid-shopping-cart',
    'solid-shorts',
    'solid-shredder',
    'solid-shuffle',
    'solid-shuttlecock',
    'solid-signal',
    'solid-skull',
    'solid-smartphone-alt',
    'solid-smartphone',
    'solid-smile',
    'solid-smoke-signal',
    'solid-snooze',
    'solid-soccer-ball',
    'solid-soccer-field',
    'solid-soccer-jersey',
    'solid-soccer-shoe',
    'solid-soda',
    'solid-sort-by-alphabet-a-to-z',
    'solid-sort-by-alphabet-z-to-a',
    'solid-sort-by-attribute-max-to-min',
    'solid-sort-by-attribute-min-to-max',
    'solid-sort-by-order-max-to-min',
    'solid-sort-by-order-min-to-max',
    'solid-sos-panic-button',
    'solid-soup',
    'solid-spam',
    'solid-speech',
    'solid-speedometer',
    'solid-sperm',
    'solid-stamp',
    'solid-stapler',
    'solid-star',
    'solid-stars',
    'solid-statistic-alt',
    'solid-statistic',
    'solid-steak',
    'solid-stethoscope',
    'solid-stop',
    'solid-stopwatch',
    'solid-subdomain',
    'solid-subject',
    'solid-subscribe',
    'solid-suitcase',
    'solid-sunblock',
    'solid-surface-acoustic-wave',
    'solid-surfboard',
    'solid-swipe',
    'solid-sync',
    'solid-tag',
    'solid-tags',
    'solid-tape-recorder',
    'solid-target-alt',
    'solid-target',
    'solid-task',
    'solid-tea',
    'solid-team-strategy',
    'solid-teamwork',
    'solid-tee',
    'solid-telegraph',
    'solid-telepathy',
    'solid-television-alt',
    'solid-television',
    'solid-template',
    'solid-tennis-ball',
    'solid-tennis-field',
    'solid-tent',
    'solid-test-tube',
    'solid-text-colour',
    'solid-text-lines',
    'solid-thermometer',
    'solid-tie',
    'solid-time',
    'solid-timeline',
    'solid-tin-cans-phone',
    'solid-tomato',
    'solid-tooth',
    'solid-tracking-code',
    'solid-tracks',
    'solid-trademark',
    'solid-traffic',
    'solid-train',
    'solid-transmission',
    'solid-trash-empty',
    'solid-trash-full',
    'solid-trash',
    'solid-travel-ticket',
    'solid-tripod',
    'solid-trophy',
    'solid-typewriter',
    'solid-umbrella',
    'solid-underline',
    'solid-unfollow',
    'solid-unlock',
    'solid-unsubscribe',
    'solid-up-alt',
    'solid-up',
    'solid-upload-queue',
    'solid-upload',
    'solid-usb-port',
    'solid-user-alt',
    'solid-user',
    'solid-vaccine',
    'solid-verification-fail',
    'solid-verification',
    'solid-vga-port',
    'solid-video-call',
    'solid-video-recorder',
    'solid-video',
    'solid-vintage-phone',
    'solid-viral-marketing',
    'solid-virus',
    'solid-voicemail',
    'solid-volley-ball',
    'solid-volume-disable',
    'solid-volume-down',
    'solid-volume-up',
    'solid-volume',
    'solid-vr-box',
    'solid-wallet',
    'solid-watch-alt',
    'solid-watch',
    'solid-water-bottle',
    'solid-water-glass',
    'solid-watermelon',
    'solid-wave',
    'solid-webcam',
    'solid-wheelchair',
    'solid-white-hat',
    'solid-wifi-alt',
    'solid-wifi-router-alt',
    'solid-wifi-router',
    'solid-wifi',
    'solid-work',
    'solid-workflow',
    'solid-wrench',
    'solid-write',
    'solid-x-ray',
    'solid-yogurt',
    'filled-line-d-glasses',
    'filled-line-d-hologram',
    'filled-line-accounting',
    'filled-line-action-cam',
    'filled-line-activity-log',
    'filled-line-add-contact',
    'filled-line-add-friend',
    'filled-line-ads-manager',
    'filled-line-advertiser',
    'filled-line-advertising',
    'filled-line-affiliate',
    'filled-line-airplane',
    'filled-line-alarm',
    'filled-line-alert',
    'filled-line-align-center',
    'filled-line-align-left',
    'filled-line-align-right',
    'filled-line-all-inboxes',
    'filled-line-ambulance',
    'filled-line-american-football',
    'filled-line-antenna-alt',
    'filled-line-antenna',
    'filled-line-app-notification',
    'filled-line-app-shop',
    'filled-line-apple',
    'filled-line-appointment',
    'filled-line-archery',
    'filled-line-arrival',
    'filled-line-asparagus',
    'filled-line-asterisk',
    'filled-line-attachment',
    'filled-line-audio-player',
    'filled-line-audio',
    'filled-line-augmentative-communication',
    'filled-line-authentication',
    'filled-line-auto-responder',
    'filled-line-avocado',
    'filled-line-back',
    'filled-line-backlink',
    'filled-line-backpack',
    'filled-line-bag-trolley',
    'filled-line-banana',
    'filled-line-bandage',
    'filled-line-bank',
    'filled-line-banner-exchange',
    'filled-line-bar-chart-down',
    'filled-line-bar-chart-up',
    'filled-line-barbell',
    'filled-line-barcode',
    'filled-line-baseball-bat',
    'filled-line-baseball-field',
    'filled-line-baseball',
    'filled-line-basic-kid-phone',
    'filled-line-basket-ring',
    'filled-line-basketball-field',
    'filled-line-basketball-jersey',
    'filled-line-basketball',
    'filled-line-beach',
    'filled-line-bell-pepper',
    'filled-line-bicycle',
    'filled-line-bill',
    'filled-line-billiard-ball',
    'filled-line-binocular-disable',
    'filled-line-binocular',
    'filled-line-bio',
    'filled-line-biscuit',
    'filled-line-black-hat',
    'filled-line-block-group',
    'filled-line-block',
    'filled-line-blocked-user',
    'filled-line-blog',
    'filled-line-blood-donation',
    'filled-line-blood-transfusion',
    'filled-line-blood',
    'filled-line-bluetooth',
    'filled-line-bold',
    'filled-line-bone',
    'filled-line-bones',
    'filled-line-book',
    'filled-line-bookmark',
    'filled-line-boost',
    'filled-line-bot',
    'filled-line-bowling-ball',
    'filled-line-box',
    'filled-line-boxing',
    'filled-line-braille',
    'filled-line-brain',
    'filled-line-bread',
    'filled-line-briefcase',
    'filled-line-bug',
    'filled-line-bulleted-list',
    'filled-line-burger',
    'filled-line-business-account',
    'filled-line-business-growth',
    'filled-line-business-law',
    'filled-line-business-model-canvas',
    'filled-line-business-note',
    'filled-line-business-performance',
    'filled-line-business-plan',
    'filled-line-business-strategy',
    'filled-line-business-to-business',
    'filled-line-business-to-consumer',
    'filled-line-businessman',
    'filled-line-businesswoman',
    'filled-line-cabin-bag',
    'filled-line-cabinet',
    'filled-line-cake',
    'filled-line-calculator',
    'filled-line-calendar-add-event-alt',
    'filled-line-calendar-add-event',
    'filled-line-calendar-alert-alt',
    'filled-line-calendar-alert',
    'filled-line-calendar-alt',
    'filled-line-calendar-favorite-alt',
    'filled-line-calendar-favorite',
    'filled-line-calendar-remove-event-alt',
    'filled-line-calendar-remove-event',
    'filled-line-calendar',
    'filled-line-call-block',
    'filled-line-call',
    'filled-line-camera-lens',
    'filled-line-camera',
    'filled-line-candle-stick-chart',
    'filled-line-canvas',
    'filled-line-car',
    'filled-line-cardiogram',
    'filled-line-carrot',
    'filled-line-cash',
    'filled-line-cassette',
    'filled-line-cctv',
    'filled-line-cereal',
    'filled-line-certificate',
    'filled-line-channel',
    'filled-line-chat-check-double',
    'filled-line-chat-check',
    'filled-line-chat',
    'filled-line-check-double',
    'filled-line-check',
    'filled-line-cheese',
    'filled-line-cheque',
    'filled-line-chicken',
    'filled-line-chili-pepper',
    'filled-line-chocolate',
    'filled-line-classic-phone',
    'filled-line-click',
    'filled-line-close-friend',
    'filled-line-cloud-disable',
    'filled-line-cloud-download',
    'filled-line-cloud-upload',
    'filled-line-cloud',
    'filled-line-cogwheel-alt',
    'filled-line-cogwheel',
    'filled-line-coin',
    'filled-line-coins',
    'filled-line-collections',
    'filled-line-comment',
    'filled-line-company',
    'filled-line-compass',
    'filled-line-confectionery',
    'filled-line-conflict',
    'filled-line-connect',
    'filled-line-consultation',
    'filled-line-contact-list',
    'filled-line-contract',
    'filled-line-conversation-marketing',
    'filled-line-conversation',
    'filled-line-conversion-rate',
    'filled-line-cookie',
    'filled-line-copy',
    'filled-line-copywriting',
    'filled-line-corn',
    'filled-line-cost-per-click',
    'filled-line-cpanel',
    'filled-line-cpu',
    'filled-line-create-group',
    'filled-line-credit-card-alt',
    'filled-line-credit-card',
    'filled-line-credit-control',
    'filled-line-croissant',
    'filled-line-cross',
    'filled-line-crown-alt',
    'filled-line-crown',
    'filled-line-cruise',
    'filled-line-css',
    'filled-line-curriculum',
    'filled-line-customer-service',
    'filled-line-cut',
    'filled-line-dashboard',
    'filled-line-data-communication',
    'filled-line-deal',
    'filled-line-decision-making',
    'filled-line-delivery',
    'filled-line-departure',
    'filled-line-desk-alt',
    'filled-line-desk',
    'filled-line-desktop-alt',
    'filled-line-desktop',
    'filled-line-diamond',
    'filled-line-dictionary',
    'filled-line-direct-linking',
    'filled-line-direction',
    'filled-line-directory',
    'filled-line-disconnect',
    'filled-line-discount',
    'filled-line-dislike',
    'filled-line-diving',
    'filled-line-dna',
    'filled-line-dollar-coin',
    'filled-line-dollar-paper',
    'filled-line-domain',
    'filled-line-donation',
    'filled-line-donut-chart',
    'filled-line-donut',
    'filled-line-down-alt',
    'filled-line-down',
    'filled-line-download-queue',
    'filled-line-download',
    'filled-line-draft-form-library',
    'filled-line-draft',
    'filled-line-drone',
    'filled-line-duct-tape',
    'filled-line-dvd-player',
    'filled-line-dvd',
    'filled-line-earphone-alt',
    'filled-line-earphone',
    'filled-line-edit-profile-alt',
    'filled-line-edit-profile',
    'filled-line-egg',
    'filled-line-eject',
    'filled-line-email-attachment',
    'filled-line-email-draft',
    'filled-line-email-encryption',
    'filled-line-email-save',
    'filled-line-email-search',
    'filled-line-email',
    'filled-line-enema',
    'filled-line-exit-fullscreen',
    'filled-line-export-data',
    'filled-line-external-link',
    'filled-line-eye-chart',
    'filled-line-eye-close',
    'filled-line-eye-open',
    'filled-line-fake-traffic',
    'filled-line-fax',
    'filled-line-feather-pen',
    'filled-line-feedback',
    'filled-line-feeling',
    'filled-line-female-user-alt',
    'filled-line-female-user',
    'filled-line-fencing-helmet',
    'filled-line-fencing',
    'filled-line-file-storage',
    'filled-line-file',
    'filled-line-film',
    'filled-line-filter',
    'filled-line-fish',
    'filled-line-flag-alt',
    'filled-line-flag',
    'filled-line-flash',
    'filled-line-flashdisk',
    'filled-line-flask',
    'filled-line-folder-close-alt',
    'filled-line-folder-close',
    'filled-line-folder-open-alt',
    'filled-line-folder-open',
    'filled-line-follow',
    'filled-line-followers',
    'filled-line-following',
    'filled-line-font',
    'filled-line-footstep',
    'filled-line-forest',
    'filled-line-form-2',
    'filled-line-form-library',
    'filled-line-form',
    'filled-line-free',
    'filled-line-french-fries',
    'filled-line-friend-list',
    'filled-line-ftp',
    'filled-line-fullscreen',
    'filled-line-funnel',
    'filled-line-gameboy',
    'filled-line-gamepad',
    'filled-line-gasoline',
    'filled-line-gift',
    'filled-line-glass-hour',
    'filled-line-globe',
    'filled-line-goal',
    'filled-line-gold',
    'filled-line-grape',
    'filled-line-grocery',
    'filled-line-group',
    'filled-line-gymnastic',
    'filled-line-handicap',
    'filled-line-handsfree',
    'filled-line-handy-talkie',
    'filled-line-hashtag',
    'filled-line-hdd',
    'filled-line-hdmi-port',
    'filled-line-headphone-alt',
    'filled-line-headphone',
    'filled-line-heart-rate',
    'filled-line-heart',
    'filled-line-help',
    'filled-line-hierarchial-structure',
    'filled-line-hieroglyphic',
    'filled-line-hit',
    'filled-line-hiv-ribbon',
    'filled-line-hockey',
    'filled-line-home-alt',
    'filled-line-home-theater',
    'filled-line-home',
    'filled-line-honey',
    'filled-line-horizontal-graphic-alt',
    'filled-line-horizontal-graphic',
    'filled-line-hospital-sign',
    'filled-line-hospital',
    'filled-line-hosting',
    'filled-line-hot-coffee',
    'filled-line-hotel',
    'filled-line-html',
    'filled-line-human-to-human',
    'filled-line-ice-coffee',
    'filled-line-ice-cream-cone',
    'filled-line-ice-skate',
    'filled-line-id-card',
    'filled-line-idea',
    'filled-line-import-data',
    'filled-line-important',
    'filled-line-impression',
    'filled-line-inbox-empty',
    'filled-line-inbox-full',
    'filled-line-incoming-call',
    'filled-line-indent-less',
    'filled-line-indent-more',
    'filled-line-infographic',
    'filled-line-information',
    'filled-line-infusion',
    'filled-line-initiate-money-transfer',
    'filled-line-ink',
    'filled-line-insights',
    'filled-line-intercom',
    'filled-line-internal-link',
    'filled-line-internet-banking',
    'filled-line-investment',
    'filled-line-invite-friends',
    'filled-line-invoice',
    'filled-line-italic',
    'filled-line-joystick',
    'filled-line-juice',
    'filled-line-justify',
    'filled-line-karate',
    'filled-line-kayak',
    'filled-line-key',
    'filled-line-keyboard-alt',
    'filled-line-keyboard',
    'filled-line-keyword-density',
    'filled-line-keyword-research',
    'filled-line-keyword',
    'filled-line-kidney',
    'filled-line-kiwi',
    'filled-line-label',
    'filled-line-language',
    'filled-line-laptop-alt',
    'filled-line-laptop',
    'filled-line-leader',
    'filled-line-leads',
    'filled-line-left-alt',
    'filled-line-left',
    'filled-line-legal',
    'filled-line-lemon',
    'filled-line-lettuce',
    'filled-line-lifebuoy',
    'filled-line-like',
    'filled-line-link-exchange',
    'filled-line-link-farm',
    'filled-line-link',
    'filled-line-linked-accounts',
    'filled-line-list',
    'filled-line-live-video',
    'filled-line-liver',
    'filled-line-loading',
    'filled-line-local-subaquatic-network',
    'filled-line-location-area',
    'filled-line-location-pin',
    'filled-line-lock',
    'filled-line-login',
    'filled-line-logout',
    'filled-line-lollipop',
    'filled-line-luggage',
    'filled-line-lungs',
    'filled-line-magazine',
    'filled-line-magnet',
    'filled-line-mailbox',
    'filled-line-mailing-list',
    'filled-line-map-alt',
    'filled-line-map',
    'filled-line-marketing',
    'filled-line-mastercard',
    'filled-line-medal',
    'filled-line-medical-bag',
    'filled-line-medicine',
    'filled-line-megaphone-alt',
    'filled-line-megaphone',
    'filled-line-melon',
    'filled-line-membership-card',
    'filled-line-menu-burger',
    'filled-line-menu-dots',
    'filled-line-message-bottle',
    'filled-line-message',
    'filled-line-messages',
    'filled-line-meta-tag',
    'filled-line-microphone-disable',
    'filled-line-microphone',
    'filled-line-microscope',
    'filled-line-milk',
    'filled-line-mini-traffic',
    'filled-line-minus',
    'filled-line-mobile-banking',
    'filled-line-mocktail',
    'filled-line-moderator',
    'filled-line-money-exchange',
    'filled-line-monopod',
    'filled-line-morse-code',
    'filled-line-mortar',
    'filled-line-mouse-alt',
    'filled-line-mouse',
    'filled-line-movie-ticket',
    'filled-line-muffin',
    'filled-line-multiple-destinations',
    'filled-line-navigation',
    'filled-line-network',
    'filled-line-neutral-trading',
    'filled-line-news-feed',
    'filled-line-newspaper',
    'filled-line-next',
    'filled-line-nintendo-switch',
    'filled-line-nintendo-wii',
    'filled-line-noodle',
    'filled-line-note',
    'filled-line-notification-disable',
    'filled-line-notification',
    'filled-line-numpad',
    'filled-line-office-alt',
    'filled-line-office-chair',
    'filled-line-office',
    'filled-line-olive-oil',
    'filled-line-onion',
    'filled-line-orange',
    'filled-line-organic',
    'filled-line-organization',
    'filled-line-outbox-empty',
    'filled-line-outbox-full',
    'filled-line-outgoing-call',
    'filled-line-packaging',
    'filled-line-page',
    'filled-line-pager',
    'filled-line-pages',
    'filled-line-paid',
    'filled-line-panel',
    'filled-line-paperplane',
    'filled-line-passport',
    'filled-line-password-alt',
    'filled-line-password',
    'filled-line-pasta',
    'filled-line-paste',
    'filled-line-pause',
    'filled-line-pay-per-click',
    'filled-line-pear',
    'filled-line-pen',
    'filled-line-pepper',
    'filled-line-percentage',
    'filled-line-petri-dish',
    'filled-line-phone-book',
    'filled-line-phone-booth',
    'filled-line-phone-lock',
    'filled-line-phone-support-24-hour',
    'filled-line-phone-unlock',
    'filled-line-php',
    'filled-line-picture',
    'filled-line-pie-chart',
    'filled-line-pills',
    'filled-line-pineapple',
    'filled-line-pizza',
    'filled-line-play',
    'filled-line-playstation',
    'filled-line-plugs',
    'filled-line-plus',
    'filled-line-pocket-knife',
    'filled-line-podium',
    'filled-line-popcorn',
    'filled-line-popsicle',
    'filled-line-post-engagements',
    'filled-line-post',
    'filled-line-potion',
    'filled-line-power-sign',
    'filled-line-presentation-alt',
    'filled-line-presentation',
    'filled-line-primary-inbox',
    'filled-line-print-alt',
    'filled-line-print',
    'filled-line-private-account',
    'filled-line-profile-picture-alt',
    'filled-line-profile-picture',
    'filled-line-profit',
    'filled-line-projector',
    'filled-line-promote',
    'filled-line-public-speaking',
    'filled-line-publish',
    'filled-line-publisher',
    'filled-line-purse',
    'filled-line-push-notification',
    'filled-line-pushpin',
    'filled-line-puzzle',
    'filled-line-qr-code',
    'filled-line-quote',
    'filled-line-racing-flag',
    'filled-line-racing-helmet',
    'filled-line-racket',
    'filled-line-radio',
    'filled-line-ranking',
    'filled-line-reach',
    'filled-line-receipt',
    'filled-line-recommendation',
    'filled-line-record',
    'filled-line-refresh-wifi',
    'filled-line-refresh',
    'filled-line-remote',
    'filled-line-remove-contact',
    'filled-line-remove-friend',
    'filled-line-remove-group',
    'filled-line-repeat',
    'filled-line-reply-all',
    'filled-line-reply',
    'filled-line-rice',
    'filled-line-right-alt',
    'filled-line-right',
    'filled-line-rocket-launch',
    'filled-line-rugby-ball',
    'filled-line-running-shoe',
    'filled-line-sad',
    'filled-line-sale',
    'filled-line-sandals',
    'filled-line-sandwich',
    'filled-line-satellite-alt',
    'filled-line-satellite',
    'filled-line-sauce',
    'filled-line-sausage',
    'filled-line-save',
    'filled-line-savings',
    'filled-line-scale',
    'filled-line-scam',
    'filled-line-scoreboard',
    'filled-line-screenshot',
    'filled-line-script',
    'filled-line-scroll',
    'filled-line-search-alt',
    'filled-line-search-check-alt',
    'filled-line-search-check',
    'filled-line-search-cross-alt',
    'filled-line-search-cross',
    'filled-line-search-engine-result-page',
    'filled-line-search-minus-alt',
    'filled-line-search-minus',
    'filled-line-search-plus-alt',
    'filled-line-search-plus',
    'filled-line-search',
    'filled-line-semaphore',
    'filled-line-send-alt',
    'filled-line-send',
    'filled-line-server',
    'filled-line-share',
    'filled-line-shared-hosting',
    'filled-line-shield',
    'filled-line-ship-flag',
    'filled-line-ship',
    'filled-line-shipping',
    'filled-line-shirt',
    'filled-line-shop-sign',
    'filled-line-shop',
    'filled-line-shopping-bag-alt',
    'filled-line-shopping-bag',
    'filled-line-shopping-cart-alt',
    'filled-line-shopping-cart',
    'filled-line-shorts',
    'filled-line-shredder',
    'filled-line-shuffle:before',
    'filled-line-shuttlecock',
    'filled-line-signal',
    'filled-line-skull',
    'filled-line-smartphone-alt',
    'filled-line-smartphone',
    'filled-line-smile',
    'filled-line-smoke-signal',
    'filled-line-snooze',
    'filled-line-soccer-ball',
    'filled-line-soccer-field',
    'filled-line-soccer-jersey',
    'filled-line-soccer-shoe',
    'filled-line-soda',
    'filled-line-sort-by-alphabet-a-to-z',
    'filled-line-sort-by-alphabet-z-to-a',
    'filled-line-sort-by-attribute-max-to-min',
    'filled-line-sort-by-attribute-min-to-max',
    'filled-line-sort-by-order-max-to-min',
    'filled-line-sort-by-order-min-to-max',
    'filled-line-sos-panic-button',
    'filled-line-soup',
    'filled-line-spam',
    'filled-line-speech',
    'filled-line-speedometer',
    'filled-line-sperm',
    'filled-line-stamp',
    'filled-line-stapler',
    'filled-line-star',
    'filled-line-stars',
    'filled-line-statistic-alt',
    'filled-line-statistic',
    'filled-line-steak',
    'filled-line-stethoscope',
    'filled-line-stop',
    'filled-line-stopwatch',
    'filled-line-subdomain',
    'filled-line-subject',
    'filled-line-subscribe',
    'filled-line-suitcase',
    'filled-line-sunblock',
    'filled-line-surface-acoustic-wave',
    'filled-line-surfboard',
    'filled-line-swipe',
    'filled-line-sync',
    'filled-line-tag',
    'filled-line-tags',
    'filled-line-tape-recorder',
    'filled-line-target-alt',
    'filled-line-target',
    'filled-line-task',
    'filled-line-tea',
    'filled-line-team-strategy',
    'filled-line-teamwork',
    'filled-line-tee',
    'filled-line-telegraph',
    'filled-line-telepathy',
    'filled-line-television-alt',
    'filled-line-television',
    'filled-line-template',
    'filled-line-tennis-ball',
    'filled-line-tennis-field',
    'filled-line-tent',
    'filled-line-test-tube',
    'filled-line-text-colour',
    'filled-line-text-lines',
    'filled-line-thermometer',
    'filled-line-tie',
    'filled-line-time',
    'filled-line-timeline',
    'filled-line-tin-cans-phone',
    'filled-line-tomato',
    'filled-line-tooth',
    'filled-line-tracking-code',
    'filled-line-tracks',
    'filled-line-trademark',
    'filled-line-traffic',
    'filled-line-train',
    'filled-line-transmission',
    'filled-line-trash-empty',
    'filled-line-trash-full',
    'filled-line-trash',
    'filled-line-travel-ticket',
    'filled-line-tripod',
    'filled-line-trophy',
    'filled-line-typewriter',
    'filled-line-umbrella',
    'filled-line-underline',
    'filled-line-unfollow',
    'filled-line-unlock',
    'filled-line-unsubscribe',
    'filled-line-up-alt',
    'filled-line-up',
    'filled-line-upload-queue',
    'filled-line-upload',
    'filled-line-usb-port',
    'filled-line-user-alt',
    'filled-line-user',
    'filled-line-vaccine',
    'filled-line-verification-fail',
    'filled-line-verification',
    'filled-line-vga-port',
    'filled-line-video-call',
    'filled-line-video-recorder',
    'filled-line-video',
    'filled-line-vintage-phone',
    'filled-line-viral-marketing',
    'filled-line-virus',
    'filled-line-voicemail',
    'filled-line-volley-ball',
    'filled-line-volume-disable',
    'filled-line-volume-down-2',
    'filled-line-volume-down',
    'filled-line-volume-up',
    'filled-line-volume',
    'filled-line-vr-box',
    'filled-line-wallet',
    'filled-line-watch-alt',
    'filled-line-watch',
    'filled-line-water-bottle',
    'filled-line-water-glass',
    'filled-line-watermelon',
    'filled-line-wave',
    'filled-line-webcam',
    'filled-line-wheelchair',
    'filled-line-white-hat',
    'filled-line-wifi-alt',
    'filled-line-wifi-router-alt',
    'filled-line-wifi-router',
    'filled-line-wifi',
    'filled-line-work',
    'filled-line-workflow',
    'filled-line-wrench',
    'filled-line-write',
    'filled-line-x-ray',
    'filled-line-yogurt',
  ]
}
