import React, { useState } from 'react'
import Tippy from '@tippyjs/react'
import PropTypes from 'prop-types'
import styles from './_tooltip.scss'
import TooltipListModel from './model/placement-list.model'

const Tooltip = ({
  children,
  content,
  placement,
  interactive,
  eventListener
}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  return (
    <div className={styles.containerTooltip}>
      <Tippy
        appendTo='parent'
        content={content}
        visible={visible}
        placement={placement}
        interactive={interactive}
        onClickOutside={hide}
        render={(attrs) =>
          visible && (
            <div className={styles.box} tabIndex='-1'>
              <div className={styles.content}>{content}</div>
              <div
                data-testid='test-box-arrow'
                data-placement={attrs['data-placement']}
                className={styles.arrow}
              />
            </div>
          )
        }
      >
        <div
          aria-hidden='true'
          onMouseOver={eventListener === 'hover' && show}
          onMouseLeave={eventListener === 'hover' && hide}
          onFocus={eventListener === 'hover' && show}
          onClick={eventListener === 'mouseClick' && visible ? hide : show}
        >
          {children}
        </div>
      </Tippy>
    </div>
  )
}

export default Tooltip

Tooltip.defaultProps = {
  content: 'Text for tooltip',
  placement: 'top',
  interactive: false,
  eventListener: 'hover'
}

Tooltip.propTypes = {
  placement: PropTypes.oneOf(TooltipListModel.placementList),
  eventListener: PropTypes.oneOf(['mouseClick', 'hover']),
  content: PropTypes.node,
  interactive: PropTypes.bool
}
