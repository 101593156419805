import React from 'react'
import styles from './_modalFooter.scss'

const ModalFooter = ({ children }) => (
    <div className={styles.modalFooter}>
    <div className={styles.modalContent}>
    {children}
    </div>
    </div>
)
export default ModalFooter