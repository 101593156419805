import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_columncardaction.scss'
import Button from '../../../buttons'

const ColumnCardContentActions = ({ buttons, className, children }) => {
  const cardActionContainer = [styles.columnCardActionsContainer]

  return (
    <div className={cardActionContainer.concat(className).join(' ')}>
      {buttons &&
        buttons.map((button) => (
          <div
            className={styles.columnCardActionItem}
            key={`card-action-column-item-${nanoid()}`}
          >
            {/* <Button utility="alignRight" variant="text" color="primary" > */}
            <Button
              utility='alignRight'
              variant='text'
              color={button.color ? button.color : 'primary'}
              suffix='arrow-right'
              onClick={button.onClick}
            >
              {button.label}
            </Button>
          </div>
        ))}
      {children}
    </div>
  )
}

ColumnCardContentActions.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      prefix: PropTypes.string,
      suffix: PropTypes.string,
      color: PropTypes.oneOf(['primary', 'secondary']),
      variant: PropTypes.oneOf(['', 'outline', 'text']),
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool
    })
  ),
  className: PropTypes.string,
  children: PropTypes.node
}

export default ColumnCardContentActions
