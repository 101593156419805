import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_info.scss'

const ModalInfo = ({ image, title, description, buttons }) => (
  <div className={styles.modalInfo}>
    {image}
    {title && <h4>{title}</h4>}
    {description && <p>{description}</p>}
    <div className={styles.buttonSection}>
      {buttons &&
        buttons.map((btn) => <div key={`button-${nanoid()}`}>{btn}</div>)}
    </div>
  </div>
)

ModalInfo.defaultProps = {
  image: React.createElement('img'),
  title: '',
  description: '',
  buttons: []
}

ModalInfo.propTypes = {
  image: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.node)
}

export default ModalInfo
