import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types'
import styles from './_recommender.scss';

const Recommender = ({
  distanceX="8", // horizontal distance from left 
  distanceY="8", // vertical distance from top
  placement = 'bottom', // componente position: top, bottom, right, left 
  showArrow = true, // arrow visualization
  className, // pass custom className
  children,
}) => {
  return (
    <article
      className={classNames(className, styles.recommenderContainer)}
      data-placement={showArrow ? placement : ''}
      style={{
        '--recommender-distanceX': `${distanceX}%`,
        '--recommender-distanceY': `${distanceY}%`,
      }}
    >
      {children}
    </article>
  )
};

Recommender.defaultProps = {
  distanceX: "8",
  distanceY: "8",
  placement: 'bottom',
  showArrow: true,
  className: "",
  children: {},
}

Recommender.PropTypes = {
  distanceX: PropTypes.string,
  distanceY: PropTypes.string,
  placement: PropTypes.string.isRequired,
  showArrow: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.element,
}

export default Recommender;


