import React from 'react'

const ERRORMOBILE = () => {
  const elm = (
    <svg
      width='140px'
      height='128px'
      viewBox='0 0 140 128'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <polygon
          id='path-1'
          points='0.000124444444 0 112 0 112 112 0.000124444444 112'
        ></polygon>
        <polygon
          id='path-3'
          points='0.526275556 0.385155556 19.9971933 0.385155556 19.9971933 41.2584356 0.526275556 41.2584356'
        ></polygon>
      </defs>
      <g
        id='MENSAJERIAS'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
      >
        <g id='APP-ERROR' transform='translate(-90.000000, -40.000000)'>
          <g id='ERROR-APP' transform='translate(90.000000, 40.000000)'>
            <g transform='translate(14.000000, 5.000000)'>
              <g>
                <g id='ROSTRO-Y-CUERPO'>
                  <g id='Group-3' transform='translate(0.000000, 2.562933)'>
                    <mask id='mask-2' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-2'></g>
                    <path
                      d='M112.000124,56 C112.000124,86.9281778 86.92768,112 56.0001244,112 C25.0719467,112 0.000124444444,86.9281778 0.000124444444,56 C0.000124444444,25.0718222 25.0719467,0 56.0001244,0 C86.92768,0 112.000124,25.0718222 112.000124,56'
                      id='Fill-1'
                      fill='#F3E0E1'
                      mask='url(#mask-2)'
                    ></path>
                  </g>
                  <path
                    d='M95.6236711,71.2778578 C95.6236711,55.2214133 82.6074044,42.2051467 66.55096,42.2051467 L45.4632267,42.2051467 C29.40616,42.2051467 16.3898933,55.2214133 16.3898933,71.2778578 L16.3898933,98.6967022 C26.5246489,108.83768 40.5296267,115.110924 55.9999378,115.110924 C71.4770933,115.110924 85.4876711,108.831458 95.6236711,98.6830133 L95.6236711,71.2778578 Z'
                    id='Fill-4'
                    fill='#BC3951'
                  ></path>
                  <path
                    d='M83.8305689,111.388729 C78.9990133,111.22384 74.3877244,107.044373 72.2404356,102.263218 L56.79128,67.8717511 L54.7939467,68.6663289 L53.2041689,64.6716622 L57.20568,59.2228622 L61.1935022,61.4921067 L62.3962578,58.6995733 L57.8783022,55.0987733 L58.0139467,53.5021511 L64.7943022,56.9741511 L64.3799022,65.6224178 L82.00808,90.6693511 L82.00808,72.50544 L95.6235467,72.50544 L95.6148356,99.2603733 C95.6135911,102.345973 94.5757244,105.37744 92.5335911,107.689618 C90.7198133,109.742951 87.94968,111.529351 83.8305689,111.388729'
                    id='Fill-6'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M56.1520089,68.1291022 L55.1396533,65.7659022 C55.0662311,65.5947911 55.1458756,65.3963022 55.3169867,65.32288 C55.4880978,65.2494578 55.6865867,65.3278578 55.7606311,65.5002133 L56.7736089,67.8627911 L56.1520089,68.1291022 Z'
                    id='Fill-8'
                    fill='#EF8674'
                  ></path>
                  <polygon
                    id='Fill-10'
                    fill='#F4B6A8'
                    points='65.1207822 45.3092267 46.8927822 45.3092267 48.2430044 27.0812267 63.77056 27.0812267'
                  ></polygon>
                  <path
                    d='M47.5229689,26.4079822 L42.3062578,23.34416 C41.1539022,22.6671822 40.7681244,21.1838044 41.44448,20.0320711 C42.1214578,18.8790933 43.6048356,18.4939378 44.7571911,19.1709156 L49.97328,22.2347378 L47.5229689,26.4079822 Z'
                    id='Fill-12'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M64.49128,26.4079822 L69.7079911,23.34416 C70.8597244,22.6671822 71.2455022,21.1838044 70.5691467,20.0320711 C69.8921689,18.8790933 68.4094133,18.4939378 67.2564356,19.1709156 L62.0403467,22.2347378 L64.49128,26.4079822 Z'
                    id='Fill-16'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M56.0225867,40.5835733 L56.0225867,40.5835733 C50.8021422,40.5835733 46.5710311,36.35184 46.5710311,31.1320178 L46.5710311,12.2071289 C46.5710311,9.23601778 48.9796533,6.82739556 51.9507644,6.82739556 L56.3517422,6.82739556 C61.3898756,6.82739556 65.4741422,10.91104 65.4741422,15.9497956 L65.4741422,31.1320178 C65.4741422,36.35184 61.2430311,40.5835733 56.0225867,40.5835733'
                    id='Fill-20'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M55.8318133,18.6417778 C55.8318133,18.6417778 58.3163467,21.6950222 58.4165244,27.1369778 L54.9793689,26.9496889'
                    id='Fill-30'
                    fill='#F4B6A8'
                  ></path>
                  <g id='Group-36' transform='translate(46.044444, 0.000000)'>
                    <mask id='mask-4' fill='white'>
                      <use></use>
                    </mask>
                    <g id='Clip-35'></g>
                    <path
                      d='M18.0797867,20.0611911 C18.0797867,24.5219022 14.5897422,28.3479467 10.1302756,28.4294578 C5.58618667,28.5128356 1.87649778,24.8541689 1.87649778,20.3299911 L1.87649778,14.5203022 C1.87649778,12.6536356 3.49863111,11.2032356 5.35409778,11.4085689 C8.34449778,11.7395911 11.7804089,12.0886578 12.2999644,12.0090133 C14.0813867,12.0575467 15.46272,11.5915022 16.5410311,10.8442133 C17.5166756,11.9430578 18.0797867,13.3710578 18.0797867,14.87808 L18.0797867,20.0611911 Z M17.7524978,0.813991111 L17.7518756,0.813991111 C15.5473422,-0.287964444 12.8773867,0.817724444 12.0983644,3.15665778 L10.1993422,6.82714667 L5.90663111,6.82714667 C3.02823111,6.82714667 0.66752,9.10696889 0.566097778,11.98288 L0.526275556,13.09168 L0.526275556,28.4313244 L0.526275556,31.1317689 L0.526275556,31.80688 C0.526275556,37.0273244 4.75800889,41.2584356 9.97783111,41.2584356 C15.1982756,41.2584356 19.4300089,37.0273244 19.4300089,31.80688 L19.4300089,31.1317689 L19.4300089,28.4313244 L19.4300089,14.5912356 C19.4300089,14.5912356 19.5569422,11.3693689 17.9740089,9.49585778 C18.97392,8.24519111 19.5525867,6.72136889 19.8817422,5.37985778 C20.3309867,3.55052444 19.4374756,1.65710222 17.7524978,0.813991111 L17.7524978,0.813991111 Z'
                      id='Fill-34'
                      fill='#EF8A33'
                      mask='url(#mask-4)'
                    ></path>
                  </g>
                  <path
                    d='M65.9471556,93.8698756 L55.6724,93.8698756 C54.8056444,93.8698756 54.1031556,93.1667644 54.1031556,92.3006311 L54.1031556,72.2700533 C54.1031556,71.4032978 54.8056444,70.7008089 55.6724,70.7008089 L65.9471556,70.7008089 C66.8145333,70.7008089 67.5164,71.4032978 67.5164,72.2700533 L67.5164,92.3006311 C67.5164,93.1667644 66.8145333,93.8698756 65.9471556,93.8698756'
                    id='Fill-39'
                    fill='#F6F3F5'
                  ></path>
                  <path
                    d='M57.8291467,73.3086044 C57.8291467,73.9258489 57.3282578,74.4267378 56.7110133,74.4267378 C56.0937689,74.4267378 55.5935022,73.9258489 55.5935022,73.3086044 C55.5935022,72.69136 56.0937689,72.1904711 56.7110133,72.1904711 C57.3282578,72.1904711 57.8291467,72.69136 57.8291467,73.3086044'
                    id='Fill-41'
                    fill='#D4D0D5'
                  ></path>
                  <path
                    d='M64.3520889,89.5701956 L63.5761778,82.5851289 L52.9928,83.5197067 L30.0054222,95.8222844 L30.0054222,90.6696622 L30.0054222,72.5051289 L16.3899556,72.5051289 L16.3992889,99.2600622 C16.3999111,102.345662 17.4384,105.376507 19.4805333,107.689307 C20.7492444,109.127262 22.4896,110.429573 24.8297778,111.03624 C29.1959111,112.310551 34.6316444,110.381662 38.2934222,107.085751 L55.0679111,92.0889511 L55.0610667,92.0771289 L64.3520889,89.5701956 Z'
                    id='Fill-43'
                    fill='#F4B6A8'
                  ></path>
                  <path
                    d='M56.0730489,53.00736 C63.2603378,53.00736 69.4719822,48.8459378 72.4406044,42.8029156 C70.5384711,42.4115378 68.5685156,42.2055822 66.5506489,42.2055822 L45.4629156,42.2055822 C43.4910933,42.2055822 41.5671822,42.4034489 39.7048711,42.7780267 C42.6691378,48.8316267 48.8782933,53.00736 56.0730489,53.00736'
                    id='Fill-45'
                    fill='#F4B6A8'
                  ></path>
                </g>
                <g id='EXPRESIONES' transform='translate(42.486589, 15.439766)'>
                  <path
                    d='M3.39875303,7.44208248 C3.3502197,7.44208248 3.30106414,7.43088248 3.25439748,7.40910471 L0.193064145,5.96119359 C0.0244419224,5.88154915 -0.0471136332,5.68057137 0.0325308113,5.51132693 C0.112797478,5.34394915 0.3150197,5.27301582 0.482397478,5.35141582 L3.54310859,6.79932693 C3.71173081,6.87897137 3.78328637,7.07994915 3.70364192,7.24857137 C3.64577526,7.37052693 3.52506414,7.44208248 3.39875303,7.44208248'
                    id='Fill-14'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M23.6422553,7.44208248 C23.5159441,7.44208248 23.395233,7.37052693 23.3373664,7.24857137 C23.2577219,7.07994915 23.3292775,6.87897137 23.4978997,6.79932693 L26.5586108,5.35141582 C26.7278553,5.27301582 26.9275886,5.34394915 27.0078553,5.51132693 C27.0874997,5.68057137 27.0159441,5.88154915 26.8473219,5.96119359 L23.7866108,7.40910471 C23.7399441,7.43088248 23.6907886,7.44208248 23.6422553,7.44208248'
                    id='Fill-18'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M10.5039086,4.74599359 C10.583553,5.29977137 10.1996419,5.81310471 9.64586414,5.89274915 C9.09208637,5.97239359 8.57875303,5.58786026 8.49910859,5.03408248 C8.41946414,4.48030471 8.80399748,3.96697137 9.35777526,3.88794915 C9.91155303,3.80830471 10.4242641,4.19221582 10.5039086,4.74599359'
                    id='Fill-22'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M18.7149397,4.74599359 C18.7945841,5.29977137 18.410673,5.81310471 17.8568953,5.89274915 C17.3031175,5.97239359 16.7897841,5.58786026 16.7101397,5.03408248 C16.6304953,4.48030471 17.0156508,3.96697137 17.5688064,3.88794915 C18.1225841,3.80830471 18.6359175,4.19221582 18.7149397,4.74599359'
                    id='Fill-24'
                    fill='#1F1E1E'
                  ></path>
                  <path
                    d='M6.37266414,2.15213582 C6.17479748,1.95364693 6.17479748,1.63444693 6.37204192,1.43658026 C7.30413081,0.503246928 9.62004192,-0.849464183 11.711953,0.717291373 C11.935953,0.885291373 11.9813753,1.20200248 11.8139975,1.42600248 C11.6453753,1.64938026 11.3286641,1.69542471 11.1052864,1.52804693 C9.1938197,0.0969358172 7.17346414,2.06875804 7.08759748,2.15338026 C6.98928637,2.25169137 6.85986414,2.30022471 6.73044192,2.30022471 C6.6010197,2.30022471 6.47097526,2.25106915 6.37266414,2.15213582 Z'
                    id='Fill-26'
                    fill='#EF8A33'
                  ></path>
                  <path
                    d='M19.8276597,2.15338026 C19.7411708,2.06938026 17.7189486,0.0975580394 15.8112153,1.52804693 C15.5859708,1.69666915 15.2698819,1.64938026 15.1018819,1.42662471 C14.9345041,1.20200248 14.9799264,0.885291373 15.2033041,0.717291373 C17.2964597,-0.848841961 19.6105041,0.502624706 20.5432153,1.43658026 C20.7404597,1.63444693 20.7404597,1.95364693 20.542593,2.15213582 C20.4442819,2.25106915 20.3142375,2.30022471 20.1848153,2.30022471 C20.055393,2.30022471 19.9259708,2.25169137 19.8276597,2.15338026 Z'
                    id='Fill-28'
                    fill='#EF8A33'
                  ></path>
                  <path
                    d='M16.2740241,12.0540558 L12.4747353,11.8468558 C12.2880686,11.836278 12.1455797,11.6776114 12.1555353,11.4915669 C12.1654908,11.3055225 12.3322464,11.1736114 12.5108241,11.1723669 L15.582113,11.3403669 C15.3854908,6.30161137 13.106913,3.44374471 13.0832686,3.41512248 C12.9656686,3.27076693 12.9874464,3.05796693 13.1318019,2.94036693 C13.2761575,2.82338915 13.4883353,2.84330026 13.6071797,2.98890026 C13.711713,3.11770026 16.1663797,6.19583359 16.2671797,11.6913003 L16.2740241,12.0540558 Z'
                    id='Fill-32'
                    fill='#EF8674'
                  ></path>
                  <path
                    d='M15.2823886,17.4589892 C15.3881664,18.1975669 14.8760775,18.8813892 14.1381219,18.9877892 C13.4001664,19.0935669 12.7157219,18.581478 12.6093219,17.8429003 C12.5035441,17.1055669 13.015633,16.4211225 13.7542108,16.3147225 C14.4915441,16.2089447 15.1759886,16.7210336 15.2823886,17.4589892'
                    id='Fill-37'
                    fill='#1F1E1E'
                  ></path>
                </g>
              </g>
              <g id='ERROR' transform='translate(84.000000, 11.000000)'>
                <circle
                  id='Oval'
                  fill='#FFFFFF'
                  cx='10'
                  cy='10.7116667'
                  r='8'
                ></circle>
                <g id='00.Token/Icons/Solid/16x16/cross' fill='#BF0238'>
                  <path
                    d='M10,1.25 C5.16125,1.25 1.25,5.16125 1.25,10 C1.25,14.83875 5.16125,18.75 10,18.75 C14.83875,18.75 18.75,14.83875 18.75,10 C18.75,5.16125 14.83875,1.25 10,1.25 Z M13.5111595,13.5111595 C13.1927055,13.8296135 12.6782798,13.8296135 12.3598258,13.5111595 L10,11.1513337 L7.6401742,13.5111595 C7.3217202,13.8296135 6.8072945,13.8296135 6.4888405,13.5111595 C6.1703865,13.1927055 6.1703865,12.6782798 6.4888405,12.3598258 L8.8486663,10 L6.4888405,7.6401742 C6.1703865,7.3217202 6.1703865,6.8072945 6.4888405,6.4888405 C6.8072945,6.1703865 7.3217202,6.1703865 7.6401742,6.4888405 L10,8.8486663 L12.3598258,6.4888405 C12.6782798,6.1703865 13.1927055,6.1703865 13.5111595,6.4888405 C13.8296135,6.8072945 13.8296135,7.3217202 13.5111595,7.6401742 L11.1513337,10 L13.5111595,12.3598258 C13.821448,12.6701143 13.821448,13.1927055 13.5111595,13.5111595 Z'
                    id='color-icono'
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )

  return <React.Fragment>{elm}</React.Fragment>
}

export default ERRORMOBILE
