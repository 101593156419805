import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_sidebarElement.scss'
import Icon from '../../icons'

const SidebarElement = ({
  text,
  icon,
  sublevel,
  level,
  notification,
  active,
  open
}) => {
  const defaultIcon =
    !icon && level === 3 ? <Icon name='old-arrow-right' size='1' /> : icon
  let containerClasses = [styles.sidebarElement, styles[`level-${level}`]]

  if (active === true || open) {
    containerClasses = containerClasses.concat(styles.active)
  }

  return (
    <React.Fragment>
      <div
        role='navigation'
        data-status={active ? 'active' : ''}
        className={containerClasses.join(' ')}
      >
        <div className={[styles.textContainer].join(' ')}>
          {defaultIcon}
          {text}
        </div>
        {notification === true && (
          <span className={[styles.notification].join(' ')} />
        )}
      </div>
      {open &&
        sublevel.map((child) => (
          <SidebarElement key={nanoid()} level={level + 1} {...child} />
        ))}
    </React.Fragment>
  )
}

const levelProps = {
  text: PropTypes.string,
  icon: PropTypes.element,
  level: PropTypes.number,
  notification: PropTypes.bool,
  active: PropTypes.bool
}

SidebarElement.defaultProps = {
  level: 1,
  open: false,
  sublevel: []
}

SidebarElement.propTypes = {
  ...levelProps,
  level: PropTypes.number,
  open: PropTypes.bool,
  sublevel: PropTypes.arrayOf(
    PropTypes.shape({
      ...levelProps,
      sublevel: PropTypes.arrayOf(PropTypes.shape({ ...levelProps }))
    })
  )
}

export default SidebarElement
