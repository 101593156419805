import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import InputCore from '../core'
import Icon from '../../icons'
import styles from './_search.scss'
import InputChip from '../chips'

const InputSearch = ({
  label,
  filterAction,
  tagLinks,
  clearAction,
  ...props
}) => {
  let inputContainerClases = [styles.inputConteiner]

  if (props.disabled) {
    inputContainerClases = inputContainerClases.concat(styles.disabled)
  }

  const prefix = <Icon name='old-line-search' size={1} />
  const suffix = (
    <Icon
      onClick={clearAction}
      className={styles.crossIcon}
      name='old-cross'
      size={1}
    />
  )

  return (
    <React.Fragment>
      <div className={inputContainerClases.join(' ').trim()}>
        {label && <span className={styles.inputLabel}> {label} </span>}
        <div className={styles.inputRow}>
          <InputCore prefix={prefix} suffix={suffix} />
        </div>
        {tagLinks && (
          <div className={styles.tagLinkContainer}>
            {tagLinks.map((item) => (
              <div
                key={`tagLinks-item-${nanoid()}`}
                className={styles.tagLinkItem}
              >
                <InputChip value={item.label} onClose={item.onClick} />
              </div>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

InputSearch.defaultProps = {
  filterAction: /* istanbul ignore next */ () => null,
  clearAction: /* istanbul ignore next */ () => null
}

InputSearch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  filterAction: PropTypes.func,
  clearAction: PropTypes.func,
  tagLinks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  )
}

export default InputSearch
