import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import styles from './_cardaction.scss'
import Button from '../../buttons'

const CardActions = ({ buttons, className, children }) => {
  const cardActionContainer = [styles.cardActionsContainer]

  return (
    <div className={cardActionContainer.concat(className).join(' ')}>
      {buttons &&
        buttons.map((button) => (
          <div
            className={styles.cardActionItem}
            key={`card-action-item-${nanoid()}`}
          >
            <Button
              style={{ width: '90%', justifyContent: 'center' }}
              {...button}
            >
              {' '}
              {button.label}{' '}
            </Button>
          </div>
        ))}
      {children}
    </div>
  )
}

CardActions.defaultProps = {
  className: '',
  buttons: []
}
CardActions.propTypes = {
  className: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      prefix: PropTypes.string,
      suffix: PropTypes.string,
      color: PropTypes.oneOf(['primary', 'secondary']),
      variant: PropTypes.oneOf(['', 'outline', 'text']),
      onClick: PropTypes.func.isRequired,
      disabled: PropTypes.bool
    })
  ),
  children: PropTypes.node
}

export default CardActions
