import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styles from './_columncardcontent.scss'

const ColumnCardContent = ({
  highlight,
  title,
  content,
  className,
  children
}) => {
  const [columnCardContentStyle, setColumnCardContentStyle] = useState([
    styles.columnCardContentContainer
  ])

  useEffect(() => {
    if (highlight) {
      setColumnCardContentStyle([
        styles.columnCardContentContainer,
        styles.withHighlight
      ])
    } else {
      setColumnCardContentStyle([styles.columnCardContentContainer])
    }
  }, [highlight])

  return (
    <div className={columnCardContentStyle.concat(className).join(' ')}>
      {highlight && (
        <div className={styles.columnCardContentHeaderHighlight}>
          <p>{highlight}</p>
        </div>
      )}
      {title && (
        <div className={styles.columnCardContentHeaderTitle}>
          <p>{title}</p>
        </div>
      )}
      {content && (
        <div className={styles.columnCardContent}>
          <p className='note'>{content}</p>
        </div>
      )}
      {children}
    </div>
  )
}

ColumnCardContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.node
}

export default ColumnCardContent
