export default [
  {
    AREA: 'Celular con digito verificador',
    CODE: 569
  },
  {
    AREA: 'Celular',
    CODE: 56
  },
  {
    AREA: 'Arica',
    CODE: 58
  },
  {
    AREA: 'Iquique',
    CODE: 57
  },
  {
    AREA: 'Antofagasta',
    CODE: 55
  },
  {
    AREA: 'Copiapo',
    CODE: 52
  },
  {
    AREA: 'La Serena',
    CODE: 51
  },
  {
    AREA: 'Ovalle',
    CODE: 53
  },
  {
    AREA: 'Valparaiso',
    CODE: 32
  },
  {
    AREA: 'Quillota',
    CODE: 33
  },
  {
    AREA: 'Los Andes',
    CODE: 34
  },
  {
    AREA: 'San Antonio',
    CODE: 35
  },
  {
    AREA: 'Santiago',
    CODE: 2
  },
  {
    AREA: 'Rancagua',
    CODE: 72
  },
  {
    AREA: 'Talca',
    CODE: 71
  },
  {
    AREA: 'Linares',
    CODE: 73
  },
  {
    AREA: 'Curico',
    CODE: 75
  },
  {
    AREA: 'Concepción',
    CODE: 41
  },
  {
    AREA: 'Chillan',
    CODE: 42
  },
  {
    AREA: 'Los Ángeles',
    CODE: 43
  },
  {
    AREA: 'Temuco',
    CODE: 45
  },
  {
    AREA: 'Valdivia',
    CODE: 63
  },
  {
    AREA: 'Puerto Montt',
    CODE: 65
  },
  {
    AREA: 'Osorno',
    CODE: 64
  },
  {
    AREA: 'Coyhaique',
    CODE: 67
  },
  {
    AREA: 'Punta Arenas',
    CODE: 61
  }
]
