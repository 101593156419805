import React from 'react'
import PropTypes from 'prop-types'
import styles from './_publicFooterBottomBar.scss'

const PublicFooterBottomBar = ({ children, className }) => (
  <div
    className={[styles.publicFooterBottomBar, styles.footerDesktop]
      .concat(className)
      .join(' ')}
  >
    {children}
  </div>
)

PublicFooterBottomBar.defaultProps = {}

PublicFooterBottomBar.propTypes = {
  className: PropTypes.string
}

export default PublicFooterBottomBar
