import React from 'react'
import PropTypes from 'prop-types'
import { nanoid } from 'nanoid'
import BulletElement from './Bullet'

const Bullets = ({ items, typeList = '' }) => {
  const getTypeOfList = (typeOfBullet, element, index) => {
    let bulletElement
    if (typeOfBullet === 'order') {
      bulletElement = (
        <BulletElement
          key={nanoid()}
          count={index}
          typeList={typeOfBullet}
          {...element}
        />
      )
    }
    if (typeOfBullet === 'unorder' || typeOfBullet === 'icons') {
      bulletElement = (
        <BulletElement key={nanoid()} {...element} typeList={typeOfBullet} />
      )
    }
    return bulletElement
  }

  return (
    <div>
      {items.map((element, index) => getTypeOfList(typeList, element, index))}
    </div>
  )
}

Bullets.defaults = {
  typeList: 'order',
  items: []
}

Bullets.propTypes = {
  typeList: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      prefixType: PropTypes.string,
      contentType: PropTypes.string,
      icon: PropTypes.string
    })
  )
}

export default Bullets
