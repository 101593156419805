import React from 'react'
import PropTypes from 'prop-types'
import styles from './_notificationbadge.scss'

const NotificationBadge = ({ content }) => {
  const containerClass = [styles.notificationBadgeContainer]

  return <div className={containerClass.join(' ')}>{content}</div>
}

export default NotificationBadge

NotificationBadge.defaultProps = {
  content: 0
}

NotificationBadge.propTypes = {
  content: PropTypes.number
}
