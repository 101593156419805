import React, { useState, useEffect } from 'react'
import { nanoid } from 'nanoid'
import Functions from '../utils/functions'
import styles from './_table.scss'

const Table = ({ header, tableData, fullWidth }) => {
  const [status, setStatus] = useState(true)
  const [styleContainer, setStyleContainer] = useState([styles.tableContainer])

  useEffect(() => {
    if (!fullWidth)
      setStyleContainer(Functions.makeStyles(styles.tableContainer))

    if (fullWidth) {
      setStyleContainer(Functions.makeStyles(styles.fullWidth, styleContainer))
    }

    setStatus(!status)
  }, [fullWidth])

  return (
    <div className={styleContainer.join(' ')}>
      <table className={styles.table}>
        {header && (
          <thead>
            <tr key='table-tr_header' className={styles.tableHeader}>
              {header.map((head) => (
                <th key={`table-th_${nanoid()}`}>{head}</th>
              ))}
            </tr>
          </thead>
        )}

        <tbody>
          {tableData.map((row) => (
            <tr key={`table-tr_${nanoid()}`}>
              {row.map((cell) => {
                let isNumber = false
                if (typeof cell === 'number') {
                  isNumber = true
                }

                return (
                  <td
                    key={`table-td_${nanoid()}`}
                    className={isNumber ? styles.number : ''}
                  >
                    {cell}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Table
